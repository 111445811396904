import { ILayoutTypeElement } from '@/interfaces/element';

export function getElementsForRender(elements: ILayoutTypeElement[]): (ILayoutTypeElement & { key: string })[] {
  return elements
    .filter((element) => {
      if (
        'properties' in element &&
        typeof element.properties !== 'undefined' &&
        'hide' in element.properties &&
        typeof element.properties.hide !== 'undefined'
      ) {
        return !element.properties.hide;
      }

      return true;
    })
    .map((element, elementKey) => {
      return {
        ...element,
        key:
          elementKey +
          element.type +
          (element.properties &&
          'name' in element.properties &&
          element.properties.name &&
          Array.isArray(element.properties.name)
            ? element.properties.name.join('-')
            : '')
      };
    });
}
