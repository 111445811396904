import { UAParser } from 'ua-parser-js';

export function isDevice(device: string) {
  return new UAParser(navigator.userAgent).getDevice().type === device;
}

export function getDevice(): string {
  switch (new UAParser(navigator.userAgent).getDevice().type) {
    case 'mobile':
      return 'mobile';

    case 'tablet':
      return 'tablet';

    default:
      return 'desktop';
  }
}
