<template>
  <div
    class="skeleton-loader"
    :class="[
      `skeleton-loader--type-${type}`,
      size ? `skeleton-loader--size-${size}` : undefined,
      { 'skeleton-loader--has-size': size }
    ]"
  >
    <div v-if="type === 'image'" class="skeleton-loader__image">
      <div class="skeleton-loader__shine"></div>

      <div class="skeleton-loader__measure">
        <slot>
          <p-paragraph>&nbsp;</p-paragraph>
        </slot>
      </div>
    </div>
    <p-container v-else>
      <div v-for="index in lines" :key="index" class="skeleton-loader__text">
        <div class="skeleton-loader__shine"></div>

        <div class="skeleton-loader__measure">
          <slot>
            <p-paragraph>&nbsp;</p-paragraph>
          </slot>
        </div>
      </div>
    </p-container>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PropType } from 'vue';

@Component
export default class SkeletonLoader extends Vue {
  @Prop({ type: Number, required: false, default: 1, validator: (value: number) => value <= 10 })
  public readonly lines!: number;

  @Prop({
    type: String as PropType<'text' | 'image'>,
    required: false,
    default: 'text',
    validator: (value: string) => ['text', 'image'].includes(value)
  })
  public readonly type!: 'text' | 'image';

  @Prop({
    type: String as PropType<'extra-small' | 'small' | 'medium' | 'large' | 'extra-large'>,
    required: false,
    default: undefined,
    validator: (value: string) => ['extra-small', 'small', 'medium', 'large', 'extra-large'].includes(value)
  })
  public readonly size!: 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large';
}
</script>

<style lang="scss">
.skeleton-loader {
  display: inline-block;
  width: 100%;

  &--has-size {
    width: auto;
  }

  &__image,
  &__text {
    border-radius: 6px;
    background-color: var(--color-base-300);
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    opacity: 0.3;
  }

  &__shine {
    animation: loading 1.5s infinite;
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.3), transparent);
  }

  &__measure {
    pointer-events: none;
    visibility: hidden;
  }

  &--type-image {
    &.skeleton-loader {
      &--size-extra-small {
        width: var(--skeleton-image-extra-small);
        height: var(--skeleton-image-extra-small);
      }

      &--size-small {
        width: var(--skeleton-image-small);
        height: var(--skeleton-image-small);
      }

      &--size-medium {
        width: var(--skeleton-image-medium);
        height: var(--skeleton-image-medium);
      }

      &--size-large {
        width: var(--skeleton-image-large);
        height: var(--skeleton-image-large);
      }

      &--size-extra-large {
        width: var(--skeleton-image-extra-large);
        height: var(--skeleton-image-extra-large);
      }
    }
  }

  &--size-extra-small {
    .skeleton-loader__text {
      width: var(--field-width-extra-small);
    }
  }

  &--size-small {
    .skeleton-loader__text {
      width: var(--field-width-small);
    }
  }

  &--size-medium {
    .skeleton-loader__text {
      width: var(--field-width-medium);
    }
  }

  &--size-large {
    .skeleton-loader__text {
      width: var(--field-width-large);
    }
  }

  &--size-extra-large {
    .skeleton-loader__text {
      width: var(--field-width-extra-large);
    }
  }
}

@keyframes loading {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}
</style>
