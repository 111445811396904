<template>
  <div class="chart" :class="{ 'chart--loading': loading }">
    <canvas ref="canvas" class="chart__render"></canvas>

    <div class="chart__loader" v-if="loading">
      <p-loading />
    </div>
  </div>
</template>

<script lang="ts">
import { Chart, ChartConfiguration, ChartDataset } from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { PropType } from 'vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ChartAreaDataSet } from './types';

@Component({
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly loading!: boolean;

  @Prop({ type: Array as PropType<ChartAreaDataSet[]>, required: false, default: undefined })
  public readonly dataSets?: ChartAreaDataSet[];

  private chartInstance: Chart<'line'> | null = null;
  private chartContext2d: CanvasRenderingContext2D | null = null;

  public mounted() {
    if (this.$refs.canvas instanceof HTMLCanvasElement) {
      this.chartContext2d = this.$refs.canvas.getContext('2d');
    }

    this.draw();
  }

  @Watch('dataSets', { deep: true })
  public onDataChange() {
    this.draw();
  }

  public draw() {
    if (this.chartContext2d === null) {
      return;
    }

    const datasets: ChartDataset<'line'>[] =
      this.dataSets?.map<ChartDataset<'line'>>((dataSet) => {
        return {
          backgroundColor: dataSet.color,
          borderColor: dataSet.color,
          label: dataSet.text,
          data: dataSet.data.map((dataPoint) => {
            return {
              x: dataPoint.date,
              y: dataPoint.value
            };
          })
        };
      }) ?? [];

    const totalDataSetItems =
      this.dataSets?.reduce((acc, dataSet) => {
        return acc + dataSet.data.length;
      }, 0) ?? 0;

    const chartOptions: ChartConfiguration<'line'> = {
      type: 'line',
      data: {
        datasets: datasets
      },
      options: {
        plugins: {
          tooltip: {
            position: 'nearest',
            mode: 'nearest'
          },
          legend: {
            display: false
          }
        },
        elements: {
          line: {
            tension: 0,
            borderColor: '#000000'
          }
        },
        interaction: {
          intersect: false
        },
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          mode: 'nearest',
          intersect: false
        },
        scales: {
          x: {
            display: totalDataSetItems > 0,
            grid: {
              drawOnChartArea: true
            },
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'dd-MM-yyyy'
              },
              tooltipFormat: 'dd-MM-yyyy'
            }
          },
          y: {
            display: true
          }
        }
      }
    };

    if (this.chartInstance === null) {
      this.chartInstance = new Chart(this.chartContext2d, chartOptions);
    } else {
      this.chartInstance.data.labels = chartOptions.data?.labels;
      this.chartInstance.data.datasets = chartOptions.data?.datasets;
      this.chartInstance.options = chartOptions.options ?? {};
      this.chartInstance.update();
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 375px;
  position: relative;

  &__render {
    width: 100%;
    height: 100%;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &--loading {
    > .chart__render {
      opacity: 0.5;
    }
  }
}
</style>
