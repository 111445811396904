<template>
  <div class="divider"></div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false
})
export default class extends Vue {}
</script>

<style lang="scss" scoped>
@import '../../scss/mixins/typography';

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--color-border-decorative);
}
</style>
