import { Heading } from '@tiptap/extension-heading';

export const CustomHeading = Heading.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      allAttributes: {
        default: {},
        parseHTML: (element) => {
          const attributes: Record<string, string> = {};
          element.getAttributeNames().forEach((attr) => {
            const value = element.getAttribute(attr);
            if (value !== null) {
              attributes[attr] = value;
            }
          });
          return attributes;
        },
        renderHTML: (attributes) => {
          const allAttributes: Record<string, string> = {};
          Object.keys(attributes.allAttributes || {}).forEach((attr) => {
            allAttributes[attr] = attributes.allAttributes[attr];
          });
          return allAttributes;
        }
      }
    };
  },

  parseHTML() {
    return this.options.levels.map((level) => ({
      tag: `h${level}`,
      getAttrs: (node) => {
        const allAttributes: Record<string, string> = {};

        node.getAttributeNames().forEach((attr) => {
          const value = node.getAttribute(attr);
          if (value !== null) {
            allAttributes[attr] = value;
          }
        });

        return { allAttributes, level };
      }
    }));
  },

  renderHTML({ node, HTMLAttributes }) {
    const { level } = node.attrs;

    const attrs = {
      ...HTMLAttributes,
      ...HTMLAttributes.allAttributes
    };

    // Remove 'allAttributes' to avoid duplication in the final output
    delete attrs.allAttributes;

    return [`h${level}`, attrs, 0];
  }
});
