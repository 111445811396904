<template>
  <div class="switch" :class="{ 'switch--on': modelValue, 'switch--disabled': disabled }">
    <div class="switch__display" @click="toggle()"></div>
    <p-label v-if="label" :disabled.prop="disabled" @click="toggle()">{{ label }}</p-label>
    <p-help-text v-if="helpText" :in-tooltip.prop="helpInTooltip" :disabled.prop="disabled">{{ helpText }}</p-help-text>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: Boolean, required: true }) public readonly modelValue!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly helpInTooltip!: boolean;

  public toggle() {
    if (this.disabled) {
      return;
    }

    this.$emit('update:modelValue', !this.modelValue);
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/mixins/typography';

.switch {
  display: inline-flex;
  align-items: center;
  gap: var(--gap-size-small);

  &__display {
    width: 28px;
    height: 18px;
    position: relative;

    border-radius: 9px;
    border: 1px solid var(--field-color-border-default);
    background: var(--field-color-background-default-inverted);
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      position: absolute;
      left: -1px;
      top: -1px;

      border-radius: 50%;
      border: 1px solid var(--field-color-border-default);
      background: var(--field-color-background-default);
      box-sizing: border-box;
    }
  }

  &--on {
    > .switch__display {
      background: var(--color-brand-primary);

      &:before {
        left: auto;
        right: -1px;
      }
    }
  }

  &--disabled {
    > .switch__display {
      cursor: not-allowed;
      border-color: var(--field-color-border-disabled);
      background: var(--field-color-background-disabled);

      &:before {
        border-color: var(--field-color-border-disabled);
        background: var(--field-color-background-default);
      }
    }
  }
}
</style>
