<template>
  <p-form-element
    :size="size"
    :label="label"
    :help-text="helpText"
    :readonly="readonly"
    :disabled="disabled"
    :helpInTooltip="helpInTooltip"
    :error="error"
  >
    <p-row gap-size="small">
      <div class="input input--size">
        <p-input
          type="number"
          :value="inputValue"
          :disabled.prop="disabled"
          :readonly.prop="readonly"
          :placeholder="placeholder"
          :min="max ? 0 : undefined"
          :max="max"
          :error.prop="!!error"
          :append="units.length === 1 ? units[0] : undefined"
          @keypress="$emit('keypress', $event)"
          @keydown="$emit('keydown', $event)"
          @change="handleInputEvent"
        />
      </div>

      <p-tabs v-if="units.length > 1">
        <p-tabs-item
          v-for="tab in tabs"
          :key="tab.id"
          :text="tab.label"
          :disabled.prop="disabled || readonly"
          :selected.prop="selectedUnit === tab.id"
          @select="handleTabEvent(tab.id)"
        />
      </p-tabs>
    </p-row>
  </p-form-element>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PropType } from 'vue';

@Component({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: [String, Number], required: false, default: '' }) public readonly modelValue!: string | number;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly readonly!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly placeholder?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly error?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly helpInTooltip?: boolean;
  @Prop({ type: Number, required: false, default: undefined }) public readonly max?: string;
  @Prop({
    type: String as PropType<'small' | 'medium'>,
    default: 'small',
    required: false,
    validator: (value: string) => ['small', 'medium'].includes(value)
  })
  public readonly size!: 'small' | 'medium';

  @Prop({
    type: Array as PropType<string[]>,
    required: false,
    default: () => ['px', 'vh', '%']
  })
  public readonly units!: string[];

  public lastUnit = this.units && this.units.length > 0 && this.units[0] !== '' ? this.units[0] : 'px';

  public get inputValue() {
    const value = this.modelValue.toString();

    // Use a regular expression to extract the first numeric part of the string
    // This regex matches any leading numbers, possibly including decimals
    const numericValue = value.match(/^-?\d+\.?\d*/);

    // Return the numeric part as a number, or return an empty string if no numeric part was found
    return numericValue ? Number(numericValue[0]) : '';
  }

  public get selectedUnit() {
    // if there is only one unit, return it
    if (this.units.length === 1) {
      this.lastUnit = this.units[0];
      return this.lastUnit;
    }

    const currentUnit = this.units.find((unit) => this.modelValue.toString().includes(unit));

    if (currentUnit) {
      this.lastUnit = currentUnit;
    }

    return this.lastUnit;
  }

  public get tabs() {
    return this.units.map((unit) => ({ id: unit, label: unit }));
  }

  public handleInputEvent(evt: CustomEvent<(number | string)[]>) {
    if (evt.detail[0] === '') {
      this.$emit('update:modelValue', '');
      return;
    }

    this.$emit('update:modelValue', `${Number(evt.detail[0])}${this.selectedUnit}`);
  }

  public handleTabEvent(value: string) {
    let inputValue = this.inputValue;

    if (this.modelValue === '') {
      inputValue = '0';
    }

    this.$emit('update:modelValue', `${inputValue}${value}`);
  }
}
</script>

<style lang="scss" scoped>
.input {
  flex: 1;
}
</style>
