<template>
  <div class="stat-box-inverted">
    <div class="stat-box-inverted__left">
      <p-headline size="3" v-if="headline">{{ headline }}</p-headline>

      <slot name="left" />
    </div>

    <div class="stat-box-inverted__right" v-if="$slots.right">
      <div class="stat-box-inverted__right-chart">
        <slot name="right" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class extends Vue {
  @Prop({ type: String, required: true }) public readonly headline!: string;
}
</script>

<style lang="scss" scoped>
@import '../../../scss/mixins/typography';
@import '../../../scss/mixins/devices';

.stat-box-inverted {
  display: flex;
  width: 100%;
  min-height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--gap-size-medium);
  background: var(--color-background-layer-1-inverted);
  color: var(--text-color-default-inverted);

  --text-color-headline: var(--text-color-headline-inverted);
  --text-color-default: var(--text-color-default-inverted);
  --loading-border-color: var(--text-color-default-inverted);

  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-size-large);
    padding: var(--base-size-500) var(--base-size-100) var(--base-size-1000) var(--base-size-700);
  }

  &__right {
    display: flex;
    width: 225px;
    justify-content: center;
    align-items: center;
    padding-right: var(--base-size-700);
    padding-bottom: var(--base-size-1000);
    align-self: flex-end;

    &-chart {
      width: 100%;
    }
  }
}

@include for-mobile-only {
  .stat-box-inverted {
    &__right {
      display: none;
    }
  }
}
</style>
