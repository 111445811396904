import { defineStore } from 'pinia';
import { UserResource } from '@/types/api/user';

interface IUsersStore {
  users: UserResource[];
}

export const useUsersStore = defineStore('users', {
  state: (): IUsersStore => ({ users: [] })
});
