<template>
  <p-form-input
    :useMask="element.properties.mask"
    :label="element.properties.label"
    :help-text="element.properties.help"
    :help-in-tooltip="element.properties.helpInTooltip"
    :type="element.properties.type"
    :placeholder="element.properties.placeholder"
    :disabled="element.properties.disabled"
    :size="element.properties.size"
    :readonly="element.properties.readonly"
    :max-length="element.properties.maxLength"
    :error="element.properties.errors.join(', ')"
    :autofocus="element.properties.autofocus"
    v-model="element.properties.value"
    :prepend="element.properties.prepend"
    :append="element.properties.append"
    :ai-assistant="element.properties.aiAssistant"
    @blur="onBlur"
    @keydown="onKeydown"
    @keypress="onInteract"
    @keyup="onInteract"
    @update:modelValue="onInteract"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementTextField } from '@/interfaces/element';
import { debounce } from 'lodash-decorators';
import { Trigger } from '@/Trigger';
import { Iframe } from '@/iframe';
import { autobind } from 'core-decorators';
import { cancelBlueprintUpdate } from '@/utility';

@Component({
  name: 'layout-element-text-field'
})
export default class extends Vue {
  @Prop() public element!: IElementTextField;

  private didInteract = false;
  private didInteractTimer: number | null = null;

  @autobind
  onInteract() {
    this.didInteract = true;

    // Cancel existing blueprint calls so that a update doesn't come through if user types a bit slow
    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      cancelBlueprintUpdate(this.$el);
    }

    if (this.didInteractTimer !== null) {
      clearTimeout(this.didInteractTimer);
    }

    this.didInteractTimer = setTimeout(() => {
      this.didInteract = false;
      this.didInteractTimer = null;
    }, 500);
  }

  onBlur() {
    if (
      this.element.properties.mask &&
      (this.element.properties.value + '').length !== this.element.properties.mask.length
    ) {
      this.element.properties.value = '';
    }
  }

  @Watch('element.properties.value')
  onFastValueChange(newValue: number) {
    this.validateNumber(newValue);

    if (this.element.properties.name) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }
  }

  @Watch('element.properties.value')
  @debounce(375)
  onValueChange(newValue: string) {
    if (this.didInteract) {
      this.$el.dispatchEvent(
        new CustomEvent('BLUEPRINT_INTERACT', {
          bubbles: true,
          composed: true
        })
      );
    }

    if (this.didInteract && this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(this.element.properties.trigger, this.$el, this.element.properties.name, newValue);
    }
  }

  onKeydown(e: KeyboardEvent) {
    this.onInteract();

    if (e.key === 'Enter') {
      this.$el.dispatchEvent(
        new CustomEvent('BLUEPRINT_SUBMIT', {
          bubbles: true,
          composed: true
        })
      );
      return;
    }
  }

  @debounce(50)
  validateNumber(newValue: number) {
    if (this.element.properties.type && this.element.properties.type === 'number') {
      const value = parseInt(newValue + '', 10);

      if (typeof this.element.properties.min === 'number' && value < this.element.properties.min) {
        this.element.properties.value = this.element.properties.min;
      }

      if (typeof this.element.properties.max === 'number' && value > this.element.properties.max) {
        this.element.properties.value = this.element.properties.max;
      }
    }
  }
}
</script>
