<template>
  <p-dictionary :items="element.properties.items" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementDictionary } from '@/interfaces/element';

@Component({
  name: 'layout-element-dictionary'
})
export default class extends Vue {
  @Prop() public element!: IElementDictionary;
}
</script>
