import Vue from 'vue';

const desktopListeners: HTMLElement[] = [];
const mobileListeners: HTMLElement[] = [];

// Mobile listener
const mobileQueryListener = window.matchMedia('(max-width: 750px)');
mobileQueryListener.addEventListener('change', (e) => {
  if (e.matches) {
    mobileListeners.forEach((listener) => {
      listener.style.display = '';
    });

    desktopListeners.forEach((listener) => {
      listener.style.display = 'none';
    });
  } else {
    mobileListeners.forEach((listener) => {
      listener.style.display = 'none';
    });

    desktopListeners.forEach((listener) => {
      listener.style.display = '';
    });
  }
});

Vue.directive('device-desktop', {
  inserted: function (el: HTMLElement) {
    if (!desktopListeners.includes(el)) {
      desktopListeners.push(el);

      if (mobileQueryListener.matches) {
        el.style.display = 'none';
      }
    }
  },
  unbind: function (el: HTMLElement) {
    desktopListeners.splice(desktopListeners.indexOf(el), 1);
  }
});

Vue.directive('device-mobile', {
  inserted: function (el: HTMLElement) {
    if (!mobileListeners.includes(el)) {
      mobileListeners.push(el);

      if (!mobileQueryListener.matches) {
        el.style.display = 'none';
      }
    }
  },
  unbind: function (el: HTMLElement) {
    mobileListeners.splice(mobileListeners.indexOf(el), 1);
  }
});
