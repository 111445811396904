<template>
  <p-form-tags-input
    :disabled="element.properties.disabled"
    :label="element.properties.label"
    :help-text="element.properties.help"
    :error="element.properties.errors.join(', ')"
    :size="element.properties.size"
    :model-value="value"
    @update:modelValue="onUpdate"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementTagsInput } from '@/interfaces/element';
import { Iframe } from '@/iframe';
import { Trigger } from '@/Trigger';

@Component({
  name: 'layout-element-tags-input'
})
export default class extends Vue {
  @Prop() public element!: IElementTagsInput;

  public get value(): string[] {
    if (Array.isArray(this.element.properties.value)) {
      return this.element.properties.value;
    }

    if (typeof this.element.properties.value === 'string' && this.element.properties.value !== '') {
      return this.element.properties.value.split(',');
    }

    return [];
  }

  public onUpdate(value: string[]) {
    if (this.element.properties.useArray) {
      this.element.properties.value = value;
    } else {
      this.element.properties.value = value.join(',');
    }

    if (this.element.properties.name) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }

    this.$el.dispatchEvent(
      new CustomEvent('BLUEPRINT_INTERACT', {
        bubbles: true,
        composed: true
      })
    );

    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(this.element.properties.trigger, this.$el, this.element.properties.name);
    }
  }
}
</script>
