<template>
  <div class="device-preview">
    <div class="device-preview__desktop">
      <div class="device-preview__image">
        <img
          v-if="element.properties?.desktopUrl"
          :src="element.properties.desktopUrl"
          @load="desktopLoading = false"
        />
        <div class="device-preview__loader" v-if="desktopLoading">
          <p-loading size="large" />
        </div>
      </div>
    </div>

    <div class="device-preview__mobile">
      <div class="device-preview__image">
        <img v-if="element.properties?.mobileUrl" :src="element.properties.mobileUrl" @load="mobileLoading = false" />
        <div class="device-preview__loader" v-if="mobileLoading">
          <p-loading size="large" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementDevicePreview } from '@/interfaces/element';

@Component({
  name: 'layout-element-device-preview'
})
export default class extends Vue {
  @Prop() public element!: IElementDevicePreview;
  public desktopLoading = true;
  public mobileLoading = true;
}
</script>

<style lang="scss" scoped>
@import '../../scss/mixins/devices.scss';

.device-preview {
  position: relative;
  margin-bottom: 45px;

  img {
    max-width: 100%;
  }

  &__desktop {
    width: 480px;
    height: 276px;
    background-image: url('@/assets/images/preview-desktop.svg');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 14px 33px 24px 39px;
  }

  &__mobile {
    width: 108px;
    height: 209px;
    background-image: url('@/assets/images/preview-mobile.svg');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 23px 8px 22px 8px;
    position: absolute;
    right: -45px;
    bottom: -36px;
  }

  &__image {
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__loader {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-grey-900);
    display: flex;
    justify-content: center;
    align-items: center;

    --loading-border-color: var(--text-color-default-inverted);
  }
}

@include for-mobile-only {
  .device-preview {
    &__desktop {
      width: 275px;
      height: 158px;
      background-size: contain;
      padding: 8px 21px 20px 22px;
    }

    &__mobile {
      width: 62px;
      height: 120px;
      background-size: contain;
      padding: 13px 5px 13px 5px;
      right: -23px;
      bottom: -18px;
    }
  }
}
</style>
