var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('menu-overlay',{attrs:{"show":_vm.show,"title":"Workspace","body-border":false},on:{"close-request":function($event){return _vm.$emit('close-request')}}},[_c('p-table-client',{attrs:{"searchable":"","searchable-autofocus":"","pagination":"","header":[
      {
        id: 'name',
        label: 'Name',
        width: '400px',
        sortable: true,
        verticalAlign: 'middle'
      },
      {
        id: 'contact_name',
        label: 'Contact name',
        width: '180px',
        sortable: true,
        verticalAlign: 'middle'
      },
      {
        id: 'contact_email',
        label: 'Contact email',
        width: '180px',
        sortable: true,
        verticalAlign: 'middle'
      },
      {
        id: 'actions',
        verticalAlign: 'middle'
      }
    ],"body":_vm.workspaces,"skeleton-loader":_vm.loading},scopedSlots:_vm._u([{key:"bodyCell",fn:function({ header, row, column }){return [(header.id === 'name')?_c('div',{staticClass:"customer-name"},[_vm._v(_vm._s(column))]):(header.id === 'actions')?_c('p-link',{attrs:{"href":`/api/v1/workspace/${row.uuid}/impersonate`}},[_c('p-button',{attrs:{"color-type":"tertiary","size":"small","disabled":row.id === _vm.workSpaceStore.workspace?.id}},[_vm._v("Login")])],1):[_vm._v(_vm._s(column))]]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }