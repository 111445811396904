<template>
  <p-chart-area v-if="element.properties.variant === 'area'" :data-sets="areaDataSet" />
  <p-chart-doughnut
    v-else-if="element.properties.variant === 'doughnut'"
    :data-sets="doughnutDataSet"
    :labels="element.properties.labels"
  />
  <h3 v-else>TODO: Chart missing variant</h3>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementChart } from '@/interfaces/element';
import { ChartAreaData, ChartAreaDataSet, ChartDoughnutDataSet } from '../ui/chart/types';

@Component({
  name: 'layout-element-chart'
})
export default class LayoutElementChart extends Vue {
  @Prop() public element!: IElementChart;

  get doughnutDataSet(): ChartDoughnutDataSet[] {
    const datasets: ChartDoughnutDataSet[] = [];

    if (Array.isArray(this.element.properties.data) && Array.isArray(this.element.properties.colors)) {
      datasets.push({
        colors: this.element.properties.colors,
        data: this.element.properties.data
      });
    }

    return datasets;
  }

  get areaDataSet(): ChartAreaDataSet[] {
    const datasets: ChartAreaDataSet[] = [];
    const colors =
      !Array.isArray(this.element?.properties?.colors) && typeof this.element?.properties?.colors === 'object'
        ? this.element.properties.colors
        : {};

    if (Array.isArray(this.element.properties.labels)) {
      this.element.properties.labels?.forEach((label, labelId) => {
        const data: ChartAreaData[] = [];

        if (typeof this.element.properties.data === 'object') {
          for (const date in this.element.properties.data) {
            if (Object.prototype.hasOwnProperty.call(this.element.properties.data, date)) {
              const dateItem = this.element.properties.data[Number(date)];

              if (typeof dateItem === 'object' && typeof dateItem[`${labelId}`] !== 'undefined') {
                data.push({
                  date: parseInt(date, 10),
                  value: dateItem[`${labelId}`]
                });
              }
            }
          }
        }

        const dataset: ChartAreaDataSet = {
          text: label,
          color: colors[labelId] ?? '#000000',
          data
        };

        return dataset;
      });
    }

    return datasets;
  }
}
</script>
