<template>
  <layout-element-blueprint v-if="currentPath" :url="currentPath" />
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import LayoutElement from '@/components/LayoutElement.vue';
import LayoutElementBlueprint from '@/components/elements/LayoutElementBlueprint.vue';
import { EventBus } from '@/main';

@Component({
  components: { LayoutElementBlueprint, LayoutElement },
  name: 'page'
})
export default class Page extends Vue {
  public currentPath = '';

  public created() {
    EventBus.$on('blueprint-response-url', (url: string) => {
      url = '/' + url.replace(/(https?:\/\/[-a-z.:0-9]+)\//gi, '').replace(/^mobile\//i, '');

      if (this.currentPath !== url && this.$route.path !== url) {
        this.$router.push(url);
      }
    });
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(newValue: Route) {
    if (!newValue.query && newValue.path === this.currentPath) {
      return;
    }

    const queryString = [];

    if (newValue.query) {
      for (const queryName in newValue.query) {
        queryString.push(queryName + '=' + encodeURIComponent(newValue.query[`${queryName}`] + ''));
      }
    }

    if (typeof window.dataLayer !== 'undefined') {
      window.dataLayer.push({
        event: 'virtualPageView'
      });
    }

    this.currentPath = newValue.path + (queryString.length > 0 ? '?' + queryString.join('&') : '');
  }
}
</script>
