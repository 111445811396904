<template>
  <div class="device-switch">
    <p-section
      v-if="selectedTab"
      :headline="selectedTab.properties.title"
      :description="selectedTab.properties.description"
      :headline-size="titleSize"
      :gap-size="selectedTab.properties.gapSize"
    >
      <p-tabs v-if="tabs.length > 1 && !tabsHasLabel" slot="toolbar">
        <p-tabs-item
          v-for="tab in tabs"
          :key="tab.id"
          :size="element.properties.size ?? 'large'"
          :text="tab.text"
          :icon="tab.icon"
          :disabled.prop="tab.disabled"
          :error.prop="tab.error"
          :selected.prop="element.properties.value === tab.id"
          @select="onTabChange(tab.id)"
        />
      </p-tabs>

      <p-select
        v-else-if="tabs.length > 1 && tabsHasLabel"
        slot="toolbar"
        @select="onTabChange($event.detail[0])"
        class="device-switch__selection"
      >
        <p-select-option
          v-for="tab in tabs"
          :key="tab.id"
          :value="tab.id"
          :selected.prop="element.properties.value === tab.id"
          >{{ tab.text ? tab.text.substring(0, 1).toUpperCase() + tab.text.substring(1) : '' }}</p-select-option
        >
      </p-select>

      <layout-element v-for="child in selectedTabChildren" :element="child" :key="child.key" />
    </p-section>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementDeviceSwitch, IElementSection, IElementTabsItem } from '@/interfaces/element';
import { Iframe } from '@/iframe';
import { getElementsForRender, traverseElements } from '@/utility';

@Component({
  name: 'layout-element-device-switch'
})
export default class extends Vue {
  @Prop() public element!: IElementDeviceSwitch;

  public get selectedTab(): IElementSection | undefined {
    const selectedTab = this.element.properties.tabs.find((tab) => {
      return this.element.properties.value === tab.id;
    });

    if (
      selectedTab &&
      selectedTab.content &&
      selectedTab.content.length > 0 &&
      selectedTab.content[0].type === 'section'
    ) {
      return selectedTab.content[0];
    }

    // Default to first tab
    if (
      !selectedTab &&
      this.element.properties.tabs[0] &&
      this.element.properties.tabs[0].content &&
      this.element.properties.tabs[0].content.length > 0 &&
      this.element.properties.tabs[0].content[0].type === 'section'
    ) {
      return this.element.properties.tabs[0].content[0];
    }

    return undefined;
  }

  public get selectedTabChildren() {
    return getElementsForRender(this.selectedTab?.children ?? []);
  }

  public onTabChange(tab: string) {
    Vue.set(this.element.properties, 'value', tab);

    Iframe.emit('frame-form-action', {
      type: 'deviceChange',
      device: tab
    });
  }

  public get titleSize(): string | undefined {
    if (this.selectedTab?.properties.heading !== undefined) {
      return String(this.selectedTab.properties.heading);
    }

    return undefined;
  }

  public get tabs(): IElementTabsItem[] {
    return this.element.properties.tabs.map<IElementTabsItem>((tab) => {
      let error = false;

      if (tab.content) {
        traverseElements(tab.content, (element) => {
          if (
            element.properties &&
            'errors' in element.properties &&
            element.properties.errors &&
            element.properties.errors.length > 0
          ) {
            error = true;
          }
        });
      }

      tab.error = error;

      return tab;
    });
  }

  public get tabsHasLabel(): boolean {
    return this.tabs.some((tab) => !!tab.text);
  }
}
</script>

<style lang="scss" scoped>
.device-switch {
  width: 100%;

  &__selection {
    width: 200px;
  }
}
</style>
