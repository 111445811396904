<template>
  <div ref="linkMenu" class="bubble-menu" style="display: none" @mousedown.prevent @mouseup.prevent.stop>
    <div v-if="computedToolbar.includes('link')">
      <div v-if="showLinkButton">
        <p-button color-type="tertiary" icon="link" @click="triggerOpenLinkModal">Link...</p-button>
      </div>
      <div v-else-if="showActiveLinkOptions">
        <p-button color-type="tertiary" icon="link" @click="triggerOpenLinkModal">Link...</p-button>
        <p-button color-type="tertiary" icon="trash" @click="removeLink">Remove link</p-button>
        <p-button color-type="tertiary" icon="external-link" @click="openLink">Open link</p-button>
      </div>
      <div v-if="showImageButton">
        <p-button color-type="tertiary" icon="image" @click="openImageDetailsModal">Image...</p-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';

@Component
export default class EditorBubbleMenu extends Vue {
  @Prop({ type: Object, required: true }) public readonly editor!: Editor;
  @Prop({ type: Array, required: true }) public readonly computedToolbar!: string[];

  private floatingElement: HTMLElement | null = null;
  private editorElement: HTMLElement | null = null;

  public mounted() {
    this.initializeFloatingUI();
  }

  public beforeDestroy() {
    if (this.editorElement) {
      this.editorElement.removeEventListener('contextmenu', this.showMenu);
    }
  }

  public get showLinkButton(): boolean {
    return !this.editor.isActive('link') && (this.editor.isActive('paragraph') || this.editor.isActive('heading'));
  }

  public get showActiveLinkOptions(): boolean {
    return this.editor.isActive('link');
  }

  public get showImageButton(): boolean {
    return this.computedToolbar.includes('mediaSelector') && this.editor.isActive('image');
  }

  private initializeFloatingUI() {
    const linkMenu = this.$refs.linkMenu as HTMLElement;
    const editorElement = this.editor.options.element as HTMLElement;

    if (linkMenu && editorElement) {
      this.floatingElement = linkMenu;
      this.editorElement = editorElement;

      this.editorElement.addEventListener('contextmenu', this.showMenu);
    }
  }

  private showMenu = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    // seems to work better than nextTick... not sure why nextTick doesn't work here
    setTimeout(() => this.updateLinkMenuVisibility(event), 0);
  };

  private updateLinkMenuVisibility(event: MouseEvent) {
    if (this.floatingElement) {
      this.floatingElement.style.left = `${event.clientX}px`;
      this.floatingElement.style.top = `${event.clientY}px`;
      this.floatingElement.style.position = 'fixed';
      this.floatingElement.style.display = 'flex';
    }
  }

  public removeLink() {
    if (this.editor.isActive('link')) {
      this.editor.commands.unsetLink();
    }
  }

  public openLink() {
    if (this.editor.isActive('link')) {
      const linkAttributes = this.editor.getAttributes('link');
      const url = linkAttributes.href;

      if (url) {
        window.open(url, '_blank');
      }
    }
  }

  public triggerOpenLinkModal() {
    this.$emit('triggerOpenLinkModal');
  }

  public openImageDetailsModal() {
    this.$emit('openImageDetailsModal');
  }
}
</script>

<style lang="scss">
.bubble-menu {
  display: flex;
  z-index: 1000;
  flex-direction: column;
  position: fixed;
  padding: 5px 9px;
  border-radius: 4px;
  max-width: 200px;
  color: #26323d;
  box-shadow: 0 0 20px 4px #9aa1b126, 0 4px 80px -8px #24282f40, 0 4px 4px -2px #5b5e6926;
  background-color: #fff;

  > div > div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
</style>
