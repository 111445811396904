// eslint-disable-next-line security/detect-unsafe-regex
export const RGB_COLOR_REGEX = /\((\d+),\s*(\d+),\s*(\d+)(,\s*(\d*.\d*))?\)/;

export class Color {
  public r: number | undefined;
  public g: number | undefined;
  public b: number | undefined;
  public a: number | undefined;

  constructor(colorStr?: string) {
    if (typeof colorStr === 'string') {
      colorStr = colorStr.trim();
      if (colorStr.indexOf('#') === 0) {
        colorStr = colorStr.substr(colorStr.indexOf('#') + 1);
        this.r = parseInt(colorStr.substr(0, 2), 16);
        this.g = parseInt(colorStr.substr(2, 2), 16);
        this.b = parseInt(colorStr.substr(4, 2), 16);
      } else if (colorStr.indexOf('rgb') === 0) {
        const res = RGB_COLOR_REGEX.exec(colorStr);

        if (res) {
          this.r = parseInt(res[1], 10);
          this.g = parseInt(res[2], 10);
          this.b = parseInt(res[3], 10);
          this.a = res[5] ? parseFloat(res[5]) : 1;
        }
      }
    }
  }

  toHex() {
    if (typeof this.r === 'undefined' || typeof this.g === 'undefined' || typeof this.b === 'undefined') {
      return null;
    }

    let r = this.r.toString(16);
    let g = this.g.toString(16);
    let b = this.b.toString(16);

    if (r.length === 1) {
      r = '0' + r;
    }

    if (g.length === 1) {
      g = '0' + g;
    }

    if (b.length === 1) {
      b = '0' + b;
    }

    return '#' + r + g + b;
  }

  toRgb() {
    return `rgb(${this.r}, ${this.g}, ${this.b})`;
  }

  toRgba() {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
  }
}
