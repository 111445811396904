<template>
  <div class="swiper-container">
    <div ref="swiper" class="swiper">
      <div class="swiper-wrapper">
        <div v-for="slide in slides" :key="slide.id" class="swiper-slide">
          <img :src="slide.image" />
          <div class="slide-content">
            <p-headline v-if="slide.headline" size="2">{{ slide.headline }}</p-headline>
            <p-paragraph v-if="slide.paragraph">{{ slide.paragraph }}</p-paragraph>
            <p-link :href="`${slide.buttonLink}`" target="_blank">
              <p-button>{{ slide.buttonText }}</p-button>
            </p-link>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import Swiper from 'swiper';
import { Pagination, Autoplay } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { PropType } from 'vue';

export interface SlideData {
  id: number;
  image: string;
  headline?: string;
  paragraph?: string;
  buttonText?: string;
  buttonLink?: string;
}

@Component({})
export default class extends Vue {
  @Prop({ type: Array as PropType<SlideData[]>, required: true }) slides!: Array<SlideData>;

  mounted() {
    new Swiper(this.$refs.swiper as HTMLElement, {
      // configure Swiper to use modules
      modules: [Pagination, Autoplay],
      // Optional parameters
      loop: true,

      autoplay: {
        delay: 5000
      },

      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    });
  }
}
</script>

<style lang="scss" scoped>
/* https://github.com/nolimits4web/swiper/issues/3599#issuecomment-1290283431 */
.swiper-container {
  display: grid;
  background: var(--color-grey-900);
  min-width: 240px;
}

.swiper-container .swiper {
  max-width: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.65;
}

.slide-content {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-size-small);
  position: absolute;
  flex-shrink: 0;
  text-align: start;
  width: 100%;

  // overwrites p and h2 color
  --text-color-default: var(--color-base-50);
  --text-color-headline: var(--color-base-50);
}
</style>
