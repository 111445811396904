<template>
  <p-form-element :disabled="disabled" :label="label" :help-text="helpText" :error="error" error-position="top">
    <p-row align-items="stretch" :justify-content="justifyContent">
      <p-card
        v-for="card in cards"
        :key="card.value"
        :icon="card.icon"
        :error.prop="card.error || !!error"
        :image="card.image"
        :image-rotation="card.imageRotation"
        :image-width="card.imageWidth"
        :image-overlay="card.imageOverlay"
        :headline="card.headline"
        :description="card.description"
        :selected.prop="card.value === modelValue || (Array.isArray(modelValue) && modelValue.includes(card.value))"
        :disabled.prop="card.disabled || disabled"
        can-select
        @click="selectCard(card)"
      />
    </p-row>
  </p-form-element>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CardItem } from './types';

@Component({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: [String, Array], required: false, default: undefined }) public readonly modelValue?:
    | string
    | Array<string>;

  @Prop({ type: Array as PropType<CardItem[]>, required: true }) public readonly cards!: CardItem[];
  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly error?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled?: boolean;

  @Prop({
    type: String as PropType<
      'space-between' | 'space-around' | 'space-evenly' | 'end' | 'start' | 'center' | 'stretch'
    >,
    required: false,
    default: 'start',
    validator: (value: string) => {
      return ['space-between', 'space-around', 'space-evenly', 'end', 'start', 'center', 'stretch'].includes(value);
    }
  })
  public readonly justifyContent!:
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'end'
    | 'start'
    | 'center'
    | 'stretch';

  public selectCard(card: CardItem) {
    if (card.disabled) {
      return;
    }

    if (Array.isArray(this.modelValue)) {
      if (this.modelValue.includes(card.value)) {
        this.$emit(
          'update:modelValue',
          this.modelValue.filter((value) => value !== card.value)
        );
      } else {
        this.$emit('update:modelValue', [...this.modelValue, card.value]);
      }
    } else {
      this.$emit('update:modelValue', card.value);
    }
  }
}
</script>
