export class Iframe {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static emit(action: string, data?: any) {
    if (this.inIframe()) {
      // nosem
      window.parent.postMessage(
        JSON.stringify({
          type: action,
          url: window.location.href,
          data
        }),
        '*'
      );
    }
  }

  static inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  static valueChange(name: string[], value: any) {
    if (value === true) {
      value = '1';
    }

    if (value === false) {
      value = '0';
    }

    this.emit('frame-form-action', {
      type: 'fieldChange',
      name: name
        .map((name, index) => {
          return index > 0 ? '[' + name + ']' : name;
        })
        .join(''),
      value
    });
  }
}
