<template>
  <p-form-element :disabled="disabled" :error="error" :size="size">
    <p-checkbox
      :model-value="modelValue"
      :disabled="disabled"
      :error="!!error"
      :label="label"
      :help-text="helpText"
      :help-in-tooltip="helpInTooltip"
      @update:modelValue="$emit('update:modelValue', $event)"
    />
  </p-form-element>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: Boolean, required: true }) public readonly modelValue!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly error?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly helpInTooltip?: boolean;

  @Prop({
    type: String as PropType<'extra-small' | 'small' | 'medium' | 'large' | 'extra-large'>,
    default: undefined
  })
  public readonly size?: 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large';
}
</script>
