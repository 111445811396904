<template>
  <layout-template :template="{ name: 'not-found' }" />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import LayoutTemplate from '@/components/LayoutTemplate.vue';

@Component({
  components: { LayoutTemplate },
  name: 'page-not-found'
})
export default class PageNotFound extends Vue {}
</script>
