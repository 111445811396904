<template>
  <div class="login">
    <div class="login__content login__content--left">
      <img class="login__content-logo" :src="logoFront" alt="" />
    </div>

    <div class="login__content">
      <div class="login__content-wrapper">
        <p-container>
          <p-headline v-device-desktop size="3">Login to the Playable platform</p-headline>
          <p-headline v-device-mobile size="4">Login to the Playable platform</p-headline>

          <form v-on:submit.prevent>
            <p-container>
              <slot />
            </p-container>
          </form>
        </p-container>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ILayoutTemplate } from '@/interfaces/template';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logoFront = require('@/assets/images/logo-front.svg');

@Component({
  components: {},
  name: 'template-login'
})
export default class TemplateLogin extends Vue {
  @Prop() public template!: ILayoutTemplate;
  public logoFront = logoFront;
}
</script>

<style lang="scss">
@import '../scss/mixins/devices.scss';

.login {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  min-height: 100vh;
  background-color: #fff;

  &__content {
    display: flex;
    width: 50%;
    min-width: 50%;
    padding: 74px 126px 90px 74px;

    form {
      width: 100%;
    }

    &--left {
      position: relative;
      background: linear-gradient(135deg, rgb(131, 68, 101) 0%, rgb(25, 15, 86) 68%);
      overflow: hidden;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: -50%;
        right: -70%;
        height: 250%;
        width: 150%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='top-blop__inner' xmlns:xlink='http://www.w3.org/1999/xlink' width='1656.999' height='1551.768' viewBox='0 0 1656.999 1551.768'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.529' y1='0.177' x2='0.144' y2='-0.032' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23190f56'/%3E%3Cstop offset='1' stop-color='%23AA4B6A'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath id='Path_90' data-name='Path 90' d='M1313.131-1057.443c-3.245-1.61-6.515-3.144-9.837-4.589a3.011,3.011,0,0,0-.418-.191c-189.8-83.184-395,89.308-344.953,290.986a.2.2,0,0,0,.013.089c27.18,132.908-30.534,275.843-133.742,352.8q-6.255,4.412-12.309,9.09a.012.012,0,0,0-.012.012C542.366-200.732,689.4,236.328,1034.936,234.614q6.125-.037,12.2-.329h.19c.216-.013.443-.026.659-.026C1765.014,200.089,1952.773-749.963,1313.131-1057.443Z' transform='translate(602.155 2016.924) rotate(-60)' fill='url(%23linear-gradient)'/%3E%3C/svg%3E");
        opacity: 0.3;
        z-index: 0;
      }
    }

    &-logo {
      position: relative;
      max-width: 400px;
      margin: 0 auto;
      backface-visibility: hidden;
      animation: popIn 1000ms ease-out 1000ms backwards;
      z-index: 1;
    }

    &-wrapper {
      align-self: center;
      width: 100%;
    }
  }
}

@keyframes popIn {
  from {
    transform: scale(0.7) translateZ(0);
    opacity: 0;
  }

  to {
    transform: scale(1) translateZ(0);
    opacity: 1;
  }
}

@include for-mobile-only {
  .login {
    --field-width-medium: 100%;

    &__content {
      width: 100%;
      padding: 74px 20px 90px 20px;

      &--left {
        display: none;
      }
    }
  }
}
</style>
