import { ITrigger } from '@/interfaces/element';

export class Trigger {
  static handle(trigger: ITrigger, context: Element, name?: string[], value?: any) {
    context.dispatchEvent(
      new CustomEvent('BLUEPRINT_TRIGGER_HANDLE', {
        detail: {
          dueTo: name,
          value,
          trigger
        },
        bubbles: true,
        composed: true
      })
    );
  }
}
