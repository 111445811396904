<template>
  <p-form-textarea
    :label="element.properties.label"
    :help-text="element.properties.help"
    :disabled="element.properties.disabled"
    :placeholder="element.properties.placeholder"
    :max-length="element.properties.maxLength"
    :readonly="element.properties.readonly"
    :size="element.properties.size"
    :error="element.properties.errors.join(', ')"
    :ai-assistant="element.properties.aiAssistant"
    v-model="element.properties.value"
    @keyup="onInteract"
    @update:modelValue="onInteract"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementTextarea } from '@/interfaces/element';
import { Trigger } from '@/Trigger';
import debounce from 'lodash-decorators/debounce';
import { Iframe } from '@/iframe';
import { autobind } from 'core-decorators';
import { cancelBlueprintUpdate } from '@/utility';

@Component({
  name: 'layout-element-textarea'
})
export default class extends Vue {
  @Prop() public element!: IElementTextarea;

  private didInteract = false;
  private didInteractTimer: number | null = null;

  @autobind
  onInteract() {
    this.didInteract = true;

    // Cancel existing blueprint calls so that a update doesn't come through if user types a bit slow
    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      cancelBlueprintUpdate(this.$el);
    }

    if (this.didInteractTimer !== null) {
      clearTimeout(this.didInteractTimer);
    }

    this.didInteractTimer = setTimeout(() => {
      this.didInteract = false;
      this.didInteractTimer = null;
    }, 500);
  }

  @Watch('element.properties.value')
  onFastValueChange(newValue: string | undefined, oldValue: string | undefined) {
    if (!newValue && !oldValue) {
      return;
    }

    if (this.element.properties.name) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }
  }

  @Watch('element.properties.value')
  @debounce(375)
  onValueChange(newValue: string | undefined, oldValue: string | undefined) {
    if ((!newValue && !oldValue) || !this.didInteract) {
      return;
    }

    this.$el.dispatchEvent(
      new CustomEvent('BLUEPRINT_INTERACT', {
        bubbles: true,
        composed: true
      })
    );

    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(this.element.properties.trigger, this.$el, this.element.properties.name, newValue);
    }
  }
}
</script>
