<template>
  <p-form-element :help-text="helpText" :disabled="disabled" :size="size" :helpInTooltip="helpInTooltip">
    <p-positioner
      :modelValue="modelValue"
      :image="image"
      :label="label"
      :canvas="canvas"
      :canvas-height="canvasHeight"
      :canvas-width="canvasWidth"
      :border-radius="borderRadius"
      :title="title"
      :loading="loading"
      :loading-success="loadingSuccess"
      :disabled="disabled"
      @update:modelValue="
        (value) => {
          $emit('update:modelValue', value);
        }
      "
    ></p-positioner>
  </p-form-element>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PropType } from 'vue';
import { PositionerItem } from '@/components/ui/form/Positioner.vue';

@Component({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: Array as PropType<PositionerItem[]>, required: true }) public readonly modelValue?: PositionerItem[];
  @Prop({ type: String, required: false }) public readonly image!: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly loading!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly loadingSuccess!: boolean;
  @Prop({ type: String, required: false }) public readonly title!: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly canvas!: boolean;
  @Prop({ type: Number, required: false, default: null }) public readonly canvasWidth!: number | null;
  @Prop({ type: Number, required: false, default: null }) public readonly canvasHeight!: number | null;
  @Prop({ type: Number, required: false, default: null }) public readonly borderRadius!: number | null;
  @Prop({ type: String, required: false, default: undefined }) public readonly placeholder?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;

  @Prop({ type: Boolean, required: false, default: false }) public readonly helpInTooltip?: boolean;
  @Prop({
    type: String as PropType<'extra-small' | 'small' | 'medium' | 'large' | 'extra-large'>,
    default: 'extra-large'
  })
  public readonly size!: 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large';
}
</script>
