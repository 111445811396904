<template>
  <p-form-element :disabled="disabled" :label="label" :help-text="helpText">
    <p-tabs>
      <p-tabs-item
        v-for="tab in tabs"
        :key="tab.id"
        :text="tab.label"
        :icon="tab.icon"
        :disabled.prop="tab.disabled || disabled"
        :error.prop="tab.error"
        :size="size"
        :selected.prop="tab.id === modelValue"
        @select="$emit('update:modelValue', tab.id)"
      />
    </p-tabs>
  </p-form-element>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TabItem } from './types';

@Component({
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
})
export default class extends Vue {
  @Prop({ type: [Number, String], required: true }) public readonly modelValue!: number | string;
  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: Array as PropType<TabItem[]>, required: true }) public readonly tabs!: TabItem[];
  @Prop({ type: Boolean }) public readonly disabled!: boolean;
  @Prop({
    type: String as PropType<'small' | 'medium' | 'large'>,
    default: 'medium',
    validator: (value: string) => ['small', 'medium', 'large'].includes(value)
  })
  public readonly size!: 'small' | 'medium' | 'large';
}
</script>
