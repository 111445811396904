<template>
  <p-modal-plain
    :ready.prop="contentReady"
    :show.prop="true"
    :headline="headline"
    position="top"
    @close-request="$emit('closePopup')"
  >
    <layout-element-blueprint
      :url="url"
      :ejected="true"
      default-template="popup"
      id="popup"
      @content-ready="contentReady = true"
      @action-close-request="$emit('actionClosePopup')"
    />
  </p-modal-plain>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
  name: 'layout-element-popup',
  inheritAttrs: false
})
export default class LayoutElementPopup extends Vue {
  @Prop({ type: String, required: true }) public readonly url!: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly headline?: string;

  public contentReady = false;
}
</script>

<style lang="scss" scoped>
.popup__iframe {
  min-height: 500px;
}
</style>
