<template>
  <p-form-select
    v-model="selectedBlockType"
    :options="blockTypeOptions"
    title="Block type"
    @update:modelValue="setHeading"
    placeholder="Select block type"
    size="small"
  />
</template>

<script lang="ts">
import { DropdownOption } from '@/components/ui/form/types';

import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';

@Component
export default class EditorBlock extends Vue {
  @Prop({ type: Object, required: true }) public readonly editor!: Editor;

  public selectedBlockType: 'p' | `h${number}` = 'p';

  public blockTypeOptions: DropdownOption[] = [
    { text: 'Paragraph', value: 'p' },
    { text: 'Heading 1', value: 'h1' },
    { text: 'Heading 2', value: 'h2' },
    { text: 'Heading 3', value: 'h3' },
    { text: 'Heading 4', value: 'h4' },
    { text: 'Heading 5', value: 'h5' },
    { text: 'Heading 6', value: 'h6' }
  ];

  public mounted() {
    this.editor.on('selectionUpdate', () => {
      this.updateSelectedBlockType();
    });
  }

  // Update selectedBlockType based on editor's current block type
  public updateSelectedBlockType() {
    if (this.editor) {
      for (let level = 1; level <= 6; level++) {
        if (this.editor.isActive('heading', { level })) {
          this.selectedBlockType = `h${level}`;
          return;
        }
      }
      this.selectedBlockType = 'p'; // Default to paragraph
    }
  }

  // Set heading in the editor based on selected block type
  public setHeading(level: string) {
    if (this.editor) {
      // Store current alignment
      const currentAlignment = this.editor.isActive({ textAlign: 'left' })
        ? 'left'
        : this.editor.isActive({ textAlign: 'center' })
        ? 'center'
        : this.editor.isActive({ textAlign: 'right' })
        ? 'right'
        : '';

      if (level === 'p') {
        this.editor.chain().focus().setParagraph().run();
      } else {
        this.editor
          .chain()
          .focus()
          // @ts-ignore
          .toggleHeading({ level: parseInt(level.replace('h', '')) })
          .run();
      }

      // Reapply text alignment after changing the block type
      this.editor.chain().focus().setTextAlign(currentAlignment).run();

      this.updateSelectedBlockType();
    }
  }
}
</script>
