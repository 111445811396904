import { defineStore } from 'pinia';
import { UserResource } from '@/types/api/user';
import { WorkspaceResource } from '@/types/api/workspace';

export interface IUserStore {
  user?: UserResource;

  origin?: {
    user: UserResource;
    workspace: WorkspaceResource;
  };
}

export const useUserStore = defineStore('user', {
  state: (): IUserStore => ({ user: undefined, origin: undefined })
});
