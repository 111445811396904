import axios from 'axios';

export interface AppConfigInterface {
  intercom?: string;
  analytics?: {
    endPoint: string;
    samEndpoint?: string;
  };
}

export class AppConfigNotFoundError extends Error {}
export class AppConfigInvalidError extends Error {}

export class AppConfig {
  private static data: AppConfigInterface | null = null;

  /**
   * Get application configuration.
   */
  public static async get() {
    if (AppConfig.data !== null) {
      return AppConfig.data;
    }

    try {
      const response = await axios.get(`/config.json?r=${Date.now()}`, {
        timeout: 10 * 1000
      });

      const data = await response.data;

      AppConfig.data = {};

      if (typeof data.intercom !== 'undefined') {
        AppConfig.data.intercom = data.intercom;
      }

      if (typeof data.analytics !== 'undefined') {
        AppConfig.data.analytics = data.analytics;
      }

      return AppConfig.data;
    } catch (e) {
      throw new AppConfigNotFoundError('Unable to locate config.json');
    }
  }
}
