import { Node } from '@tiptap/core';

export const CustomDiv = Node.create({
  name: 'div',

  group: 'block',

  content: 'block*', // Allow block content inside the div

  inline: false, // Div is a block-level element

  addAttributes() {
    return {
      dynamic: {
        default: {},
        parseHTML: (element) => {
          const attributes: Record<string, string> = {};
          element.getAttributeNames().forEach((attr) => {
            const value = element.getAttribute(attr);
            if (value !== null) {
              attributes[attr] = value;
            }
          });
          return attributes;
        },
        renderHTML: (attributes) => attributes.dynamic || {}
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div',
        getAttrs: (node: HTMLElement) => {
          const attributes: any = {};

          // Loop through all attributes of the node and preserve them
          node.getAttributeNames().forEach((attr) => {
            attributes[attr] = node.getAttribute(attr); // Dynamically add all attributes
          });

          return { dynamic: attributes };
        }
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    // Merge dynamic attributes with other HTML attributes
    const attrs = {
      ...HTMLAttributes,
      ...HTMLAttributes.dynamic
    };

    // Remove 'dynamic' to prevent duplication
    delete attrs.dynamic;

    return ['div', attrs, 0];
  }
});
