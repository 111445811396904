<template>
  <p-tooltip
    v-if="element.properties.tooltip"
    :text="element.properties.tooltip.text"
    :position="element.properties.tooltip.position"
  >
    <p-indicator :type="element.properties.type" :no-fill="element.properties.noFill" :size="element.properties.size" />
  </p-tooltip>
  <p-indicator
    v-else
    :type="element.properties.type"
    :no-fill="element.properties.noFill"
    :size="element.properties.size"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementIndicator } from '@/interfaces/element';

@Component({
  name: 'layout-element-indicator'
})
export default class extends Vue {
  @Prop() public element!: IElementIndicator;
}
</script>
