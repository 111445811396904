<template>
  <div class="chart" :class="{ 'chart--loading': loading }">
    <canvas ref="canvas" class="chart__render"></canvas>

    <div class="chart__loader" v-if="loading">
      <p-loading />
    </div>
  </div>
</template>

<script lang="ts">
import { Chart, ChartConfiguration, ChartDataset } from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { PropType } from 'vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ChartBarDataSet } from './types';

@Component({
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly loading!: boolean;

  @Prop({ type: Array as PropType<string[]>, required: true })
  public readonly labels?: string[];

  @Prop({ type: Array as PropType<ChartBarDataSet[]>, required: false, default: undefined })
  public readonly dataSets?: ChartBarDataSet[];

  private chartInstance: Chart<'bar'> | null = null;
  private chartContext2d: CanvasRenderingContext2D | null = null;
  private textColor = '';

  public mounted() {
    if (this.$refs.canvas instanceof HTMLCanvasElement) {
      this.chartContext2d = this.$refs.canvas.getContext('2d');
      this.textColor = getComputedStyle(this.$refs.canvas).getPropertyValue('--text-color-default');
    }

    this.draw();
  }

  @Watch('dataSets', { deep: true })
  public onDataChange() {
    this.draw();
  }

  public draw() {
    if (this.chartContext2d === null) {
      return;
    }

    const datasets: ChartDataset<'bar'>[] =
      this.dataSets?.map<ChartDataset<'bar'>>((dataSet) => {
        return {
          label: dataSet.label,
          backgroundColor: dataSet.colors,
          borderColor: dataSet.colors,
          data: dataSet.data
        };
      }) ?? [];

    const chartOptions: ChartConfiguration<'bar'> = {
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: datasets
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: { color: this.textColor },
            grid: {
              color: this.textColor,
              drawOnChartArea: false
            }
          },
          x: {
            ticks: {
              color: this.textColor,
              maxRotation: 90,
              minRotation: 90
            },
            grid: {
              color: this.textColor,
              drawOnChartArea: false
            }
          }
        },
        plugins: {
          tooltip: {
            position: 'nearest',
            mode: 'nearest',
            callbacks: {
              label: (tooltipItem) => {
                if (tooltipItem.label === 'No data') {
                  return '';
                }

                return `${tooltipItem.formattedValue} GB`;
              }
            }
          },
          legend: {
            display: false
          }
        }
      }
    };

    if (this.chartInstance === null) {
      this.chartInstance = new Chart(this.chartContext2d, chartOptions);
    } else {
      this.chartInstance.data.labels = chartOptions.data?.labels;
      this.chartInstance.data.datasets = chartOptions.data?.datasets;
      this.chartInstance.options = chartOptions.options ?? {};
      this.chartInstance.update();
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 100%;
  position: relative;

  &__render {
    width: 100%;
    height: 100%;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &--loading {
    > .chart__render {
      opacity: 0.5;
    }
  }
}
</style>
