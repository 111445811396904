import { Node } from '@tiptap/core';

export const CustomSup = Node.create({
  name: 'sup',

  group: 'inline', // Define as an inline element

  content: 'text*', // Generally contains text

  inline: true, // Inline element

  atom: false, // Set to false since sup is not atomic

  addAttributes() {
    return {
      // Dynamic attributes for sup, similar to span
      dynamic: {
        default: {},
        parseHTML: (element: HTMLElement): Record<string, string> => {
          const attributes: Record<string, string> = {}; // Explicitly typed
          element.getAttributeNames().forEach((attr) => {
            const value = element.getAttribute(attr);
            if (value !== null) {
              attributes[attr] = value;
            }
          });
          return attributes;
        },
        renderHTML: (attributes) => attributes.dynamic || {}
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'sup',
        getAttrs: (node: HTMLElement) => {
          const attributes: Record<string, string> = {}; // Explicitly typed
          node.getAttributeNames().forEach((attr) => {
            attributes[attr] = node.getAttribute(attr) as string; // Cast to string
          });
          return { dynamic: attributes };
        }
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const attrs = {
      ...HTMLAttributes,
      ...HTMLAttributes.dynamic
    };

    delete attrs.dynamic;

    return ['sup', attrs, 0];
  }
});
