var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form"},[(_vm.element.properties.errors.length > 0)?_c('p-message',{attrs:{"description":"The provided validation code is incorrect. Please ensure you're using the accurate code","type":"negative"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-element form-element--verification-code",class:[
      _vm.element.properties.classNames,
      {
        'form-element--has-error': _vm.element.properties.errors.length > 0,
        'form-element--is-disabled': _vm.element.properties.disabled
      }
    ]},[_c('div',{staticClass:"input-group"},[_vm._l((_vm.valueChars),function(val,index){return [_c('div',{key:`input-${index}`,staticClass:"input",class:{
            'input--error': _vm.element.properties.errors.length > 0,
            'input--disabled': _vm.element.properties.disabled
          }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.valueChars[index]),expression:"valueChars[index]"}],ref:_vm.inputRefName(index),refInFor:true,class:{ 'v-input--has-value': _vm.element.properties.value !== '' },attrs:{"type":"text","autofocus":_vm.element.properties.autofocus && index === 0,"data-id":index,"maxLength":1,"placeholder":_vm.element.properties.placeholder,"disabled":_vm.element.properties.disabled,"readonly":_vm.element.properties?.readonly,"autocomplete":"off"},domProps:{"value":(_vm.valueChars[index])},on:{"keydown":_vm.onKeydown,"keypress":_vm.onInteract,"keyup":function($event){return _vm.onKeyup(index, $event)},"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.valueChars, index, $event.target.value)},function($event){return _vm.onInput($event.target.value, index)}]}})])]})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }