<template>
  <p-form-element :size="size" :disabled="disabled" :label="label" :help-text="helpText" :error="error">
    <div class="slider">
      <p-range-slider
        :model-value="modelValue"
        :disabled="disabled"
        :readonly="readonly"
        :error="!!error"
        :min="min"
        :max="max"
        :step="step"
        :ticks="ticks"
        @update:modelValue="
          (value) => {
            $emit('update:modelValue', value);
          }
        "
      />
      <div class="slider__input">
        <p-input
          :min="min"
          :max="max"
          type="number"
          :disabled="disabled"
          :placeholder="placeholder"
          :value="modelValue"
          @change="$emit('update:modelValue', Number($event.detail[0]))"
        />
      </div>
    </div>
  </p-form-element>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: Number, required: false, default: undefined }) public readonly modelValue!: undefined | number;
  @Prop({ type: String, required: false, default: undefined }) public readonly placeholder?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly error?: string;
  @Prop({ type: Number, required: true }) public readonly min!: number;
  @Prop({ type: Number, required: true }) public readonly max!: number;
  @Prop({ type: Number, required: false, default: 1 }) public readonly step!: number;
  @Prop({ type: Boolean, required: false, default: false }) public readonly ticks!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly readonly!: boolean;
  @Prop({
    type: String as PropType<'small' | 'medium'>,
    default: 'medium',
    validator: (value: string) => ['small', 'medium'].includes(value)
  })
  public readonly size!: 'small' | 'medium';
}
</script>

<style lang="scss" scoped>
.slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-size-small);

  &__input {
    width: 100px;
  }
}
</style>
