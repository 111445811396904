import { mergeAttributes, Node } from '@tiptap/core';

export const CustomListItem = Node.create<any>({
  name: 'listItem',

  addOptions() {
    return {
      HTMLAttributes: {},
      bulletListTypeName: 'bulletList',
      orderedListTypeName: 'orderedList'
    };
  },

  content: 'paragraph block*',

  defining: true,

  parseHTML() {
    return [
      {
        tag: 'li',
        getAttrs: (element) => {
          const style = element.getAttribute('style') || '';
          const textAlign = element.style.textAlign || '';
          return { style, textAlign };
        }
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['li', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addAttributes() {
    return {
      style: {
        default: null,
        parseHTML: (element) => element.getAttribute('style') || null,
        renderHTML: (attributes) => {
          return attributes.style ? { style: attributes.style } : {};
        }
      },
      textAlign: {
        default: 'left',
        parseHTML: (element) => element.style.textAlign || null,
        renderHTML: (attributes) => {
          if (!attributes.textAlign) {
            return {};
          }
          let styles = `text-align: ${attributes.textAlign}; list-style-position: inside;`;

          if (attributes.textAlign === 'left') {
            styles = `text-align: ${attributes.textAlign};`;
          }

          return { style: styles };
        }
      }
    };
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.splitListItem(this.name),
      Tab: () => this.editor.commands.sinkListItem(this.name),
      'Shift-Tab': () => this.editor.commands.liftListItem(this.name)
    };
  }
});
