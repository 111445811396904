import { Node } from '@tiptap/core';

export interface VideoOptions {
  HTMLAttributes: {
    [key: string]: any;
  };
}

export const CustomVideo = Node.create<VideoOptions>({
  name: 'video',
  group: 'block',
  atom: true,

  addAttributes() {
    return {
      width: {
        default: null
      },
      height: {
        default: null
      },
      controls: {
        default: true
      },
      poster: {
        default: null
      },
      autoplay: {
        default: false
      },
      muted: {
        default: false
      },
      class: {
        default: null
      },
      id: {
        default: null
      },
      style: {
        default: null
      },
      sources: {
        default: []
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'video',
        getAttrs: (dom: HTMLElement) => {
          const sources: { src: string; type: string }[] = [];
          dom.querySelectorAll('source').forEach((source) => {
            const src = source.getAttribute('src');
            const type = source.getAttribute('type');
            if (src && type) {
              sources.push({ src, type });
            }
          });

          return {
            width: dom.getAttribute('width') || null,
            height: dom.getAttribute('height') || null,
            controls: dom.hasAttribute('controls'),
            poster: dom.getAttribute('poster') || null,
            autoplay: dom.hasAttribute('autoplay'),
            muted: dom.hasAttribute('muted'),
            class: dom.getAttribute('class') || null,
            id: dom.getAttribute('id') || null,
            style: dom.getAttribute('style') || null,
            sources
          };
        }
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { sources, ...rest } = HTMLAttributes;

    return [
      'video',
      rest,
      ...sources.map((source: { src: string; type: string }) => ['source', { src: source.src, type: source.type }])
    ];
  }
});
