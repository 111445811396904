import { Mark } from '@tiptap/core';
export const CustomSpan = Mark.create({
  name: 'span',

  // Define any attributes you want to support on the <span> tag
  addAttributes() {
    return {
      class: {
        default: null,
        parseHTML: (element) => element.getAttribute('class'),
        renderHTML: (attributes) => {
          if (!attributes.class) {
            return {};
          }
          return { class: attributes.class };
        }
      },
      style: {
        default: null,
        parseHTML: (element) => element.getAttribute('style'),
        renderHTML: (attributes) => {
          if (!attributes.style) {
            return {};
          }
          return { style: attributes.style };
        }
      }
      // Add more attributes as needed
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span'
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', HTMLAttributes, 0];
  }
});
