<template>
  <p-container>
    <p-row :justify-content="element.properties.justifyContent">
      <p-form-tabs
        v-if="element.properties.label || element.properties.help"
        :label="element.properties.label"
        :help-text="element.properties.help"
        :size="element.properties.size"
        :disabled="element.properties.disabled"
        :tabs="tabs"
        v-model="element.properties.value"
        @update:modelValue="onInteract()"
      />
      <p-tabs v-else>
        <p-tabs-item
          v-for="tab in element.properties.tabs"
          :key="tab.id"
          :size="element.properties.size"
          :text="tab.text"
          :icon="tab.icon"
          :disabled.prop="tab.disabled"
          :error.prop="tab.error"
          :selected.prop="element.properties.value === tab.id"
          @select="onTabSelect(tab)"
        />
      </p-tabs>
    </p-row>

    <layout-element v-for="child in selectedTabChildren" :element="child" :key="child.key" />
  </p-container>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementTabs, IElementTabsItem } from '@/interfaces/element';
import { TabItem } from '../ui/form/types';
import debounce from 'lodash-decorators/debounce';
import { Iframe } from '@/iframe';
import { Trigger } from '@/Trigger';
import { getElementsForRender, traverseElements } from '@/utility';

@Component({
  name: 'layout-element-tabs'
})
export default class extends Vue {
  @Prop() public element!: IElementTabs;

  private didInteract = false;
  private didInteractTimer: number | null = null;

  onTabSelect(tab: IElementTabsItem) {
    Vue.set(this.element.properties, 'value', tab.id);
    this.onInteract();
  }

  onInteract() {
    this.didInteract = true;

    if (this.didInteractTimer !== null) {
      clearTimeout(this.didInteractTimer);
    }

    this.didInteractTimer = setTimeout(() => {
      this.didInteract = false;
      this.didInteractTimer = null;
    }, 500);
  }

  @Watch('element.properties.value')
  @debounce(25)
  onTabChange(newValue: string) {
    if (this.didInteract) {
      this.$el.dispatchEvent(
        new CustomEvent('BLUEPRINT_INTERACT', {
          bubbles: true,
          composed: true
        })
      );
    }

    if (this.didInteract && this.element.properties.name) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }

    if (this.didInteract && this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(this.element.properties.trigger, this.$el, this.element.properties.name, newValue);
    }
  }

  public get selectedTab(): IElementTabsItem | undefined {
    if (!this.element.properties.value) {
      return undefined;
    }

    return this.element.properties.tabs.find((tab) => tab.id === this.element.properties.value);
  }

  public get selectedTabChildren() {
    return getElementsForRender(this.selectedTab?.content ?? []);
  }

  public get tabs(): TabItem[] {
    return this.element.properties.tabs.map<TabItem>((tab) => {
      let error = false;

      if (tab.content) {
        traverseElements(tab.content, (element) => {
          if (
            element.properties &&
            'errors' in element.properties &&
            element.properties.errors &&
            element.properties.errors.length > 0
          ) {
            error = true;
          }
        });
      }

      return {
        id: tab.id,
        label: tab.text,
        icon: tab.icon,
        error
      };
    });
  }
}
</script>
