<template>
  <p-button
    title="Redo (⌘Y)"
    color-type="tertiary"
    icon="corner-up-right"
    @click="redo"
    :disabled.prop="buttonDisabled"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';

@Component
export default class EditorRedo extends Vue {
  @Prop({ type: Object, required: true }) public readonly editor!: Editor;

  public get buttonDisabled() {
    return !this.editor.can().redo();
  }
  // Redo the last undone change in the editor
  public redo() {
    this.editor.chain().focus().redo().run();
  }
}
</script>
