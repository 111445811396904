<template>
  <div class="debugger-element">
    <p-modal-plain :show.prop="showDebugger" headline="Audit Log" position="top" @close-request="showDebugger = false">
      <p-accordion
        v-for="(entry, entryIndex) in entries"
        :title="`AuditLog ${entry.id}  (${entry.className})`"
        :key="`entry-${entryIndex}`"
        :open="true"
      >
        <p-container>
          <p-table-client
            searchable
            sortable
            searchable-autofocus
            pagination
            :header="[
              { id: 'event', label: 'Event', width: '70px', verticalAlign: 'middle' },
              { id: 'url', label: 'Url', width: '400px', verticalAlign: 'middle' },
              { id: 'ip', label: 'IP', width: '100px', verticalAlign: 'middle' },
              { id: 'user_name', label: 'User', width: '100px', verticalAlign: 'middle' },
              { id: 'user_email', label: 'Email', width: '160px', verticalAlign: 'middle' },
              { id: 'created_at', label: 'Created At', width: '140px', verticalAlign: 'middle' },
              { id: 'actions', label: '', width: '50px', verticalAlign: 'middle' }
            ]"
            :body="entry.logs"
          >
            <template #bodyCell="{ row, header }">
              <template v-if="header.id === 'url'">
                {{ stripUrl(row[header.id]) }}
              </template>

              <template v-else-if="header.id === 'created_at'">
                {{ format(new Date(row[header.id].toString()), 'yyyy-MM-dd') }}
              </template>

              <template v-else-if="header.id === 'actions'">
                <p-tooltip text="View log" position="bottom">
                  <p-button @click="viewLog(row)" color-type="tertiary" icon="eye" size="medium" />
                </p-tooltip>
              </template>

              <template v-else>
                <p-paragraph>
                  {{ row[header.id] }}
                </p-paragraph>
              </template>
            </template>
          </p-table-client>
        </p-container>
      </p-accordion>
    </p-modal-plain>

    <p-modal-plain
      :show.prop="true"
      :headline="`Log ${selectedLog.auditable_id} - ${selectedLog.created_at}`"
      position="top"
      v-if="selectedLog"
      @close-request="selectedLog = null"
    >
      <p-container>
        <div class="debugger-element__log-details">
          <div class="debugger-element__log-detail">
            <span class="debugger-element__log-key">Event:</span> {{ selectedLog.event }}
          </div>
          <div class="debugger-element__log-detail">
            <span class="debugger-element__log-key">URL:</span> {{ selectedLog.url }}
          </div>
          <div class="debugger-element__log-detail">
            <span class="debugger-element__log-key">IP:</span> {{ selectedLog.ip }}
          </div>
          <div class="debugger-element__log-detail">
            <span class="debugger-element__log-key">Username:</span> {{ selectedLog.user_name }}
          </div>
          <div class="debugger-element__log-detail">
            <span class="debugger-element__log-key">Email:</span> {{ selectedLog.user_email }}
          </div>
          <div class="debugger-element__log-detail">
            <span class="debugger-element__log-key">Created At:</span> {{ selectedLog.created_at }}
          </div>
        </div>

        <p-row>
          <p-column style="min-width: 0">
            <p-container>
              <p-headline size="5">Old value: </p-headline>
              <pre
                class="debugger-element__value--old"
              ><code>{{ formatSettingsValue(selectedLog.old_values) }}</code></pre>
            </p-container>
          </p-column>
          <p-column style="min-width: 0">
            <p-container>
              <p-headline size="5">New value: </p-headline>
              <pre
                class="debugger-element__value--new"
              ><code>{{ formatSettingsValue(selectedLog.new_values) }}</code></pre>
            </p-container>
          </p-column>
        </p-row>
      </p-container>
    </p-modal-plain>

    <div class="debugger-element__button" v-if="entries && entries.length > 0">
      <p-button size="small" type="cta" color-type="primary" @click="showDebugger = true" v-device-desktop
        >Audit log</p-button
      >
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PropType } from 'vue';
import { AuditLogEntry, CampaignLog } from '@/interfaces/template';
import LayoutElementPopup from '@/components/LayoutElementPopup.vue';
import { format } from 'date-fns';

@Component({
  name: 'layout-element-debugger',
  methods: { format },
  components: { LayoutElementPopup }
})
export default class extends Vue {
  @Prop({ type: Array as PropType<CampaignLog[]> }) public readonly entries!: CampaignLog[];

  public showDebugger = false;
  public selectedLog: AuditLogEntry | null = null;

  public formatSettingsValue(newValue: any): string {
    if (newValue && typeof newValue.settings === 'string') {
      const parsedSettings = JSON.parse(newValue.settings);
      return `settings: ${JSON.stringify(parsedSettings, null, 2)}`;
    } else if (typeof newValue === 'string') {
      const parsed = JSON.parse(newValue);
      return JSON.stringify(parsed, null, 2);
    } else if (newValue && typeof newValue === 'object') {
      return JSON.stringify(newValue, null, 2);
    }

    return newValue;
  }

  public stripUrl(url: string) {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.pathname.replace(/^\//, '');
    } catch (e) {
      return url;
    }
  }

  public viewLog(log: AuditLogEntry) {
    this.selectedLog = log;
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/mixins/typography';
@import '../../scss/mixins/devices';

.debugger-element {
  pre {
    background-color: var(--color-base-100);
    color: var(--color-base-600);
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    max-width: 100%;
    overflow: auto;
    display: block;
    padding: 0.5em 1em;
    border-radius: 4px;
    width: 100%;
    overflow-x: auto;
  }

  &__button {
    position: fixed;
    left: 5px;
    bottom: 80px;
  }

  &__value--old {
    border-left: 3px solid var(--color-border-negative); /* Red for old values */
  }

  &__value--new {
    border-left: 3px solid var(--color-border-positive); /* Green for new values */
  }

  &__log-details {
    margin-bottom: 20px;
  }

  &__log-detail {
    margin: 10px 0;
  }

  &__log-key {
    font-weight: bold;
  }
}
</style>
