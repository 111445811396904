import { AppConfig } from '@/app_config';

export interface IntercomSettings {
  user: {
    id: number;
    name: string;
    email: string;
    hash: string;
    created_at?: number;
    last_login?: number;
  };

  company: {
    id: number;
    name: string;
    created_at?: number;
    plan: string;
    upgraded_at?: number;
    license_period_start_at?: number;
    license_period_end_at?: number;
    hubspot_company_id?: string;
  };
}

export async function enableIntercom(settings: IntercomSettings) {
  // Free trial should not have Intercom enabled
  if (settings.company.plan === 'free' || settings.company.plan === 'Freemium') {
    disableIntercom();
    return;
  }

  const config = await AppConfig.get();

  (window as any).intercomSettings = {
    api_base: 'https://api-iam.intercom.io',
    app_id: config.intercom,
    id: settings.user.id,
    user_id: settings.user.id,
    user_hash: settings.user.hash,
    name: settings.user.name,
    email: settings.user.email,
    ...(settings.user.created_at !== undefined && { created_at: settings.user.created_at }),
    ...(settings.user.last_login !== undefined && { last_login: settings.user.last_login }),
    language_override: 'en',
    alignment: 'left',
    horizontal_padding: 20,
    vertical_padding: 20,
    company: {
      id: settings.company.id,
      name: settings.company.name,
      plan: settings.company.plan,
      ...(settings.company.created_at !== undefined && { created_at: settings.company.created_at }),
      ...(settings.company.upgraded_at !== undefined && { upgraded_at: settings.company.upgraded_at }),
      ...(settings.company.license_period_start_at !== undefined && {
        license_period_start_at: settings.company.license_period_start_at
      }),

      ...(settings.company.license_period_end_at !== undefined && {
        license_period_end_at: settings.company.license_period_end_at
      }),

      ...(settings.company.hubspot_company_id !== undefined && {
        hubspot_company_id: settings.company.hubspot_company_id
      })
    }
  };

  const ic = (window as any).Intercom;

  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', (window as any).intercomSettings);
  } else {
    const i = function () {
      // eslint-disable-next-line prefer-rest-params
      (i as any).c(arguments);
    };

    (i as any).q = [];
    (i as any).c = function (args: any) {
      (i as any).q.push(args);
    };

    (window as any).Intercom = i;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://widget.intercom.io/widget/' + config.intercom;
    const x = document.getElementsByTagName('script')[0];

    if (x && x.parentNode) {
      x.parentNode.insertBefore(script, x);
    }
  }
}

export function disableIntercom() {
  if (typeof (window as any).Intercom !== 'undefined') {
    (window as any).Intercom('shutdown');
  }
}
