<template>
  <p-media-selector
    v-if="element.properties.tmp || element.properties.selector?.type === 'folder'"
    :disabled="element.properties.disabled"
    :readonly="element.properties.readonly"
    :preview="element.properties.preview"
    :placeholder="element.properties.placeholder"
    :error="element.properties.errors.length > 0"
    v-model="element.properties.value"
    :tmp="element.properties.tmp"
    :button-text="element.properties.label"
    :multiple="element.properties.multiple"
    :max-width="element.properties.selector?.validations?.maxWidth ?? undefined"
    :max-height="element.properties.selector?.validations?.maxHeight ?? undefined"
    :min-width="element.properties.selector?.validations?.minWidth ?? undefined"
    :min-height="element.properties.selector?.validations?.minHeight ?? undefined"
    :aspect-ratio="element.properties.selector?.validations?.aspectRatio ?? undefined"
    :extensions="element.properties.selector?.extensions ?? undefined"
    :selection-mode="element.properties.selector?.type ?? 'file'"
    :help-in-tooltip="element.properties.helpInTooltip"
    @update:modelValue="onInteract"
    @dialogOpen="onDialogOpen"
    @dialogClose="onDialogClose"
  >
    <layout-element v-if="element.properties.altText" :element="element.properties.altText" />
  </p-media-selector>

  <p-form-media-selector
    v-else
    :label="element.properties.label"
    :help-text="element.properties.help"
    :disabled="element.properties.disabled"
    :readonly="element.properties.readonly"
    :preview="element.properties.preview"
    :cursor-select="!!element.properties?.cursorSelect?.x"
    :placeholder="element.properties.placeholder"
    :size="element.properties.size"
    :error="element.properties.errors.join(', ')"
    v-model="element.properties.value"
    :tmp="element.properties.tmp"
    :multiple="element.properties.multiple"
    :max-width="element.properties.selector?.validations?.maxWidth ?? undefined"
    :max-height="element.properties.selector?.validations?.maxHeight ?? undefined"
    :min-width="element.properties.selector?.validations?.minWidth ?? undefined"
    :min-height="element.properties.selector?.validations?.minHeight ?? undefined"
    :aspect-ratio="element.properties.selector?.validations?.aspectRatio ?? undefined"
    :extensions="element.properties.selector?.extensions ?? undefined"
    :selection-mode="element.properties.selector?.type ?? 'file'"
    :help-in-tooltip="element.properties.helpInTooltip"
    @update:modelValue="onInteract"
    @dialog-open="onDialogOpen"
    @dialog-close="onDialogClose"
    @cursor-select="onCursorSelect"
  >
    <layout-element v-if="element.properties.altText" :element="element.properties.altText" />
  </p-form-media-selector>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementMedia, TriggerType } from '@/interfaces/element';
import { PropType } from 'vue';
import { Iframe } from '@/iframe';
import { Trigger } from '@/Trigger';

@Component({
  name: 'layout-element-media'
})
export default class extends Vue {
  @Prop({ type: Object as PropType<IElementMedia>, required: true }) public readonly element!: IElementMedia;

  private didInteract = false;
  private didInteractTimer: number | null = null;

  public onInteract() {
    this.didInteract = true;

    if (this.didInteractTimer !== null) {
      clearTimeout(this.didInteractTimer);
    }

    this.didInteractTimer = setTimeout(() => {
      this.didInteract = false;
      this.didInteractTimer = null;
    }, 500);
  }

  public onDialogOpen() {
    Iframe.emit('frame-request-full-size');
  }

  public onDialogClose() {
    Iframe.emit('frame-request-regular-size-before');

    setTimeout(() => {
      Iframe.emit('frame-request-regular-size');
    }, 475);
  }

  public onCursorSelect(x: number, y: number) {
    if (this.element.properties?.cursorSelect?.x && this.element.properties?.cursorSelect?.y) {
      // Update X
      Trigger.handle(
        {
          type: TriggerType.SET_FIELD_VALUE,
          field: this.element.properties.cursorSelect.x,
          value: x
        },
        this.$el,
        this.element.properties.name
      );

      // Update Y
      Trigger.handle(
        {
          type: TriggerType.SET_FIELD_VALUE,
          field: this.element.properties.cursorSelect.y,
          value: y
        },
        this.$el,
        this.element.properties.name
      );
    }
  }

  @Watch('element.properties.value')
  public onFastValueChange() {
    if (this.element.properties.name) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }
  }

  @Watch('element.properties.value')
  public onElementValueChange() {
    if (!this.didInteract) {
      return;
    }

    this.$el.dispatchEvent(
      new CustomEvent('BLUEPRINT_INTERACT', {
        bubbles: true,
        composed: true
      })
    );

    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(
        this.element.properties.trigger,
        this.$el,
        this.element.properties.name,
        this.element.properties.value
      );
    }
  }
}
</script>
