<template>
  <p-modal-plain
    position="top"
    :show.prop="true"
    headline="Create folder"
    @close-request="$emit('close-request')"
    body-padding
    has-footer
  >
    <div class="create-folder">
      <div class="create-folder__icon">
        <p-icon size="extra-large" icon="folder" />
      </div>

      <div class="create-folder__description">Enter folder name below</div>

      <div class="create-folder__input">
        <p-form-input
          v-device-desktop
          size="medium"
          :error="error"
          v-model="folderName"
          autofocus
          :disabled="loading || loadingSuccess"
        />

        <p-form-input
          v-device-mobile
          size="extra-large"
          :error="error"
          v-model="folderName"
          autofocus
          :disabled="loading || loadingSuccess"
        />
      </div>
    </div>

    <p-button slot="footer" color-type="tertiary" @click="$emit('close-request')">Cancel</p-button>

    <p-button
      slot="footer"
      :loading.prop="loading || loadingSuccess"
      :loading-success.prop="loadingSuccess"
      color-type="primary"
      @click="create()"
      >Save</p-button
    >
  </p-modal-plain>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MediaObject } from './types';
import { AppRequest } from '@/app_request';
import { MediaResource } from '@/types/api/media';
import axios from 'axios';

@Component({})
export default class extends Vue {
  @Prop({ type: Object as PropType<MediaObject>, required: false, default: undefined })
  public readonly folder?: MediaObject;

  public loading = false;
  public loadingSuccess = false;
  public folderName = '';
  public error = '';

  public async create() {
    if (this.folderName.trim() === '') {
      this.error = 'Folder name cannot be empty';
      return;
    }

    this.error = '';
    this.loading = true;

    try {
      await AppRequest.post<{ data: MediaResource }>('/api/v1/media', {
        name: this.folderName,
        type: 'folder',
        folder_id: this.folder?.id
      });

      this.$emit('created');

      this.loadingSuccess = true;

      setTimeout(() => {
        this.$emit('close-request');
      }, 1500);
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 400) {
        this.error = 'A folder already exists with this name';
        this.loading = false;
        return;
      }

      throw e;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/mixins/typography';
@import '../../../../scss/mixins/devices';

.create-folder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--gap-size-medium);

  &__description {
    @include component-text-helptext;
  }

  &__input {
    width: var(--field-width-medium);
  }
}

@include for-mobile-only {
  .create-folder__input {
    width: var(--field-width-extra-large);
  }
}
</style>
