<template>
  <div class="topbar">
    <p-message
      type="positive"
      description="Congratulations! You've surpassed 100 registrations! Unlock all registrations by upgrading your plan."
      display-type="banner"
    >
      <p-button size="small" color-type="tertiary" @click="onClick">Contact our support team</p-button>
    </p-message>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false
})
export default class extends Vue {
  public onClick() {
    (window as any).Intercom('showNewMessage');
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/mixins/devices';

.topbar {
  width: 100%;
  min-width: 1200px;

  /** This is an exception to our rule of no overwrites of CSS inside of other UI elements. */
  /** It will be rewritten once we make actual routes instead of blueprinting in the future. */
  .message {
    border-radius: 0 !important;

    &__body {
      width: 1200px;
      margin: 0 auto;
      max-width: 100%;
      flex-grow: 0 !important;
    }
  }
}

@include for-mobile-only {
  .topbar {
    min-width: 0;
    max-width: 100%;
  }
}

@media print {
  .topbar {
    display: none;
  }
}
</style>
