<template>
  <p-row v-if="element.properties.isGrouped">
    <p-column v-for="group in element.properties.options" :key="group.group">
      <p-form-radio
        :label="group.group"
        :disabled="element.properties.disabled"
        :error="element.properties.errors.join(', ')"
        :options="group.options"
        v-model="element.properties.value"
      />
    </p-column>
  </p-row>
  <p-form-radio
    v-else
    :label="element.properties.label"
    :help-text="element.properties.help"
    :disabled="element.properties.disabled"
    :error="element.properties.errors.join(', ')"
    :options="element.properties.options"
    :display="element.properties.display"
    v-model="element.properties.value"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementRadio } from '@/interfaces/element';
import { Trigger } from '@/Trigger';
import { Iframe } from '@/iframe';

@Component({
  name: 'layout-element-radio'
})
export default class extends Vue {
  @Prop() public element!: IElementRadio;

  @Watch('element.properties.value')
  onChange() {
    if (this.element.properties.name) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }

    this.$el.dispatchEvent(
      new CustomEvent('BLUEPRINT_INTERACT', {
        bubbles: true,
        composed: true
      })
    );

    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(
        this.element.properties.trigger,
        this.$el,
        this.element.properties.name,
        this.element.properties.value
      );
    }
  }
}
</script>
