<template>
  <p-modal-plain
    :show.prop="true"
    position="top"
    headline="Rename"
    @close-request="$emit('close-request')"
    body-padding
    has-footer
  >
    <div class="rename">
      <div class="rename__icon">
        <p-icon size="extra-large" icon="file" />
      </div>

      <div class="rename__description">Rename your files below</div>

      <p-table :header="header" :body="tableBody">
        <template #bodyCell="{ row, column, header }">
          <template v-if="header.id === 'newName'">
            <p-form-input
              v-if="row.type === 'file' && row.extension"
              v-model="newNameMap[row.id]"
              :append="`.${row.extension}`"
              size="medium"
              :error="showErrors && errorMap[row.id] ? errorMap[row.id] : undefined"
            />
            <p-form-input
              v-else
              v-model="newNameMap[row.id]"
              size="medium"
              :error="showErrors && errorMap[row.id] ? errorMap[row.id] : undefined"
            />
          </template>
          <template v-else-if="header.id === 'preview'">
            <p-container align-items="center">
              <p-tooltip v-if="row.type === 'file' && row.image">
                <p-icon size="large" icon="image" />

                <template #text>
                  <p-image :src="row.file" :max-width="200" :max-height="200" />
                </template>
              </p-tooltip>
              <p-icon v-else-if="row.type === 'folder'" size="large" icon="folder" />
              <p-icon v-else size="large" icon="file" />
            </p-container>
          </template>
          <template v-else>
            {{ column }}
          </template>
        </template>
      </p-table>
    </div>

    <p-button slot="footer" color-type="tertiary" @click="$emit('close-request')">Cancel</p-button>

    <p-button
      slot="footer"
      :loading.prop="loading || loadingSuccess"
      :loading-success.prop="loadingSuccess"
      color-type="primary"
      @click="save()"
      >Save</p-button
    >
  </p-modal-plain>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MediaObject } from './types';
import { TableHeader } from '../../Table.vue';
import { AppRequest } from '@/app_request';
import { MediaResource } from '@/types/api/media';

@Component({})
export default class extends Vue {
  @Prop({ type: Array as PropType<MediaObject[]>, required: true })
  public readonly objects!: MediaObject[];

  public header: TableHeader[] = [
    {
      id: 'preview',
      label: 'Preview',
      verticalAlign: 'middle',
      width: '75px'
    },
    {
      id: 'name',
      label: 'Existing name'
    },
    {
      id: 'newName',
      label: 'New name',
      width: '380px'
    }
  ];

  public loading = false;
  public loadingSuccess = false;
  public showErrors = false;

  public newNameMap: Record<string, string> = {};
  public errorMap: Record<string, string> = {};

  public beforeMount() {
    this.newNameMap = this.objects.reduce<Record<string, string>>((acc, object) => {
      acc[object.id] = object.type === 'file' ? object.name.split('.')[0] : object.name;
      return acc;
    }, {});
  }

  public get tableBody() {
    return this.objects.map((object) => {
      return {
        ...object,
        extension: object.type === 'file' ? object.name.split('.')[1] : undefined
      };
    });
  }

  public async save() {
    this.showErrors = true;
    this.errorMap = {};

    Object.entries(this.newNameMap).forEach(([id, newName]) => {
      if (newName.trim() === '') {
        this.errorMap[id] = 'Name cannot be empty';
      } else if (newName.length > 255) {
        this.errorMap[id] = 'Name exceeds the maximum length of 255 characters';
      }
    });

    if (Object.keys(this.errorMap).length > 0) {
      return;
    }

    this.loading = true;

    try {
      const newFileNameMap: Record<string, string> = {};

      for (const object of this.objects) {
        const existingObject = (await AppRequest.get<{ data: MediaResource }>(`/api/v1/media/${object.id}`)).data.data;
        const extension = object.type === 'file' ? object.name.split('.')[1] : undefined;

        newFileNameMap[object.id] = `${this.newNameMap[object.id]}${
          object.type === 'file' && extension ? `.${extension}` : ''
        }`;

        await AppRequest.put(`/api/v1/media/${existingObject.id}`, {
          ...existingObject,
          name: `${this.newNameMap[object.id]}${object.type === 'file' && extension ? `.${extension}` : ''}`
        });
      }

      this.loadingSuccess = true;

      setTimeout(() => {
        this.$emit('renamed', newFileNameMap);
        this.$emit('close-request');
      }, 1500);
    } catch (e) {
      // Unknown error occurred. Remove the loading state allowing the user to re-attempt.
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/mixins/typography';
@import '../../../../scss/mixins/devices';

.rename {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--gap-size-medium);
  width: 900px;
  max-width: 100%;

  &__description {
    @include component-text-helptext;
  }
}
</style>
