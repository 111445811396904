import { Table as DefaultTable } from '@tiptap/extension-table';

export const CustomTable = DefaultTable.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      border: {
        default: null,
        parseHTML: (element) => element.getAttribute('border'),
        renderHTML: (attributes) => {
          if (attributes.border) {
            return { border: attributes.border };
          }
        }
      },
      cellpadding: {
        default: null,
        parseHTML: (element) => element.getAttribute('cellpadding'),
        renderHTML: (attributes) => {
          if (attributes.cellpadding) {
            return { cellpadding: attributes.cellpadding };
          }
        }
      },
      style: {
        default: null,
        parseHTML: (element) => element.getAttribute('style'),
        renderHTML: (attributes) => {
          if (attributes.style) {
            return { style: attributes.style };
          }
        }
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'table',
        getAttrs: (node: HTMLElement) => {
          return {
            border: node.getAttribute('border'),
            cellpadding: node.getAttribute('cellpadding'),
            style: node.getAttribute('style')
          };
        }
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['table', HTMLAttributes, ['tbody', 0]]; // Wrap content with <tbody>
  }
});
