<template>
  <div ref="codeEditor" class="code-editor" :class="{ 'code-editor--error': error }">
    <div class="code-editor__resize-handle" @mousedown="startResize">
      <svg width="10" height="10" focusable="false">
        <g fill-rule="nonzero">
          <path d="M8.1 1.1A.5.5 0 119 2l-7 7A.5.5 0 111 8l7-7zM8.1 5.1A.5.5 0 119 6l-3 3A.5.5 0 115 8l3-3z"></path>
        </g>
      </svg>
    </div>
    <div class="code-editor__editor" ref="editor" :style="{ height: `${editorHeight}px` }"></div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as monaco from 'monaco-editor';

@Component({
  components: {},
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: String, required: false, default: null }) public readonly modelValue!: string | null;
  @Prop({ type: String, required: false, default: 'javascript' }) public readonly language!: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly error?: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly readonly!: boolean;

  private editorInstance: monaco.editor.IStandaloneCodeEditor | null = null;
  private isResizing = false;

  public editorHeight = 500;
  public codeEditor: HTMLElement | null = null;

  public startResize(event: MouseEvent) {
    this.isResizing = true;
    let startY = event.pageY;
    event.preventDefault();

    const handleMouseMove = (event: MouseEvent) => {
      if (this.isResizing) {
        const diffY = event.pageY - startY;
        this.editorHeight += diffY;
        startY = event.pageY;

        if (this.$refs.codeEditor instanceof HTMLElement) {
          const parentElm = this.$refs.codeEditor.parentElement;

          if (parentElm && parentElm.classList.contains('form-element__body')) {
            parentElm.style.height = `${this.editorHeight}px`;
            parentElm.style.minHeight = `400px`;
          }
        }
      }
    };

    const handleMouseUp = () => {
      this.isResizing = false;
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  }

  public get options(): monaco.editor.IStandaloneEditorConstructionOptions {
    return {
      value: this.modelValue || '',
      language: this.language || 'javascript',
      theme: 'vs',
      automaticLayout: true,
      readOnly: this.readonly || this.disabled,
      scrollBeyondLastLine: false,
      scrollbar: {
        vertical: 'visible',
        verticalScrollbarSize: 14,
        horizontal: 'auto',
        alwaysConsumeMouseWheel: false
      }
    };
  }

  @Watch('modelValue')
  public onModelValueChanged(newValue: string) {
    if (this.editorInstance && this.editorInstance.getValue() !== newValue) {
      this.editorInstance.setValue(newValue);
    }
  }

  public mounted() {
    if (this.$refs.editor && this.$refs.editor instanceof HTMLElement) {
      this.editorInstance = monaco.editor.create(this.$refs.editor, this.options);

      this.editorInstance.onDidChangeModelContent(() => {
        const newValue = this.editorInstance?.getValue() || '';

        // Emit an event or update a reactive property with the new value
        this.$emit('update:modelValue', newValue);
      });
    }
  }

  public updated() {
    if (this.editorInstance) {
      this.editorInstance.updateOptions(this.options);
    }
  }
}
</script>

<style lang="scss" scoped>
.code-editor {
  position: relative;
  margin-bottom: 17px; // 17px is the height of the resize handle

  &__resize-handle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 17px;
    cursor: nwse-resize;
    border: 1px solid var(--field-color-border-default);
    z-index: 1;
    background: var(--field-color-background-default);
    border-bottom-left-radius: var(--field-border-radius-default);
    border-bottom-right-radius: var(--field-border-radius-default);
    overflow: hidden;

    svg {
      position: absolute;
      bottom: 0;
      right: 0;
      fill: rgba(34, 47, 62, 0.7);
    }
  }

  .code-editor__resize-handle {
    bottom: -15px;
  }

  &__editor {
    width: 100%;
    height: 100%;
    min-height: 400px;
    border: 1px solid var(--field-color-border-default);
    border-radius: var(--field-border-radius-default);
    background-color: var(--field-color-background-default);
    overflow: hidden;
  }

  &--error {
    .code-editor__editor,
    .code-editor__resize-handle {
      border-color: var(--field-color-border-negative);
    }
  }
}

// fixes issue for windows machine, making it impossible to scroll the parent container
.code-editor__editor ::v-deep textarea {
  z-index: 0;
}
</style>
