import { TableHeader as DefaultTableHeader } from '@tiptap/extension-table-header';

export const CustomTableHeader = DefaultTableHeader.extend({
  addOptions() {
    return {
      ...this.parent?.()
    };
  },

  addAttributes() {
    return {
      ...this.parent?.()
    };
  },

  // Only allow inline content in table header cells
  content: 'inline*',

  parseHTML() {
    return [
      {
        tag: 'th',
        getAttrs: (node: HTMLElement) => ({
          colspan: node.getAttribute('colspan'),
          rowspan: node.getAttribute('rowspan')
        })
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['th', HTMLAttributes, 0]; // Render the <th> directly without <p> inside
  }
});
