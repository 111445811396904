<template>
  <p-form-date-time-picker
    :label="element.properties.label"
    :help-text="element.properties.help"
    :help-in-tooltip="element.properties.helpInTooltip"
    :error="element.properties.errors.join(', ')"
    :disabled="element.properties.disabled"
    :min-date="element.properties.minDate"
    :max-date="element.properties.maxDate"
    v-model="element.properties.value"
    @input="onInteract()"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementDateTimepicker } from '@/interfaces/element';
import { Trigger } from '@/Trigger';
import { debounce } from 'lodash-decorators';
import { Iframe } from '@/iframe';

@Component({
  name: 'layout-element-date-timepicker'
})
export default class extends Vue {
  @Prop() public element!: IElementDateTimepicker;

  private didInteract = false;
  private didInteractTimer: number | null = null;

  onInteract() {
    this.didInteract = true;

    if (this.didInteractTimer !== null) {
      clearTimeout(this.didInteractTimer);
    }

    this.didInteractTimer = setTimeout(() => {
      this.didInteract = false;
      this.didInteractTimer = null;
    }, 400);
  }

  @Watch('element.properties.value')
  onFastValueChange() {
    if (this.element.properties.name) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }
  }

  @Watch('element.properties.value')
  @debounce(375)
  onTabChange(newValue: string) {
    if (this.didInteract) {
      this.$el.dispatchEvent(
        new CustomEvent('BLUEPRINT_INTERACT', {
          bubbles: true,
          composed: true
        })
      );
    }

    if (this.didInteract && this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(this.element.properties.trigger, this.$el, this.element.properties.name, newValue);
    }
  }
}
</script>
