<template>
  <div class="accordion" :class="[`accordion--level-${level}`, size ? `accordion--size-${size}` : '']">
    <div class="accordion__header" @click="toggle">
      <div class="accordion__header-top">
        <div class="accordion__header-icon" v-if="icon">
          <p-icon :size="level === 'primary' ? 'medium' : 'small'" :icon="icon" />
        </div>
        <div class="accordion__header-number" v-if="number && level === 'primary'">
          <p-headline size="5">{{ number }}</p-headline>
        </div>
        <div class="accordion__header-title">
          <p-headline v-if="size === 'extra-large'" size="3">{{ title }}</p-headline>
          <p-paragraph v-else typography="component-text-strong-large">{{ title }}</p-paragraph>
        </div>
        <div class="accordion__header-state">
          <p-icon icon="chevron-right" size="medium" v-if="!internalOpen" />
          <p-icon icon="minus" size="medium" v-else />
        </div>
      </div>

      <div class="accordion__header-description" v-if="description">
        {{ description }}
      </div>
    </div>

    <div class="accordion__body">
      <p-transition :duration="275" animation="fade">
        <div class="accordion__body-inner" v-if="internalOpen" key="open">
          <slot />
        </div>
        <div v-else key="closed"></div>
      </p-transition>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
  model: {
    prop: 'open',
    event: 'update:open'
  }
})
export default class extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) public readonly open!: boolean;

  @Prop({
    type: String as PropType<'primary' | 'secondary'>,
    default: 'primary',
    required: false,
    validator: (value: string) => {
      return ['primary', 'secondary'].includes(value);
    }
  })
  public readonly level!: 'primary' | 'secondary';

  @Prop({ type: String, required: true }) public readonly title!: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly description?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly number?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly icon?: string;

  @Prop({
    type: String as PropType<'extra-large' | null>,
    default: null,
    required: false,
    validator: (value: string) => 'extra-large'.includes(value)
  })
  public readonly size!: 'extra-large' | null;

  public internalOpen = false;

  beforeMount() {
    this.internalOpen = this.open;
  }

  public toggle() {
    this.internalOpen = !this.internalOpen;
    this.$emit('update:open', this.internalOpen);
  }

  @Watch('open')
  public onOpenChange() {
    this.internalOpen = this.open;
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/mixins/typography';

.accordion {
  width: 100%;
  border: 1px solid var(--field-color-border-default);

  &--level-primary {
    background: var(--color-background-layer-3);
  }

  &--level-secondary {
    background: var(--color-background-layer-1);
  }

  &--size-extra-large > .accordion__header,
  &--size-extra-large > .accordion__body-inner {
    padding: 0;
  }

  &--size-extra-large > .accordion__body-inner {
    padding: 0;
    margin-top: var(--gap-size-medium);
  }

  &--size-extra-large {
    padding: var(--base-size-700);
    border: none;
  }

  &__header {
    display: flex;
    flex-direction: column;
    padding: var(--base-size-200);
    gap: var(--gap-size-small);
    cursor: pointer;
    user-select: none;

    &-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--gap-size-small);
    }

    &-icon {
      flex-grow: 0;
      flex-shrink: 0;
    }

    &-number {
      display: flex;
      width: 34px;
      height: 34px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: var(--border-radius-medium);
      background: var(--color-base-300);
      color: var(--text-color-headline);
    }

    &-title {
      width: 100%;
      flex-shrink: 1;
      flex-grow: 1;
    }

    &-state {
      flex-grow: 0;
      flex-shrink: 0;
    }

    &-description {
      @include component-text-helptext;
      color: var(--text-color-subdued);
    }
  }

  &__body {
    &-inner {
      padding: var(--base-size-100) var(--base-size-200) var(--base-size-300) var(--base-size-200);
    }
  }
}
</style>
