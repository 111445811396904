<template>
  <p-container
    :align-items="element.properties?.alignItems"
    :gap-size="element.properties?.gapSize"
    :height="element.properties?.height"
  >
    <layout-element v-for="child in children" :element="child" :key="child.key" />
  </p-container>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementContainer } from '@/interfaces/element';
import { PropType } from 'vue';
import { getElementsForRender } from '@/utility';

@Component({
  name: 'layout-element-container'
})
export default class extends Vue {
  @Prop({ type: Object as PropType<IElementContainer>, required: true }) public readonly element!: IElementContainer;

  public get children() {
    return getElementsForRender(this.element.children ?? []);
  }
}
</script>
