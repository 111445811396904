<template>
  <div
    ref="container"
    class="transition"
    :class="{ 'transition--overflow': transitioning }"
    :style="`min-height:${minHeight ?? 0}px;`"
  >
    <transition
      :name="animation"
      @enter="enter"
      @before-enter="onBeforeEnter"
      @after-enter="onAfterEnter"
      @before-leave="onBeforeLeave"
      @after-leave="onAfterLeave"
      :duration="duration"
    >
      <slot />
    </transition>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Vue, Ref, Prop } from 'vue-property-decorator';

@Component({
  inheritAttrs: false
})
export default class extends Vue {
  @Ref() public container!: HTMLElement;
  @Prop({ type: Number, required: false, default: 500 }) public readonly duration!: number;
  @Prop({
    type: String as PropType<'fade' | 'fade-up' | 'fade-right' | 'fade-down' | 'fade-left'>,
    required: false,
    default: 'fade',
    validator: (value: string) => {
      return ['fade', 'fade-up', 'fade-right', 'fade-down', 'fade-left'].includes(value);
    }
  })
  public readonly animation!: string;

  @Prop({ type: Number, required: false, default: undefined }) public readonly minHeight?: number;

  public transitioning = false;

  public enter(el: HTMLElement, done: () => void) {
    this.container.offsetWidth; // force reflow

    this.container.style.transition = `height ${this.duration}ms linear`;

    this.container.offsetWidth; // force reflow

    this.container.style.height = `${el.offsetHeight}px`;
    el.style.transitionDuration = `${this.duration}ms`;
    el.style.transitionDelay = `${this.duration}ms`;

    el.addEventListener('transitionend', done, { once: true });
  }

  public onAfterEnter(el: HTMLElement) {
    if (this.container) {
      this.container.style.transition = this.container.style.height = '';
    }

    el.style.transitionDuration = '';
  }

  public onBeforeEnter() {
    this.container.style.height = `${this.container.offsetHeight}px`;
    this.transitioning = true;
  }

  public onBeforeLeave(el: HTMLElement) {
    el.style.width = `${el.offsetWidth}px`;
    el.style.transitionDuration = `${this.duration}ms`;
    el.style.transitionDelay = '0ms';
  }

  public onAfterLeave() {
    this.transitioning = false;
  }
}
</script>

<style lang="scss" scoped>
.transition {
  position: relative;
  width: 100%;

  &--overflow {
    overflow: hidden;
  }
}

/* Existing slide-fade styles */
.fade {
  &-enter {
    &-active {
      opacity: 0;
      transition: opacity 500ms linear;
    }

    &-to {
      opacity: 1;
    }
  }

  &-leave {
    &-active {
      opacity: 1;
      transition: opacity 500ms linear;
      position: absolute;
      left: 0;
      top: 0;
    }

    &-to {
      opacity: 0;
    }
  }
}

.fade-down {
  &-enter {
    &-active {
      opacity: 0;
      transform: translateY(-25%);
      transition: opacity 500ms linear, transform 500ms linear;
    }

    &-to {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  &-leave {
    &-active {
      opacity: 1;
      transform: translateY(0%);
      transition: opacity 500ms linear, transform 500ms linear;
      position: absolute;
      left: 0;
      top: 0;
    }

    &-to {
      opacity: 0;
      transform: translateY(25%);
    }
  }
}

.fade-up {
  &-enter {
    &-active {
      opacity: 0;
      transform: translateY(25%);
      transition: opacity 500ms linear, transform 500ms linear;
    }

    &-to {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  &-leave {
    &-active {
      opacity: 1;
      transform: translateY(0%);
      transition: opacity 500ms linear, transform 500ms linear;
      position: absolute;
      left: 0;
      top: 0;
    }

    &-to {
      opacity: 0;
      transform: translateY(-25%);
    }
  }
}

.fade-left {
  &-enter {
    &-active {
      opacity: 0;
      transform: translateX(25%);
      transition: opacity 500ms linear, transform 500ms linear;
    }

    &-to {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  &-leave {
    &-active {
      opacity: 1;
      transform: translateX(0%);
      transition: opacity 500ms linear, transform 500ms linear;
      position: absolute;
      left: 0;
      top: 0;
    }

    &-to {
      opacity: 0;
      transform: translateX(-25%);
    }
  }
}

.fade-right {
  &-enter {
    &-active {
      opacity: 0;
      transform: translateX(-25%);
      transition: opacity 500ms linear, transform 500ms linear;
    }

    &-to {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  &-leave {
    &-active {
      opacity: 1;
      transform: translateX(0%);
      transition: opacity 500ms linear, transform 500ms linear;
      position: absolute;
      left: 0;
      top: 0;
    }

    &-to {
      opacity: 0;
      transform: translateX(25%);
    }
  }
}
</style>
