<template>
  <p-modal-plain
    :show.prop="true"
    position="top"
    headline="Media library - Upload files"
    @close-request="$emit('close-request')"
    has-footer
  >
    <p-container align-items="center">
      <p-button size="medium" color-type="secondary" :disabled.prop="loading" @click="openBrowser()"
        >select file for upload</p-button
      >

      <p-headline size="5">Or</p-headline>

      <div
        class="dropzone"
        :class="{ 'dropzone--over': dropOver, 'dropzone--has-files': selectedFiles.length > 0 }"
        @drop.prevent="onDrop"
        @dragover="onDragOver"
        @dragenter="onDragOver"
        @dragleave="onDragLeave"
        @click="openBrowser"
      >
        <input type="file" ref="file" :accept="inputAccept" @change="onInputFileChange" multiple />

        <template v-if="selectedFiles.length > 0">
          <div class="dropzone__file" v-for="(selection, selectionKey) in selectedFiles" :key="selectionKey">
            <div class="dropzone__file-delete">
              <p-button
                size="medium"
                icon="trash"
                color-type="tertiary"
                :disabled.prop="selection.uploading"
                @click.stop="deleteSelection(selection)"
              />
            </div>

            <div class="dropzone__file-image">
              <div
                class="dropzone__file-image-inner"
                :style="selection.preview ? `background-image:url(${selection.preview});` : ''"
              >
                <p-icon v-if="!selection.preview" icon="file" size="large" />
              </div>
            </div>

            <div v-if="selection.error" class="dropzone__file-upload-error" key="error">
              {{ selection.error }}
            </div>
            <div
              v-else-if="selection.uploading && selection.uploadingProgress !== undefined"
              class="dropzone__file-upload-progress"
              :style="`width:${selection.uploadingProgress ?? 0}%;`"
              key="progress"
            >
              {{ selection.uploadingProgress.toFixed(1).replace('.', ',') }}%
            </div>

            <div class="dropzone__file-name">{{ selection.file.name }}</div>
          </div>
        </template>

        <template v-else>
          <p-headline size="4" v-if="dropOver"><p-icon icon="upload" /> Drop files to add them</p-headline>
          <p-headline size="4" v-else>Drag files here</p-headline>
        </template>
      </div>
    </p-container>

    <p-button slot="footer" color-type="tertiary" @click="$emit('close-request')" size="medium">Cancel</p-button>

    <p-button
      slot="footer"
      :loading.prop="loading || loadingSuccess"
      :loading-success.prop="loadingSuccess"
      color-type="primary"
      icon="upload"
      size="medium"
      @click="upload()"
      >Upload</p-button
    >
  </p-modal-plain>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MediaObject, UploadRequestResponse } from './types';
import { AppRequest } from '@/app_request';
import axios, { AxiosError } from 'axios';
import { MediaResource } from '@/types/api/media';
import { convertMediaResourceToObject, getDefaultExtensions } from './utils';
import mime from 'mime-types';

interface FileSelection {
  file: File;
  image?: HTMLImageElement;
  preview: string | null;
  uploading: boolean;
  uploadingProgress: number;
  uploadSuccess: boolean;
  error: string;
}

@Component({})
export default class extends Vue {
  @Prop({ type: Object as PropType<MediaObject>, required: false, default: undefined })
  public readonly folder?: MediaObject;

  @Prop({
    type: Array as PropType<string[]>,
    required: false,
    default: () => getDefaultExtensions()
  })
  public readonly extensions?: string[];

  @Prop({ type: Boolean, required: false, default: false })
  public readonly tmp!: boolean;

  public loading = false;
  public loadingSuccess = false;
  public dropOver = false;

  public selectedFiles: FileSelection[] = [];

  public fileAllowed(file: File) {
    // Only allow files
    if (file.size === 0) {
      return false;
    }

    const ext = file.name.split('.').pop()?.toLowerCase() ?? '';

    // Only allow allowed extensions
    if (this.extensions && this.extensions.length > 0 && !this.extensions.includes(ext)) {
      return false;
    }

    return true;
  }

  private handleFileAdd(file: File) {
    if (this.fileAllowed(file)) {
      if (file.name.match(/\.(jpeg|jpg|gif|png|svg|webp)$/) !== null) {
        const url = URL.createObjectURL(file);
        const img = new Image();

        img.onload = () => {
          this.selectedFiles.push({
            file,
            image: img,
            preview: url,
            uploading: false,
            uploadingProgress: 0,
            uploadSuccess: false,
            error: ''
          });
        };

        img.src = url;
      } else {
        this.selectedFiles.push({
          file,
          preview: null,
          uploading: false,
          uploadingProgress: 0,
          uploadSuccess: false,
          error: ''
        });
      }
    }
  }

  public onInputFileChange() {
    if (this.$refs.file instanceof HTMLInputElement) {
      Array.prototype.slice.call(this.$refs.file.files).forEach((file: File) => {
        this.handleFileAdd(file);
      });

      this.$refs.file.value = '';
    }
  }

  public onDrop(e: DragEvent) {
    this.dropOver = false;

    this.getFilesFromEvent(e).forEach((file) => {
      this.handleFileAdd(file);
    });
  }

  public onDragOver(e: DragEvent) {
    if (!this.loading) {
      e.preventDefault();
      this.dropOver = true;
    }
  }

  public onDragLeave(e: DragEvent) {
    if (!this.loading) {
      e.preventDefault();
      this.dropOver = false;
    }
  }

  private getFilesFromEvent(e: DragEvent): File[] {
    if (!e.dataTransfer) {
      return [];
    }

    const files: File[] = [];

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      // Use DataTransferItemList interface to access the file(s)
      [...e.dataTransfer.items].forEach((item) => {
        // If dropped items aren't files, reject them
        if (item.kind === 'file') {
          const file = item.getAsFile();

          if (file && this.fileAllowed(file)) {
            files.push(file);
          }
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...e.dataTransfer.files].forEach((file) => {
        if (file && this.fileAllowed(file)) {
          files.push(file);
        }
      });
    }

    return files;
  }

  public async upload() {
    this.loading = true;
    const uploadedFiles: MediaObject[] = [];

    try {
      for (const file of this.selectedFiles) {
        if (!file.error && file.uploadingProgress !== 100) {
          const uploadedFile = await this.uploadFile(file);
          uploadedFiles.push(uploadedFile);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Failed to upload media file:', e);
      this.loading = false;
      return;
    }

    if (this.selectedFiles.filter((selection) => !!selection.error).length === 0) {
      this.$emit('uploaded', uploadedFiles);

      this.loadingSuccess = true;

      setTimeout(() => {
        this.$emit('close-request');
      }, 1500);
    } else {
      this.loading = false;
    }
  }

  private async uploadFile(selection: FileSelection): Promise<MediaObject> {
    selection.uploading = true;
    selection.uploadingProgress = 0;

    const extension = selection.file.name.split('.').pop()?.toLowerCase() ?? '';
    if (!extension) {
      selection.uploading = false;
      selection.error = 'File has no extension';
      return Promise.reject('File has no extension');
    }

    try {
      let mimeType = selection.file.type;

      // Fallback for systems that does not provide a mimetype on the File object
      if (!mimeType) {
        const fileGuessedMime = mime.types[extension] ?? null;

        if (!fileGuessedMime) {
          selection.error = 'Unable to determine mime type';
          selection.uploading = false;
          return Promise.reject('File has no mime type');
        } else {
          mimeType = fileGuessedMime;
        }
      }

      if (selection.image && selection.image.naturalWidth > 2560 && selection.image.naturalHeight > 2560) {
        selection.error = 'Image too large. Resize to 2560x2560 pixels or smaller.';
        selection.uploading = false;

        return Promise.reject(selection.error);
      } else if (selection.image && selection.image.naturalWidth > 2560) {
        selection.error = 'Image exceeds limit. Resize width to 2560 pixels or less.';
        selection.uploading = false;

        return Promise.reject(selection.error);
      } else if (selection.image && selection.image.naturalHeight > 2560) {
        selection.error = 'Image exceeds limit. Resize height to 2560 pixels or less.';
        selection.uploading = false;

        return Promise.reject(selection.error);
      }

      const uploadRequestResponse = (
        await AppRequest.post<UploadRequestResponse>(
          '/api/v1/media/pre-sign-upload',
          {
            extension,
            contentType: mimeType,
            contentLength: selection.file.size,
            tmp: this.tmp
          },
          {
            params: this.apiExtendedParams
          }
        )
      ).data;

      selection.uploadingProgress = 40;

      const formData = new FormData();

      for (const name in uploadRequestResponse.inputs) {
        let value = uploadRequestResponse.inputs[`${name}`];

        if (name === 'key') {
          value = uploadRequestResponse.url.slice(1);
        }

        formData.append(name, value);
      }

      formData.append('file', selection.file);

      const s3Response = await fetch(uploadRequestResponse.attributes.action, {
        method: uploadRequestResponse.attributes.method,
        body: formData
      });

      if (s3Response.status !== 204) {
        selection.error = 'Upload failed';
        selection.uploading = false;

        return Promise.reject('Upload failed');
      }

      selection.uploadingProgress = 85;
      let mediaResource: MediaResource;

      if (this.tmp) {
        mediaResource = {
          id: 'tmp',
          file: uploadRequestResponse.url,
          folder_id: null,
          type: 'file',
          name: selection.file.name
        };

        selection.uploadingProgress = 100;
      } else {
        mediaResource = (
          await AppRequest.post<{ data: MediaResource }>(
            '/api/v1/media',
            {
              ...(this.folder && { folder_id: this.folder.id }),
              name: selection.file.name,
              file: uploadRequestResponse.url,
              type: 'file'
            },
            { params: this.apiExtendedParams }
          )
        ).data.data;

        selection.uploadingProgress = 100;
      }

      setTimeout(() => {
        selection.uploadSuccess = true;
      }, 1000);

      return convertMediaResourceToObject(mediaResource);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const error = e as AxiosError<{ message?: string }>;

        if (error.response?.status === 422 && error.response.data.message) {
          selection.uploadingProgress = 0;
          selection.uploading = false;

          selection.error = error.response.data.message;
          return Promise.reject(error.response.data.message);
        }

        throw e;
      } else {
        throw e;
      }
    }
  }

  public openBrowser() {
    if (!this.loading && this.$refs.file instanceof HTMLInputElement) {
      this.$refs.file.click();
    }
  }

  public deleteSelection(selection: FileSelection) {
    this.selectedFiles = this.selectedFiles.filter((item) => item !== selection);
  }

  public get inputAccept(): string {
    if (this.extensions) {
      return this.extensions.map((ext) => `.${ext}`).join(',');
    }

    return '';
  }

  public get apiExtendedParams(): Record<string, string> {
    if (this.$route.params.fastTrackHash) {
      return { ['fast-track']: this.$route.params.fastTrackHash };
    }

    return {};
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/mixins/typography';

.dropzone {
  display: flex;
  padding: 190px 10px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: var(--border-radius-none);
  border: 3px dashed var(--color-border-decorative);
  background: var(--color-background-layer-1);
  cursor: pointer;
  gap: var(--gap-size-medium) var(--gap-size-medium);
  flex-wrap: wrap;

  input {
    display: none;
  }

  &:hover {
    background: var(--color-background-layer-2);
  }

  &__file {
    width: 160px;
    height: 160px;
    position: relative;

    &-delete {
      position: absolute;
      right: 0;
      top: var(--base-size-100);

      --text-color-default: var(--text-color-default-inverted);
    }

    &-image {
      width: 100%;
      height: 120px;
      background-color: var(--color-background-layer-0);
      padding: 10px;

      &-inner {
        width: 100%;
        height: 100%;
        background-color: var(--color-background-layer-0);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-name {
      display: block;
      width: 100%;
      height: 40px;
      background-color: var(--color-background-layer-2);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
      padding: 0 var(--base-size-100);

      @include component-text-helptext;
      line-height: 40px;
    }

    &-upload {
      &-error {
        display: flex;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 40px;
        background-color: var(--color-background-negative);
        padding: var(--base-size-100);
        transition: width 175ms linear;
        width: 100%;

        @include text-body-default;
        color: var(--text-color-negative);
      }

      &-progress {
        display: flex;
        align-items: center;
        position: absolute;
        left: 0;
        top: 90px;
        height: 30px;
        background-color: var(--color-background-positive);
        padding: 0 var(--base-size-100);
        transition: width 175ms linear;
        width: 0%;
        min-width: 40px;

        @include text-body-default;
        color: var(--text-color-positive);
      }
    }
  }

  &--has-files {
    padding: 30px 10px;
  }
}
</style>
