<template>
  <p-tooltip
    v-if="element.properties.tooltip"
    :text="element.properties.tooltip.text"
    :position="element.properties.tooltip.position"
  >
    <p-badge
      :type="element.properties.display"
      :text="element.properties.text"
      :icon="element.properties.icon"
      :clickable.prop="!!element.properties.trigger"
      @click="onClick()"
    />
  </p-tooltip>
  <p-badge
    v-else
    :type="element.properties.display"
    :text="element.properties.text"
    :icon="element.properties.icon"
    :clickable.prop="!!element.properties.trigger"
    @click="onClick()"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementBadge } from '@/interfaces/element';
import { Trigger } from '@/Trigger';

@Component({
  name: 'layout-element-badge'
})
export default class extends Vue {
  @Prop() public element!: IElementBadge;

  public onClick() {
    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      this.$el.dispatchEvent(
        new CustomEvent('BLUEPRINT_INTERACT', {
          bubbles: true,
          composed: true
        })
      );

      Trigger.handle(this.element.properties.trigger, this.$el);
    }
  }
}
</script>
