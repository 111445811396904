<template>
  <Fragment>
    <layout-element v-for="element in elementsNotHidden" :element="element" :key="element.key" />
  </Fragment>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ILayoutTypeElement } from '@/interfaces/element';
import { PropType } from 'vue';
import { getElementsForRender } from '@/utility';

@Component({
  name: 'layout-elements'
})
export default class extends Vue {
  @Prop({ type: Array as PropType<ILayoutTypeElement[]>, required: true })
  public readonly elements!: ILayoutTypeElement[];

  public get elementsNotHidden() {
    return getElementsForRender(this.elements);
  }
}
</script>
