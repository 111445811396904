import LayoutElementBlueprint from '@/components/elements/LayoutElementBlueprint.vue';
import Vue from 'vue';

export function getBlueprint(component: Vue): LayoutElementBlueprint | null {
  let current: Vue | null = component;

  while (current) {
    if (current instanceof LayoutElementBlueprint) {
      return current;
    }

    current = current.$parent;
  }

  return null;
}
