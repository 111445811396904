<template>
  <p-link :href="element.properties.href" :target="element.properties.target" :download="downloadable">
    <layout-elements :elements="element.children" />
  </p-link>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementLink } from '@/interfaces/element';

@Component({
  name: 'layout-element-link'
})
export default class extends Vue {
  @Prop() public element!: IElementLink;

  // Condition to determine if a download attribute should be enabled
  get downloadable(): string | undefined {
    return this.element.properties.download ? this.element.properties.download : undefined;
  }
}
</script>
