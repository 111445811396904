<template>
  <p-form-switch
    :label="element.properties.label"
    :help-text="element.properties.help"
    :help-in-tooltip="element.properties.helpInTooltip"
    :error="element.properties.errors.join(', ')"
    :disabled="element.properties.disabled"
    v-model="element.properties.value"
    @update:modelValue="onInteract"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementSwitch } from '@/interfaces/element';
import { Iframe } from '@/iframe';
import { Trigger } from '@/Trigger';
import { PropType } from 'vue';
import { cancelBlueprintUpdate } from '@/utility';
import debounce from 'lodash-decorators/debounce';

@Component({
  name: 'layout-element-switch'
})
export default class extends Vue {
  @Prop({ type: Object as PropType<IElementSwitch>, required: true }) public readonly element!: IElementSwitch;

  private didInteract = false;
  private didInteractTimer: number | null = null;

  public onInteract() {
    this.didInteract = true;

    // Cancel existing blueprint calls so that a update doesn't come through if user types a bit slow
    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      cancelBlueprintUpdate(this.$el);
    }

    if (this.didInteractTimer !== null) {
      clearTimeout(this.didInteractTimer);
    }

    this.didInteractTimer = setTimeout(() => {
      this.didInteract = false;
      this.didInteractTimer = null;
    }, 500);
  }

  @Watch('element.properties.value')
  onFastValueChange() {
    if (this.element.properties.name) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }
  }

  @Watch('element.properties.value')
  @debounce(375)
  onValueChange() {
    if (!this.didInteract) {
      return;
    }

    this.$el.dispatchEvent(
      new CustomEvent('BLUEPRINT_INTERACT', {
        bubbles: true,
        composed: true
      })
    );

    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(
        this.element.properties.trigger,
        this.$el,
        this.element.properties.name,
        this.element.properties.value
      );
    }
  }
}
</script>
