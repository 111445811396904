<template>
  <GmapMap
    ref="mapRef"
    :center="center"
    :zoom="element.properties.zoom"
    :options="options"
    style="width: 100%; height: 300px"
  >
    <GmapMarker :key="index" v-for="(m, index) in element.properties.markers" :position="m.position" :icon="m.icon" />
  </GmapMap>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementGoogleMap } from '@/interfaces/element';
import { PropType } from 'vue';

interface LatLngPosition {
  lng: number;
  lat: number;
}

@Component({
  name: 'layout-element-google-map'
})
export default class LayoutElementGoogleMap extends Vue {
  @Prop({ type: Object as PropType<IElementGoogleMap>, required: true }) public readonly element!: IElementGoogleMap;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private htmlMarkers: any[] = [];

  mounted() {
    this.refreshMarkers();
  }

  @Watch('element.properties.markers')
  public onMarkerChange() {
    this.refreshMarkers();
  }

  private refreshMarkers() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.mapRef as any).$mapPromise.then(async (map: any) => {
      if (this.htmlMarkers.length > 0) {
        this.htmlMarkers.forEach((marker) => {
          marker.delete();
        });

        this.htmlMarkers = [];
      }

      if (this.element.properties.markers) {
        const { createHtmlMarker } = await import('./GoogleMap/html');

        this.element.properties.markers.forEach((marker) => {
          if (marker.html) {
            this.htmlMarkers.push(createHtmlMarker(marker.position.lat, marker.position.lng, marker.html, map));
          }
        });
      }
    });
  }

  // eslint-disable-next-line
  get options(): any {
    return {
      styles: [
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#a2a29d'
            },
            {
              lightness: 17
            }
          ]
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5'
            },
            {
              lightness: 20
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#ffffff'
            },
            {
              lightness: 17
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#ffffff'
            },
            {
              lightness: 29
            },
            {
              weight: 0.2
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff'
            },
            {
              lightness: 18
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff'
            },
            {
              lightness: 16
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5'
            },
            {
              lightness: 21
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#dedede'
            },
            {
              lightness: 21
            }
          ]
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              visibility: 'on'
            },
            {
              color: '#ffffff'
            },
            {
              lightness: 16
            }
          ]
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              saturation: 36
            },
            {
              color: '#333333'
            },
            {
              lightness: 40
            }
          ]
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f2f2f2'
            },
            {
              lightness: 19
            }
          ]
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#fefefe'
            },
            {
              lightness: 20
            }
          ]
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#fefefe'
            },
            {
              lightness: 17
            },
            {
              weight: 1.2
            }
          ]
        }
      ]
    };
  }

  get center(): LatLngPosition {
    if (this.element.properties.centerLat && this.element.properties.centerLng) {
      return {
        lat: this.element.properties.centerLat,
        lng: this.element.properties.centerLng
      };
    }

    if (this.element.properties && this.element.properties.markers && this.element.properties.markers.length > 0) {
      return this.element.properties.markers[0].position;
    }

    return {
      lat: 10,
      lng: 10
    };
  }
}
</script>
