<template>
  <div class="editor-color-picker">
    <p-color-picker v-model="color">
      <template v-slot="{ color }">
        <p-button color-type="tertiary" icon="color-picker" title="Color picker" :class="buttonClass" />
        <div class="editor-color-picker__color-indicator" :style="`background:${color};`"></div>
      </template>
      <template v-slot:extra>
        <button class="editor-color-picker__remove-button" @click="removeColor">/</button>
      </template>
    </p-color-picker>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';
import { Sketch } from 'vue-color';

@Component({
  components: { Sketch }
})
export default class EditorColorPicker extends Vue {
  @Prop({ type: Object, required: true }) public readonly editor!: Editor;

  // Convert 'color' into a computed property
  public get color() {
    return this.editor.getAttributes('textStyle').color || '';
  }

  public set color(value: string) {
    this.editor.chain().setColor(value).run();
    setTimeout(() => {
      this.editor.view.focus();
    }, 0);
  }

  public removeColor() {
    this.editor.chain().unsetColor().run();
    setTimeout(() => {
      this.editor.view.focus();
    }, 0);
  }

  // Computed property for the button class
  public get buttonClass() {
    return { 'toolbar-button--active': this.editor.isActive('color') };
  }
}
</script>

<style lang="scss" scoped>
.editor-color-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &__remove-button {
    position: absolute;
    bottom: 1px;
    right: 0;
    background: none;
    border: none;
    color: var(--color-negative-400);
    cursor: pointer;
    font-size: 1.5rem;
    padding: var(--button-padding-medium);
    height: var(--field-height-medium);
    border-radius: var(--button-border-radius);

    &:active {
      background: var(--button-color-background-pressed);
      border-color: var(--button-color-border-pressed);
      color: var(--text-color-default-inverted);
      text-decoration-line: none;
    }
  }

  &__color-indicator {
    height: 3px;
    width: 50%;
    bottom: 3px;
    position: absolute;
    margin: 0 auto;
    right: 25%;
  }

  > ::v-deep {
    .color-picker {
      border: none;
      background: none;
      gap: 0;
      height: auto;
      padding: 0;
    }
  }
}
</style>
