<template>
  <div class="media-selector" :class="{ 'media-selector--button': tmp || selectionMode === 'folder' }">
    <template v-if="tmp || selectionMode === 'folder'">
      <p-button :disabled.prop="disabled" @click="tmp ? openTmpDialog() : openDialog()">{{ buttonText }}</p-button>
    </template>

    <template v-else>
      <div
        class="media-selector__preview"
        :class="{ 'media-selector__preview--select': cursorSelect }"
        v-if="preview || cursorSelect"
        @click="onCursorSelect"
        @mousedown.prevent="onCursorSelectStart"
        @mousemove="onCursorSelectMove"
        @mouseleave="onCursorSelectEnd"
        @mouseup="onCursorSelectEnd"
      >
        <img
          v-if="modelValue && typeof modelValue === 'string' && cursorSelect"
          class="media-selector__preview-img"
          :src="modelValue"
          ref="image"
          @drag.prevent
        />

        <div
          class="media-selector__preview-image"
          v-else-if="modelValue && typeof modelValue === 'string'"
          :style="{ backgroundImage: `url(${modelValue})` }"
        ></div>
      </div>

      <div class="media-selector__body">
        <div class="media-selector__row">
          <div class="media-selector__button">
            <p-button size="medium" color-type="secondary" :disabled.prop="disabled || readonly" @click="openDialog()"
              >Select media</p-button
            >
          </div>

          <div class="media-selector__input">
            <div class="media-selector__input-wrapper">
              <p-input
                :readonly.prop="readonly"
                :disabled.prop="disabled"
                :placeholder="placeholder"
                :value="modelValue"
                :error.prop="error"
                @change="onInputChange"
              />

              <div class="media-selector__input-copy" v-if="modelValue" @click="copy">
                <p-icon size="small" icon="check" v-if="copied" />
                <p-icon size="small" icon="copy" v-else />
              </div>
            </div>
          </div>
        </div>

        <div class="media-selector__row">
          <slot></slot>
        </div>
      </div>
    </template>

    <p-modal-media
      v-if="showModal"
      @close-request="closeDialog()"
      :initial-opened-media="modelValue"
      :max-width="maxWidth"
      :max-height="maxHeight"
      :min-width="minWidth"
      :min-height="minHeight"
      :aspect-ratio="aspectRatio"
      :extensions="extensions"
      :modelValue="modelValue"
      :selection-mode="selectionMode"
      selection
      @select="onSelect"
    />

    <upload
      v-else-if="showTmpModal"
      @close-request="closeTmpDialog()"
      :max-width="maxWidth"
      :max-height="maxHeight"
      :min-width="minWidth"
      :min-height="minHeight"
      :aspect-ratio="aspectRatio"
      :extensions="extensions"
      tmp
      @uploaded="onUploaded"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getDefaultExtensions } from '../modal/media/utils';
import { PropType } from 'vue';
import Upload from '../modal/media/Upload.vue';
import { MediaObject } from '../modal/media/types';
import { copyToClipboard } from '@/utility';

@Component({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false,
  components: { Upload }
})
export default class extends Vue {
  @Prop({ type: [String, Array], required: true }) public readonly modelValue!: string | string[];
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly readonly!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly preview!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly cursorSelect!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly error!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly placeholder?: string;

  @Prop({ type: String as PropType<'file' | 'folder'>, required: false, default: 'file' })
  public readonly selectionMode!: 'file' | 'folder';

  @Prop({ type: Number, required: false, default: undefined })
  public readonly maxWidth!: number;

  @Prop({ type: Number, required: false, default: undefined })
  public readonly maxHeight!: number;

  @Prop({ type: Number, required: false, default: undefined })
  public readonly minWidth!: number;

  @Prop({ type: Number, required: false, default: undefined })
  public readonly minHeight!: number;

  @Prop({ type: String, required: false, default: undefined })
  public readonly aspectRatio!: string;

  @Prop({
    type: Array as PropType<string[]>,
    required: false,
    default: () => getDefaultExtensions()
  })
  public readonly extensions?: string[];

  @Prop({ type: Boolean, required: false, default: false })
  public readonly multiple!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  public readonly tmp!: boolean;

  @Prop({ type: String, required: false, default: 'Upload file' })
  public readonly buttonText!: string;

  public showModal = false;
  public showTmpModal = false;
  public trackingCursor = false;
  public copied = false;

  public onUploaded(objects: MediaObject[]) {
    if (objects.length > 0) {
      this.$emit('update:modelValue', this.multiple ? objects.map((o) => o.file) : objects[0].file);
    }
  }

  public onCursorSelect(e: MouseEvent) {
    if (this.cursorSelect && this.$refs.image instanceof HTMLImageElement) {
      const bounds = this.$refs.image.getBoundingClientRect();
      const x = Math.min(Math.max(0, Math.ceil(e.pageX - Math.abs(window.scrollX) - bounds.left)), bounds.width);
      const y = Math.min(Math.max(0, Math.ceil(e.pageY - Math.abs(window.scrollY) - bounds.top)), bounds.height);

      this.$emit('cursor-select', x, y);
    }
  }

  public onCursorSelectStart() {
    this.trackingCursor = true;
  }

  public onCursorSelectMove(e: MouseEvent) {
    if (this.cursorSelect && this.trackingCursor) {
      this.onCursorSelect(e);
    }
  }

  public onCursorSelectEnd() {
    this.trackingCursor = false;
  }

  public onSelect(path: string) {
    this.closeDialog();
    this.$emit('update:modelValue', path);
  }

  public openDialog() {
    this.showModal = true;
    this.$emit('dialog-open');
  }

  public closeDialog() {
    this.showModal = false;
    this.$emit('dialog-close');
  }

  public openTmpDialog() {
    this.showTmpModal = true;
    this.$emit('dialog-open');
  }

  public closeTmpDialog() {
    this.showTmpModal = false;
    this.$emit('dialog-close');
  }

  public copy() {
    if (this.copied || typeof this.modelValue !== 'string') {
      return;
    }

    this.copied = true;

    copyToClipboard(
      (this.modelValue.startsWith('/files') ? window.location.protocol + '//' + window.location.host : '') +
        this.modelValue
    );

    setTimeout(() => {
      this.copied = false;
    }, 1500);
  }

  public onInputChange(e: CustomEvent<string[]>) {
    if (e.detail[0].startsWith('http') && e.detail[0].includes('/files/')) {
      const newValue = e.detail[0].split('/files').pop();
      this.$emit('update:modelValue', `/files${newValue}`);
      return;
    }

    this.$emit('update:modelValue', e.detail[0]);
  }
}
</script>

<style lang="scss" scoped>
.media-selector {
  &:not(.media-selector--button) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: var(--gap-size-medium);
    width: 100%;
  }

  &--button {
    display: inline-block;
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: var(--gap-size-small);
    justify-content: space-between;
    width: 100%;
  }

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: var(--gap-size-medium);
    width: 100%;
  }

  &__preview {
    width: 81px;
    height: 81px;
    position: relative;

    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPgo8ZGVmcz4KPHBhdHRlcm4gaWQ9InNtYWxsR3JpZCIgd2lkdGg9IjgiIGhlaWdodD0iOCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CjxwYXRoIGQ9Ik0gOCAwIEwgMCAwIDAgOCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJncmF5IiBzdHJva2Utd2lkdGg9IjAuMyIgc3Ryb2tlLW9wYWNpdHk9IjAuMyI+PC9wYXRoPgo8L3BhdHRlcm4+CjxwYXR0ZXJuIGlkPSJtZWRpdW1HcmlkIiB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHBhdHRlcm5Vbml0cz0idXNlclNwYWNlT25Vc2UiPgo8cmVjdCB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIGZpbGw9InVybCgjc21hbGxHcmlkKSI+PC9yZWN0Pgo8cGF0aCBkPSJNIDQwIDAgTCAwIDAgMCA0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJncmF5IiBzdHJva2Utd2lkdGg9IjAuOCIgc3Ryb2tlLW9wYWNpdHk9IjAuMiI+PC9wYXRoPgo8L3BhdHRlcm4+CjxwYXR0ZXJuIGlkPSJncmlkIiB3aWR0aD0iODAiIGhlaWdodD0iODAiIHBhdHRlcm5Vbml0cz0idXNlclNwYWNlT25Vc2UiPgo8cmVjdCB3aWR0aD0iODAiIGhlaWdodD0iODAiIGZpbGw9InVybCgjbWVkaXVtR3JpZCkiPjwvcmVjdD4KPHBhdGggZD0iTSA4MCAwIEwgMCAwIDAgODAiIGZpbGw9Im5vbmUiIHN0cm9rZT0iZ3JheSIgc3Ryb2tlLXdpZHRoPSIyLjUiIHN0cm9rZS1vcGFjaXR5PSIwLjEiPjwvcGF0aD4KPC9wYXR0ZXJuPgo8L2RlZnM+CjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JpZCkiPjwvcmVjdD4KPC9zdmc+);
    background-repeat: repeat;

    flex-shrink: 0;

    &--select {
      width: 161px;
      height: 161px;
      padding: 10px;
      cursor: crosshair;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-image {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 80%;
      height: 80%;
      transform: translate(-50%, -50%);
      background-size: contain;
      background-repeat: no-repeat;
    }

    &-img {
      max-width: 100%;
    }
  }

  &__button {
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__input {
    width: 100%;

    &-copy {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 var(--base-size-200);
      background-color: var(--field-color-background-hover);
      border-top-right-radius: var(--border-radius-small);
      border-bottom-right-radius: var(--border-radius-small);
      border: 1px solid var(--field-color-border-default);
      border-left: none;
      cursor: pointer;
      opacity: 0;
      transition: opacity 125ms linear;
      pointer-events: none;
    }

    &-wrapper {
      position: relative;

      &:hover > .media-selector__input-copy {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
</style>
