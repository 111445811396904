<template>
  <p-modal-plain v-if="show" :show.prop="true" position="top" headline="Source code" @close-request="closeModal">
    <p-row justify-content="center">
      <p-column>
        <p-container>
          <p-form-textarea size="full" v-model="localHtml" :rows="15" />
          <p-row justify-content="flex-end">
            <p-button type="default" color-type="tertiary" @click="closeModal">Cancel</p-button>
            <p-button type="default" color-type="primary" @click="emitInsert">Save</p-button>
          </p-row>
        </p-container>
      </p-column>
    </p-row>
  </p-modal-plain>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class CodeModal extends Vue {
  @Prop({ type: Boolean, required: true }) public readonly show!: boolean;
  @Prop({ type: String, required: true }) public readonly html!: string;

  public localHtml = '';

  // Emit insert event to parent component
  public emitInsert() {
    this.$emit('update:html', this.localHtml); // Emit updated localHtml back to parent
    this.$emit('insert'); // Emit insert event to parent to trigger the editor insertion
  }

  // Emit close event to parent component
  public closeModal() {
    this.$emit('close');
  }

  // Sync localHtml when parent prop changes
  @Watch('html', { immediate: true, deep: true })
  public onHtmlChanged(newVal: string) {
    this.localHtml = newVal; // Sync localHtml with the parent prop when it changes
  }
}
</script>
