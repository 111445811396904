<template>
  <p-form-element :disabled="disabled?.length === options.length" :error="error" :label="label" :help-text="helpText">
    <p-checkboxes
      :model-value="modelValue"
      @update:modelValue="
        (value) => {
          $emit('update:modelValue', value);
        }
      "
      :options="options"
      :disabled="disabled"
      :error="!!error"
    />
  </p-form-element>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CheckboxesOption } from './types';

@Component({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: Array as PropType<Array<string | number>>, required: true }) public readonly modelValue!: Array<
    string | number
  >;
  @Prop({ type: Array as PropType<CheckboxesOption[]>, required: true }) public readonly options!: CheckboxesOption[];
  @Prop({ type: String, required: false, default: undefined }) public readonly error?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: Array as PropType<Array<string | number>>, required: false, default: undefined })
  public readonly disabled?: Array<string | number>;
}
</script>
