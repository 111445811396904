<template>
  <div class="indicator" :class="[`indicator--type-${type} indicator--size-${size}`, { 'indicator--no-fill': noFill }]">
    <p-icon :size="iconSize" :icon="icon" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PropType } from 'vue';

@Component({
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public readonly noFill!: boolean;

  @Prop({
    type: String as PropType<'positive' | 'warning' | 'negative' | 'info' | 'neutral' | 'premium'>,
    required: true,
    validator: (value: string) => ['positive', 'warning', 'negative', 'info', 'neutral', 'premium'].includes(value)
  })
  public readonly type!: 'positive' | 'warning' | 'negative' | 'info' | 'neutral' | 'premium';

  @Prop({
    type: String as PropType<'small' | 'medium' | 'large'>,
    required: false,
    default: 'large',
    validator: (value: string) => {
      return ['small', 'medium', 'large'].includes(value);
    }
  })
  public readonly size!: 'small' | 'medium' | 'large';

  public get icon() {
    switch (this.type) {
      case 'positive':
        return 'check';

      case 'warning':
        return 'alert-triangle';

      case 'negative':
        return 'x';

      case 'neutral':
        return 'circle';

      case 'premium':
        return 'star';

      case 'info':
        return 'info';
    }

    return null;
  }

  public get iconSize() {
    switch (this.size) {
      case 'small':
        return 'small';

      case 'medium':
        return 'medium';

      case 'large':
        return 'large';
    }

    return null;
  }
}
</script>

<style lang="scss" scoped>
.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;

  &--size {
    &-small {
      width: 34px;
      height: 34px;
    }

    &-medium {
      width: 48px;
      height: 48px;
    }

    &-large {
      width: 60px;
      height: 60px;
    }
  }

  &--type {
    &-positive {
      color: var(--text-color-positive);

      &:not(.indicator--no-fill) {
        background: var(--color-background-positive);
        border: 1px solid var(--color-border-positive);
      }
    }

    &-warning {
      color: var(--text-color-warning);

      &:not(.indicator--no-fill) {
        border: 1px solid var(--color-border-warning);
        background: var(--color-background-warning);
      }
    }

    &-negative {
      color: var(--text-color-negative);

      &:not(.indicator--no-fill) {
        border: 1px solid var(--color-border-negative);
        background: var(--color-background-negative);
      }
    }

    &-info {
      color: var(--text-color-info);

      &:not(.indicator--no-fill) {
        border: 1px solid var(--color-border-info);
        background: var(--color-background-info);
      }
    }

    &-neutral {
      color: var(--text-color-neutral);

      &:not(.indicator--no-fill) {
        border: 1px solid var(--color-border-decorative);
        background: var(--color-background-layer-base);
      }
    }

    &-premium {
      color: var(--text-color-premium);

      &:not(.indicator--no-fill) {
        border: 1px solid var(--color-border-premium);
        background: var(--color-background-premium);
      }
    }
  }
}
</style>
