<template>
  <p-form-select
    :label="element.properties.label"
    :help-text="element.properties.help"
    :help-in-tooltip="element.properties.helpInTooltip"
    :disabled="element.properties.disabled"
    :multiple="element.properties.multiple"
    :icon="element.properties.icon"
    :searchable="element.properties.searchable || element.properties.typeahead?.url"
    :filterable="element.properties.searchable && !element.properties.typeahead?.url"
    :error="element.properties.errors.join(', ')"
    :size="element.properties.size"
    :placeholder="element.properties.placeholder"
    :options="element.properties.options"
    v-model="element.properties.value"
    @update:modelValue="onChange()"
    @search="onSearchChange($event.detail[0])"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementSelect } from '@/interfaces/element';
import { Trigger } from '@/Trigger';
import { Iframe } from '@/iframe';
import { EventBus } from '@/main';
import { debounce } from 'lodash-decorators';
import Axios, { CancelTokenSource } from 'axios';
import { autobind } from 'core-decorators';

const CancelToken = Axios.CancelToken;

@Component({
  components: {},
  name: 'layout-element-select'
})
export default class extends Vue {
  @Prop() public element!: IElementSelect;
  public loading = false;
  public loadingTimer: number | null = null;
  public source: CancelTokenSource | null = null;

  created() {
    EventBus.$on('TRIGGER_UPDATE', this.onUpdateListener);
    EventBus.$on('BLUEPRINT_UPDATED', this.onUpdatedListener);
    this.onSearchChange();
  }

  beforeDestroy() {
    EventBus.$off('TRIGGER_UPDATE', this.onUpdateListener);
    EventBus.$off('BLUEPRINT_UPDATED', this.onUpdatedListener);
  }

  onUpdateListener(target: HTMLElement) {
    if (
      this.$el === target ||
      this.$el.parentElement === target ||
      (this.$el.parentElement && this.$el.parentElement.parentElement === target) ||
      (this.$el.parentElement &&
        this.$el.parentElement.parentElement &&
        this.$el.parentElement.parentElement.parentElement === target)
    ) {
      if (this.loadingTimer !== null) {
        clearTimeout(this.loadingTimer);
        this.loadingTimer = null;
      }

      this.loadingTimer = setTimeout(() => {
        this.loading = true;
        this.loadingTimer = null;
      }, 500);
    }
  }

  onUpdatedListener() {
    if (this.loadingTimer !== null) {
      clearTimeout(this.loadingTimer);
      this.loadingTimer = null;
    }

    this.loading = false;
  }

  @debounce(375)
  @autobind
  async onSearchChange(search?: string) {
    if (this.element.properties.value && !search) {
      return;
    }

    if (this.element.properties?.typeahead?.url) {
      this.loading = true;

      if (this.source !== null) {
        this.source.cancel();
        this.source = null;
      }

      this.source = CancelToken.source();

      try {
        const response = await this.axios.get(this.element.properties.typeahead.url, {
          cancelToken: this.source.token,
          headers: {
            accept: 'application/json'
          },
          responseType: 'json',
          params: {
            search: search || ''
          }
        });

        this.element.properties.options = response.data;
        this.loading = false;
      } catch (e) {
        if (Axios.isCancel(e)) {
          return;
        }

        throw e;
      }
    }
  }

  @Watch('element.properties.value')
  onFastValueChange() {
    if (this.element.properties.name) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }
  }

  onChange() {
    this.$el.dispatchEvent(
      new CustomEvent('BLUEPRINT_INTERACT', {
        bubbles: true,
        composed: true
      })
    );

    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(this.element.properties.trigger, this.$el, this.element.properties.name);
    }
  }
}
</script>
