<template>
  <Fragment>
    <p-main-header v-if="!hasTemplate" />

    <p-custom-freemium-topbar v-if="workSpaceStore.workspace?.freemium_limit_exceeded" />

    <router-view />
  </Fragment>
</template>

<script lang="ts">
import { useWorkspaceStore } from '@/store/workspaceStore';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class extends Vue {
  public workSpaceStore = useWorkspaceStore();

  public get hasTemplate() {
    return !!this.$route.query.template;
  }
}
</script>
