<template>
  <p-modal-plain
    v-if="show"
    :show.prop="true"
    position="top"
    :headline="editMode ? 'Edit link' : 'Insert link'"
    @close-request="cancelLink"
  >
    <p-row justify-content="center">
      <p-column size="medium">
        <p-container>
          <p-form-select
            v-model="linkType"
            label="Link Type"
            :options="
              [
                { text: 'URL', value: 'url' },
                popovers && popovers.length > 0 && { text: 'Open popovers', value: 'popover' },
                popovers && popovers.length > 0 && { text: 'Close popover', value: 'close-popover' },
                sections.length > 0 && { text: 'Go to section', value: 'section' }
              ].filter(Boolean)
            "
            placeholder="Select link type"
            size="medium"
          />

          <p-form-input v-if="linkType === 'url'" v-model="linkUrl" label="URL" placeholder="Enter URL" size="medium" />
          <p-form-select
            v-if="linkType === 'popover'"
            v-model="linkSelectedPopover"
            label="Select Popover"
            :options="popoverOptions"
            placeholder="Select a popover"
            size="medium"
          />
          <p-form-select
            v-if="linkType === 'section'"
            v-model="linkSelectedSection"
            label="Select Section"
            :options="sectionOptions"
            placeholder="Select a section"
            size="medium"
          />

          <p-form-input
            v-if="!editor.isActive('image') && !hasMultipleParagraphs"
            v-model="linkText"
            label="Text to Display"
            placeholder="Text to Display"
            size="medium"
          />
          <p-form-input v-model="linkTitle" type="text" label="Title" placeholder="Title" size="medium" />

          <p-form-select
            v-if="linkType === 'url'"
            v-model="linkTarget"
            label="Open link in..."
            :options="[
              { text: 'Open in current window', value: '_self' },
              { text: 'Open in new window', value: '_blank' }
            ]"
            placeholder="Select link target"
            size="medium"
          />

          <p-row justify-content="flex-end">
            <p-button type="default" color-type="tertiary" @click="cancelLink">Cancel</p-button>
            <p-button type="default" color-type="primary" @click="insertLink">Save</p-button>
          </p-row>
        </p-container>
      </p-column>
    </p-row>
  </p-modal-plain>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EditorPageOption } from '../../types';
import { Editor } from '@tiptap/core';

@Component
export default class LinkModal extends Vue {
  @Prop({ type: Boolean, required: true }) public readonly show!: boolean;
  @Prop({ type: Boolean, required: true }) public readonly editMode!: boolean;
  @Prop({ type: Array, required: false, default: () => [] }) public readonly popovers!: EditorPageOption[];
  @Prop({ type: Number, required: false, default: () => null }) public readonly selectedPopover!: number | null;
  @Prop({ type: Array, required: false, default: () => [] }) public readonly sections!: EditorPageOption[];
  @Prop({ type: Number, required: false, default: () => null }) public readonly selectedSection!: number | null;
  @Prop({ type: Object, required: false }) public readonly editor!: Editor;
  @Prop({ type: String, required: false }) public readonly type!: string;
  @Prop({ type: String, required: false }) public readonly text!: string;
  @Prop({ type: String, required: false }) public readonly url!: string;
  @Prop({ type: String, required: false }) public readonly title!: string;
  @Prop({ type: String, required: false }) public readonly target!: string;
  @Prop({ type: Boolean, required: false }) public readonly hasMultipleParagraphs!: boolean;

  public linkType = 'url';
  public linkSelectedSection: number | null = null;
  public linkSelectedPopover: number | null = null;
  public linkUrl = '';
  public linkText = '';
  public linkTitle = '';
  public linkTarget = '_self';

  // Watch for when showLinkModal becomes true, and load data from props
  @Watch('show', { immediate: true })
  public onShowLinkModalChanged(newVal: boolean) {
    if (newVal) {
      this.linkType = this.type || 'url';
      this.linkSelectedPopover = this.selectedPopover || null;
      this.linkSelectedSection = this.selectedSection || null;
      this.linkText = this.text || '';
      this.linkUrl = this.url || '';
      this.linkTitle = this.title || '';
      this.linkTarget = this.target || '_self';
    }
  }

  public get popoverOptions() {
    return this.popovers.map((popover) => ({
      text: popover.text,
      value: popover.value
    }));
  }
  public get sectionOptions() {
    return this.sections.map((section) => ({
      text: section.text,
      value: section.value
    }));
  }

  public cancelLink() {
    this.$emit('close-request');
  }

  public insertLink() {
    // Check if the URL doesn't start with http:// or https://
    if (this.linkType === 'url' && this.linkUrl && !/^https?:\/\//.test(this.linkUrl)) {
      const addPrefix = window.confirm(
        'The URL you entered seems to be an external link. Do you want to add the required https:// prefix?'
      );
      if (addPrefix) {
        this.linkUrl = `https://${this.linkUrl}`;
      }
    }

    this.$emit('insert-link', {
      linkType: this.linkType,
      linkUrl: this.linkUrl,
      selectedPopover: this.linkSelectedPopover,
      selectedSection: this.linkSelectedSection,
      // if we are selection multiple text paragraph, we don't want to insert the link text
      // since it would overwrite them all and just make one big link
      linkText: this.hasMultipleParagraphs ? '' : this.linkText,
      linkTitle: this.linkTitle,
      linkTarget: this.linkTarget
    });
  }
}
</script>
