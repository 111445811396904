import { BaseSnowplowEvent } from '@/snowplow/events/BaseSnowplowEvent';
import {
  newTracker,
  SelfDescribingEvent,
  trackSelfDescribingEvent,
  trackPageView,
  PageViewEvent,
  CommonEventProperties
} from '@snowplow/browser-tracker';
import { AppConfig } from '@/app_config';

let initialized = false;

const initTracker = async () => {
  if (initialized) {
    return;
  }

  initialized = true;

  const config = await AppConfig.get();

  if (config && config.analytics) {
    newTracker('playableTracker', config.analytics.endPoint, {
      appId: 'playable-analytics',
      plugins: []
    });
  }
};

/**
 * Snowplow Documentation on Custom Events:
 * https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/browser-tracker-v3-reference/tracking-events/#tracking-custom-self-describing-events
 */
export class Snowplow {
  static async emit(event: BaseSnowplowEvent<unknown>) {
    await initTracker();

    trackSelfDescribingEvent(event.getSnowplowRequestData() as SelfDescribingEvent, ['playableTracker']);
  }

  static async pageView(event?: PageViewEvent & CommonEventProperties) {
    await initTracker();

    trackPageView(event, ['playableTracker']);
  }
}
