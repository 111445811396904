<template>
  <div class="menu-header" :class="{ 'menu-header--open': menuOpen }">
    <menu-campaigns
      :show="campaignsOverlay"
      @close-request="campaignsOverlay = false"
      @open-create-campaign="openCreateCampaign()"
    />

    <menu-user-overview
      v-if="user && workspace"
      :show="userOverlay"
      :user="user"
      :workspace="workspace"
      @close-request="userOverlay = false"
    />

    <menu-workspaces :show="workspacesOverlay" @close-request="workspacesOverlay = false" />
    <menu-search :show="searchOverlay" @close-request="searchOverlay = false" />

    <div class="menu-header__inner">
      <div class="menu-header__left">
        <p-link href="/dashboard">
          <img :src="logo" :width="125" :height="30" />
        </p-link>

        <ul v-device-desktop>
          <li :class="{ active: $route.path.startsWith('/dashboard') }">
            <router-link to="/dashboard">Dashboard</router-link>
          </li>
          <li :class="{ active: $route.path.startsWith('/campaigns') || $route.path.startsWith('/campaign/') }">
            <a href="#" @click.prevent.stop="openCampaignsOverlay()">Campaigns</a>
          </li>
          <li :class="{ active: $route.path.startsWith('/account') }">
            <router-link to="/account/my-settings">Account</router-link>
          </li>
          <li><a href="#" @click.prevent.stop="showMedia = true">Media</a></li>
          <li><a href="https://help.playable.com/en/" target="_blank">Help center</a></li>
        </ul>

        <div class="menu-header__mobile-navigation">
          <div class="menu-header__mobile-navigation-close">
            <p-button color-type="tertiary" icon="x" size="large" @click="toggleMenu()" />
          </div>

          <ul class="menu-header__mobile-list">
            <li :class="{ active: $route.path.startsWith('/dashboard') }">
              <router-link to="/dashboard">Dashboard</router-link>
            </li>
            <li :class="{ active: $route.path.startsWith('/campaigns') || $route.path.startsWith('/campaign/') }">
              <a href="#" @click.prevent.stop="openCampaignsOverlay()">Campaigns</a>
            </li>
            <li :class="{ active: $route.path.startsWith('/account') }">
              <router-link to="/account/my-settings">Account</router-link>
            </li>
            <li><a href="#" @click.prevent.stop="toggleMediaOn()">Media</a></li>
            <li><a href="https://help.playable.com/en/" target="_blank">Help center</a></li>

            <li class="mobile-workspace"><a href="#" @click.prevent.stop="openWorkspaceOverlay()">Workspace</a></li>
          </ul>
        </div>
      </div>

      <div class="menu-header__right">
        <ul>
          <li><p-button color-type="tertiary" icon="search" size="medium" @click="openSearchOverlay()" /></li>
          <li class="beamerTrigger">
            <p-button color-type="tertiary" icon="bell" size="medium" @click="openBeamer()" />
          </li>
          <li>
            <menu-users @click.native="openUserOverlay()" stacked />
          </li>

          <li v-device-mobile>
            <p-button color-type="tertiary" icon="menu" size="medium" @click="toggleMenu()" />
          </li>
        </ul>

        <div class="customer" v-device-desktop>
          <div class="customer__current" @click="openWorkspaceOverlay()">
            <span class="customer__current-text">{{ workspace?.name ?? 'Loading...' }}</span>
            <span class="customer__current-icon"><p-icon size="small" icon="chevron-down"></p-icon></span>
          </div>
        </div>

        <p-button type="cta" color-type="secondary" @click="openCreateCampaign()" v-device-desktop
          >Create campaign</p-button
        >
      </div>
    </div>

    <layout-element-popup
      v-if="showCreateCampaign"
      :url="$route.query.type ? `/campaign/create-guide/${$route.query.type}` : '/campaign/create-guide/'"
      headline="Create new campaign"
      @closePopup="showCreateCampaign = false"
    />

    <p-modal-media v-if="showMedia" @close-request="showMedia = false" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import logo from '@/assets/images/logo.svg';
import MenuCampaigns from './MenuCampaigns.vue';
import MenuUserOverview from './MenuUserOverview.vue';
import MenuWorkspaces from './MenuWorkspaces.vue';
import MenuUsers from './MenuUsers.vue';
import MenuSearch from './MenuSearch.vue';
import { AppRequest } from '@/app_request';
import LayoutElementPopup from '@/components/LayoutElementPopup.vue';
import { EventBus } from '@/main';
import { UserResource } from '@/types/api/user';
import { WorkspaceResource } from '@/types/api/workspace';
import Axios from 'axios';
import { enableIntercom } from '@/utility/intercom';
import { useUserStore } from '@/store/userStore';
import { useWorkspaceStore } from '@/store/workspaceStore';

interface IntercomDetails {
  user: {
    id: number;
    name: string;
    email: string;
    hash: string;
    last_login: number | null;
    created_on: number | null;
  };

  workspace: {
    id: number;
    name: string;
    plan: string;
    created_on: number | null;
  };
}

@Component({
  components: { LayoutElementPopup, MenuCampaigns, MenuUserOverview, MenuWorkspaces, MenuSearch, MenuUsers }
})
export default class extends Vue {
  public campaignsOverlay = false;
  public administrationOverlay = false;
  public workspacesOverlay = false;
  public userOverlay = false;
  public searchOverlay = false;
  public menuOpen = false;
  public showMedia = false;

  public showCreateCampaign = false;

  public logo = logo;
  public user: UserResource | null = null;
  public workspace: WorkspaceResource | null = null;

  private closeOverlays() {
    this.campaignsOverlay =
      this.userOverlay =
      this.administrationOverlay =
      this.workspacesOverlay =
      this.searchOverlay =
      this.menuOpen =
        false;
  }

  public async mounted() {
    EventBus.$on('BLUEPRINT_CLOSE_POPUP', this.closePopup);

    const userStore = useUserStore();

    try {
      const response = (
        await AppRequest.get<{ user: UserResource; origin: { user: UserResource; workspace: WorkspaceResource } }>(
          '/api/v1/user/whoami'
        )
      ).data;

      this.user = response.user;

      userStore.user = this.user;
      userStore.origin = response.origin;
    } catch (e) {
      if (Axios.isAxiosError(e) && e.response?.status === 503) {
        await this.$router.push({ path: '/maintenance' });
        return;
      }

      if (Axios.isAxiosError(e) && e.response?.status === 401) {
        this.$router.push('/');
        return;
      }

      throw e;
    }

    const response = await AppRequest.get<{ data: WorkspaceResource }>('/api/v1/workspace/whoami');
    this.workspace = response.data.data;
    useWorkspaceStore().workspace = this.workspace;

    try {
      const intercomDetails = (await AppRequest.get<IntercomDetails>('/api/v1/user/intercom')).data;

      if (intercomDetails.user.hash) {
        enableIntercom({
          user: {
            id: intercomDetails.user.id,
            name: intercomDetails.user.name,
            email: intercomDetails.user.email,
            hash: intercomDetails.user.hash,
            last_login: intercomDetails.user.last_login ?? undefined,
            created_at: intercomDetails.user.created_on ?? undefined
          },
          company: {
            id: intercomDetails.workspace.id,
            name: intercomDetails.workspace.name,
            plan: intercomDetails.workspace.plan,
            created_at: intercomDetails.workspace.created_on ?? undefined
          }
        });
      }
    } catch (e) {
      // 404 errors means intercom is not enabled for the customer
      if (Axios.isAxiosError(e) && e.response?.status === 404) {
        return;
      }

      throw e;
    }

    this.$router.afterEach(() => {
      this.closeOverlays();
    });
  }

  public beforeUnmount() {
    EventBus.$off('BLUEPRINT_CLOSE_POPUP', this.closePopup);
  }

  public closePopup() {
    this.showCreateCampaign = false;
  }

  public openCampaignsOverlay() {
    if (this.campaignsOverlay) {
      this.campaignsOverlay = false;
      return;
    }

    this.closeOverlays();
    this.campaignsOverlay = true;
  }

  public openUserOverlay() {
    this.closeOverlays();
    this.userOverlay = true;
  }

  public openAdministrationOverlay() {
    if (this.administrationOverlay) {
      this.administrationOverlay = false;
      return;
    }

    this.closeOverlays();
    this.administrationOverlay = true;
  }

  public openWorkspaceOverlay() {
    if (this.workspacesOverlay) {
      this.workspacesOverlay = false;
      return;
    }

    this.closeOverlays();
    this.workspacesOverlay = true;
  }

  public openSearchOverlay() {
    if (this.searchOverlay) {
      this.searchOverlay = false;
      return;
    }

    this.closeOverlays();
    this.searchOverlay = true;
  }

  public openBeamer() {
    this.closeOverlays();

    (window as any).Beamer.show();
  }

  public openCreateCampaign() {
    this.closeOverlays();
    this.showCreateCampaign = true;
  }

  public toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  public toggleMediaOn() {
    this.showMedia = true;
    this.menuOpen = false;
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/mixins/typography';
@import '../../../../scss/mixins/devices';

.menu-header {
  position: relative;
  display: flex;
  justify-content: center;
  background: var(--color-background-layer-2-inverted);
  color: var(--navigation-color-text-default);
  z-index: 10;
  min-width: 1200px;

  &__mobile-list {
    height: 100%;

    .mobile-workspace {
      margin-top: auto;
      margin-bottom: 62px; // top height 48px + 20px padding
    }
  }

  ::v-deep {
    .beamer_icon {
      right: 0 !important;
      top: -5px !important;
    }
  }

  &__inner {
    display: flex;
    width: 1200px;
    max-width: 100%;
    padding: var(--base-size-500) 0;
    justify-content: space-between;
    align-items: center;
    position: relative;

    --text-color-default: var(--navigation-color-text-default);
    --text-color-subdued: var(--navigation-color-text-default);
  }

  &__mobile-navigation {
    display: none;
    width: 200px;
    background: var(--navigation-color-layer-1);
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;

    &-close {
      display: flex;
      justify-content: flex-end;
    }

    > ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      padding: 0 var(--base-size-300);

      > li {
        > a {
          display: inline-block;
          padding: var(--base-size-100);
          border-left: 3px solid transparent;

          @include component-text-headline;
          color: var(--navigation-color-text-secondary);
          text-decoration: none;
        }

        &.active {
          > a {
            @include component-text-strong;
            color: var(--navigation-color-text-default);
            border-color: var(--color-brand-primary);
          }
        }
      }
    }
  }

  &__left {
    display: flex;
    align-items: center;
    gap: var(--gap-size-large);

    > a {
      display: flex;
      align-items: center;
    }

    > ul {
      display: flex;
      align-items: flex-start;
      gap: var(--gap-size-small);

      > li {
        display: flex;
        align-items: flex-start;
        gap: var(--gap-size-small);
        border-bottom: 3px solid transparent;

        > a {
          display: inline-block;
          padding: var(--base-size-100);

          @include component-text-headline;

          color: var(--navigation-color-text-secondary);
          text-decoration: none;

          &:hover {
            color: var(--navigation-color-text-default);
          }
        }

        &.active {
          border-bottom: 3px solid var(--color-brand-primary);

          a {
            @include component-text-strong;

            color: var(--navigation-color-text-default);
          }
        }
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    gap: var(--gap-size-small);

    > ul {
      display: flex;
      align-items: flex-start;
    }

    .customer {
      position: relative;
      display: flex;
      padding: var(--base-size-100);
      align-items: flex-start;
      justify-content: flex-end;
      max-width: 195px;

      &__current {
        max-width: 100%;
        position: relative;
        cursor: pointer;

        &-text {
          display: block;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          user-select: none;
          padding-right: var(--icon-small);

          @include component-text-headline-small;
          text-align: right;
        }

        &-icon {
          position: absolute;
          right: 0;
          top: -2px;
        }
      }
    }
  }

  &--open {
    .menu-header__mobile-navigation {
      display: block;
    }
  }
}

@media print {
  .menu-header {
    display: none;
  }
}

@include for-mobile-only {
  .menu-header {
    min-width: 0;
    max-width: 100%;
    &__inner {
      padding: 10px var(--base-size-100);
    }
    &__right {
      flex-grow: 1;
      > ul {
        flex-grow: 1;
        justify-content: space-around;
      }
    }
  }
}
</style>
