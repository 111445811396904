import { Paragraph } from '@tiptap/extension-paragraph';

export const CustomParagraph = Paragraph.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      // Generalized attributes capturing
      allAttributes: {
        default: {},
        parseHTML: (element) => {
          const attributes: Record<string, string> = {};
          element.getAttributeNames().forEach((attr) => {
            const value = element.getAttribute(attr);
            if (value !== null) {
              attributes[attr] = value;
            }
          });
          return attributes;
        },
        renderHTML: (attributes) => {
          const allAttributes: Record<string, string> = {};
          Object.keys(attributes.allAttributes || {}).forEach((attr) => {
            allAttributes[attr] = attributes.allAttributes[attr];
          });
          return allAttributes;
        }
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'p',
        getAttrs: (node) => {
          const allAttributes: Record<string, string> = {};

          node.getAttributeNames().forEach((attr) => {
            const value = node.getAttribute(attr);
            if (value !== null) {
              allAttributes[attr] = value;
            }
          });

          return { allAttributes };
        }
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    // Merge all attributes, including any custom attributes like class, id, etc.
    const attrs = {
      ...HTMLAttributes,
      ...HTMLAttributes.allAttributes
    };

    // Remove 'allAttributes' to avoid duplication in the final output
    delete attrs.allAttributes;

    return ['p', attrs, 0];
  },

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        const isParagraphActive = editor.isActive('paragraph');
        const isBulletListActive = editor.isActive('bulletList');
        const isOrderedListActive = editor.isActive('orderedList');

        // Only apply this behavior for paragraphs, excluding list items
        if (isParagraphActive && !isBulletListActive && !isOrderedListActive) {
          // Capture all current attributes of the paragraph
          const { allAttributes, ...otherAttrs } = editor.getAttributes('paragraph');

          // Split the block and apply the same attributes to the new node
          editor.commands.splitBlock();
          editor.commands.setNode('paragraph', { ...allAttributes, ...otherAttrs });

          return true;
        }

        return false;
      }
    };
  }
});
