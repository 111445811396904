export function copyToClipboard(text: string) {
  if (!navigator.clipboard) {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');

      if (!successful) {
        // eslint-disable-next-line no-console
        console.error('[Playable] Copy operation failed without reason');
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('[Playable] Copy operation failed:', err);
    }

    document.body.removeChild(textArea);
    return;
  }

  navigator.clipboard.writeText(text).catch((err) => {
    // eslint-disable-next-line no-console
    console.error('[Playable] Copy operation failed:', err);
  });
}
