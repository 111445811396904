<template>
  <div class="dictionary">
    <div class="dictionary__item" v-for="(item, itemKey) in items" :key="itemKey">
      <span class="dictionary__item-key">{{ itemKey }}:</span>
      <span class="dictionary__item-value">{{ item }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: Object as PropType<Record<string, string>>, required: true }) public readonly items!: Record<
    string,
    string
  >;
}
</script>

<style lang="scss" scoped>
@import '../../scss/mixins/typography';

.dictionary {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-size-small);
  color: var(--text-color-default);

  &__item {
    display: flex;
    align-items: flex-end;
    gap: var(--gap-size-medium);
    width: 100%;

    &-key,
    &-value {
      display: table-cell;
      text-align: left;
    }

    &-key {
      display: flex;
      width: var(--field-width-small);
      align-items: flex-start;

      @include text-body-small;
    }

    &-value {
      display: flex;
      width: var(--field-width-small);
      align-items: flex-start;

      @include text-body-small-strong;
    }
  }
}
</style>
