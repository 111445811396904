<template>
  <p-button
    color-type="tertiary"
    icon="underline"
    title="Underline (⌘U)"
    @click="toggleUnderline"
    :disabled.prop="buttonDisabled"
    :class="buttonClass"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';

@Component
export default class EditorUnderline extends Vue {
  @Prop({ type: Object, required: true }) public readonly editor!: Editor;

  public get buttonDisabled() {
    return !this.editor.can().chain().focus().toggleUnderline().run();
  }

  public get buttonClass() {
    return { 'toolbar-button--active': this.editor.isActive('orderedList') };
  }
  // Toggles the underline style in the editor
  public toggleUnderline() {
    this.editor.chain().focus().toggleUnderline().run();
  }
}
</script>
