<template>
  <div class="timepicker">
    <div class="timepicker__selector">
      <p-select
        size="extra-small"
        :options="hoursList"
        placeholder="Hour"
        :disabled.prop="disabled"
        :error.prop="!!error"
        :model-value="selectedHour"
        @select="onHourChange($event.detail[0])"
      >
        <p-select-option
          v-for="option in hoursList"
          :key="option.value"
          :value="option.value"
          :header="option.header"
          :selected.prop="option.header ? false : selectedHour === option.value"
          >{{ option.text }}</p-select-option
        >
      </p-select>
    </div>
    <span class="timepicker__divider">:</span>
    <div class="timepicker__selector">
      <p-select
        size="extra-small"
        :options="minutesList"
        placeholder="Min"
        :disabled.prop="disabled"
        :error.prop="!!error"
        :model-value="selectedMinute"
        @select="onMinuteChange($event.detail[0])"
      >
        <p-select-option
          v-for="option in minutesList"
          :key="option.value"
          :value="option.value"
          :header="option.header"
          :selected.prop="option.header ? false : selectedMinute === option.value"
          >{{ option.text }}</p-select-option
        >
      </p-select>
    </div>
    <span class="timepicker__divider" v-if="seconds">:</span>
    <div class="timepicker__selector" v-if="seconds">
      <p-select
        size="extra-small"
        :options="secondsList"
        :disabled.prop="disabled"
        :error.prop="!!error"
        placeholder="Sec"
        :model-value="selectedSecond"
        @select="onSecondChange($event.detail[0])"
      >
        <p-select-option
          v-for="option in secondsList"
          :key="option.value"
          :value="option.value"
          :header="option.header"
          :selected.prop="option.header ? false : selectedSecond === option.value"
          >{{ option.text }}</p-select-option
        >
      </p-select>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DropdownOption } from './types';

@Component({
  components: {},
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: String, required: false, default: null }) public readonly modelValue!: string | null;
  @Prop({ type: Boolean, required: false, default: false }) public readonly error?: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Boolean, required: false, default: true }) public readonly seconds!: boolean;

  public hoursList: DropdownOption[] = Array.from(Array(24).keys()).map((hour) => ({
    text: hour.toString().padStart(2, '0'),
    value: hour.toString().padStart(2, '0')
  }));

  public minutesList: DropdownOption[] = Array.from(Array(60).keys()).map((minute) => ({
    text: minute.toString().padStart(2, '0'),
    value: minute.toString().padStart(2, '0')
  }));

  public secondsList: DropdownOption[] = Array.from(Array(60).keys()).map((second) => ({
    text: second.toString().padStart(2, '0'),
    value: second.toString().padStart(2, '0')
  }));

  public onHourChange(value: string) {
    const [, minute, second] = (this.modelValue ?? '').split(':');

    if (this.seconds) {
      this.$emit('update:modelValue', `${value}:${minute ?? ''}:${second ?? ''}`);
    } else {
      this.$emit('update:modelValue', `${value}:${minute ?? ''}`);
    }
  }

  public onMinuteChange(value: string) {
    const [hour, , second] = (this.modelValue ?? '').split(':');

    if (this.seconds) {
      this.$emit('update:modelValue', `${hour ?? ''}:${value}:${second ?? ''}`);
    } else {
      this.$emit('update:modelValue', `${hour ?? ''}:${value}`);
    }
  }

  public onSecondChange(value: string) {
    const [hour, minute] = (this.modelValue ?? '').split(':');
    this.$emit('update:modelValue', `${hour ?? ''}:${minute ?? ''}:${value}`);
  }

  public get selectedHour() {
    if (!this.modelValue) {
      return '';
    }

    const [hour, ,] = this.modelValue.split(':');
    return hour ?? '';
  }

  public get selectedMinute() {
    if (!this.modelValue) {
      return '';
    }

    const [, minute, _second] = this.modelValue.split(':');
    return minute ?? '';
  }

  public get selectedSecond() {
    if (!this.modelValue) {
      return '';
    }

    const [, , second] = this.modelValue.split(':');
    return second ?? '';
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/mixins/typography';

.timepicker {
  display: inline-flex;
  width: 100%;
  gap: var(--gap-size-extra-small);
  justify-content: flex-start;
  align-items: center;

  &__divider {
    @include text-body-strong;
    color: var(--text-color-default);
  }

  &__selector {
    width: var(--field-width-extra-small);
  }
}
</style>
