<template>
  <div class="settings-navigation" :class="{ 'settings-navigation--single-item': items.length === 1 }">
    <div class="settings-navigation__container">
      <button
        v-for="item in items"
        :key="item.id"
        :disabled="disabled"
        class="settings-navigation__button"
        :class="{ 'settings-navigation__button--active': item.id === modelValue }"
        @click="onChangeItem(item)"
      >
        <span v-if="item.label">{{ item.label }}</span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface SettingsNavItem {
  id: number | string;
  label?: string;
}

@Component({
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
})
export default class extends Vue {
  @Prop({ type: [Number, String], required: true }) public readonly modelValue!: number | string;
  @Prop({ type: Array as PropType<SettingsNavItem[]>, required: true }) public readonly items!: SettingsNavItem[];
  @Prop({ type: Boolean }) public readonly disabled!: boolean;

  public onChangeItem(item: SettingsNavItem) {
    if (this.disabled) return;

    this.$emit('update:modelValue', item.id);
    this.$emit('change', item.id);
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/mixins/typography';

.settings-navigation {
  justify-content: space-between;
  width: 100%;

  &__container {
    background: var(--navigation-color-layer-base);
    width: 100%;
    display: flex;
  }

  &__button {
    display: flex;
    height: 46px;
    padding: 10px;
    flex: 1 0 0;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include component-text-headline;
    color: var(--navigation-color-text-secondary);

    &:disabled {
      cursor: not-allowed;
      color: var(--text-color-disabled);
    }

    &:hover {
      color: var(--navigation-color-text-default);
    }

    > span {
      display: inline-block;
      padding: var(--base-size-75) var(--base-size-100);
      border-bottom: 3px solid transparent;
    }

    &--active {
      @include component-text-strong;
      color: var(--navigation-color-text-default);

      > span {
        border-bottom: 3px solid var(--color-brand-primary);
      }
    }
  }

  &--single-item {
    .settings-navigation__button {
      flex: 0;
      justify-content: flex-start;
    }
  }
}
</style>
