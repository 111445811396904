<template>
  <p-form-element
    :size="size"
    :disabled="disabled"
    :readonly="readonly"
    :label="label"
    :help-text="helpText"
    :helpInTooltip="helpInTooltip"
    :ai-assistant="aiAssistant"
    :error="error"
    :modelValue="String(modelValue ?? '')"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <p-input
      :disabled.prop="disabled"
      :readonly.prop="readonly"
      :error.prop="!!error"
      :max="max"
      :min="min"
      :use-mask="useMask"
      :autofocus.prop="autofocus"
      :placeholder="placeholder"
      :append="append"
      :prepend="prepend"
      :max-length="maxLength"
      :type="type"
      :value="modelValue"
      @change="$emit('update:modelValue', $event.detail[0])"
      @blur="$emit('blur')"
      @keyup="$emit('keyup', $event)"
      @keydown="$emit('keydown', $event)"
      @keypress="$emit('keypress', $event)"
    />
  </p-form-element>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: [String, Number], required: false, default: undefined }) public readonly modelValue?: string | number;
  @Prop({
    type: String as PropType<'extra-small' | 'small' | 'medium' | 'large' | 'extra-large'>,
    default: 'extra-large'
  })
  public readonly size!: 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large';

  @Prop({
    type: String as PropType<'text' | 'email' | 'password' | 'number' | 'url'>,
    default: 'text',
    validator: (value: string) => ['text', 'email', 'password', 'number', 'url'].includes(value)
  })
  public readonly type!: 'text' | 'email' | 'password' | 'number' | 'url';

  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly helpInTooltip?: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly readonly!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly aiAssistant!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly autofocus!: boolean;
  @Prop({ type: Number, required: false, default: undefined }) public readonly maxLength?: number;
  @Prop({ type: Number, required: false, default: undefined }) public readonly max?: number;
  @Prop({ type: Number, required: false, default: undefined }) public readonly min?: number;
  @Prop({ type: String, required: false, default: undefined }) public readonly useMask?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly error?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly placeholder?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly append?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly prepend?: string;
}
</script>
