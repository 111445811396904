<template>
  <p-context-menu :close-on-click="element.properties.close ?? true">
    <template #activator>
      <layout-elements :elements="element.properties.activator" />
    </template>

    <layout-elements :elements="element.children" />
  </p-context-menu>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementContextmenu } from '@/interfaces/element';

@Component({
  name: 'layout-element-contextmenu'
})
export default class extends Vue {
  @Prop() public element!: IElementContextmenu;
}
</script>
