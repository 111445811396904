<template>
  <div>
    <span>
      <p-button icon="magic-wand" :disabled.prop="disabled" color-type="tertiary" @click="openAiDialog()" />
    </span>
    <p-modal-ai-assistant
      v-if="showAiModal"
      :modelValue="modelValue"
      @close-request="closeAiDialog()"
      @update:modelValue="$emit('update:modelValue', $event)"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Iframe } from '@/iframe';

@Component({
  name: 'p-ai-assistant',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: [String, Number], required: false }) public readonly modelValue!: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;

  public showAiModal = false;

  public openAiDialog() {
    this.showAiModal = true;
    Iframe.emit('frame-request-full-size');
    this.$emit('ai-dialog-open');
  }

  public closeAiDialog() {
    this.showAiModal = false;
    Iframe.emit('frame-request-regular-size-before');
    setTimeout(() => {
      Iframe.emit('frame-request-regular-size');
    }, 475);
    this.$emit('ai-dialog-close');
  }
}
</script>
