<template>
  <p-form-element :size="size" :disabled="disabled" :label="label" :help-text="helpText" :error="error">
    <p-search
      :model-value="modelValue"
      :disabled="disabled"
      :readonly="readonly"
      :error="!!error"
      :placeholder="placeholder"
      :autofocus="autofocus"
      @update:modelValue="
        (value) => {
          $emit('update:modelValue', value);
        }
      "
    />
  </p-form-element>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: String, required: true }) public readonly modelValue!: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly readonly!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly autofocus!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly placeholder?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly error?: string;
  @Prop({
    type: String as PropType<'small' | 'medium'>,
    default: 'medium',
    validator: (value: string) => ['small', 'medium'].includes(value)
  })
  public readonly size!: 'small' | 'medium';
}
</script>
