<template>
  <p-form-element
    :size="element.properties.size"
    :disabled="element.properties.disabled"
    :label="element.properties.label"
    :helpText="element.properties.help"
    :helpInTooltip="element.properties.helpInTooltip"
  >
    <p-phone-number
      :disabled.prop="element.properties.disabled"
      :country-codes="element.properties.phoneCodes"
      :value="element.properties.value ?? ''"
      @change="onChange"
    />
  </p-form-element>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementPhoneNumber } from '@/interfaces/element';

@Component({
  name: 'layout-element-phone-number'
})
export default class extends Vue {
  @Prop() public element!: IElementPhoneNumber;

  onChange(e: CustomEvent<string[]>) {
    this.element.properties.value = e.detail[0];
  }
}
</script>
