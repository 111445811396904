<template>
  <p-form-element
    type="dropdown"
    :size="size"
    :disabled="disabled"
    :label="label"
    :helpText="helpText"
    :helpInTooltip="helpInTooltip"
    :error="error"
    :slot="$attrs.slot"
  >
    <p-select
      :multiple.prop="multiple"
      :disabled.prop="disabled"
      :searchable.prop="searchable"
      :filterable.prop="filterable"
      :error.prop="!!error"
      :clearable.prop="clearable"
      :icon="icon"
      :placeholder="placeholder"
      :loading-options.prop="loadingOptions"
      @select="onSelect"
      @deselect="onDeselect"
      @clear="onClear"
      @search="$emit('search', $event)"
    >
      <p-select-option
        v-for="option in options"
        :key="`${option.value}${option.text}${option.header}`"
        :value="option.value"
        :header="option.header"
        :selected.prop="
          option.header
            ? false
            : Array.isArray(modelValue)
            ? modelValue.includes(option.value)
            : modelValue === option.value
        "
        >{{ option.text }}</p-select-option
      >
    </p-select>
  </p-form-element>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PropType } from 'vue';
import { DropdownOption } from './types';

@Component({
  components: {},
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: [String, Number, Array], required: false, default: null }) public readonly modelValue!:
    | string
    | number
    | Array<string | number>
    | null;
  @Prop({ type: Array as PropType<DropdownOption[]>, required: true }) public readonly options!: DropdownOption[];
  @Prop({
    type: String as PropType<'extra-small' | 'small' | 'medium' | 'large' | 'extra-large'>,
    required: false,
    default: 'extra-large',
    validator: (value: string) => ['extra-small', 'small', 'medium', 'large', 'extra-large'].includes(value)
  })
  public readonly size?: string;

  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly helpInTooltip?: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly placeholder?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly error?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly icon?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly searchable!: boolean;
  @Prop({ type: Boolean, required: false, default: true }) public readonly filterable!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly clearable!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly multiple!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly loadingOptions!: boolean;

  public onSelect(evt: CustomEvent<string[]>): void {
    if (this.multiple) {
      const newValue = Array.isArray(this.modelValue) ? [...this.modelValue] : [];
      newValue.push(evt.detail[0]);
      this.$emit('update:modelValue', newValue);
    } else {
      this.$emit('update:modelValue', evt.detail[0]);
    }
  }

  public onDeselect(evt: CustomEvent<string[]>): void {
    if (this.multiple) {
      const newValue = Array.isArray(this.modelValue) ? [...this.modelValue] : [];

      // Look for the string value first, if no value exists, look for the number value as a fallback
      const index = newValue.indexOf(evt.detail[0]);
      const numberIndex = newValue.indexOf(Number(evt.detail[0]));

      if (index !== -1) {
        newValue.splice(index, 1);
      } else if (numberIndex !== -1) {
        newValue.splice(numberIndex, 1);
      }

      this.$emit('update:modelValue', newValue);
    } else {
      this.$emit('update:modelValue', null);
    }
  }

  public onClear(): void {
    this.$emit('update:modelValue', null);
  }
}
</script>
