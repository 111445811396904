import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Page from '../views/Page.vue';
import PageNotFound from '../views/PageNotFound.vue';
import PageInternalError from '../views/PageInternalError.vue';
import DefaultLayout from '../views/DefaultLayout.vue';
import PageMaintenance from '../views/Maintenance.vue';
import Dashboard from '../views/Dashboard.vue';
import Campaigns from '../views/Campaigns.vue';
import CampaignStatistics from '../views/campaign/Statistics.vue';
import CampaignFastTrack from '../views/campaign/FastTrack.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '',
    name: 'Home',
    component: Page
  },
  {
    path: '/login',
    name: 'Login',
    component: Page
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: Page
  },
  {
    path: '/reset-password/*',
    name: 'ForgotPasswordConfirm',
    component: Page
  },
  {
    path: '/invite-activate/*',
    name: 'InviteActivate',
    component: Page
  },
  {
    path: '/api/edit/campaign/*',
    name: 'CampaignEditPages',
    component: Page
  },
  {
    path: '/create-account',
    name: 'CreateAccount',
    component: Page
  },
  {
    path: '/oauth',
    name: 'OAuth',
    component: Page
  },
  {
    path: '/404',
    name: 'PageNotFound',
    component: PageNotFound
  },
  {
    path: '/500',
    name: 'PageInternalError',
    component: PageInternalError
  },
  {
    path: '/campaign/fast-track/:fastTrackHash',
    name: 'CampaignFastTrackUserAuth',
    component: CampaignFastTrack
  },
  {
    path: '/campaign/:campaignType/:campaignId/fast-track',
    name: 'CampaignFastTrackHashAuth',
    component: CampaignFastTrack
  },
  {
    path: '/campaign/:type/:id/activity/statistics/:hash',
    name: 'CampaignStatisticsPublic',
    component: CampaignStatistics
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: PageMaintenance
  },
  {
    path: '*',
    name: 'Admin',
    component: DefaultLayout,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: '/campaigns',
        name: 'Campaigns',
        component: Campaigns
      },
      {
        path: '/campaign/:type/:campaignId/activity/statistics',
        name: 'CampaignStatistics',
        component: CampaignStatistics
      },
      {
        path: '/ui-demo',
        name: 'UIDemo',
        component: () => import('../views/UiDemo.vue')
      },
      {
        path: '*',
        name: 'Page',
        component: Page
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
