<template>
  <p-column
    :size="element.properties?.size"
    :justify-content="element.properties?.justifyContent"
    :align-items="element.properties?.alignItems"
  >
    <layout-element v-for="child in children" :element="child" :key="child.key" />
  </p-column>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementColumn } from '@/interfaces/element';
import { PropType } from 'vue';
import { getElementsForRender } from '@/utility';

@Component({
  name: 'layout-element-column'
})
export default class extends Vue {
  @Prop({ type: Object as PropType<IElementColumn>, required: true }) public readonly element!: IElementColumn;

  public get children() {
    return getElementsForRender(this.element.children ?? []);
  }
}
</script>
