<template>
  <p-custom-stat-box-inverted headline="Bandwidth usage" v-if="loading">
    <template #left>
      <p-container gap-size="none">
        <p-row gap-size="small" align-items="center">
          <p-skeleton-loader type="text"
            ><p-paragraph typography="component-text-strong-large">&nbsp;</p-paragraph></p-skeleton-loader
          >
        </p-row>
      </p-container>

      <p-container gap-size="none">
        <p-skeleton-loader type="text">
          <p-row gap-size="small" align-items="center">
            <p-paragraph typography="component-text-strong-large">&nbsp;</p-paragraph>
          </p-row>

          <p-row gap-size="small">
            <p-help-text>&nbsp;</p-help-text>
          </p-row>
        </p-skeleton-loader>
      </p-container>

      <p-container gap-size="none">
        <p-skeleton-loader type="text">
          <p-row gap-size="small" align-items="center">
            <p-paragraph typography="component-text-strong-large">&nbsp;</p-paragraph>
          </p-row>

          <p-row gap-size="small">
            <p-help-text>&nbsp;</p-help-text>
          </p-row>
        </p-skeleton-loader>
      </p-container>
    </template>

    <template #right>
      <div class="bandwidth-chart">
        <p-chart-bar v-if="chartBarColor" :data-sets="[]" :labels="chartLabels" loading />
      </div>
    </template>
  </p-custom-stat-box-inverted>

  <p-custom-stat-box-inverted headline="Bandwidth usage" v-else-if="apiError">
    <template #left>
      <p-container gap-size="medium">
        <p-paragraph typography="component-text-strong-large"
          >The connection to this service seems to be lost</p-paragraph
        >

        <p-help-text
          >It seems that the bandwidth service is currently offline. Please contact customer support</p-help-text
        >
      </p-container>
    </template>
  </p-custom-stat-box-inverted>

  <p-custom-stat-box-inverted headline="Bandwidth usage" v-else>
    <template #left>
      <p-container gap-size="none">
        <p-row gap-size="small" align-items="center">
          <p-paragraph typography="component-text-strong-large">{{ monthUsage }} GB</p-paragraph>
          <p-paragraph typography="component-text-strong">used in {{ currentMonth }}</p-paragraph>
        </p-row>
      </p-container>

      <p-container gap-size="none">
        <p-row gap-size="small" align-items="center">
          <p-paragraph typography="component-text-strong-large">{{ averageLast3Months }} GB</p-paragraph>
          <p-paragraph typography="component-text-strong">avg. per month</p-paragraph>
        </p-row>

        <p-row gap-size="small">
          <p-help-text>Average is based on the latest 3 months use.</p-help-text>
        </p-row>
      </p-container>

      <p-container gap-size="none">
        <p-row gap-size="small" align-items="center">
          <p-paragraph typography="component-text-strong-large">{{ totalUsage }} GB</p-paragraph>
          <p-paragraph typography="component-text-strong">used in total ({{ totalUsagePercentage }}%)</p-paragraph>
        </p-row>

        <p-row>
          <p-help-text>2000 GB in contract.</p-help-text>
        </p-row>
      </p-container>
    </template>

    <template #right>
      <div class="bandwidth-chart">
        <p-chart-bar v-if="chartBarColor" :data-sets="chartDataSet" :labels="chartLabels" />
      </div>
    </template>
  </p-custom-stat-box-inverted>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ChartBarDataSet } from '../chart/types';
import { AppRequest } from '@/app_request';

interface ApiLatestResponsePeriod {
  period: `${number}-${number}`;
  requests: number;
  bytes: number;
}

@Component({
  inheritAttrs: false
})
export default class extends Vue {
  public chartBarColor = '';

  public apiError = false;
  public loading = true;

  public latest: ApiLatestResponsePeriod[] | null = null;

  public months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  public async mounted() {
    const computedDocumentStyle = window.getComputedStyle(document.documentElement);
    this.chartBarColor = computedDocumentStyle.getPropertyValue('--color-brand-primary');

    try {
      this.latest = (await AppRequest.get<ApiLatestResponsePeriod[]>('/api/v1/campaign/bandwidth/latest')).data;
    } catch (e) {
      this.apiError = true;
    }

    this.loading = false;
  }

  public get currentMonth(): string {
    const month = new Date().getMonth();
    return this.months[month];
  }

  // Last 6 months, including the current month
  public get chartLabels(): string[] {
    const currentDate = new Date();
    const result = [];

    for (let i = 5; i >= 0; i--) {
      const monthIndex = (currentDate.getMonth() - i + 12) % 12;
      const monthAbbreviation = this.months[monthIndex].substring(0, 3);

      result.push(monthAbbreviation);
    }

    return result;
  }

  // Get the usage for the current month in GB
  public get monthUsage(): number {
    const currentMonth = this.latest?.find(
      (period) => Number(period.period.split('-')[1]) === new Date().getMonth() + 1
    );

    return currentMonth ? Math.floor(currentMonth.bytes / 1024 / 1024 / 1024) : 0;
  }

  // Get the total usage in GB
  public get totalUsage(): number {
    const totalBytes = this.latest?.reduce((acc, period) => acc + period.bytes, 0);
    return totalBytes ? Math.floor(totalBytes / 1024 / 1024 / 1024) : 0;
  }

  // Get the total usage in percentage of the contract (2000 limit)
  public get totalUsagePercentage(): number {
    const totalBytes = this.latest?.reduce((acc, period) => acc + period.bytes, 0);
    return totalBytes ? Math.floor((totalBytes / 2000 / 1024 / 1024 / 1024) * 100) : 0;
  }

  // Get the average usage for the last 3 months in GB
  public get averageLast3Months(): number {
    const lastThreeMonths = this.lastThreeMonths;
    let totalBytes = 0;

    this.latest?.forEach((period) => {
      if (lastThreeMonths.includes(period.period)) {
        totalBytes += period.bytes;
      }
    });

    return Math.floor(totalBytes / 1024 / 1024 / 1024 / 3);
  }

  // Get the data set for the chart
  public get chartDataSet(): ChartBarDataSet[] {
    const pastSixMonths = this.getPastMonths(6);

    // Return the data based on the latest response, only including the perios of the last 6 months (including the current month) and sorting them from oldest to newest
    if (this.latest) {
      const data = pastSixMonths.map((month) => {
        const period = this.latest?.find((p) => p.period === month);
        return period ? Math.floor(period.bytes / 1024 / 1024 / 1024) : 0;
      });

      return [
        {
          colors: [this.chartBarColor],
          data
        }
      ];
    }

    return [];
  }

  // Get the last three months in the format 'YYYY-MM'
  private get lastThreeMonths(): string[] {
    return this.getPastMonths(3);
  }

  // Get the last n months in the format 'YYYY-MM'
  private getPastMonths(numberOfMonths: number): string[] {
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

    const currentDate = new Date();
    const result: string[] = [];

    for (let i = numberOfMonths - 1; i >= 0; i--) {
      let year = currentDate.getFullYear();
      const monthIndex = (currentDate.getMonth() - i + 12) % 12; // Ensure the month index is within [0, 11]

      if (currentDate.getMonth() - i < 0) {
        year -= 1;
      }

      const monthWithLeadingZero = months[monthIndex];

      result.push(`${year}-${monthWithLeadingZero}`);
    }

    return result;
  }
}
</script>

<style scoped>
.bandwidth-chart {
  width: 100%;
  height: 160px;
}
</style>
