<template>
  <div class="datetime-picker">
    <p-date-picker
      :modelValue="dateValue"
      :min-date="minDate"
      :max-date="maxDate"
      :placeholder="placeholder"
      :error="!!error"
      :disabled="disabled"
      @update:modelValue="updateDate($event)"
    />
    <p-time-picker
      :modelValue="timeValue"
      :seconds="seconds"
      :disabled="disabled"
      :error="!!error"
      @update:modelValue="updateTime($event)"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
  inheritAttrs: false,
  model: {
    prop: 'modelValue', // format Y-m-d H:i
    event: 'update:modelValue'
  }
})
export default class extends Vue {
  @Prop({ type: String, required: false, default: null }) public readonly modelValue!: string | null;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly placeholder?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly error?: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly minDate?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly maxDate?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly seconds?: boolean;

  public get dateValue(): string {
    if (!this.modelValue) {
      return '';
    }

    return this.modelValue.split(' ')[0];
  }

  public get timeValue(): string {
    if (!this.modelValue) {
      return '';
    }

    return this.modelValue.split(' ')[1] || '';
  }

  public updateDate(date: string) {
    if (!date) {
      this.$emit('update:modelValue', '');
      this.$emit('input', '');
      return;
    }

    const newModelValue = `${date} ${this.timeValue}`;

    if (this.modelValue != newModelValue) {
      this.$emit('update:modelValue', newModelValue);
      this.$emit('input', newModelValue);
    }
  }

  public updateTime(time: string) {
    const newModelValue = `${this.dateValue} ${time}`;

    if (this.modelValue != newModelValue) {
      this.$emit('update:modelValue', newModelValue);
      this.$emit('input', newModelValue);
    }
  }
}
</script>

<style lang="scss" scoped>
.datetime-picker {
  display: flex;
  gap: var(--gap-size-small);
}
</style>
