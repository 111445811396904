<template>
  <p-form-code-editor
    :label="element.properties.label"
    :help-text="element.properties.help"
    :help-in-tooltip="element.properties.helpInTooltip"
    :error="element.properties.errors.join(', ')"
    :disabled="element.properties.disabled"
    :readonly="element.properties.readonly"
    :language="element.properties.language"
    v-model="element.properties.value"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementCodeEditor } from '@/interfaces/element';
import debounce from 'lodash-decorators/debounce';
import { Trigger } from '@/Trigger';
import { Iframe } from '@/iframe';

@Component({
  name: 'layout-element-code-editor'
})
export default class extends Vue {
  @Prop() public element!: IElementCodeEditor;

  @Watch('element.properties.value')
  @debounce(375)
  onValueChange(newValue: string) {
    this.$el.dispatchEvent(
      new CustomEvent('BLUEPRINT_INTERACT', {
        bubbles: true,
        composed: true
      })
    );

    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(this.element.properties.trigger, this.$el, this.element.properties.name, newValue);
    }
  }

  @Watch('element.properties.value')
  onFastValueChange() {
    if (this.element.properties.name) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }
  }
}
</script>
