<template>
  <layout-element-blueprint
    :url="element.properties.url"
    :ejected="true"
    id="embed"
    :transition-height-on-enter="true"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementEmbed } from '@/interfaces/element';

@Component({
  components: {},
  name: 'layout-element-embed'
})
export default class extends Vue {
  @Prop() public element!: IElementEmbed;
}
</script>
