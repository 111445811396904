<template>
  <p-transition
    :duration="element.properties.duration"
    :animation="element.properties.animation"
    :min-height="element.properties?.minHeight"
  >
    <layout-element
      v-for="(child, childKey) in element.children"
      :element="child"
      :key="'id' in child.properties ? child.properties.id : childKey"
    />
  </p-transition>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementTransitionGroup } from '@/interfaces/element';

@Component({
  name: 'layout-element-transition-group'
})
export default class extends Vue {
  @Prop() public element!: IElementTransitionGroup;
}
</script>
