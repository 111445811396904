var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"blueprint",staticClass:"blueprint"},[_c('div',{ref:"topbar",staticClass:"blueprint__topbar",class:{
      'blueprint__topbar--loading': _vm.isFetching,
      'blueprint__topbar--finishing': _vm.isFetchingFinishing,
      'blueprint__topbar--theme-inverted': _vm.scrollY > 85
    }}),_vm._v(" "),(_vm.internalServerError)?_c('layout-template',{attrs:{"template":{
      name: 'internal-server-error',
      params: { info: _vm.internalServerErrorInfo }
    }}}):_vm._e(),_vm._v(" "),(_vm.notFound)?_c('layout-template',{attrs:{"template":{ name: 'not-found' }}}):_vm._e(),_vm._v(" "),_c('layout-actions',{attrs:{"actions":_vm.actions}}),_vm._v(" "),(_vm.fetchLoader && !_vm.confirmCondition)?_c('p-modal-loader'):_vm._e(),_vm._v(" "),(_vm.template && _vm.blueprint)?_c('layout-template',{key:_vm.template.name,attrs:{"template":_vm.template}},_vm._l((_vm.children),function(child){return _c('layout-element',{key:`${child.key}${_vm.urlRendering}`,attrs:{"element":child}})}),1):_vm._e(),_vm._v(" "),(_vm.blueprint && !_vm.template)?_c('p-container',{key:_vm.urlRendering},_vm._l((_vm.children),function(child){return _c('layout-element',{key:child.key,attrs:{"element":child}})}),1):_vm._e(),_vm._v(" "),(_vm.confirmCondition)?_c('p-modal-confirm',{attrs:{"title":_vm.confirmCondition.title,"modal-title":_vm.confirmCondition.modalTitle,"description":_vm.confirmCondition.description,"button-yes":_vm.confirmCondition.button.yes,"button-no":_vm.confirmCondition.button.no,"confirm-text":_vm.confirmCondition.challenge.text},domProps:{"show":true,"callback":_vm.confirmCallback},on:{"close-request":function($event){_vm.confirmCondition = null;
      _vm.confirmCallback = null;}}}):_vm._e(),_vm._v(" "),(_vm.popupUrl !== null && _vm.popup)?_c('layout-element-popup',{attrs:{"url":_vm.popupUrl,"headline":_vm.popupHeadline},on:{"closePopup":function($event){return _vm.closePopup()}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }