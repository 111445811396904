<template>
  <p-button
    color-type="tertiary"
    icon="list2"
    title="Bullet list"
    @click="toggleBulletList"
    :disabled.prop="buttonDisabled"
    :class="buttonClass"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';

@Component
export default class EditorBulletList extends Vue {
  @Prop({ type: Object, required: true }) public readonly editor!: Editor;

  public get buttonDisabled() {
    return !this.editor.can().chain().focus().toggleBulletList().run();
  }

  public get buttonClass() {
    return { 'toolbar-button--active': this.editor.isActive('bulletList') };
  }

  public toggleBulletList() {
    this.editor.chain().focus().toggleBulletList().run();
  }
}
</script>
