<template>
  <div class="blueprint" ref="blueprint">
    <div
      class="blueprint__topbar"
      ref="topbar"
      :class="{
        'blueprint__topbar--loading': isFetching,
        'blueprint__topbar--finishing': isFetchingFinishing,
        'blueprint__topbar--theme-inverted': scrollY > 85
      }"
    ></div>

    <layout-template
      v-if="internalServerError"
      :template="{
        name: 'internal-server-error',
        params: { info: internalServerErrorInfo }
      }"
    />

    <layout-template v-if="notFound" :template="{ name: 'not-found' }" />

    <layout-actions :actions="actions" />

    <p-modal-loader v-if="fetchLoader && !confirmCondition" />

    <layout-template v-if="template && blueprint" :template="template" :key="template.name">
      <layout-element v-for="child in children" :element="child" :key="`${child.key}${urlRendering}`" />
    </layout-template>

    <p-container v-if="blueprint && !template" :key="urlRendering">
      <layout-element v-for="child in children" :element="child" :key="child.key" />
    </p-container>

    <p-modal-confirm
      :show.prop="true"
      v-if="confirmCondition"
      :title="confirmCondition.title"
      :modal-title="confirmCondition.modalTitle"
      :description="confirmCondition.description"
      :button-yes="confirmCondition.button.yes"
      :button-no="confirmCondition.button.no"
      :confirm-text="confirmCondition.challenge.text"
      :callback.prop="confirmCallback"
      @close-request="
        confirmCondition = null;
        confirmCallback = null;
      "
    />

    <layout-element-popup
      v-if="popupUrl !== null && popup"
      :url="popupUrl"
      :headline="popupHeadline"
      @closePopup="closePopup()"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  ActionType,
  Condition,
  IConditionConfirm,
  IElementBlueprint,
  IElementNavigatorItem,
  IElementSection,
  IElementSelect,
  IElementStepGuide,
  ILayoutActionType,
  ILayoutTypeElement,
  ILayoutTypeElements,
  ITrigger,
  TriggerType
} from '@/interfaces/element';
import LayoutTemplate from '@/components/LayoutTemplate.vue';
import LayoutActions from '@/components/LayoutActions.vue';
import { EventBus } from '@/main';
import Axios, { CancelTokenSource } from 'axios';
import { FormValidationError, IntervalServerError } from '@/interfaces/error';
import { Iframe } from '@/iframe';
import { autobind } from 'core-decorators';
import { getElementsForRender, traverseElements } from '@/utility';
import { AppRequest } from '@/app_request';
import LayoutElementPopup from '@/components/LayoutElementPopup.vue';
import { ILayoutTemplate } from '@/interfaces/template';
import { ActionMessageDisplay } from '@/interfaces/element';
import hotkeys from 'hotkeys-js';

interface UpdateEventDetails {
  dueTo: string[];
  value: string | number;
  trigger?: ITrigger;
}

interface UpdateEvent extends Event {
  detail: UpdateEventDetails;
}

interface IBlueprintData {
  [key: string]: string | number | boolean | null | Array<string | number | boolean | null>;
}

interface IAdditionalData {
  [key: string]: string;
}

const CancelToken = Axios.CancelToken;

@Component({
  components: { LayoutElementPopup, LayoutActions, LayoutTemplate },
  name: 'layout-element-blueprint'
})
export default class LayoutElementBlueprint extends Vue {
  @Prop() public element!: IElementBlueprint | null;
  @Prop() private url!: string;
  @Prop() private ejected!: boolean;
  @Prop() private allowTopLevelNavigation!: boolean;
  @Prop() private id!: string;
  @Prop({ type: String, required: false, default: undefined }) private defaultTemplate?: string;

  public blueprint: IElementBlueprint | null = null;
  public notFound = false;
  public internalServerError = false;
  public internalServerErrorInfo: IntervalServerError | null = null;
  public confirmCondition: IConditionConfirm | null = null;
  public confirmCallback: (() => Promise<void>) | null = null;
  public urlRendering = '';
  public popup = false;
  public popupUrl: string | null = null;
  public popupHeadline = '';
  public fetchLoader = false;
  public reloadDueToSave = false;
  public isFetching = false;
  public isFetchingFinishing = false;
  public scrollY = 0;

  private popupOnClose: ILayoutActionType[] | null = null;
  private fetchStartTimer: number | null = null;
  private sessionTimeout: number | null = null;
  private source: CancelTokenSource | null = null;
  private navigation: string | null = null;
  private isFetchingCallbacks: number[] = [];
  private validationToken?: string = undefined;

  private resizeObserver: ResizeObserver | null = null;

  mounted() {
    this.scrollY = Math.abs(window.scrollY);
    this.registerSessionTimeout();

    this.$el.addEventListener('BLUEPRINT_TRIGGER_HANDLE', this.triggerHandleProxy);
    this.$el.addEventListener('BLUEPRINT_SUBMIT', this.onSubmit);
    this.$el.addEventListener('BLUEPRINT_CANCEL_UPDATE', this.onCancelUpdate);

    if (this.inIframe()) {
      this.resizeObserver = new ResizeObserver(() => {
        if (!(this.$refs.blueprint instanceof HTMLDivElement)) {
          return;
        }

        Iframe.emit('frame-form-resize', {
          height: Math.max(document.body.offsetHeight, this.$refs.blueprint.offsetHeight)
        });
      });

      if (this.resizeObserver) {
        if (this.$refs.blueprint instanceof HTMLDivElement) {
          this.resizeObserver.observe(this.$refs.blueprint);
        }

        this.resizeObserver.observe(document.body);
      }
    }

    window.addEventListener('message', this.onMessage);
    window.addEventListener('scroll', this.onScroll);

    EventBus.$on('BLUEPRINT_RELOAD', this.reload);
    EventBus.$on('BLUEPRINT_CLOSE_POPUP', this.closePopup);
    EventBus.$on('BLUEPRINT_ACTION', this.actionHandler);

    if (this.url && !this.element) {
      this.onUrlChange();
    }

    if (this.element) {
      this.blueprint = this.element;
    }

    hotkeys.filter = () => true;
    hotkeys('cmd+s,ctrl+s', this.onSubmitRequest);
  }

  private onSubmitRequest(e: KeyboardEvent) {
    e.preventDefault();

    if (this.blueprint?.children) {
      traverseElements(this.blueprint.children, (element) => {
        if (element.type === 'button' && element.properties?.submit) {
          Vue.set(element.properties, 'autoClick', true);
        }
      });
    }
  }

  destroyed() {
    hotkeys.unbind('cmd+s,ctrl+s', this.onSubmitRequest);

    if (this.sessionTimeout !== null) {
      clearTimeout(this.sessionTimeout);
      this.sessionTimeout = null;
    }
  }

  registerSessionTimeout() {
    // If blueprint is ejected we don't want session timeout to be registered.
    if (this.ejected) {
      return;
    }

    if (this.sessionTimeout !== null) {
      clearTimeout(this.sessionTimeout);
      this.sessionTimeout = null;
    }

    this.sessionTimeout = setTimeout(() => {
      window.location.reload();
    }, 60 * 60 * 8 * 1000);
  }

  @autobind
  actionHandler(action: ILayoutActionType) {
    if (action.bubbleToTop && this.ejected) {
      return;
    }

    if (this?.blueprint?.properties && typeof this.blueprint.properties.actions === 'undefined') {
      Vue.set(this.blueprint.properties, 'actions', []);
    }

    action.bubbleToTop = false;

    if (this?.blueprint?.properties?.actions) {
      this.blueprint.properties.actions.push(action);
    }
  }

  @Watch('element')
  onElementChange() {
    this.blueprint = this.element;
  }

  @autobind
  reload(topLevel: boolean, forId: string, fullReload?: boolean) {
    const id = this.id ?? this.element?.properties.id ?? this.blueprint?.properties.id;

    if (forId && id !== forId) {
      return;
    }

    if (topLevel && this.ejected) {
      return;
    }

    if (fullReload) {
      this.fetch('view');
    } else {
      this.update();
    }
  }

  @autobind
  beforeDestroy() {
    EventBus.$off('BLUEPRINT_RELOAD', this.reload);
    EventBus.$off('BLUEPRINT_CLOSE_POPUP', this.closePopup);
    EventBus.$off('BLUEPRINT_ACTION', this.actionHandler);

    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('message', this.onMessage);

    if (this.resizeObserver) {
      this.resizeObserver.unobserve(document.body);
    }
  }

  public onScroll() {
    this.scrollY = Math.abs(window.scrollY);
  }

  @autobind
  onSubmit() {
    const buttons = this.$el.querySelectorAll<HTMLElement>('p-button[type="default"][color-type="primary"]');

    if (buttons.length === 1) {
      buttons[0].click();
    }
  }

  @autobind
  closePopup() {
    this.popup = false;
    this.$emit('action-close-request');

    if (this.popupOnClose && this.blueprint && this.blueprint.properties) {
      this.blueprint.properties.actions = this.popupOnClose;
    }

    Iframe.emit('frame-request-regular-size-before');

    setTimeout(() => {
      Iframe.emit('frame-request-regular-size');
      this.popupUrl = null;
      this.popupOnClose = null;
    }, 475);
  }

  @Watch('url')
  onUrlChange() {
    if (this.url === '/logout') {
      if (typeof (window as any)._na !== 'undefined') {
        (window as any)._na.logout();
        delete (window as any)._na;
      }
    }

    this.navigation = this.url;
  }

  @Watch('navigation')
  onNavigationChange() {
    this.fetch('view');
  }

  onMessage(e: MessageEvent) {
    try {
      const data = JSON.parse(e.data);

      if (typeof data.type !== 'undefined') {
        switch (data.type) {
          case 'navigate':
            if (!this.ejected && typeof data.url !== 'undefined' && this.$router.currentRoute.fullPath !== data.url) {
              this.$router.push({ path: data.url });
            }
            break;

          case 'data-change-request':
            if (
              this.blueprint?.children &&
              'data' in data &&
              typeof data === 'object' &&
              'name' in data.data &&
              Array.isArray(data.data.name) &&
              'value' in data.data
            ) {
              traverseElements(this.blueprint.children, (child: ILayoutTypeElement) => {
                if (
                  'name' in child.properties &&
                  Array.isArray(child.properties.name) &&
                  child.properties.name.join('') === data.data.name.join('')
                ) {
                  Vue.set(child.properties, 'value', data.data.value);
                }
              });
            }
            break;

          case 'edit-device-change':
            if (this.blueprint?.children) {
              traverseElements(this.blueprint.children, (child: ILayoutTypeElement) => {
                if (child.type === 'device-switch' && 'properties' in child) {
                  let hasDeviceAvailable = false;

                  child.properties.tabs.forEach((tab) => {
                    if (tab.id === data.device) {
                      hasDeviceAvailable = true;
                    }
                  });

                  if (hasDeviceAvailable) {
                    child.properties.value = data.device;
                  } else if (data.device === 'tablet') {
                    child.properties.value = 'desktop';
                  }
                }
              });
            }
            break;

          case 'set-active-section':
            if (typeof data.data !== 'undefined') {
              const sections = this.getSections();

              if (typeof sections[data.data] !== 'undefined') {
                document.querySelectorAll('.section__title').forEach((section) => {
                  if (section.innerHTML === sections[data.data]) {
                    section.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center'
                    });
                  }
                });
              }
            }
            break;
        }
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  @autobind
  onCancelUpdate(e: Event) {
    e.stopPropagation();

    if (this.source !== null) {
      this.source.cancel();
      this.source = null;
    }
  }

  /**
   * Since we cannot stop propagation when handler is async,
   * then we have a proxy method which we do this in before calling the original one.
   */
  @autobind
  private triggerHandleProxy(e: Event) {
    e.stopPropagation();
    this.triggerHandle(e);
  }

  async triggerHandle(e: Event) {
    let condition: Condition | null = null;

    const trigger = (e as UpdateEvent).detail?.trigger;

    if (trigger) {
      if ((e as UpdateEvent).detail?.trigger?.condition) {
        condition = (e as UpdateEvent).detail.trigger?.condition as Condition;
      }

      const callback = async () => {
        if (trigger.type === TriggerType.CLOSE_POPUP) {
          this.closePopup();
          return;
        }

        if (trigger.type === TriggerType.POPUP) {
          if (typeof trigger.popup !== 'undefined') {
            Iframe.emit('frame-request-full-size');
            this.popup = true;
            this.popupUrl = trigger.popup;
            this.popupHeadline = trigger.popupHeadline ?? '';

            if (trigger.popupOnClose) {
              this.popupOnClose = trigger.popupOnClose;
            }
          }

          return;
        }

        if (trigger.type === TriggerType.COPY && trigger.copyTarget) {
          let target = document.querySelector(trigger.copyTarget);

          if (
            target !== null &&
            target.nodeName.toLowerCase() !== 'input' &&
            target.nodeName.toLowerCase() !== 'textarea' &&
            target.nodeName.toLowerCase() !== 'p-input'
          ) {
            target = target.querySelector('input,textarea,p-input');
          }

          if (target !== null) {
            (target as HTMLInputElement).select();
            document.execCommand('copy');
          }

          return;
        }

        if (trigger.type === TriggerType.SET_FIELD_VALUE && trigger.field && trigger.value !== undefined) {
          const lookFor = trigger.field.join('');

          if (this.blueprint && this.blueprint.children) {
            traverseElements(this.blueprint.children, (child) => {
              if (child.properties && 'name' in child.properties && child.properties.name) {
                const name = child.properties.name.join('');

                if (name === lookFor) {
                  child.properties.value = trigger.value;
                }
              }
            });
          }
          return;
        }

        if (trigger.type === TriggerType.NAVIGATION && trigger.url) {
          if (this.ejected && !this.allowTopLevelNavigation) {
            if (trigger.url === this.navigation) {
              const updated = await this.fetch('view');

              // If the update was cancelled/precented, stop processing it further there
              if (!updated) {
                return;
              }
            } else {
              this.navigation = trigger.url;
            }
          } else {
            await this.$router.push({ path: trigger.url });
          }
          return;
        }

        const name = (e as UpdateEvent).detail?.dueTo;

        EventBus.$emit('TRIGGER_UPDATE', e.target);
        await this.update(trigger, name, (e as UpdateEvent).detail?.value);
      };

      if (condition !== null) {
        this.confirmCallback = callback;
        this.confirmCondition = condition;
      } else {
        await callback();
      }
    }
  }

  public getUrl() {
    return this.url;
  }

  @autobind
  async update(trigger?: ITrigger, reloadDueTo?: string[] | null, reloadDueToValue?: string | number | null) {
    reloadDueTo = reloadDueTo ?? null;
    reloadDueToValue = reloadDueToValue ?? null;

    const type = trigger?.type;
    const action = trigger?.action;
    const additionalData: IAdditionalData = {};

    if (typeof type !== 'undefined') {
      additionalData.triggerType = type;

      if (type === TriggerType.ACTION && typeof action !== 'undefined' && action !== null) {
        additionalData.triggerAction = action;
      }
    }

    const updated = await this.fetch('update', reloadDueTo, reloadDueToValue as string | number | null, additionalData);

    // If the update was cancelled/precented, stop processing it further there
    if (updated) {
      EventBus.$emit(
        'BLUEPRINT_UPDATED',
        !!this.blueprint?.properties.actions?.find(
          (action) => action.type === ActionType.MESSAGE && action.display === ActionMessageDisplay.SUCCESS
        )
      );

      Iframe.emit('frame-form-reload');
    }
  }

  public getDataFromChild(child: ILayoutTypeElement, data: IBlueprintData) {
    data = data || {};

    if (child.type === 'media' && child.properties.altText && child.properties.altText.properties.value) {
      data = this.getDataFromChild(child.properties.altText, data);
    }

    if (
      'properties' in child &&
      'type' in child &&
      child.type !== 'button' &&
      'name' in child.properties &&
      typeof child.properties.name !== 'undefined'
    ) {
      if (
        child &&
        child.type === 'navigator' &&
        'items' in child.properties &&
        typeof (child as any).properties.items !== 'undefined' &&
        (child as any).properties.items !== null
      ) {
        ((child as any).properties.items as IElementNavigatorItem[]).forEach((item) => {
          item.trigger.forEach((child) => {
            data = this.getDataFromChild(child as ILayoutTypeElement, data);
          });

          item.content.forEach((child) => {
            data = this.getDataFromChild(child as ILayoutTypeElement, data);
          });
        });

        return data;
      }

      if (
        child &&
        'toolbar' in child &&
        typeof (child as any).toolbar !== 'undefined' &&
        (child as any).toolbar !== null
      ) {
        ((child as any).toolbar as ILayoutTypeElements).forEach((child) => {
          data = this.getDataFromChild(child as ILayoutTypeElement, data);
        });
      }

      if (
        'value' in child.properties &&
        typeof child.properties.value !== 'undefined' &&
        (typeof child.properties.value === 'number' ||
          typeof child.properties.value === 'string' ||
          typeof child.properties.value === 'boolean')
      ) {
        const name = child.properties.name
          .map((name, index) => {
            return index > 0 ? '[' + name + ']' : name;
          })
          .join('');

        if (typeof data[`${name}`] !== 'undefined') {
          if (Array.isArray(data[`${name}`])) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (data[`${name}`] as any).push(
              'value' in child.properties && typeof child.properties.value !== 'undefined' ? child.properties.value : ''
            );
          } else {
            data[`${name}`] = [
              data[`${name}`] as string | number | boolean | null,
              'value' in child.properties && typeof child.properties.value !== 'undefined' ? child.properties.value : ''
            ];
          }
        } else {
          data[`${name}`] =
            'value' in child.properties && typeof child.properties.value !== 'undefined' ? child.properties.value : '';
        }
      } else if (
        'value' in child.properties &&
        'name' in child.properties &&
        'useArray' in child.properties &&
        typeof child.properties.name !== 'undefined' &&
        typeof child.properties.value !== 'undefined' &&
        child.type === 'tags-input' &&
        child.properties.useArray &&
        Array.isArray(child.properties.value)
      ) {
        const name = child.properties.name
          .map((name, index) => {
            return index > 0 ? '[' + name + ']' : name;
          })
          .join('');

        if (child.properties.value.length > 0) {
          for (const valueIndex in child.properties.value) {
            data[name + '[' + valueIndex + ']'] = child.properties.value[Number(valueIndex)];
          }
        } else {
          data[`${name}`] = '';
        }
      } else if (child.type === 'card' && Array.isArray(child.properties.value)) {
        const name = child.properties.name
          .map((name, index) => {
            return index > 0 ? '[' + name + ']' : name;
          })
          .join('');

        if (child.properties.value.length > 0) {
          for (const valueIndex in child.properties.value) {
            data[name + '[' + valueIndex + ']'] = child.properties.value[Number(valueIndex)];
          }
        }
      } else if (
        'value' in child.properties &&
        'name' in child.properties &&
        typeof child.properties.name !== 'undefined' &&
        child.type === 'positioner' &&
        typeof child.properties.value !== 'undefined'
      ) {
        child.properties.value.forEach((value, index: number) => {
          for (const valueKey of ['id', 'x', 'y', 'w', 'h', 'label']) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (typeof (value as any)[`${valueKey}`] !== 'undefined') {
              data[
                [...(child.properties.name ?? []), index, valueKey]
                  .map((name, index) => {
                    return index > 0 ? '[' + name + ']' : name;
                  })
                  .join('')
              ] = (value as any)[`${valueKey}`] + '';
            }
          }
        });
      } else if (
        'value' in child.properties &&
        typeof child.properties.value !== 'undefined' &&
        child.type === 'datepicker' &&
        typeof child.properties.value === 'object'
      ) {
        for (const valueKey in child.properties.value) {
          data[
            [...(child.properties.name ?? []), valueKey]
              .map((name, index) => {
                return index > 0 ? '[' + name + ']' : name;
              })
              .join('')
          ] = (child.properties.value as any)[`${valueKey}`] + '';
        }
      } else if (
        'value' in child.properties &&
        typeof child.properties.value !== 'undefined' &&
        (child.type === 'select' || child.type === 'checkboxes') &&
        typeof child.properties.value === 'object'
      ) {
        for (const valueKey in child.properties.value) {
          data[
            [...((child as IElementSelect).properties.name as string[]), valueKey]
              .map((name, index) => {
                return index > 0 ? '[' + name + ']' : name;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              })
              .join('')
          ] = (child.properties.value as any)[`${valueKey}`] + '';
        }
      } else if (
        'value' in child.properties &&
        typeof child.properties.value !== 'undefined' &&
        child.type === 'table' &&
        typeof child.properties.value === 'object'
      ) {
        if ('page' in child.properties.value) {
          data[
            [...child.properties.name, 'page']
              .map((name, index) => {
                return index > 0 ? '[' + name + ']' : name;
              })
              .join('')
          ] = child.properties.value.page;
        }

        if ('bulk' in child.properties.value && child.properties.value.bulk !== null && child.properties.value.bulk) {
          const childName = child.properties.name;

          child.properties.value.bulk.forEach((bulkSelected, bulkIndex) => {
            data[
              [...childName, 'bulk', bulkIndex]
                .map((name, index) => {
                  return index > 0 ? '[' + name + ']' : name;
                })
                .join('')
            ] = bulkSelected;
          });
        }

        if ('search' in child.properties.value) {
          data[
            [...child.properties.name, 'search']
              .map((name, index) => {
                return index > 0 ? '[' + name + ']' : name;
              })
              .join('')
          ] = child.properties.value.search;
        }

        if ('itemsPerPage' in child.properties.value) {
          data[
            [...child.properties.name, 'itemsPerPage']
              .map((name, index) => {
                return index > 0 ? '[' + name + ']' : name;
              })
              .join('')
          ] = child.properties.value.itemsPerPage;
        }

        if ('sortBy' in child.properties.value) {
          data[
            [...child.properties.name, 'sortBy']
              .map((name, index) => {
                return index > 0 ? '[' + name + ']' : name;
              })
              .join('')
          ] = child.properties.value.sortBy;
        }

        if ('sortByDirection' in child.properties.value) {
          data[
            [...child.properties.name, 'sortByDirection']
              .map((name, index) => {
                return index > 0 ? '[' + name + ']' : name;
              })
              .join('')
          ] = child.properties.value.sortByDirection;
        }

        if (
          'body' in child.properties &&
          typeof child.properties.body !== 'undefined' &&
          child.properties.body.length > 0
        ) {
          for (const row of child.properties.body) {
            for (const column in row.columns) {
              if (
                Array.isArray(row.columns[String(column)].column) &&
                (row.columns[String(column)].column as ILayoutTypeElement[]).length > 0
              ) {
                const elements = row.columns[String(column)].column as ILayoutTypeElement[];

                for (const element of elements) {
                  data = this.getDataFromChild(element, data);
                }
              }
            }
          }
        }

        return data;
      } else {
        data[
          child.properties.name
            .map((name, index) => {
              return index > 0 ? '[' + name + ']' : name;
            })
            .join('')
        ] = '';
        return data;
      }
    }

    if (
      'properties' in child &&
      'type' in child &&
      'value' in child.properties &&
      typeof child.properties.value !== 'undefined' &&
      child.type === 'table' &&
      typeof child.properties.value === 'object' &&
      'body' in child.properties &&
      typeof child.properties.body !== 'undefined' &&
      child.properties.body.length > 0
    ) {
      for (const row of child.properties.body) {
        for (const column in row.columns) {
          if (
            Array.isArray(row.columns[String(column)].column) &&
            (row.columns[String(column)].column as ILayoutTypeElement[]).length > 0
          ) {
            const elements = row.columns[String(column)].column as ILayoutTypeElement[];

            for (const element of elements) {
              data = this.getDataFromChild(element, data);
            }
          }
        }
      }
    }

    if ('children' in child && typeof child.children !== 'undefined' && child.children !== null) {
      child.children.forEach((child) => {
        data = this.getDataFromChild(child, data);
      });
    }

    if ('properties' in child && 'tabs' in child.properties) {
      child.properties.tabs.forEach((tab) => {
        if (tab.content !== null) {
          tab.content.forEach((tabContent) => {
            data = this.getDataFromChild(tabContent, data);
          });
        }
      });
    }

    if ('properties' in child && 'steps' in child.properties) {
      (child as IElementStepGuide).properties.steps.forEach((step) => {
        data = this.getDataFromChild(step.element, data);
      });
    }

    if ('properties' in child && child.type === 'settings-navigation' && 'items' in child.properties) {
      child.properties.items.forEach((item) => {
        if (item.content !== null) {
          item.content.forEach((itemContent) => {
            data = this.getDataFromChild(itemContent, data);
          });
        }
      });
    }

    if ('toolbar' in child) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((child as any).toolbar as ILayoutTypeElement[]).forEach((child) => {
        data = this.getDataFromChild(child, data);
      });
    }

    return data;
  }

  getElementValue(value: string | number | boolean | null): string {
    if (value === true) {
      return '1';
    } else if (value === false) {
      return '0';
    } else {
      return value + '';
    }
  }

  getData(): IBlueprintData {
    let data: IBlueprintData = {};

    if (
      typeof this.blueprint !== 'undefined' &&
      this.blueprint !== null &&
      typeof this.blueprint.children !== 'undefined' &&
      this.blueprint.children !== null &&
      this.blueprint.children.length > 0
    ) {
      this.blueprint.children.forEach((element) => {
        data = this.getDataFromChild(element, data);
      });
    }

    return data;
  }

  async fetch(
    state: string,
    reloadDueTo?: string[] | null,
    reloadDueToValue?: string | number | null,
    additionalData?: IAdditionalData
  ): Promise<boolean> {
    if (state === 'view') {
      this.confirmCondition = null;
      this.confirmCallback = null;
    }

    if (this.$refs.topbar instanceof HTMLDivElement) {
      this.$refs.topbar.style.transition = 'none';
      this.$refs.topbar.offsetWidth;
      this.$refs.topbar.style.width = '0%';
      this.$refs.topbar.offsetWidth;
      this.$refs.topbar.style.transition = '';
      this.$refs.topbar.style.width = '';
    }

    if (this.isFetchingCallbacks.length > 0) {
      this.isFetchingCallbacks.forEach((callback) => {
        window.clearTimeout(callback);
      });

      this.isFetchingCallbacks = [];
    }

    this.isFetching = true;
    this.isFetchingFinishing = false;

    if (reloadDueTo && reloadDueTo[0] === 'save') {
      this.reloadDueToSave = true;
    } else {
      this.reloadDueToSave = false;
    }

    this.registerSessionTimeout();

    if (!(additionalData && additionalData.triggerAction === 'save')) {
      this.fetchRequestStart();
    }

    let url = (this.navigation || this.element?.properties.url) + '';

    if (this.defaultTemplate && !url.includes('template=')) {
      url += `${url.includes('?') ? '&' : '?'}template=${this.defaultTemplate}`;
    }

    if (this.source !== null) {
      this.source.cancel();
      this.source = null;
    }

    const source = CancelToken.source();

    this.source = source;
    let response = null;

    if (state === 'view') {
      try {
        response = await AppRequest.get<IElementBlueprint>('/api' + url, {
          cancelToken: source.token,
          headers: {
            accept: 'application/json'
          },
          responseType: 'json'
        });

        if (!this.ejected) {
          EventBus.$emit('blueprint-response-url', response.request.responseURL.replace('/api', ''));
        }

        this.$nextTick(() => {
          Iframe.emit('frame-form-dom-ready');
          this.$emit('content-ready');
        });

        this.notFound = false;
        this.internalServerError = false;

        if (!this.ejected) {
          const scrollY = window.scrollY || 0;

          if (Math.abs(scrollY) > 0 && typeof window.scroll !== 'undefined') {
            window.scroll({
              top: 0,
              left: 0
            });
          }
        }

        // Cloudfront returns our /index.html when a 403 happens.
        // We need to make it so that when this happens,
        // then it redirects to the login page.
        if (
          response.headers &&
          response.headers['x-cache'] &&
          response.headers['x-cache'] === 'Error from cloudfront'
        ) {
          if (this.inIframe()) {
            Iframe.emit('frame-form-logged-out');
          }

          this.fetchRequestEnd();
          await this.$router.push({ path: '/' });
          return false;
        }
      } catch (err: any) {
        this.blueprint = null;

        this.notFound = typeof err.response !== 'undefined' && err.response.status === 404;

        if (typeof err.response !== 'undefined' && err.response.status === 403) {
          if (this.inIframe()) {
            Iframe.emit('frame-form-logged-out');
          }

          this.fetchRequestEnd();
          await this.$router.push({ path: '/' });
          return false;
        }

        if (err.response && err.response.status === 503) {
          this.fetchRequestEnd();

          if (this.inIframe()) {
            Iframe.emit('frame-form-logged-out');
          }

          await this.$router.push({ path: '/maintenance' });
          return false;
        }

        this.internalServerError =
          (!err.response ||
            (typeof err.response !== 'undefined' && [403, 404, 200].indexOf(err.response.status) === -1)) &&
          !err.__CANCEL__;

        if (this.internalServerError && err.response?.data) {
          this.internalServerErrorInfo = err.response.data as IntervalServerError;
        }
      }
    } else {
      const formData = new FormData();
      const data = this.getData();

      for (const itemKey in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(itemKey)) {
          if (Array.isArray(data[`${itemKey}`])) {
            (data[`${itemKey}`] as Array<string | number | boolean | null>).forEach((fieldValue) => {
              formData.append(itemKey, this.getElementValue(fieldValue));
            });
          } else {
            formData.append(itemKey, this.getElementValue(data[`${itemKey}`] as string | number | boolean | null));
          }
        }
      }

      if (typeof additionalData !== 'undefined') {
        for (const itemKey in additionalData) {
          // eslint-disable-next-line no-prototype-builtins
          if (additionalData.hasOwnProperty(itemKey)) {
            formData.append(itemKey, additionalData[`${itemKey}`]);
          }
        }
      }

      if (typeof reloadDueTo !== 'undefined' && reloadDueTo !== null) {
        const reloadDueToName = reloadDueTo
          .map((name, index) => {
            return index > 0 ? '[' + name + ']' : name;
          })
          .join('');

        if (typeof reloadDueToValue !== 'undefined' && reloadDueToValue !== null) {
          formData.set(reloadDueToName, this.getElementValue(reloadDueToValue));
        }

        reloadDueTo.forEach((reloadDueToItemName, reloadDueToItemIndex) => {
          formData.set('reloadDueToArr[' + reloadDueToItemIndex + ']', reloadDueToItemName);
        });

        formData.set('reloadDueTo', reloadDueToName);
      }

      if (this.validationToken) {
        formData.set('validationToken', this.validationToken);
      }

      try {
        response = await AppRequest.post<IElementBlueprint>('/api' + url, formData, {
          headers: {
            accept: 'application/json'
          },
          responseType: 'json'
        });
      } catch (err: any) {
        // handle 403 errors (potentially WAF)
        if (typeof err.response !== 'undefined' && err.response.status === 403) {
          if (this.inIframe()) {
            Iframe.emit('frame-form-logged-out');
          }

          this.fetchRequestEnd();
          await this.$router.push({ path: '/' });
          return false;
        }

        if (err.response && err.response.status === 503) {
          this.fetchRequestEnd();

          if (this.inIframe()) {
            Iframe.emit('frame-form-logged-out');
          }

          await this.$router.push({ path: '/maintenance' });
          return false;
        }

        if (
          this.blueprint !== null &&
          typeof this.blueprint.children !== 'undefined' &&
          this.blueprint.children !== null &&
          'response' in err &&
          'data' in err.response &&
          'errors' in err.response.data
        ) {
          this.clearErrors(this.blueprint.children);
          this.setErrors(this.blueprint.children, err.response.data as FormValidationError);
        }
      }

      EventBus.$emit('BLUEPRINT_RELOADED');
    }

    // eslint-disable-next-line no-console
    if (source.token.reason?.message === 'canceled') {
      return false;
    }

    this.urlRendering = url;

    if (response !== null) {
      if (typeof response.headers !== 'undefined' && typeof response.headers['x-csrf-token'] !== 'undefined') {
        Axios.defaults.headers.common['X-CSRF-TOKEN'] = response.headers['x-csrf-token'];
      }

      // Cloudfront returns our /index.html when a 403 happens.
      // We need to make it so that when this happens,
      // then it redirects to the login page.
      if (response.headers && response.headers['x-cache'] && response.headers['x-cache'] === 'Error from cloudfront') {
        if (this.inIframe()) {
          Iframe.emit('frame-form-logged-out');
        }

        this.fetchRequestEnd();
        await this.$router.push({ path: '/' });
        return false;
      }

      const blueprint = response.data;

      this.validationToken = blueprint.validationToken;

      // Property references are resolved before blueprint is mounted.
      // This is to avoid us having bloated type definitions in our interfaces
      // and manually handling it everywhere.
      //
      // Instead we accept that it's using 'any' here for this very purpose to make it
      // work at a lower level.
      if (blueprint.children && blueprint.properties.property_references) {
        traverseElements(blueprint.children, (child) => {
          if (child.properties) {
            for (const objectKey in child.properties) {
              if (Object.prototype.hasOwnProperty.call(child.properties, objectKey)) {
                const value = (child.properties as any)[`${objectKey}`];

                if (
                  typeof value === 'string' &&
                  value.startsWith('PropertyRef:') &&
                  blueprint.properties.property_references
                ) {
                  (child.properties as any)[`${objectKey}`] = blueprint.properties.property_references[value] ?? null;
                }
              }
            }
          }
        });
      }

      if (blueprint.preventRender) {
        if (this.blueprint && this.blueprint.properties && blueprint.properties) {
          // Include existing properties as many are left out during prevent render
          this.blueprint.properties = blueprint.properties;
        } else {
          this.blueprint = blueprint;
        }
      } else {
        this.blueprint = blueprint;
      }

      EventBus.$emit('BLUEPRINT_RENDER', blueprint.preventRender ?? false);
    }

    this.isFetching = false;
    this.isFetchingFinishing = true;

    this.isFetchingCallbacks.push(
      setTimeout(() => {
        this.isFetchingFinishing = false;
      }, 500)
    );

    this.fetchRequestEnd();

    return true;
  }

  private fetchRequestStart() {
    if (this.fetchStartTimer !== null) {
      clearTimeout(this.fetchStartTimer);
    }

    this.fetchStartTimer = setTimeout(() => {
      this.fetchLoader = true;
      this.fetchStartTimer = null;
    }, 3000);
  }

  private fetchRequestEnd() {
    if (this.fetchStartTimer !== null) {
      clearTimeout(this.fetchStartTimer);
      this.fetchStartTimer = null;
    }

    this.fetchLoader = false;
  }

  private clearError(child: ILayoutTypeElement) {
    if (
      'properties' in child &&
      typeof child.properties !== 'undefined' &&
      'name' in child.properties &&
      typeof child.properties.name !== 'undefined' &&
      'errors' in child.properties &&
      typeof child.properties.errors !== 'undefined'
    ) {
      child.properties.errors = [];
    }
  }

  private getSections(): string[] {
    const sections: string[] = [];

    if (this.blueprint && this.blueprint.children) {
      traverseElements(this.blueprint.children, (child) => {
        if (
          child.type === 'section' &&
          'properties' in child &&
          typeof child.properties !== 'undefined' &&
          'title' in (child as IElementSection).properties &&
          typeof (child as IElementSection).properties.title !== 'undefined'
        ) {
          const section = child as IElementSection;

          if (section.properties.title && sections.indexOf(section.properties.title) === -1) {
            sections.push(section.properties.title);
          }
        }
      });
    }

    return sections;
  }

  private clearErrors(children: ILayoutTypeElements) {
    traverseElements(children, (child: ILayoutTypeElement) => {
      this.clearError(child);
    });
  }

  private setError(child: ILayoutTypeElement, error: FormValidationError): boolean {
    let found = false;

    if (
      'properties' in child &&
      typeof child.properties !== 'undefined' &&
      'name' in child.properties &&
      typeof child.properties.name !== 'undefined' &&
      'errors' in child.properties &&
      typeof child.properties.errors !== 'undefined'
    ) {
      const name = child.properties.name.join('.');

      for (const errorName in error.errors) {
        if (
          // eslint-disable-next-line no-prototype-builtins
          error.errors.hasOwnProperty(errorName) &&
          name.indexOf(errorName) === 0
        ) {
          if (name.length !== errorName.length && name.indexOf(errorName + '.') === -1) {
            continue;
          }

          child.properties.errors = error.errors[`${errorName}`];
          found = true;
        }
      }
    }

    return found;
  }

  private setErrors(children: ILayoutTypeElements, error: FormValidationError) {
    let isFirst = true;

    traverseElements(children, (child: ILayoutTypeElement) => {
      const found = this.setError(child, error);

      if (found && isFirst) {
        isFirst = false;
        Vue.set(child, 'scrollIntoView', true);
      }
    });
  }

  public inIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  public reloadPage() {
    window.location.reload();
  }

  public get template(): ILayoutTemplate | undefined {
    let template: ILayoutTemplate | undefined;

    if (this.blueprint && this.blueprint.properties.template) {
      template = this.blueprint.properties.template;
    }

    if (!template && this.defaultTemplate) {
      return {
        name: this.defaultTemplate
      };
    }

    return template;
  }

  public get actions(): ILayoutActionType[] {
    if (this.blueprint && this.blueprint.properties && this.blueprint.properties.actions) {
      return this.blueprint.properties.actions.filter((action) => {
        /**
         * Since we display a checkmark in the submit button, then we would like
         * to hide success indicators from the toast messages if the blueprint
         * update/save was due to a submit button.
         *
         * Here we assume that buttons with the name 'save' are submit buttons.
         * */
        if (
          this.reloadDueToSave &&
          action.type === ActionType.MESSAGE &&
          action.display === ActionMessageDisplay.SUCCESS &&
          !action.title &&
          !action.description
        ) {
          return false;
        }

        return true;
      });
    }

    return [];
  }

  public get children() {
    return getElementsForRender(this.blueprint?.children ?? []);
  }
}
</script>

<style lang="scss" scoped>
.blueprint {
  width: 100%;

  &__topbar {
    position: fixed;
    left: 0;
    top: 0;
    height: 2px;
    background-color: var(--color-background-layer-base);
    width: 0%;
    z-index: 20;
    transition: width 2s ease-in-out;
    opacity: 0;
    pointer-events: none;

    &--loading {
      opacity: 1;
      width: 75%;
    }

    &--finishing {
      opacity: 1;
      transition-duration: 275ms;
      width: 100%;
    }

    &--theme-inverted {
      background-color: var(--navigation-color-layer-2);
    }
  }
}
</style>
