<template>
  <div class="step-guide">
    <p-container gap-size="large" align-items="center">
      <p-progress-step
        :steps="steps"
        v-model="element.properties.value"
        :size="element.properties.size"
        @update:modelValue="onInteract()"
      />

      <div class="step-guide__content">
        <p-transition :duration="275" animation="fade">
          <layout-element v-if="activeStep" :element="activeStep.element" :key="activeStep.id" />
        </p-transition>
      </div>
    </p-container>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementStepGuide, IElementStepGuideStep } from '@/interfaces/element';
import { Step } from '../ui/ProgressStep.vue';
import { Trigger } from '@/Trigger';

@Component({
  name: 'layout-element-step-guide'
})
export default class extends Vue {
  @Prop() public element!: IElementStepGuide;
  private didInteract = false;

  public get activeStep(): IElementStepGuideStep | undefined {
    const activeStep = this.element.properties.steps.find((step) => step.id === this.element.properties.value);

    if (activeStep) {
      return activeStep;
    }

    return undefined;
  }

  public get steps(): Step[] {
    return this.element.properties.steps.map<Step>((step) => {
      return {
        id: step.id,
        label: step.title
      };
    });
  }

  public onInteract() {
    this.didInteract = true;

    setTimeout(() => {
      this.didInteract = false;
    }, 625);
  }

  @Watch('element.properties.value')
  public onTabChange(newValue: string) {
    if (this.element.properties.trigger && this.element.properties.trigger.type && this.didInteract) {
      Trigger.handle(this.element.properties.trigger, this.$el, this.element.properties.name, newValue);
    }
  }
}
</script>

<style lang="scss" scoped>
.step-guide {
  width: 100%;

  &__content {
    width: 100%;
  }
}
</style>
