<template>
  <p-form-element
    :disabled="disabled"
    :readonly="readonly"
    :label="label"
    :help-text="helpText"
    :help-in-tooltip="helpInTooltip"
    :error="error"
  >
    <p-code-editor
      :disabled="disabled"
      :readonly="readonly"
      :error="!!error"
      :language="language"
      :modelValue="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
    />
  </p-form-element>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: String, required: false, default: null }) public readonly modelValue!: string | null;
  @Prop({ type: String, required: false, default: 'javascript' }) public readonly language!: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly helpInTooltip?: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly error?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly readonly!: boolean;
}
</script>
