<template>
  <p-container gap-size="large">
    <p-settings-navigation
      :disabled="element.properties.disabled"
      :items="items"
      v-model="element.properties.value"
      @change="onInteract()"
    />

    <template v-if="selectedContent.length > 0">
      <layout-element v-for="child in selectedContent" :element="child" :key="child.key" />
    </template>
  </p-container>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementSettingsNavigation, IElementTabsItem } from '@/interfaces/element';
import debounce from 'lodash-decorators/debounce';
import { Iframe } from '@/iframe';
import { Trigger } from '@/Trigger';
import { SettingsNavItem } from '../ui/form/SettingsNavigation.vue';
import { getElementsForRender } from '@/utility';

@Component({
  name: 'layout-element-settings-navigation'
})
export default class extends Vue {
  @Prop() public element!: IElementSettingsNavigation;

  private didInteract = false;
  private didInteractTimer: number | null = null;

  onInteract() {
    this.didInteract = true;

    if (this.didInteractTimer !== null) {
      clearTimeout(this.didInteractTimer);
    }

    this.didInteractTimer = setTimeout(() => {
      this.didInteract = false;
      this.didInteractTimer = null;
    }, 500);
  }

  @Watch('element.properties.value')
  @debounce(25)
  onTabChange(newValue: string) {
    if (this.didInteract) {
      this.$el.dispatchEvent(
        new CustomEvent('BLUEPRINT_INTERACT', {
          bubbles: true,
          composed: true
        })
      );
    }

    if (this.didInteract && this.element.properties.name) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }

    if (this.didInteract && this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(this.element.properties.trigger, this.$el, this.element.properties.name, newValue);
    }
  }

  public get selectedItem(): IElementTabsItem | undefined {
    if (!this.element.properties.value) {
      return undefined;
    }

    return this.element.properties.items.find((item) => item.id === this.element.properties.value);
  }

  public get items(): SettingsNavItem[] {
    return this.element.properties.items.map<SettingsNavItem>((item) => {
      return {
        id: item.id,
        label: item.text
      };
    });
  }

  public get selectedContent() {
    return getElementsForRender(this.selectedItem?.content ?? []);
  }
}
</script>
