<template>
  <menu-overlay :show="show" title="User overview" @close-request="$emit('close-request')">
    <div class="menu-user">
      <div class="menu-user__nav">
        <menu-nav-item
          v-for="item in filteredNavItems"
          :key="item.href"
          :href="item.href"
          :active="$route.path.startsWith(item.href)"
        >
          <template v-slot:icon>
            <p-icon size="large" :icon="item.icon" />
          </template>

          {{ item.text }}
        </menu-nav-item>
      </div>

      <div class="menu-user__attributes">
        <div class="menu-user__attribute">
          <div class="menu-user__attribute-name">
            <div class="menu-user__attribute-name-icon"><p-icon size="small" icon="user" /></div>
            <div class="menu-user__attribute-name-text">User</div>
          </div>
          <div class="menu-user__attribute-value">{{ user.name }}</div>
        </div>

        <div class="menu-user__attribute">
          <div class="menu-user__attribute-name">
            <div class="menu-user__attribute-name-icon"><p-icon size="small" icon="map" /></div>
            <div class="menu-user__attribute-name-text">Plan</div>
          </div>
          <div class="menu-user__attribute-value">{{ workspace.plan }}</div>
        </div>

        <div class="menu-user__attribute">
          <div class="menu-user__attribute-name">
            <div class="menu-user__attribute-name-icon"><p-icon size="small" icon="mail" /></div>
            <div class="menu-user__attribute-name-text">SMS tokens</div>
          </div>
          <div class="menu-user__attribute-value">
            <p-skeleton-loader size="extra-small" v-if="smsTokens === null" />
            <template v-else>{{ smsTokens }} tokens available</template>
          </div>
        </div>

        <div class="menu-user__attribute" v-if="workspace.plan_alias === 'credits'">
          <div class="menu-user__attribute-name">
            <div class="menu-user__attribute-name-icon"><p-icon size="small" icon="mail" /></div>
            <div class="menu-user__attribute-name-text">Credits</div>
          </div>
          <div class="menu-user__attribute-value">
            <p-skeleton-loader v-if="credits === null" />
            <template v-else>{{ credits }} credits available</template>
          </div>
        </div>
      </div>
    </div>

    <template v-slot:footer>
      <p-button color-type="secondary" disabled>Close account</p-button>
      <p-link href="/api/logout-direct"><p-button color-type="primary">Logout</p-button></p-link>
    </template>

    <template v-slot:header>
      <menu-users />
    </template>
  </menu-overlay>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import MenuOverlay from './MenuOverlay.vue';
import { PropType } from 'vue';
import MenuNavItem from './MenuNavItem.vue';
import MenuUsers from './MenuUsers.vue';
import { AppRequest } from '@/app_request';
import { UserResource } from '@/types/api/user';
import { WorkspaceResource } from '@/types/api/workspace';

interface NavItem {
  text: string;
  href: string;
  type?: string[];
  icon: string;
}

@Component({
  components: { MenuOverlay, MenuNavItem, MenuUsers }
})
export default class extends Vue {
  @Prop({ type: Boolean, required: false, default: true }) public readonly show!: boolean;
  @Prop({ type: Object as PropType<UserResource>, required: true }) public readonly user!: UserResource;
  @Prop({ type: Object as PropType<WorkspaceResource>, required: true }) public readonly workspace!: WorkspaceResource;

  public smsTokens: number | null = null;
  public credits: number | null = null;

  public async mounted() {
    const response = (await AppRequest.get<{ credits: number; sms_tokens: number }>('/api/v1/workspace/balance')).data;

    this.smsTokens = response.sms_tokens;
    this.credits = response.credits;
  }

  private navItems: NavItem[] = [
    {
      icon: 'users',
      text: 'Account Overview',
      href: '/account/overview'
    },
    {
      icon: 'settings',
      text: 'My settings',
      href: '/account/my-settings'
    },
    {
      icon: 'users',
      type: ['ADMIN', 'SUPERADMIN'],
      text: 'Customers',
      href: '/admin/customers'
    },
    {
      icon: 'sun',
      type: ['ADMIN', 'SUPERADMIN'],
      text: 'All campaigns',
      href: '/admin/active-campaigns'
    },
    {
      icon: 'mail',
      type: ['ADMIN', 'SUPERADMIN'],
      text: 'Email',
      href: '/admin/email-templates'
    },
    {
      icon: 'mobile',
      type: ['ADMIN', 'SUPERADMIN'],
      text: 'Sms',
      href: '/admin/sms/queue-list'
    },
    {
      icon: 'file-text',
      type: ['ADMIN', 'SUPERADMIN'],
      text: 'Invoices',
      href: '/admin/invoices'
    },
    {
      icon: 'layers',
      type: ['ADMIN', 'SUPERADMIN'],
      text: 'ESP Queue',
      href: '/admin/esp-queue'
    },
    {
      icon: 'package',
      type: ['ADMIN', 'SUPERADMIN'],
      text: 'Products',
      href: '/admin/products'
    }
  ];

  public get filteredNavItems() {
    return this.navItems.filter((item) => !item.type || item.type.includes(this.workspace.type));
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/mixins/typography';
@import '../../../../scss/mixins/devices';

.menu-user {
  display: flex;
  align-items: flex-start;
  width: 100%;

  &__nav {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--gap-size-large);
    width: 100%;
    flex-wrap: wrap;
  }

  &__attributes {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-size-medium);
  }

  &__attribute {
    display: flex;
    align-items: flex-start;
    gap: var(--gap-size-medium);

    &-name {
      display: flex;
      align-items: flex-start;
      gap: var(--gap-size-small);

      &-text {
        @include text-body-strong;
      }
    }

    &-value {
      @include text-body-default;
    }
  }
}

@include for-mobile-only {
  .menu-user {
    flex-wrap: wrap;

    &__nav {
      gap: var(--gap-size-medium);
    }

    &__attributes {
      width: 100%;
    }
  }
}
</style>
