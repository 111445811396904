<template>
  <LayoutError
    :title="template.params.title"
    :description="template.params.description"
    :no-logo="template.params.noLogo"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ILayoutTemplate } from '@/interfaces/template';
import LayoutError from '@/components/LayoutError.vue';

interface ILayoutTemplateError extends ILayoutTemplate {
  params: {
    title: string;
    description: string;
    noLogo?: boolean;
  };
}

@Component({
  components: {
    LayoutError
  },
  name: 'template-error'
})
export default class TemplateError extends Vue {
  @Prop() public template!: ILayoutTemplateError;
}
</script>
