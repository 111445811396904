import { render, staticRenderFns } from "./MenuUserOverview.vue?vue&type=template&id=5bf7b250&scoped=true"
import script from "./MenuUserOverview.vue?vue&type=script&lang=ts"
export * from "./MenuUserOverview.vue?vue&type=script&lang=ts"
import style0 from "./MenuUserOverview.vue?vue&type=style&index=0&id=5bf7b250&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bf7b250",
  null
  
)

export default component.exports