<template>
  <div class="actions" :class="{ 'actions--loading': loading }">
    <div class="messages">
      <transition-group name="message" :duration="650">
        <div class="messages__item" v-for="message in messages" :key="message.id">
          <p-toast
            v-if="message.title || message.description"
            :type="message.display === 'success' ? 'positive' : 'negative'"
            :headline="message.title"
            :text="message.description"
            @close-request="removeMessage(message)"
          />
          <p-indicator
            v-else
            :type="message.display === 'success' ? 'positive' : 'negative'"
            @click="removeMessage(message)"
          />
        </div>
      </transition-group>
    </div>

    <p-modal-loader v-if="loading" :progress="progressDisplay" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EventBus } from '@/main';
import {
  ActionMessageDisplay,
  ActionType,
  ILayoutActionDataLayerEvent,
  ILayoutActionMessage,
  ILayoutActionType,
  ILayoutActionWaitForJob,
  ILayoutActionWaitForUrl,
  TriggerType
} from '@/interfaces/element';
import confetti from 'canvas-confetti';

import { Trigger } from '@/Trigger';
import { Iframe } from '@/iframe';
import { getBlueprint, getQueryStringParam } from '@/utility';

let messagesCounter = 0;

enum WaitForJobStatus {
  QUEUED = 'queued',
  EXECUTING = 'executing',
  FINISHED = 'finished',
  RETRYING = 'retrying',
  FAILED = 'failed'
}

interface WaitForJobResponse {
  status?: WaitForJobStatus;
  progress: number | null;
}

@Component({
  name: 'layout-actions'
})
export default class LayoutActions extends Vue {
  @Prop() private actions!: ILayoutActionType[];

  public messages: ILayoutActionMessage[] = [];
  public loading = false;
  public progress = 0;
  public showProgress = false;
  private waitForJobTimer: number | null = null;
  private lastKnownProgress: number | null = null;

  @Watch('actions')
  onActionsChange(newValue: ILayoutActionType[] | null, oldValue: ILayoutActionType[] | null) {
    if (newValue) {
      if (oldValue && oldValue.length > 0) {
        this.messages = [];
      }

      newValue.forEach((action) => {
        if (!action.finished) {
          if (action.bubbleToTop) {
            EventBus.$emit('BLUEPRINT_ACTION', action);

            return;
          }

          action.finished = true;

          this.handler(action);
        }
      });
    }
  }

  handler(action: ILayoutActionType) {
    switch (action.type) {
      case ActionType.IFRAME_EVENT:
        if ('name' in action) {
          Iframe.emit(action.name, action.data || null);
        }
        break;

      case ActionType.RELOAD:
        setTimeout(
          () => {
            EventBus.$emit(
              'BLUEPRINT_RELOAD',
              'topLevel' in action && typeof action.topLevel !== 'undefined' ? action.topLevel : false,
              'forId' in action && typeof action.forId !== 'undefined' ? action.forId : null,
              'fullReload' in action && typeof action.fullReload !== 'undefined' ? action.fullReload : null
            );
          },
          'delay' in action && typeof action.delay !== 'undefined' && action.delay !== null ? action.delay * 1000 : 0
        );
        break;

      case ActionType.CLOSE_WINDOW:
        window.close();
        break;

      case ActionType.CONFETTI:
        this.showConfetti();
        break;

      case ActionType.REDIRECT:
        if ('url' in action && 'delay' in action && 'topLevel' in action) {
          this.redirect(action.url, action.delay, action.topLevel);
        }
        break;

      case ActionType.OPEN_POPUP:
        if ('url' in action) {
          Trigger.handle(
            {
              type: TriggerType.POPUP,
              popup: action.url,
              popupHeadline: action.headline,
              action: null,
              condition: null
            },
            this.$el
          );
        }
        break;

      case ActionType.MESSAGE:
        if ('title' in action && 'description' in action && 'display' in action) {
          this.showMessage(action);
        }
        break;

      case ActionType.SCROLL_TO_TOP:
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        break;

      case ActionType.WAIT_FOR_URL:
        this.waitForUrl(action as ILayoutActionWaitForUrl);
        break;

      case ActionType.WAIT_FOR_JOB:
        this.waitForJob(action as ILayoutActionWaitForJob);
        break;

      case ActionType.CLOSE_POPUP:
        EventBus.$emit('BLUEPRINT_CLOSE_POPUP');
        break;

      case ActionType.DATA_LAYER_EVENT:
        if ('name' in action) {
          this.addDataLayerEvent(action as ILayoutActionDataLayerEvent);
        }
        break;
    }
  }

  // eslint-disable-next-line require-await
  async waitForJob(action: ILayoutActionWaitForJob) {
    this.loading = true;

    if (this.waitForJobTimer !== null) {
      clearInterval(this.waitForJobTimer);
      this.waitForJobTimer = null;
    }

    this.waitForJobTimer = setInterval(async () => {
      try {
        const response = await this.axios.get<WaitForJobResponse>(action.statusApi, {
          responseType: 'json'
        });

        const responseData = response.data;

        if (responseData.progress !== null) {
          this.showProgress = true;
          this.progress = responseData.progress;
        }

        if (
          typeof responseData.status !== 'undefined' &&
          [WaitForJobStatus.FAILED, WaitForJobStatus.FINISHED].includes(responseData.status)
        ) {
          if (this.waitForJobTimer !== null) {
            clearInterval(this.waitForJobTimer);
            this.waitForJobTimer = null;
          }

          this.loading = false;

          if (responseData.status === WaitForJobStatus.FINISHED && typeof action.onResolve !== 'undefined') {
            this.lastKnownProgress = null;
            this.showProgress = false;

            action.onResolve.forEach((actionItem) => {
              this.handler(actionItem);
            });
          }

          if (responseData.status === WaitForJobStatus.FAILED && typeof action.onReject !== 'undefined') {
            this.lastKnownProgress = null;
            this.showProgress = false;

            action.onReject.forEach((actionItem) => {
              this.handler(actionItem);
            });
          }
        }
      } catch (e) {
        if (this.waitForJobTimer !== null) {
          clearInterval(this.waitForJobTimer);
          this.waitForJobTimer = null;
        }

        this.loading = false;

        if (typeof action.onReject !== 'undefined') {
          action.onReject.forEach((actionItem) => {
            this.handler(actionItem);
          });
        }
      }
    }, 5000);
  }

  async waitForUrl(action: ILayoutActionWaitForUrl) {
    this.loading = true;

    try {
      await this.axios.post(action.url, typeof action.postData !== 'undefined' ? action.postData : null);

      if (typeof action.onResolve !== 'undefined') {
        action.onResolve.forEach((actionItem) => {
          this.handler(actionItem);
        });
      }
    } catch (e) {
      if (typeof action.onReject !== 'undefined') {
        action.onReject.forEach((actionItem) => {
          this.handler(actionItem);
        });
      }
    }

    this.loading = false;
  }

  redirect(href: string, delay: number | null, topLevel: boolean) {
    setTimeout(
      () => {
        if (
          href.indexOf('klaviyo.com') !== -1 ||
          href.indexOf('superoffice.com') !== -1 ||
          href.indexOf('accounts.google.com') !== -1 ||
          href.indexOf('thinkific') !== -1 ||
          href.indexOf('amazonaws.com') !== -1 ||
          href.indexOf('salesforce.com') !== -1 ||
          href.indexOf('amazoncognito') !== -1 ||
          href.indexOf('gameplay.lobyco.com') !== -1 ||
          href.indexOf('api.leadfamly.com/oauth2/authorize') !== -1
        ) {
          window.location.href = href;
          return;
        }

        if (href.indexOf('http') !== -1 && href.indexOf(window.location.host) === -1) {
          if (href.indexOf('.xls') !== -1 || href.indexOf('.csv') !== -1) {
            window.location.href = href;
            return;
          }

          // eslint-disable-next-line security/detect-non-literal-fs-filename
          window.open(href);
          return;
        }

        let path = href.replace(location.origin, '');

        const blueprint = getBlueprint(this);

        if (typeof this.$route.query.template !== 'undefined' && path.indexOf('template') === -1) {
          path += (path.indexOf('?') !== -1 ? '&' : '?') + 'template=' + this.$route.query.template;
        } else if (blueprint !== null && blueprint.getUrl()) {
          const templateFromQuery = getQueryStringParam(blueprint.getUrl(), 'template');

          if (
            templateFromQuery !== null &&
            path.indexOf('template') === -1 &&
            templateFromQuery === 'fast-track-embed'
          ) {
            path += (path.indexOf('?') !== -1 ? '&' : '?') + 'template=' + templateFromQuery;
          }
        }

        if (topLevel) {
          EventBus.$emit('BLUEPRINT_CLOSE_POPUP');
          this.$router.push({ path: path });
        } else {
          Trigger.handle(
            {
              type: TriggerType.NAVIGATION,
              url: path,
              action: null,
              condition: null
            },
            this.$el
          );
        }
      },
      delay !== null ? delay : 0
    );
  }

  public showConfetti() {
    const end = Date.now() + 2 * 1000;
    const colors = ['#bb0000', '#ffffff'];

    (function frame() {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors
      });

      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
  }

  showMessage(message: ILayoutActionMessage) {
    messagesCounter++;

    const id = messagesCounter + 0;
    message.id = id;

    this.messages.push(message);

    if (message.display === ActionMessageDisplay.SUCCESS) {
      setTimeout(
        () => {
          this.messages = this.messages.filter((message) => {
            return message.id !== id;
          });
        },
        message.display === ActionMessageDisplay.SUCCESS ? 3000 : 5000
      );
    }
  }

  addDataLayerEvent(action: ILayoutActionDataLayerEvent) {
    if (action.name) {
      (window as any).dataLayer.push({
        event: action.name,
        data: action.data ?? null
      });
    }
  }

  /**
   * Keep track of previous progress display to give the illusion of progress.
   * We do this by keeping a maximum of 20% stored as the last known progress.
   * So when it swaps between progress percentages and it's below this, then it
   * keeps showing 20% until it goes beyond this.
   */
  get progressDisplay() {
    if (this.lastKnownProgress && !this.progress) {
      return this.lastKnownProgress;
    }

    this.lastKnownProgress = this.progress;
    return this.progress;
  }

  formatProgress(value: number) {
    return `${Math.floor(value)}%`;
  }

  public removeMessage(message: ILayoutActionMessage) {
    this.messages = this.messages.filter((messageItem) => {
      return messageItem.id !== message.id;
    });
  }
}
</script>

<style lang="scss" scoped>
@import './../scss/mixins/devices.scss';

.messages {
  &__item {
    position: fixed;
    z-index: 1000;
    right: 30px;
    bottom: 30px;
  }
}

@keyframes message-enter {
  0% {
    transform: translateY(200px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes message-leave {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(200px);
  }
}

.message {
  &-enter {
    &-active {
      animation: message-enter 650ms forwards;
    }

    /*&-to {
      opacity: 1;
      transform: translateY(0%);
    }*/
  }

  &-leave {
    &-active {
      animation: message-leave 650ms forwards;
    }

    /*&-to {
      opacity: 0;
      transform: translateY(25%);
    }*/
  }
}

@include for-mobile-only {
  .messages {
    &__item {
      left: 0;
      right: 0;
      padding: 0 20px;
    }
  }
}
</style>
