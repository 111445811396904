<template>
  <p-container>
    <slot />
  </p-container>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ILayoutTemplate } from '@/interfaces/template';
import { PropType } from 'vue';

@Component({
  name: 'template-public'
})
export default class extends Vue {
  @Prop({ type: Object as PropType<ILayoutTemplate> }) public readonly template!: ILayoutTemplate;
}
</script>
