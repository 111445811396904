import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'custom-event-polyfill';
import 'formdata-polyfill';

import './class-component-hooks';

import '@beplayable/platform-ui';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueAxios from 'vue-axios';
import Axios from 'axios';
import VueTransmit from 'vue-transmit';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueCookies from 'vue-cookies';
import { Fragment } from 'vue-frag';
import { createPinia, PiniaVuePlugin } from 'pinia';

import '@beplayable/platform-ui/src/scss/root.scss';

import './ui';
import './directives/device';

import Elements from '@/components/LayoutElements.vue';
import Element from '@/components/LayoutElement.vue';
import Blueprint from '@/components/elements/LayoutElementBlueprint.vue';

declare global {
  interface Window {
    dataLayer?: Record<string, any>[];
  }
}

if (window.location.hostname === 'app.leadfamly.com') {
  window.location.href = 'https://appv2.leadfamly.com' + window.location.pathname;
}

Vue.component('Fragment', Fragment);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(VueCookies);
Vue.use(VueAxios, Axios);
Vue.use(VueTransmit);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDzTWe_IPnCX3AaNo85WBbe6PWVbwWd6B0'
  },
  installComponents: true
});

Vue.config.productionTip = false;

// Other global elements
Vue.component('layout-elements', Elements);
Vue.component('layout-element', Element);
Vue.component('layout-element-blueprint', Blueprint);

export const EventBus = new Vue();

// Cleanup session storage as we only want it to be kept per page
try {
  sessionStorage.removeItem('media-folder-path');
} catch (e) {
  // eslint-disable-next-line no-console
  console.log('Unable to remove media-folder-path from session storage');
}

setInterval(() => {
  if (typeof window.dataLayer !== 'undefined' && window.dataLayer.length > 20) {
    window.dataLayer = window.dataLayer.slice(0, 20);
  }
}, 60 * 1000);

new Vue({
  router,
  pinia,
  render: (h) => h(App)
}).$mount('#app');
