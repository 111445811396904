import { AppRequest } from '@/app_request';
import { ChartAreaData } from '@/components/ui/chart/types';
import {
  CampaignIntegrationResource,
  CampaignPageResource,
  CampaignResource,
  CampaignTypeResource
} from '@/types/api/campaign';
import { PaginatedResult } from '@/types/api/generic';
import { CampaignPageModel } from './page';
import { CampaignIntegrationModel } from './integration';
import { addDays, differenceInDays } from 'date-fns';

export interface CampaignSummaryData {
  session_duration: {
    total: number;
    average: number;
  };

  tip_a_friend: number;
  unique_registrations: number;
}

export interface CampaignRealtimeData {
  desktop: number;
  tablet: number;
  mobile: number;
}

export interface CampaignExternalLinksData {
  url: string;
  total: number;
}

export interface CampaignUtmData {
  value: string;
  sessions: number;
}

export interface CampaignCountryStatsData {
  lat: number;
  lng: number;
  sessions: {
    raw: number;
    formatted: string;
  };
  size: 'xs' | 's' | 'm' | 'l' | 'xl';
}

export interface CampaignReferrerData {
  url: string;
  sessions: number;
}

export interface CampaignChartData {
  desktop: ChartAreaData[];
  tablet: ChartAreaData[];
  mobile: ChartAreaData[];
}

export interface SentEspData {
  [key: string]: number;
}

export interface CampaignSentToEspData {
  mapped: SentEspData[];
  created: number;
  updated: number;
}

export interface CampaignFlowPageSessionsData {
  [key: string]: number;
}

// Internal class for grouping statistic related methods together.
class CampaignStatisticsModel {
  private readonly campaign: CampaignResource;
  private readonly apiId: string;

  constructor(campaign: CampaignResource, id: string) {
    this.campaign = campaign;
    this.apiId = id;
  }

  public async getSummary(): Promise<CampaignSummaryData> {
    return (
      await AppRequest.get<CampaignSummaryData>(`/api/v1/campaign/${this.apiId}/statistics/summary`, {
        params: {
          ...(window.location.href.includes('sam=1') && { sam: 1 }),
          ...(window.location.href.includes('sam=0') && { sam: 0 })
        }
      })
    ).data;
  }

  public async getUniqueRegistrations(dateFrom: string, dateTo: string): Promise<number> {
    return (
      await AppRequest.get<number>(`/api/v1/campaign/${this.apiId}/statistics/unique-registrations`, {
        params: {
          date_from: dateFrom,
          date_to: dateTo
        }
      })
    ).data;
  }

  public async getSentToEsp(): Promise<CampaignSentToEspData> {
    return (await AppRequest.get<CampaignSentToEspData>(`/api/v1/campaign/${this.apiId}/statistics/sent-to-esp`)).data;
  }

  public async getFlowPageSessions(dateFrom: string, dateTo: string): Promise<CampaignFlowPageSessionsData> {
    return (
      await AppRequest.get<CampaignFlowPageSessionsData>(
        `/api/v1/campaign/${this.apiId}/statistics/flow-page-sessions`,
        {
          params: {
            date_from: dateFrom,
            date_to: dateTo,
            ...(window.location.href.includes('sam=1') && { sam: 1 }),
            ...(window.location.href.includes('sam=0') && { sam: 0 })
          }
        }
      )
    ).data;
  }

  public async getRealtime(): Promise<CampaignRealtimeData> {
    return (
      await AppRequest.get<CampaignRealtimeData>(`/api/v1/campaign/${this.apiId}/statistics/realtime`, {
        params: {
          ...(window.location.href.includes('sam=1') && { sam: 1 }),
          ...(window.location.href.includes('sam=0') && { sam: 0 })
        }
      })
    ).data;
  }

  public async getExternalLinks(): Promise<CampaignExternalLinksData[]> {
    return (await AppRequest.get<CampaignExternalLinksData[]>(`/api/v1/campaign/${this.apiId}/statistics/links`)).data;
  }

  public async getUtm(type: 'source' | 'medium' | 'campaign'): Promise<CampaignUtmData[]> {
    return (
      await AppRequest.get<CampaignUtmData[]>(`/api/v1/campaign/${this.apiId}/statistics/utm`, {
        params: {
          type,
          ...(window.location.href.includes('sam=1') && { sam: 1 }),
          ...(window.location.href.includes('sam=0') && { sam: 0 })
        }
      })
    ).data;
  }

  public async getSessionsMap(dateFrom: string, dateTo: string): Promise<CampaignCountryStatsData[]> {
    return (
      await AppRequest.get<CampaignCountryStatsData[]>(`/api/v1/campaign/${this.apiId}/statistics/sessions-map`, {
        params: {
          date_from: dateFrom,
          date_to: dateTo,
          ...(window.location.href.includes('sam=1') && { sam: 1 }),
          ...(window.location.href.includes('sam=0') && { sam: 0 })
        }
      })
    ).data;
  }

  public async getReferrer(): Promise<CampaignReferrerData[]> {
    return (
      await AppRequest.get<CampaignReferrerData[]>(`/api/v1/campaign/${this.apiId}/statistics/referrer`, {
        params: {
          ...(window.location.href.includes('sam=1') && { sam: 1 }),
          ...(window.location.href.includes('sam=0') && { sam: 0 })
        }
      })
    ).data;
  }

  public async getSessions(dateFrom: string, dateTo: string): Promise<CampaignChartData> {
    return (
      await AppRequest.get<CampaignChartData>(`/api/v1/campaign/${this.apiId}/statistics/sessions`, {
        params: {
          date_from: dateFrom,
          date_to: dateTo,
          ...(window.location.href.includes('sam=1') && { sam: 1 }),
          ...(window.location.href.includes('sam=0') && { sam: 0 })
        }
      })
    ).data;
  }

  public async getRegistrations(dateFrom: string, dateTo: string): Promise<CampaignChartData> {
    return (
      await AppRequest.get<CampaignChartData>(`/api/v1/campaign/${this.apiId}/statistics/registrations`, {
        params: {
          date_from: dateFrom,
          date_to: dateTo
        }
      })
    ).data;
  }
}

class CampaignFlowPagesModel {
  private readonly campaign: CampaignResource;
  private readonly apiId: string;

  constructor(campaign: CampaignResource, id: string) {
    this.campaign = campaign;
    this.apiId = id;
  }

  public async list(page = 1, perPage = 30): Promise<CampaignPageModel[]> {
    const result = (
      await AppRequest.get<PaginatedResult<CampaignPageResource>>(`/api/v1/campaign/${this.apiId}/flow-page`, {
        params: {
          page,
          per_page: perPage
        }
      })
    ).data;

    return result.data.map((pageResource) => new CampaignPageModel(pageResource));
  }
}

class CampaignSectionsModel {
  private readonly campaign: CampaignResource;
  private readonly apiId: string;

  constructor(campaign: CampaignResource, id: string) {
    this.campaign = campaign;
    this.apiId = id;
  }

  public async list(page = 1, perPage = 30): Promise<CampaignPageModel[]> {
    const result = (
      await AppRequest.get<PaginatedResult<CampaignPageResource>>(`/api/v1/campaign/${this.apiId}/section`, {
        params: {
          page,
          per_page: perPage
        }
      })
    ).data;

    return result.data.map((resource) => new CampaignPageModel(resource));
  }
}

class CampaignIntegrationsModel {
  private readonly campaign: CampaignResource;
  private readonly apiId: string;

  constructor(campaign: CampaignResource, id: string) {
    this.campaign = campaign;
    this.apiId = id;
  }

  public async list(page = 1, perPage = 30): Promise<CampaignIntegrationModel[]> {
    const result = (
      await AppRequest.get<PaginatedResult<CampaignIntegrationResource>>(`/api/v1/campaign/${this.apiId}/integration`, {
        params: {
          page,
          per_page: perPage
        }
      })
    ).data;

    return result.data.map((resource) => new CampaignIntegrationModel(resource));
  }
}

export class CampaignModel {
  public readonly resource: CampaignResource;
  public readonly statistics: CampaignStatisticsModel;

  public readonly flowPages: CampaignFlowPagesModel;
  public readonly sections: CampaignSectionsModel;

  public readonly integrations: CampaignIntegrationsModel;

  private readonly apiId: string;

  constructor(resource: CampaignResource, identifyBy: 'uuid' | 'hash') {
    this.resource = resource;

    this.apiId = identifyBy === 'uuid' ? resource.uuid : resource.hash;

    this.statistics = new CampaignStatisticsModel(resource, this.apiId);
    this.flowPages = new CampaignFlowPagesModel(resource, this.apiId);
    this.sections = new CampaignSectionsModel(resource, this.apiId);
    this.integrations = new CampaignIntegrationsModel(resource, this.apiId);
  }

  public async getType(): Promise<CampaignTypeResource> {
    return (await AppRequest.get<{ data: CampaignTypeResource }>(`/api/v1/campaign/${this.apiId}/type`)).data.data;
  }

  public get daysUntilRetention() {
    if (this.resource.state !== 'in_expire_cycle') {
      return 0;
    }

    let retentionDays = 0;

    switch (this.resource.retention) {
      case '30_days_after_expire':
        retentionDays = 30;
        break;

      case '60_days_after_expire':
        retentionDays = 60;
        break;

      case '90_days_after_expire':
        retentionDays = 90;
        break;
    }

    const expireCycleEnd = addDays(new Date(this.resource.active_to), retentionDays);
    const currentDate = new Date();
    return differenceInDays(expireCycleEnd, currentDate);
  }

  static async fromUuid(uuid: string): Promise<CampaignModel> {
    const data = (
      await AppRequest.get<{ data: CampaignResource }>(`/api/v1/campaign/${uuid}`, {
        params: {
          with: ['registrations']
        }
      })
    ).data.data;

    return new CampaignModel(data, 'uuid');
  }

  static async fromId(id: number): Promise<CampaignModel> {
    const data = (
      await AppRequest.get<{ data: CampaignResource }>(`/api/v1/campaign/${id}`, {
        params: {
          with: ['registrations']
        }
      })
    ).data.data;

    return new CampaignModel(data, 'uuid');
  }

  static async fromHash(id: string): Promise<CampaignModel> {
    const data = (
      await AppRequest.get<{ data: CampaignResource }>(`/api/v1/campaign/${id}`, {
        params: {
          with: ['registrations']
        }
      })
    ).data.data;

    return new CampaignModel(data, 'hash');
  }
}
