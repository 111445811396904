<template>
  <p-nav-tabs
    :headline="campaign.name"
    :subheadline="`Campaign ID: #${campaign.id}`"
    :build-campaign-link="campaign?.demo_url"
    :items="tabs"
    :sub-items="subTabs"
  />
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CampaignResource } from '@/types/api/campaign';
import { IMenuItem } from '@/interfaces/menu';
import { TabsNavItem } from './types';

@Component({
  name: 'nav-tabs-campaign'
})
export default class extends Vue {
  @Prop({ type: String, required: false, default: undefined }) public readonly headline!: string;

  @Prop({ type: Object as PropType<CampaignResource>, required: true })
  public readonly campaign!: CampaignResource;

  @Prop({ type: Array as PropType<IMenuItem[]>, required: true }) public menuItems!: IMenuItem[];

  public get tabs(): TabsNavItem[] {
    return (
      this.menuItems
        // Fast track and build your campaign is added in a different way using attributes
        .filter((child) => {
          return child.title !== 'Build your campaign' && child.title !== 'Fast track';
        })
        .map<TabsNavItem>((tab) => {
          return {
            text: tab.title,
            href: tab.url,
            active: this.isTabActive(tab)
          };
        })
    );
  }

  public get subTabs(): TabsNavItem[] {
    const activeTab = this.menuItems.find((tab) => {
      return this.isTabActive(tab);
    });

    if (activeTab && activeTab.children && activeTab.children.length > 0) {
      return activeTab.children.map<TabsNavItem>((tab) => {
        return {
          text: tab.title,
          href: tab.url,
          active: this.isTabActive(tab)
        };
      });
    }

    return [];
  }

  public isTabActive(tab: IMenuItem) {
    if (tab.url === this.$route.path) {
      return true;
    }

    if (tab.children && tab.children.length > 0) {
      const activeTab = tab.children.reduce<IMenuItem | null>((acc, tab) => {
        if (tab.url === this.$route.path) {
          return tab;
        }

        return acc;
      }, null);

      if (activeTab) {
        return true;
      }
    }

    return false;
  }
}
</script>
