import { Node, CommandProps } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType = any> {
    audio: {
      insertAudio: (src: string) => ReturnType;
    };
  }
}

export const CustomAudio = Node.create({
  name: 'audio',

  group: 'block', // Audio is typically a block element

  inline: false, // Ensures the audio element is treated as a block-level element

  draggable: true, // Allows the audio element to be draggable in the editor

  atom: true, // Ensures the node acts as an "atomic" node (cannot be split)

  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: (element) => element.querySelector('source')?.getAttribute('src'),
        renderHTML: (attributes) => {
          if (attributes.src) {
            return { src: attributes.src };
          }
        }
      },
      controls: {
        default: 'controls',
        parseHTML: () => 'controls', // Audio should always have controls
        renderHTML: () => ({ controls: 'controls' })
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'audio',
        getAttrs: (dom) => {
          const source = dom.querySelector('source');
          return source ? { src: source.getAttribute('src') } : false;
        }
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['audio', HTMLAttributes, ['source', { src: HTMLAttributes.src, type: 'audio/mpeg' }]];
  },

  addCommands() {
    return {
      insertAudio:
        (src: string) =>
        ({ commands }: CommandProps): boolean => {
          return commands.insertContent({
            type: this.name,
            attrs: { src }
          });
        }
    };
  }
});
