<template>
  <router-link
    v-if="access"
    tag="div"
    :to="href"
    class="menu-nav-item"
    :class="{ 'menu-nav-item--active': active }"
    @mouseenter.native="$emit('mouseenter')"
    @mouseleave.native="$emit('mouseleave')"
    @click.native="$emit('click')"
  >
    <div class="menu-nav-item__icon">
      <slot name="icon" />
    </div>
    <div class="menu-nav-item__text"><slot /></div>
  </router-link>
  <div v-else class="menu-nav-item menu-nav-item--no-access" :class="{ 'menu-nav-item--active': active }">
    <div class="menu-nav-item__icon">
      <slot name="icon" />
    </div>
    <div class="menu-nav-item__text"><slot /></div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) public readonly active!: boolean;
  @Prop({ type: String, required: true }) public readonly href!: string;
  @Prop({ type: Boolean, required: false, default: true }) public readonly access!: boolean;
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/mixins/typography';

.menu-nav-item {
  display: flex;
  width: 100px;
  height: 124px;
  padding: var(--base-size-200) 0px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  border: 3px solid transparent;
  cursor: pointer;

  &:hover:not(.menu-nav-item--no-access) {
    background: var(--color-hero-100);
  }

  &:active:not(.menu-nav-item--no-access),
  &--active:not(.menu-nav-item--no-access) {
    border: 3px solid var(--color-brand-secondary);
    background: var(--color-hero-200);
  }

  &__icon {
    height: 45px;
  }

  &__text {
    display: flex;
    width: 80px;
    padding: var(--base-size-75, 4px) 0px var(--base-size-200, 12px) 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    text-align: center;

    @include component-text-headline;
  }

  &--no-access {
    cursor: not-allowed;

    > .menu-nav-item__icon {
      color: var(--icon-color-disabled);
    }

    > .menu-nav-item__text {
      color: var(--text-color-disabled);
    }
  }
}
</style>
