<template>
  <p-message
    :type="element.properties.type"
    :display-type="element.properties.display"
    :headline="element.properties.title"
    :description="element.properties.description"
    :show-icon.prop="element.properties.icon"
  >
    <layout-element v-for="child in children" :element="child" :key="child.key" />
  </p-message>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementMessage } from '@/interfaces/element';
import { PropType } from 'vue';
import { getElementsForRender } from '@/utility';

@Component({
  name: 'layout-element-message'
})
export default class extends Vue {
  @Prop({ type: Object as PropType<IElementMessage>, required: true }) public readonly element!: IElementMessage;

  public get children() {
    return getElementsForRender(this.element.children ?? []);
  }
}
</script>
