<template>
  <div class="range-slider" :class="{ 'range--error': error }">
    <input
      ref="refRangeSlider"
      class="range-slider__track"
      type="range"
      :disabled="disabled"
      :readonly="readonly"
      :min="min"
      :max="max"
      :step="step"
      :value="modelValue ?? ''"
      @input="onInput($event)"
      :style="inputRangeStyle"
    />
    <div class="range-slider__ticks" v-if="ticks">
      <span class="range-slider__tick" v-for="(tick, index) in allTicks" :key="index"></span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: Number, required: false, default: null }) public readonly modelValue!: null | number;
  @Prop({ type: Number, required: true }) public readonly min!: number;
  @Prop({ type: Number, required: true }) public readonly max!: number;
  @Prop({ type: Number, required: false, default: 1 }) public readonly step!: number;
  @Prop({ type: Boolean, required: false, default: false }) public readonly ticks!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly error!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly readonly!: boolean;

  public onInput(e: Event) {
    if (e.target instanceof HTMLInputElement) {
      this.$emit('update:modelValue', Number(e.target.value));
    }
  }

  public mounted() {
    this.calculateFillWidth();
  }

  @Watch('modelValue')
  public calculateFillWidth() {
    if (this.modelValue === null || !(this.$refs.refRangeSlider instanceof HTMLInputElement)) return;
    const targetElement = this.$refs.refRangeSlider;
    const fillWidthPixels = (targetElement.clientWidth * (this.modelValue - this.min)) / (this.max - this.min);
    targetElement.style.setProperty('--fill-width-pixels', `${fillWidthPixels}px`);
  }

  public get inputValue() {
    return this.modelValue ?? '';
  }

  public get inputRangeStyle() {
    if (!(this.$refs.refRangeSlider instanceof HTMLInputElement)) {
      return {};
    }

    if (!this.$refs.refRangeSlider) {
      return {
        '--value': this.modelValue,
        '--max': this.max,
        '--diff': this.max - this.min
      };
    }

    const fillWidthPixels =
      (this.$refs.refRangeSlider.clientWidth * (Number(this.modelValue) - this.min)) / (this.max - this.min);

    return {
      '--value': this.modelValue,
      '--max': this.max,
      '--diff': this.max - this.min,
      '--fill-width-pixels': `${Math.round(fillWidthPixels)}px`
    };
  }

  public get allTicks(): number[] {
    let currentTick = this.min; // Start from the min value, and make sure it's a number
    const maxVal = this.max;
    const stepVal = this.step ? this.step : 1; // Default step is 1 if not provided

    const ticks: number[] = [];

    // Keep generating ticks while the current tick is less than or equal to max
    while (currentTick <= maxVal) {
      ticks.push(currentTick);
      currentTick += stepVal;
    }

    return ticks;
  }
}
</script>

<style lang="scss" scoped>
$baseSize: 3px;

@mixin range-track {
  -webkit-appearance: none;
  width: 100%;
  height: $baseSize * 4;
  color: transparent;
  background: var(--color-base-200);
  border-radius: 999px;
  border: none;
}
@mixin range-thumb {
  -webkit-appearance: none;
  height: $baseSize * 4;
  width: $baseSize * 4;
  border-radius: 50%;
  background: var(--color-base-400);
  cursor: pointer;
}

.range-slider {
  width: 100%;
  box-sizing: border-box; // Ensure padding and border are included in the width and height

  &--error {
    border-color: var(--field-color-border-negative);
  }

  input[type='range'] {
    -webkit-appearance: none;
    display: block;
    margin: 0;
    padding: 0; // Remove default padding
    width: 100%;
    background: transparent;
    box-sizing: border-box; // Consistent box model
  }

  input[type='range']::-webkit-slider-runnable-track {
    @include range-track();
  }
  input[type='range']::-moz-range-track {
    @include range-track();
  }

  input[type='range']::-moz-range-track {
    background: linear-gradient(
      to right,
      var(--color-base-300) 0,
      var(--color-base-300) var(--fill-width-pixels, 0px),
      var(--color-base-200) var(--fill-width-pixels, 0px),
      var(--color-base-200) 100%
    );
    border-radius: 999px;
  }

  input[type='range']::-ms-track {
    @include range-track();
  }
  input[type='range']::-ms-fill-lower {
    display: none;
  }
  input[type='range']::-ms-fill-upper {
    display: none;
  }
  input[type='range']::-webkit-slider-thumb {
    @include range-thumb();
  }
  input[type='range']::-moz-range-thumb {
    @include range-thumb();
  }
  input[type='range']::-ms-thumb {
    @include range-thumb();
  }

  input[type='range']::-moz-range-progress {
    height: $baseSize * 4;
    background: linear-gradient(
      to right,
      var(--color-base-300) 0,
      var(--color-base-300) var(--fill-width-pixels, 0px),
      var(--color-base-200) var(--fill-width-pixels, 0px),
      var(--color-base-200) 100%
    );
  }

  input[type='range']::-moz-range-progress {
    height: $baseSize * 4;
    border-radius: 999px;
  }

  input[type='range']::-webkit-slider-runnable-track {
    background: linear-gradient(
      to right,
      var(--color-base-300) 0,
      var(--color-base-300) var(--fill-width-pixels, 0px),
      var(--color-base-200) var(--fill-width-pixels, 0px),
      var(--color-base-200) 100%
    );
  }

  input[type='range'] {
    --webkit-fill-percentage: calc((100% - 100% * (var(--max) - var(--value)) / var(--diff)));
  }

  &__ticks {
    display: flex;
    justify-content: space-between;
    //We need left & right padding that's half the width of the range thumb, so all ticks align with the center of the thumb
    padding: $baseSize $baseSize * 2;

    .range-slider__tick {
      position: relative;
      display: flex;
      justify-content: center;
      width: 1px;
      background: var(--color-base-300);
      padding-top: 3px;
      height: $baseSize;
      line-height: $baseSize * 5;
      margin-bottom: $baseSize * 2;
    }
  }
}
</style>
