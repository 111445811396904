<template>
  <router-link
    v-if="href && href[0] === '/' && !href.startsWith('/api/') && !href.startsWith('/vendor/') && target !== '_blank'"
    :to="href"
    :target="target"
    :download="downloadable"
    :class="{ 'has-button': hasButton }"
  >
    <slot name="default"></slot>
  </router-link>
  <a v-else :href="href" :target="target" :download="downloadable" :class="{ 'has-button': hasButton }">
    <slot name="default"></slot>
  </a>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PropType } from 'vue';

@Component({
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: String, required: true }) public readonly href!: string;
  @Prop({ type: String, required: false, default: '' }) public readonly download!: string;

  @Prop({
    type: String as PropType<'_blank' | '_self'>,
    default: '_self',
    required: false,
    validator: (value: string) => ['_self', '_blank'].includes(value)
  })
  public readonly target!: '_blank' | '_self';

  public hasButton = false;

  // Condition to determine if a download attribute should be enabled
  public get downloadable(): string | undefined {
    return this.download ? this.download : undefined;
  }

  public mounted() {
    if (this.$slots.default && this.$slots.default[0].elm) {
      const rootElement = this.$slots.default[0].elm as HTMLElement;
      this.hasButton = rootElement instanceof HTMLButtonElement;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/mixins/typography';

a {
  @include button-text-small-underlined;
  color: var(--text-color-default);
}

.has-button {
  text-decoration: none;
}
</style>
