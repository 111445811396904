<template>
  <p-form-element
    :disabled="disabled"
    :label="label"
    :help-text="helpText"
    :helpInTooltip="helpInTooltip"
    size="medium"
    :error="error"
  >
    <p-date-time-picker
      :disabled="disabled"
      :error="!!error"
      :seconds="seconds"
      :min-date="minDate"
      :max-date="maxDate"
      :modelValue="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
      @input="$emit('input', $event)"
    />
  </p-form-element>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: String, required: false, default: null }) public readonly modelValue!: string | null;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly helpInTooltip?: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly error?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly minDate?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly maxDate?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly seconds?: boolean;
}
</script>
