<template>
  <div class="error-page">
    <h1 class="error-page__title" v-if="title">{{ title }}</h1>
    <!-- nosem -->
    <p class="error-page__description" v-if="description" v-html="description"></p>

    <v-img class="error-page__image" v-if="image" :src="image" eager contain :max-width="imageMaxWidth" />

    <router-link class="error-page__link" v-if="linkText && linkHref" :to="linkHref" @click.native="onLinkClick()">{{
      linkText
    }}</router-link>

    <div class="error-page__info" v-if="info && info.exception">
      <h3>message: {{ info.message }}</h3>
      <h4>exception: {{ info.exception }}</h4>
      <h4>file: {{ info.file }}</h4>
      <h4>line: {{ info.line }}</h4>

      <ul v-if="info.trace && info.trace.length > 0">
        <li v-for="(trace, traceKey) in info.trace" :key="traceKey">
          <div v-if="trace.file"><b style="width: 75px">file:</b> {{ trace.file }}</div>
          <div v-if="trace.line"><b style="width: 75px">line:</b> {{ trace.line }}</div>
          <div v-if="trace.function"><b style="width: 75px">function:</b> {{ trace.function }}</div>
          <div v-if="trace.class"><b style="width: 75px">class:</b> {{ trace.class }}</div>
          <div v-if="trace.type"><b style="width: 75px">type:</b> {{ trace.type }}</div>
        </li>
      </ul>
    </div>

    <div class="error-page__footer" v-if="!noLogo">
      <img class="error-page__footer-image" :src="logo" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IntervalServerError } from '@/interfaces/error';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require('@/assets/images/logo-login.svg');

@Component({
  components: {},
  name: 'layout-elements'
})
export default class LayoutElements extends Vue {
  @Prop() public title!: string;
  @Prop() public description!: string;
  @Prop() public image!: string;
  @Prop() public imageMaxWidth!: number;
  @Prop() public info!: IntervalServerError;
  @Prop() public linkText!: string;
  @Prop() public linkHref!: string;
  @Prop() public noLogo!: boolean;
  public logo = logo;

  onLinkClick() {
    if (this.linkHref === this.$route.fullPath) {
      window.location.reload();
    }
  }
}
</script>
