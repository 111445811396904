<template>
  <div class="debug">
    <div class="debug__toggle" @click="overlayShown = true">Debug</div>

    <v-dialog
      :value="overlayShown"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keydown.esc="overlayShown = false"
      :retain-focus="false"
      class="debug__overlay"
      :attach="'.debug'"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="overlayShown = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div class="debug__container">
          <v-tabs v-model="tab" dark>
            <v-tab :key="'queries'" :href="`#tab-queries`">Queries</v-tab>

            <v-tab-item :key="'queries'" :value="'tab-queries'">
              <ul>
                <li v-for="(query, queryKey) in queries" :key="queryKey">
                  <table>
                    <tr>
                      <td width="140"><b>SQL</b></td>
                      <td>{{ query.sql }}</td>
                    </tr>
                    <tr>
                      <td width="140"><b>bindings</b></td>
                      <td>{{ query.bindings }}</td>
                    </tr>
                    <tr>
                      <td width="140"><b>time</b></td>
                      <td>{{ query.time }}</td>
                    </tr>
                    <tr>
                      <td width="140"><b>connectionName</b></td>
                      <td>{{ query.connectionName }}</td>
                    </tr>
                  </table>
                </li>
              </ul>
            </v-tab-item>

            <v-tab :key="'audit'" :href="`#tab-audit`" v-if="audit && audit.length > 0">Audit</v-tab>

            <v-tab-item :key="'audit'" :value="'tab-audit'" v-if="audit && audit.length > 0">
              <v-expansion-panels accordion>
                <v-expansion-panel v-for="auditItem in audit" :key="auditItem.id">
                  <v-expansion-panel-header>{{ auditItem.className }} ({{ auditItem.id }})</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul v-if="auditItem.logs && auditItem.logs.length > 0">
                      <li v-for="log in auditItem.logs" :key="log.auditable_id">
                        <table>
                          <tr>
                            <td width="140"><b>Created at</b></td>
                            <td>{{ log.created_at }}</td>
                          </tr>
                          <tr>
                            <td width="140"><b>Event</b></td>
                            <td>{{ log.event }}</td>
                          </tr>
                          <tr>
                            <td width="140"><b>User ID</b></td>
                            <td>{{ log.user_id }}</td>
                          </tr>
                          <tr>
                            <td width="140"><b>User Name</b></td>
                            <td>{{ log.user_name }}</td>
                          </tr>
                          <tr>
                            <td width="140"><b>User E-mail</b></td>
                            <td>{{ log.user_email }}</td>
                          </tr>
                          <tr>
                            <td width="140"><b>Old values</b></td>
                            <td>{{ log.old_values }}</td>
                          </tr>
                          <tr>
                            <td width="140"><b>New values</b></td>
                            <td>{{ log.new_values }}</td>
                          </tr>
                        </table>
                      </li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-tab-item>
          </v-tabs>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

interface IQuery {
  sql: string;
  bindings: Array<string | number | boolean | null>;
  time: number;
  connectionName: string;
}

interface IAuditLog {
  event: string;
  created_at: string;
  new_values: any;
  old_values: any;
  url: string;
  user_email: string;
  user_name: string;
  user_id: number;
  auditable_id: number;
}

interface IAudit {
  className: string;
  id: number;
  logs?: IAuditLog[];
}

@Component({
  name: 'layout-queries'
})
export default class LayoutQueries extends Vue {
  @Prop() public queries!: IQuery[];
  @Prop() public audit!: IAudit[];
  public overlayShown = false;
  public tab = 'queries';
}
</script>
