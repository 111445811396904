var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tabs",class:{ 'tabs--no-items': _vm.items.length === 0 }},[_c('div',{staticClass:"tabs__container"},[_c('div',{staticClass:"tabs__header"},[(_vm.headline)?_c('div',{staticClass:"tabs__headline"},[_c('p-headline',{directives:[{name:"device-desktop",rawName:"v-device-desktop"}],attrs:{"size":"3"}},[_vm._v(_vm._s(_vm.headline))]),_vm._v(" "),_c('p-headline',{directives:[{name:"device-mobile",rawName:"v-device-mobile"}],attrs:{"size":"4"}},[_vm._v(_vm._s(_vm.headline))]),_vm._v(" "),_c('div',{staticClass:"tabs__headline-sub"},[(_vm.subheadline)?_c('p-paragraph',{attrs:{"typography":"text-body-large"}},[_vm._v(_vm._s(_vm.subheadline))]):_vm._e()],1)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tabs__build-campaign"},[(_vm.buildCampaignLink)?_c('p-link',{directives:[{name:"device-desktop",rawName:"v-device-desktop"}],attrs:{"href":_vm.buildCampaignLink,"target":"_blank"}},[_c('p-button',{attrs:{"size":"medium","type":"cta"}},[_vm._v("Build campaign")])],1):_vm._e(),_vm._v(" "),(_vm.viewCampaignLink)?_c('p-link',{directives:[{name:"device-desktop",rawName:"v-device-desktop"}],attrs:{"href":_vm.viewCampaignLink,"target":"_blank"}},[_c('p-button',{attrs:{"size":"medium","type":"cta"}},[_vm._v("Demo Url")])],1):_vm._e()],1)]),_vm._v(" "),(_vm.items.length > 0)?_c('ul',{staticClass:"tabs__navigation"},[_vm._l((_vm.items),function(tab){return _c('li',{key:tab.href,staticClass:"tabs__navigation-item",class:{
          'tabs__navigation-item--active': tab.active,
          'tabs__navigation-item--no-access': tab.access === false
        }},[(tab.access === false)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.showNoAccessModal = true}}},[_vm._v(_vm._s(tab.text))]):_c('p-link',{attrs:{"href":tab.href}},[_vm._v(_vm._s(tab.text))])],1)}),_vm._v(" "),(_vm.fastTrackLink)?_c('li',{staticClass:"tabs__navigation-item tabs__navigation-item--fast-track"},[_c('p-link',{attrs:{"href":_vm.fastTrackLink,"target":"_blank"}},[_c('p-button',{attrs:{"color-type":"tertiary","size":"medium"}},[_vm._v("Fast track")])],1)],1):_vm._e()],2):_vm._e()]),_vm._v(" "),(_vm.items.length > 0 && _vm.subItems.length > 0)?_c('ul',{staticClass:"tabs__sub-navigation"},_vm._l((_vm.subItems),function(tab){return _c('li',{key:tab.href,staticClass:"tabs__sub-navigation-item",class:{
        'tabs__sub-navigation-item--active': tab.active,
        'tabs__sub-navigation-item--no-access': tab.access === false
      }},[(tab.access === false)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.showNoAccessModal = true}}},[_vm._v(_vm._s(tab.text))]):_c('p-link',{attrs:{"href":tab.href}},[_vm._v(_vm._s(tab.text))])],1)}),0):_vm._e(),_vm._v(" "),_c('p-modal-plain',{attrs:{"headline":"Unauthorized Access Alert","body-padding":""},domProps:{"show":_vm.showNoAccessModal},on:{"close-request":function($event){_vm.showNoAccessModal = false}}},[_c('div',{staticClass:"unauthorized"},[_c('p-row',{attrs:{"justify-content":"center"}},[_c('p-column',{attrs:{"size":"extra-large"}},[_c('p-container',{attrs:{"align-items":"center","gap-size":"large"}},[_c('p-headline',{attrs:{"size":"2"}},[_vm._v("Your Role Does Not Grant Access")]),_vm._v(" "),_c('p-paragraph',{attrs:{"typography":"component-text-helptext"}},[_vm._v("\n              We're sorry, but it seems you do not have the necessary permissions to access this page."),_c('br'),_c('br'),_vm._v("\n              Please contact your administrator to request the appropriate access or explore other areas of the\n              application that are available to your role\n            ")])],1)],1)],1)],1),_vm._v(" "),_c('p-button',{attrs:{"slot":"footer","color-type":"tertiary"},on:{"click":function($event){_vm.showNoAccessModal = false}},slot:"footer"},[_vm._v("Close")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }