<template>
  <div class="custom-select custom-select-trigger">
    <p-button
      title="Text align"
      color-type="tertiary"
      :icon="selectedTextAlign ?? 'align-left'"
      @click="toggleDropdown"
    />

    <ul v-if="dropdownOpen" class="custom-options">
      <li
        v-for="option in textAlignOptions"
        :key="option.value"
        :class="{ 'custom-option--selected': option.icon === selectedTextAlign }"
        class="custom-option"
        @click="setTextAlign(option.value)"
      >
        <p-icon :icon="option.icon" size="small" />
        <span>{{ option.label }}</span>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
type TextAlignValue = 'left' | 'center' | 'right' | 'justify';

interface TextAlignOption {
  value: TextAlignValue;
  icon: `align-${TextAlignValue}`;
  label: string;
}

import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';

@Component
export default class EditorTextAlign extends Vue {
  @Prop({ type: Object, required: true }) public readonly editor!: Editor;

  public dropdownOpen = false;
  public selectedTextAlign: `align-${TextAlignValue}` = 'align-left';

  public textAlignOptions: TextAlignOption[] = [
    { value: 'left', icon: 'align-left', label: 'Left' },
    { value: 'center', icon: 'align-center', label: 'Center' },
    { value: 'right', icon: 'align-right', label: 'Right' },
    { value: 'justify', icon: 'align-justify', label: 'Justify' }
  ];

  public toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  public setTextAlign(value: string) {
    const option = this.textAlignOptions.find((opt) => opt.value === value);
    this.selectedTextAlign = option?.icon ?? 'align-left';

    this.editor.chain().focus().setTextAlign(value).run();

    this.dropdownOpen = false;
  }

  public mounted() {
    this.editor.on('selectionUpdate', () => {
      this.updateSelectedTextAlign(this.editor);
    });
  }

  public updateSelectedTextAlign(editor: Editor) {
    if (editor.isActive({ textAlign: 'left' })) {
      this.selectedTextAlign = 'align-left';
    } else if (editor.isActive({ textAlign: 'center' })) {
      this.selectedTextAlign = 'align-center';
    } else if (editor.isActive({ textAlign: 'right' })) {
      this.selectedTextAlign = 'align-right';
    } else if (editor.isActive({ textAlign: 'justify' })) {
      this.selectedTextAlign = 'align-justify';
    }
  }
}
</script>

<style lang="scss" scoped>
/* Add your styles for the dropdown here */
.custom-select {
  position: relative;
  display: inline-block;
}
.custom-select-trigger {
  background: none;
  cursor: pointer;
}
.custom-options {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  list-style-type: none;
  margin-top: 2px;
  padding: 0;
  width: 100%;
  min-width: 91px;
  text-align: left;
  z-index: 2;
  border-radius: 3px;
}
.custom-option {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;

  &--selected {
    background-color: #f0f0f0;
  }
}
.custom-option:hover {
  background-color: #f0f0f0;
}
</style>
