<template>
  <p-container>
    <slot />
  </p-container>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ILayoutTemplate } from '@/interfaces/template';
import { PropType } from 'vue';

@Component({
  name: 'template-popup'
})
export default class extends Vue {
  @Prop({ type: Object as PropType<ILayoutTemplate> }) public readonly template!: ILayoutTemplate;

  public beforeMount() {
    if (this.inIframe()) {
      document.body.classList.add('iframe-popup');
    }
  }

  public beforeUnmount() {
    if (this.inIframe()) {
      document.body.classList.remove('iframe-popup');
    }
  }

  public inIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
}
</script>

<style lang="scss">
@import '../scss/mixins/typography';

.iframe-popup {
  background-color: transparent;

  .tox-tinymce-aux {
    z-index: 10300;
  }
}
</style>
