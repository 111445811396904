<template>
  <div
    class="tags-input"
    :class="{ 'tags-input--disabled': disabled, 'tags-input--focused': focused, 'tags-input--error': error }"
    @click="focus()"
  >
    <p-badge
      v-for="item in modelValue"
      :key="item"
      :text="item"
      :disabled.prop="disabled"
      icon="x"
      @click="remove(item)"
      clickable
    />

    <input
      ref="inputRef"
      @focus="focused = true"
      @blur="onBlur()"
      v-model="inputValue"
      :size="inputSize"
      :disabled="disabled"
      @keydown.enter="addValue()"
      @keydown.delete="deleteValue()"
    />
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
})
export default class extends Vue {
  @Prop({ type: Array as PropType<string[]>, required: true }) public readonly modelValue!: string[];
  @Prop({ type: Boolean }) public readonly disabled!: boolean;
  @Prop({ type: Boolean }) public readonly error!: boolean;
  @Ref() public readonly inputRef!: HTMLInputElement;

  public focused = false;
  public inputValue = '';

  public remove(item: string) {
    this.$emit(
      'update:modelValue',
      this.modelValue.filter((i) => i !== item)
    );
  }

  public focus() {
    if (!this.disabled) {
      this.inputRef.focus();
    }
  }

  public addValue() {
    if (this.inputValue.length > 0) {
      if (!this.modelValue.includes(this.inputValue)) {
        this.$emit('update:modelValue', [...this.modelValue, this.inputValue]);
      }

      this.inputValue = '';
    }
  }

  public deleteValue() {
    if (this.modelValue.length > 0 && this.inputValue.length === 0) {
      this.$emit('update:modelValue', this.modelValue.slice(0, -1));
    }
  }

  public onBlur() {
    this.focused = false;
    this.addValue();
  }

  public get inputSize() {
    return Math.floor(this.inputValue.length * 1.3);
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/mixins/typography';

.tags-input {
  display: flex;
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
  gap: var(--gap-size-extra-small) var(--gap-size-extra-small);
  padding: var(--base-size-75) var(--base-size-200);
  align-self: stretch;
  flex-wrap: wrap;

  border-radius: var(--border-radius-small);
  border: 1px solid var(--field-color-border-default);
  background: var(--field-color-background-default);
  cursor: text;

  > input {
    appearance: none;
    border: none;
    height: 24px;
    padding: 0;
    background-color: transparent;

    @include component-text-default;
    color: var(--text-color-subdued);
  }

  &:not(.tags-input--disabled) {
    &:hover {
      background: var(--field-color-background-hover);
    }

    &.tags-input--focused {
      border-color: var(--field-color-border-control);
      background: var(--field-color-background-control);
    }
  }

  &--disabled {
    border-color: var(--field-color-border-disabled);
    background: var(--field-color-background-disabled);
    cursor: not-allowed;

    > input {
      color: var(--text-color-disabled);
      cursor: not-allowed;
    }
  }

  &--error {
    border-color: var(--color-border-negative);
  }
}
</style>
