<template>
  <p-select
    :options.prop="options"
    :model-value="modelValue"
    :searchable.prop="searchable"
    :icon="icon"
    :filterable.prop="filterable"
    :clearable.prop="clearable"
    :multiple="multiple"
    :disabled="disabled"
    :error="error"
    :placeholder="placeholder"
    icon-selector
    @select="$emit('update:modelValue', $event.detail[0])"
    @clear="$emit('update:modelValue', null)"
  >
    <p-select-option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      :header="option.header"
      :selected.prop="option.header ? false : modelValue === option.value"
      >{{ option.text }}</p-select-option
    >
  </p-select>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PropType } from 'vue';
import { DropdownOption } from './types';

@Component({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: [String, Number, Array], required: false, default: null }) public readonly modelValue!:
    | string
    | number
    | Array<string | number>
    | null;
  @Prop({ type: Array as PropType<DropdownOption[]>, required: true }) public readonly options!: DropdownOption[];
  @Prop({ type: String, required: false, default: undefined }) public readonly placeholder?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly error!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly icon?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly searchable!: boolean;
  @Prop({ type: Boolean, required: false, default: true }) public readonly filterable!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly clearable!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly multiple!: boolean;
}
</script>
