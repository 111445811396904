<template>
  <object :width="width" :height="height" type="image/svg+xml" :data="src"><img :src="src" /></object>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: String, required: true }) public readonly src!: string;
  @Prop({ type: Number, required: true }) public readonly width!: number;
  @Prop({ type: Number, required: true }) public readonly height!: number;
}
</script>
