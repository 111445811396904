<template>
  <p-row align-items="center">
    <div class="checkbox" :class="{ 'checkbox--error': error, 'checkbox--disabled': disabled }">
      <div class="checkbox__selection" @click.stop="toggle()">
        <div class="checkbox__input">
          <div class="checkbox__input-icon" v-if="modelValue"><p-icon size="small" icon="check" /></div>
        </div>

        <p-label v-if="label" :disabled.prop="disabled">{{ label }}</p-label>
      </div>
    </div>
    <p-help-text v-if="helpText" :disabled.prop="disabled" :inTooltip.prop="helpInTooltip" tooltip-position="top">{{
      helpText
    }}</p-help-text>
  </p-row>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: Boolean, required: true }) public readonly modelValue!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly error!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly helpInTooltip?: boolean;

  public toggle() {
    if (this.disabled) {
      return;
    }

    this.$emit('update:modelValue', !this.modelValue);
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/mixins/typography';

.checkbox {
  position: relative;

  &__selection {
    display: flex;
    gap: var(--gap-size-small);
    align-items: center;
    cursor: pointer;

    &:hover {
      & > .checkbox__input {
        background: var(--field-color-background-hover);
      }
    }

    &:active {
      & > .checkbox__input {
        background: var(--button-color-background-pressed);
        border: 1px solid var(--field-color-border-control);

        & > .checkbox__input-icon {
          color: var(--icon-color-inverted);
        }
      }
    }
  }

  &__input {
    display: block;
    appearance: none;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border: 1px solid var(--field-color-border-default);
    border-radius: 2px;
    position: relative;
    margin: 0;
    background: var(--field-color-background-default);

    &-icon {
      position: absolute;
      left: -1px;
      top: -1px;
      pointer-events: none;
    }
  }

  &--disabled {
    & > .checkbox__selection {
      cursor: not-allowed;

      & > .checkbox__input {
        background: var(--field-color-background-disabled);
        border-color: var(--field-color-border-disabled);

        & > .checkbox__input-icon {
          color: var(--icon-color-disabled);
        }
      }
    }
  }

  &--error {
    & > .checkbox__selection > .checkbox__input {
      border-color: var(--field-color-border-negative);
    }
  }
}
</style>
