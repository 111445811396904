<template>
  <p-container>
    <slot />
  </p-container>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ILayoutTemplate } from '@/interfaces/template';
import LayoutDebug from '@/components/LayoutDebug.vue';
import { PropType } from 'vue';

@Component({
  components: {
    LayoutDebug
  },
  name: 'template-fast-track-embed'
})
export default class extends Vue {
  @Prop({ type: Object as PropType<ILayoutTemplate> }) public template!: ILayoutTemplate;
}
</script>
