import { v4 as uuidv4 } from 'uuid';
import { AppConfig } from '@/app_config';
import { getDevice } from '@/utility/isDevice';

const storageKey = 'p-analytics-session';

type Session = {
  id: string;
  expiration: number;
};

function createNewSession(): Session {
  const session: Session = {
    id: uuidv4(),

    // 5 minutes expiration
    expiration: Date.now() + 5 * 60 * 1000
  };

  saveSession(session);

  return session;
}

function extendSession(session: Session): Session {
  session.expiration = Date.now() + 5 * 60 * 1000;

  saveSession(session);

  return session;
}

function saveSession(session: Session) {
  if (localStorage) {
    localStorage.setItem(storageKey, JSON.stringify(session));
  }
}

function getSession() {
  const sessionFromStorage = localStorage ? localStorage.getItem(storageKey) : null;

  if (sessionFromStorage) {
    const sessionDataFromStorage = JSON.parse(sessionFromStorage) as Session;

    if (sessionDataFromStorage.expiration > Date.now()) {
      return sessionDataFromStorage;
    }
  }

  return createNewSession();
}

export async function samPageView(customerId: number, userId: number, campaignId?: number) {
  const config = await AppConfig.get();

  if (!config?.analytics?.samEndpoint) {
    return;
  }

  const session = getSession();

  extendSession(session);

  const url = window.location.href.split('?')[0];
  const urlQuery = window.location.href.includes('?') ? window.location.href.split('?')[1] : undefined;

  const referrer = document.referrer ? document.referrer.split('?')[0] : undefined;
  const referrerQuery =
    document.referrer && document.referrer.includes('?') ? document.referrer.split('?')[1] : undefined;

  await fetch(config.analytics.samEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      type: 'AdminPageView100',
      data: {
        ...(campaignId && { campaign_id: campaignId }),
        customer_id: customerId,
        user_id: userId,
        session_id: session.id,
        url,
        ...(urlQuery && { url_query: urlQuery }),
        screen_width: window.screen.width,
        screen_height: window.screen.height,
        device: getDevice(),
        ...(referrer && { referrer }),
        ...(referrerQuery && { referrer_query: referrerQuery })
      }
    })
  });
}
