import { defineStore } from 'pinia';
import { WorkspaceResource } from '@/types/api/workspace';

export interface IWorkspaceStore {
  workspace: WorkspaceResource | null;
}

export const useWorkspaceStore = defineStore('workspace', {
  state: (): IWorkspaceStore => ({ workspace: null })
});
