<template>
  <p-button
    title="Undo (⌘Z)"
    color-type="tertiary"
    icon="corner-up-left"
    @click="undo"
    :disabled.prop="buttonDisabled"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';

@Component
export default class EditorUndo extends Vue {
  @Prop({ type: Object, required: true }) public readonly editor!: Editor;

  public get buttonDisabled() {
    return !this.editor.can().undo();
  }
  // Undo the last change in the editor
  public undo() {
    this.editor.chain().focus().undo().run();
  }
}
</script>
