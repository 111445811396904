<template>
  <p-container gap-size="large">
    <slot />
  </p-container>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ILayoutTemplate } from '@/interfaces/template';
import LayoutQueries from '@/components/LayoutDebug.vue';

@Component({
  components: { LayoutQueries },
  name: 'template-iframe'
})
export default class TemplateIframe extends Vue {
  @Prop() public template!: ILayoutTemplate;

  public beforeMount() {
    document.body.classList.add('iframe');
  }

  public beforeUnmount() {
    document.body.classList.remove('iframe');
  }
}
</script>

<style lang="scss">
@import '../scss/mixins/typography';

.iframe {
  --section-padding: var(--base-size-200) var(--base-size-300);
  --tooltip-max-width: 220px;
  background-color: transparent;

  > .blueprint {
    width: 400px !important;
    float: right;
    background-color: var(--color-background-layer-2);

    > .template {
      --section-background-color: var(--color-background-layer-3);

      /** Yes i know this deviates from our Figma UI standard. But Thomas put a gun to my head */
      --font-line-height-50: var(--font-line-height-75);
      --font-line-height-75: 16px;
      --font-line-height-100: 18px;
      --font-line-height-200: var(--font-line-height-100);
      --font-line-height-300: var(--font-line-height-100);
      --font-line-height-400: var(--font-line-height-100);
      --font-line-height-500: var(--font-line-height-100);
      --font-line-height-600: var(--font-line-height-100);
      --font-line-height-700: var(--font-line-height-100);
      --font-line-height-800: var(--font-line-height-100);
      --font-line-height-900: var(--font-line-height-100);
      --font-line-height-1000: var(--font-line-height-100);

      /** Yes i know this deviates from our Figma UI standard. But Thomas put a gun to my head */
      --font-size-50: var(--font-size-75);
      --font-size-75: 12px;
      --font-size-100: 14px;
      --font-size-200: var(--font-size-100);
      --font-size-300: var(--font-size-100);
      --font-size-400: var(--font-size-100);
      --font-size-500: var(--font-size-100);
      --font-size-600: var(--font-size-100);
      --font-size-700: var(--font-size-100);
      --font-size-800: var(--font-size-100);
      --font-size-900: var(--font-size-100);
      --font-size-1000: var(--font-size-100);

      --gap-size-none: 0px;
      --gap-size-extra-small: var(--base-size-50);
      --gap-size-small: var(--base-size-75);
      --gap-size-medium: var(--base-size-100);
      --gap-size-large: var(--base-size-300);
      --gap-size-extra-large: var(--base-size-500);

      // reset style back to default for the media library
      .form-element__body {
        .media-selector {
          .media {
            --gap-size-none: 0px;
            --gap-size-extra-small: var(--base-size-75);
            --gap-size-small: var(--base-size-100);
            --gap-size-medium: var(--base-size-300);
            --gap-size-large: var(--base-size-500);
            --gap-size-extra-large: var(--base-size-700);

            --font-line-height-100: 22px;
            --font-line-height-1000: 72px;
            --font-line-height-200: 24px;
            --font-line-height-300: 26px;
            --font-line-height-400: 34px;
            --font-line-height-50: 16px;
            --font-line-height-500: 38px;
            --font-line-height-600: 42px;
            --font-line-height-700: 48px;
            --font-line-height-75: 18px;
            --font-line-height-800: 52px;
            --font-line-height-900: 64px;

            --font-size-100: 16px;
            --font-size-1000: 64px;
            --font-size-200: 18px;
            --font-size-300: 20px;
            --font-size-400: 24px;
            --font-size-50: 12px;
            --font-size-500: 28px;
            --font-size-600: 32px;
            --font-size-700: 36px;
            --font-size-75: 14px;
            --font-size-800: 40px;
            --font-size-900: 50px;
          }
        }
      }
    }
  }

  .modal {
    --headline-2-font-size: 40px;
    --headline-2-line-height: 40px;
    --headline-2-letter-spacing: -1px;
    --headline-2-font-weight: 600;
  }
}
</style>
