export function getQueryStringParam(url: string, param: string): any | null {
  const urlSplit = url.split('#')[0].split('?');
  const query = urlSplit.length === 2 ? urlSplit[1] : '';
  const vars = query.split('&');

  for (let i = 0; i < vars.length; i++) {
    const pair = vars[Number(i)].split('=');

    if (decodeURIComponent(pair[0]) === param) {
      return decodeURIComponent(pair[1]);
    }
  }

  return null;
}
