<template>
  <div class="login">
    <div class="login__content login__content--left" v-if="!isMobile">
      <img class="login__content-logo" :src="logoFront" alt="" />
    </div>

    <div class="login__content">
      <div class="login__content-wrapper">
        <p-container>
          <slot />

          <div class="login__help">
            <p-paragraph>
              If you are having trouble with your account, please contact us at
              <p-link href="mailto:support@playable.com" target="_blank">support@playable.com</p-link>
            </p-paragraph>
          </div>
        </p-container>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ILayoutTemplate } from '@/interfaces/template';
import { isDevice } from '@/utility';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logoFront = require('@/assets/images/logo-front.svg');

@Component({
  components: {},
  name: 'template-invitation-activate'
})
export default class TemplateInvitationActivate extends Vue {
  @Prop() public template!: ILayoutTemplate;
  public logoFront = logoFront;

  get isMobile(): boolean {
    return isDevice('mobile');
  }
}
</script>
