<template>
  <p-users
    v-show="stacked || otherUsers.length > 0"
    :loading.prop="users.length === 0"
    :stacked.prop="stacked"
    :clickable.prop="stacked"
  >
    <p-users-item v-if="origin" :id="String(origin.user.id)" :name="origin.user.name" highlight />
    <p-users-item v-else-if="me" :id="String(me.id)" :name="me.name" highlight />
    <p-users-item
      v-for="user in otherUsers"
      :key="user.id"
      :highlight.prop="me?.id === user.id"
      :id="String(user.id)"
      :name="user.name"
    />
  </p-users>
</template>

<script lang="ts">
import { AppRequest } from '@/app_request';
import { useUserStore } from '@/store/userStore';
import { useUsersStore } from '@/store/usersStore';
import { UserResource } from '@/types/api/user';
import { WorkspaceResource } from '@/types/api/workspace';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
  name: 'MenuUsers'
})
export default class MenuUsers extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) public readonly stacked!: boolean;

  // Timer for reloading the users list every 5 minutes
  private reloadTimer?: number;

  public mounted() {
    this.reload();

    this.reloadTimer = window.setInterval(() => {
      this.reload();
    }, 1000 * 60 * 5);
  }

  public unmounted() {
    if (this.reloadTimer) {
      window.clearInterval(this.reloadTimer);
    }
  }

  public get me(): UserResource | undefined {
    const userStore = useUserStore();
    return userStore.user ?? undefined;
  }

  public get origin():
    | {
        user: UserResource;
        workspace: WorkspaceResource;
      }
    | undefined {
    const userStore = useUserStore();

    if (!userStore.origin) {
      return undefined;
    }

    return {
      user: userStore.origin.user,
      workspace: userStore.origin.workspace
    };
  }

  public get users(): UserResource[] {
    if (!this.me) {
      return [];
    }

    const usersStore = useUsersStore();

    return usersStore.users.filter((user) => {
      return user.id === this.me?.id || user.session_active;
    });
  }

  public get otherUsers(): UserResource[] {
    return this.users.filter((user) => {
      return user.id !== this.me?.id;
    });
  }

  private async reload() {
    const usersStore = useUsersStore();
    usersStore.users = (await AppRequest.get<{ data: UserResource[] }>('/api/v1/workspace/users')).data.data;
  }
}
</script>
