<template>
  <div
    class="color-picker"
    ref="colorPicker"
    :class="{ 'color-picker--disabled': disabled, 'color-picker--error': error }"
    @click="containerClick()"
  >
    <slot :color="color">
      <div class="color-picker__indicator">
        <div class="color-picker__indicator-color" :style="swatchStyle"></div>
      </div>
      <input
        class="color-picker__input"
        :disabled="disabled"
        type="text"
        v-model="textValue"
        @keydown="$emit('interact')"
      />

      <p-icon :disabled="disabled" size="small" icon="color-picker" />
    </slot>

    <div class="color-picker__wheel" ref="colorPickerWheel" @mousedown="handleMouseDown">
      <sketch
        v-model="colorpickerValue"
        v-if="showSketch"
        :style="{ opacity: sketchIsReady ? 1 : 0 }"
        :preset-colors="presetColors"
        @input="$emit('interact')"
        @mousedown.prevent
      />

      <slot v-if="showSketch" name="extra" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PropType } from 'vue';
import { Sketch } from 'vue-color';
import { Color } from '@/color';

@Component({
  components: { Sketch },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: String, required: true }) public readonly modelValue!: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly error?: boolean;
  @Prop({ type: Array as PropType<string[]>, default: () => [] }) public readonly colorScheme!: string[];

  public textValue = '';
  public colorpickerValue: any = '';
  public colorIndicatorValue = '';
  public showSketch = false;
  public sketchIsReady = false;

  public get color() {
    return this.colorIndicatorValue || this.modelValue;
  }

  public get swatchStyle() {
    const color = this.colorIndicatorValue || this.modelValue;
    return {
      ...(color && {
        background: color
      })
    };
  }

  public handleMouseDown(event: MouseEvent) {
    const target = event.target;
    if (target instanceof HTMLElement && !target.classList.contains('vc-input__input')) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  // Compute the preset colors using the colorScheme prop
  // always include transparent as the first color
  public get presetColors(): string[] {
    return ['rgba(255, 255, 255, 0)', ...this.colorScheme];
  }

  public onClickOutside(event: MouseEvent) {
    // Check if the clicked element is within the color picker or its children
    if (
      this.showSketch &&
      event.target instanceof Element &&
      this.$refs.colorPicker instanceof Element &&
      !this.$refs.colorPicker.contains(event.target)
    ) {
      // Clicked outside, handle accordingly
      this.showSketch = false;
    }
  }

  private initializeValues() {
    this.colorpickerValue = this.modelValue || '';

    if (
      typeof this.colorpickerValue === 'string' &&
      this.colorpickerValue.length === 6 &&
      this.colorpickerValue.indexOf('#') === -1
    ) {
      this.colorpickerValue = '#' + this.colorpickerValue;
    }

    const color = new Color(this.colorpickerValue);

    if (
      typeof this.colorpickerValue === 'string' &&
      this.colorpickerValue &&
      this.colorpickerValue.indexOf('#') !== -1 &&
      this.colorpickerValue.length !== 7 &&
      (color.r === undefined || color.g === undefined || color.b === undefined)
    ) {
      if (color.r === undefined) {
        color.r = 0;
      }

      if (color.g === undefined) {
        color.g = color.r;
        color.b = color.r;
      }

      if (color.b === undefined) {
        color.g = color.r;
        color.b = color.r;
      }
    }

    this.textValue = color.toHex() || '';
  }

  public isValidHexColor(value: string): boolean {
    // This pattern matches 6-character hex codes and 8-character hex codes for transparency
    // eslint-disable-next-line security/detect-unsafe-regex
    const hexPattern = /^#?([a-fA-F0-9]{6}([a-fA-F0-9]{2})?)$/;
    // allow empty string to also be valid
    return value === '' || hexPattern.test(value);
  }

  @Watch('colorpickerValue')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onColorpickerValueChange(newValue: any) {
    if (typeof newValue === 'object' && Object.keys(newValue).length > 0 && newValue.rgba) {
      if (typeof newValue.rgba.r === 'undefined' || newValue.rgba.a === 1) {
        this.colorIndicatorValue = newValue.hex;
        this.$emit('update:modelValue', newValue.hex);
      } else {
        this.colorIndicatorValue = `rgba(${newValue.rgba.r}, ${newValue.rgba.g}, ${
          newValue.rgba.b
        }, ${newValue.rgba.a.toFixed(1)})`;
        this.$emit('update:modelValue', this.colorIndicatorValue);
      }

      this.textValue = newValue.hex || '';
    } else if (typeof newValue === 'string' && this.isValidHexColor(newValue)) {
      this.colorIndicatorValue = newValue;
      this.$emit('update:modelValue', newValue);
    }
  }

  @Watch('textValue')
  public onTextValueChange(newValue: string) {
    if (newValue && newValue.indexOf('rgb') === -1 && newValue.indexOf('#') === -1) {
      newValue = '#' + newValue;
    }

    if (!this.isValidHexColor(newValue)) return;

    if (
      typeof this.colorpickerValue === 'object' &&
      Object.keys(this.colorpickerValue).length > 0 &&
      this.colorpickerValue.hex &&
      this.colorpickerValue.hex !== newValue
    ) {
      this.colorpickerValue = newValue;
      this.$emit('update:modelValue', newValue);
    } else if (
      typeof this.colorpickerValue === 'string' &&
      this.colorpickerValue.indexOf('rgb') === 0 &&
      new Color(this.colorpickerValue).toHex() !== newValue
    ) {
      this.colorpickerValue = newValue;
      this.$emit('update:modelValue', newValue);
    } else if (
      typeof this.colorpickerValue === 'string' &&
      this.colorpickerValue.indexOf('#') === 0 &&
      this.colorpickerValue !== newValue
    ) {
      this.colorpickerValue = newValue;
      this.$emit('update:modelValue', newValue);
    } else if (
      typeof this.colorpickerValue === 'string' &&
      (this.colorpickerValue === '' ||
        (this.colorpickerValue.indexOf('#') === -1 && this.colorpickerValue.indexOf('rgb') === -1))
    ) {
      this.colorpickerValue = newValue;
      this.$emit('update:modelValue', newValue);
    } else if (newValue === '') {
      this.colorpickerValue = '';
      this.$emit('update:modelValue', '');
    }
  }

  public mounted() {
    document.addEventListener('mousedown', this.onClickOutside);
    this.initializeValues();
  }

  public beforeUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside);
  }

  @Watch('modelValue')
  onModelValueChange() {
    this.initializeValues();
  }

  public containerClick() {
    if (this.disabled) {
      return;
    }

    this.showSketch = true;

    // If the colorWheel overflows bottom then we need to adjust its top position
    // to be placed over the colorPicker rather than under
    this.$nextTick(() => {
      if (this.$refs.colorPickerWheel instanceof HTMLElement) {
        const rect = this.$refs.colorPickerWheel.getBoundingClientRect();
        if (window.innerHeight < rect.top + rect.height) {
          this.$refs.colorPickerWheel.style.top = `-${rect.height}px`;
        }
        this.sketchIsReady = true;
      }
    });
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/mixins/typography';

.color-picker {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid var(--field-color-border-default);
  background-color: var(--field-color-background-default);
  padding: var(--base-size-100) var(--base-size-200);
  border-radius: var(--field-border-radius-default);
  gap: var(--gap-size-medium);
  height: 34px;
  cursor: pointer;

  &:hover {
    background-color: var(--field-color-background-hover);
  }

  &__input {
    appearance: none;
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    color: var(--text-color-subdued);
    background: transparent;

    @include component-text-default;
  }

  &__indicator {
    z-index: 1;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;

      height: 100%;
      border-radius: 50%;
      background: transparent
        url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAIAAABLixI0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADtJREFUeNpi/P//PwMSOHv2LDLX2NiYeFkmBuqBkWAWCyWBjSY7GvakAcbRdD+a7kfT/Wi6p6NZAAEGAB/VK/lvtmpNAAAAAElFTkSuQmCC')
        no-repeat 0 0;
      background-size: contain;
    }

    &-color {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px solid var(--color-border-decorative);
    }
  }

  &__wheel {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999;
  }

  &--error {
    border-color: var(--field-color-border-negative);
  }

  &--disabled {
    border-color: var(--field-color-border-disabled);
    background-color: var(--field-color-background-disabled);
    color: var(--text-color-disabled);
    cursor: not-allowed;

    > .color-picker__input {
      color: var(--text-color-disabled);
      cursor: not-allowed;
    }
  }
}
</style>
