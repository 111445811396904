<template>
  <p-modal-plain
    :show.prop="true"
    position="top"
    headline="Media library - Crop"
    @close-request="$emit('close-request')"
    has-footer
  >
    <p-container align-items="center">
      <p-message v-if="error" type="negative" :description="`Error: ${error}`" />
      <p-message v-if="exceedsDimensions" type="negative" description="Width and height cannot exceed 2000 pixels" />
      <p-message v-if="lowDimensions" type="negative" description="Width and height needs to be 5 pixels or more" />

      <div class="cropper">
        <div class="cropper__image">
          <img ref="image" :src="object.file" @load="initialize()" />
        </div>

        <div class="cropper__configuration">
          <p-container>
            <div class="cropper__selection">
              <p-headline size="5">Selection</p-headline>
              <p-paragraph
                ><span class="cropper__selection-attribute" typography="text-body-strong">x:</span> {{ cropRegion?.x }}
                <span class="cropper__selection-attribute" typography="text-body-strong">y:</span> {{ cropRegion?.y }}
                <span class="cropper__selection-attribute" typography="text-body-strong">Width:</span>
                {{ cropRegion?.width }}
                <span class="cropper__selection-attribute" typography="text-body-strong">Height:</span>
                {{ cropRegion?.height }}</p-paragraph
              >
            </div>

            <div class="cropper__size">
              <p-container>
                <p-checkbox
                  v-model="sizeEnabled"
                  label="Enable size"
                  :disabled="initialWidth || initialHeight ? true : false"
                />

                <p-input
                  :value="width"
                  prepend="W"
                  type="number"
                  append="px"
                  :disabled.prop="!sizeEnabled"
                  @change="width = $event.detail[0]"
                />
                <p-input
                  :value="height"
                  prepend="H"
                  type="number"
                  append="px"
                  :disabled.prop="!sizeEnabled"
                  @change="height = $event.detail[0]"
                />
              </p-container>
            </div>
          </p-container>
        </div>
      </div>
    </p-container>

    <p-button slot="footer" color-type="tertiary" @click.stop="$emit('close-request')" size="medium">Cancel</p-button>

    <p-button
      slot="footer"
      :loading.prop="loading || loadingSuccess"
      :loading-success.prop="loadingSuccess"
      :disabled.prop="lowDimensions || exceedsDimensions"
      color-type="primary"
      size="medium"
      @click.stop="save()"
      >Save</p-button
    >
  </p-modal-plain>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MediaObject, UploadRequestResponse } from './types';
import { convertMediaResourceToObject, cropImage, resizeImage } from './utils';
import Croppr from 'croppr';
import { AppRequest } from '@/app_request';
import axios, { AxiosError } from 'axios';
import { MediaResource } from '@/types/api/media';

@Component({})
export default class extends Vue {
  @Prop({ type: Object as PropType<MediaObject>, required: true })
  public readonly object!: MediaObject;

  @Prop({ type: Object as PropType<MediaObject>, required: false, default: undefined })
  public readonly folder?: MediaObject;

  @Prop({ type: Number, required: false, default: undefined })
  public readonly initialWidth?: number;

  @Prop({ type: Number, required: false, default: undefined })
  public readonly initialHeight?: number;

  public loading = false;
  public loadingSuccess = false;

  public sizeEnabled = false;
  public width = 100;
  public height = 100;

  public error = '';

  private cropprInstance: Croppr | null = null;
  public cropRegion: Croppr.CropValue | null = null;

  beforeMount() {
    if (this.initialWidth) {
      this.sizeEnabled = true;
      this.width = this.initialWidth;
    }

    if (this.initialHeight) {
      this.sizeEnabled = true;
      this.height = this.initialHeight;
    }
  }

  @Watch('height')
  @Watch('width')
  @Watch('sizeEnabled')
  public onUpdateConstraints() {
    this.setConstraintedSize();
  }

  private setConstraintedSize() {
    if (!this.cropprInstance || !(this.$refs.image instanceof HTMLImageElement)) {
      return;
    }

    if (this.sizeEnabled) {
      // Options is not publicly exposed. But needed for us to change the aspect ratio.
      (this.cropprInstance as any).options.aspectRatio = this.height / this.width;
      this.cropprInstance.reset();
      this.cropRegion = this.cropprInstance.getValue();
    } else {
      this.cropprInstance.destroy();
      this.initialize();
    }
  }

  public initialize() {
    if (this.$refs.image instanceof HTMLImageElement) {
      this.cropprInstance = new Croppr(this.$refs.image, {
        startSize: [100, 100, '%'],
        onInitialize: (instance: Croppr) => {
          if (this.sizeEnabled) {
            setTimeout(() => this.setConstraintedSize(), 500);
          } else {
            this.cropRegion = instance.getValue();
          }
        },
        onCropMove: () => {
          this.cropRegion = this.cropprInstance?.getValue() ?? null;
        }
      });
    }
  }

  public save() {
    if (this.$refs.image instanceof HTMLImageElement && this.cropRegion) {
      this.loading = true;
      this.error = '';

      let crop = cropImage(this.$refs.image, this.cropRegion);
      let width = this.cropRegion.width ?? 0;
      let height = this.cropRegion.height ?? 0;

      if (this.sizeEnabled) {
        crop = resizeImage(crop, this.width, this.height);
        width = this.width;
        height = this.height;
      }

      const extension = this.object.file?.split('.').pop() ?? '';
      const originalName = this.object.name.split('.').slice(0, -1).join('.');
      const name = `${originalName}-${width}-${height}.${extension}`;
      const contentType = extension === 'png' ? 'image/png' : 'image/jpeg';

      crop.toBlob(
        async (blob) => {
          try {
            if (blob) {
              const uploadRequestResponse = (
                await AppRequest.post<UploadRequestResponse>(
                  '/api/v1/media/pre-sign-upload',
                  {
                    extension,
                    contentType,
                    contentLength: blob.size
                  },
                  { params: this.apiExtendedParams }
                )
              ).data;

              const formData = new FormData();

              for (const name in uploadRequestResponse.inputs) {
                let value = uploadRequestResponse.inputs[`${name}`];

                if (name === 'key') {
                  value = uploadRequestResponse.url.slice(1);
                }

                formData.append(name, value);
              }

              formData.append('file', blob);

              await fetch(uploadRequestResponse.attributes.action, {
                method: uploadRequestResponse.attributes.method,
                body: formData
              });

              const resource = (
                await AppRequest.post<{ data: MediaResource }>(
                  '/api/v1/media',
                  {
                    ...(this.folder && { folder_id: this.folder.id }),
                    name,
                    file: uploadRequestResponse.url,
                    type: 'file'
                  },
                  { params: this.apiExtendedParams }
                )
              ).data.data;

              this.loadingSuccess = true;

              setTimeout(() => {
                this.$emit('cropped', convertMediaResourceToObject(resource));
              }, 1500);
            }
          } catch (e) {
            if (axios.isAxiosError(e)) {
              const error = e as AxiosError<{ message?: string }>;

              if (error.response?.status === 422 && error.response.data.message) {
                this.error = error.response.data.message;
                this.loading = false;
                this.loadingSuccess = false;
                return;
              }

              throw e;
            } else {
              throw e;
            }
          }
        },
        contentType,
        0.8
      );
    }
  }

  public get lowDimensions() {
    let width = this.cropRegion?.width ?? 0;
    let height = this.cropRegion?.height ?? 0;

    if (this.sizeEnabled) {
      width = this.width;
      height = this.height;
    }

    return width < 5 || height < 5;
  }

  public get exceedsDimensions() {
    let width = this.cropRegion?.width ?? 0;
    let height = this.cropRegion?.height ?? 0;

    if (this.sizeEnabled) {
      width = this.width;
      height = this.height;
    }

    return width > 2000 || height > 2000;
  }

  public get apiExtendedParams(): Record<string, string> {
    if (this.$route.params.fastTrackHash) {
      return { ['fast-track']: this.$route.params.fastTrackHash };
    }

    return {};
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/mixins/typography';
@import '../../../../../node_modules/croppr/dist/croppr.css';

.cropper {
  display: flex;
  gap: var(--gap-size-medium);
  flex-direction: row;

  &__image {
    min-width: 401px;
    min-height: 401px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPgo8ZGVmcz4KPHBhdHRlcm4gaWQ9InNtYWxsR3JpZCIgd2lkdGg9IjgiIGhlaWdodD0iOCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CjxwYXRoIGQ9Ik0gOCAwIEwgMCAwIDAgOCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJncmF5IiBzdHJva2Utd2lkdGg9IjAuMyIgc3Ryb2tlLW9wYWNpdHk9IjAuMyI+PC9wYXRoPgo8L3BhdHRlcm4+CjxwYXR0ZXJuIGlkPSJtZWRpdW1HcmlkIiB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHBhdHRlcm5Vbml0cz0idXNlclNwYWNlT25Vc2UiPgo8cmVjdCB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIGZpbGw9InVybCgjc21hbGxHcmlkKSI+PC9yZWN0Pgo8cGF0aCBkPSJNIDQwIDAgTCAwIDAgMCA0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJncmF5IiBzdHJva2Utd2lkdGg9IjAuOCIgc3Ryb2tlLW9wYWNpdHk9IjAuMiI+PC9wYXRoPgo8L3BhdHRlcm4+CjxwYXR0ZXJuIGlkPSJncmlkIiB3aWR0aD0iODAiIGhlaWdodD0iODAiIHBhdHRlcm5Vbml0cz0idXNlclNwYWNlT25Vc2UiPgo8cmVjdCB3aWR0aD0iODAiIGhlaWdodD0iODAiIGZpbGw9InVybCgjbWVkaXVtR3JpZCkiPjwvcmVjdD4KPHBhdGggZD0iTSA4MCAwIEwgMCAwIDAgODAiIGZpbGw9Im5vbmUiIHN0cm9rZT0iZ3JheSIgc3Ryb2tlLXdpZHRoPSIyLjUiIHN0cm9rZS1vcGFjaXR5PSIwLjEiPjwvcGF0aD4KPC9wYXR0ZXJuPgo8L2RlZnM+CjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JpZCkiPjwvcmVjdD4KPC9zdmc+);
    background-repeat: repeat;
  }

  &__configuration {
    display: flex;
    width: 300px;
    padding: var(--base-size-200);
    background-color: var(--color-background-layer-2);
    flex-direction: column;
    gap: var(--gap-size-medium);
    flex-shrink: 0;
  }

  &__selection {
    &-attribute {
      @include text-body-strong;
    }
  }

  &__size {
    width: 150px;
  }
}
</style>
