<template>
  <p-form-element
    size="small"
    :disabled="disabled"
    :label="label"
    :help-text="helpText"
    :helpInTooltip="helpInTooltip"
    :error="error"
  >
    <p-color-picker
      :disabled="disabled"
      :error="!!error"
      :color-scheme="colorScheme"
      :modelValue="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
      @interact="$emit('interact')"
    />
  </p-form-element>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PropType } from 'vue';

@Component({
  components: {},
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: String, required: true }) public readonly modelValue!: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly helpInTooltip?: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly error?: string;
  @Prop({ type: Array as PropType<string[]>, default: () => [] }) public readonly colorScheme!: string[];
}
</script>
