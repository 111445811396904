<template>
  <p-form-element
    :label="label"
    :help-text="helpText"
    :disabled="disabled"
    :size="size"
    :error="error"
    :helpInTooltip="helpInTooltip"
  >
    <p-media-selector
      :disabled="disabled"
      :readonly="readonly"
      :error="!!error"
      :placeholder="placeholder"
      :preview="preview"
      :cursor-select="cursorSelect"
      :max-width="maxWidth"
      :max-height="maxHeight"
      :min-width="minWidth"
      :min-height="minHeight"
      :aspect-ratio="aspectRatio"
      :extensions="extensions"
      :modelValue="modelValue"
      :tmp="tmp"
      :multiple="multiple"
      :selection-mode="selectionMode"
      @update:modelValue="$emit('update:modelValue', $event)"
      @dialog-open="$emit('dialog-open')"
      @dialog-close="$emit('dialog-close')"
      @cursor-select="(x, y) => $emit('cursor-select', x, y)"
    >
      <slot></slot>
    </p-media-selector>
  </p-form-element>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PropType } from 'vue';
import { getDefaultExtensions } from '../modal/media/utils';

@Component({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: [String, Array], required: true }) public readonly modelValue!: string | string[];
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly readonly!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly preview!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly cursorSelect!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly placeholder?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly error?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly helpInTooltip?: boolean;
  @Prop({
    type: String as PropType<'extra-small' | 'small' | 'medium' | 'large' | 'extra-large'>,
    default: 'extra-large'
  })
  public readonly size!: 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large';

  @Prop({ type: String as PropType<'file' | 'folder'>, required: false, default: 'file' })
  public readonly selectionMode!: 'file' | 'folder';

  @Prop({ type: Number, required: false, default: undefined })
  public readonly maxWidth!: number;

  @Prop({ type: Number, required: false, default: undefined })
  public readonly maxHeight!: number;

  @Prop({ type: Number, required: false, default: undefined })
  public readonly minWidth!: number;

  @Prop({ type: Number, required: false, default: undefined })
  public readonly minHeight!: number;

  @Prop({ type: String, required: false, default: undefined })
  public readonly aspectRatio!: string;

  @Prop({
    type: Array as PropType<string[]>,
    required: false,
    default: () => getDefaultExtensions()
  })
  public readonly extensions?: string[];

  @Prop({ type: Boolean, required: false, default: false })
  public readonly multiple!: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  public readonly tmp!: boolean;
}
</script>
