import axios from 'axios';
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export class AppRequest {
  public static async get<Response = any>(url: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Response>> {
    options = options || {};

    try {
      const response = await Axios.get(url, options);

      if (typeof response.headers !== 'undefined' && typeof response.headers['x-csrf-token'] !== 'undefined') {
        Axios.defaults.headers.common['X-CSRF-TOKEN'] = response.headers['x-csrf-token'];
      }

      return response;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.headers['x-csrf-token']) {
        Axios.defaults.headers.common['X-CSRF-TOKEN'] = e.response.headers['x-csrf-token'];
      }

      throw e;
    }
  }

  public static async post<Response = any>(
    url: string,
    data?: any,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<Response>> {
    options = options || {};

    const response = await Axios.post(url, data, options);

    if (typeof response.headers !== 'undefined' && typeof response.headers['x-csrf-token'] !== 'undefined') {
      Axios.defaults.headers.common['X-CSRF-TOKEN'] = response.headers['x-csrf-token'];
    }

    return response;
  }

  public static async patch<Response = any>(
    url: string,
    data?: any,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<Response>> {
    options = options || {};

    const response = await Axios.patch(url, data, options);

    if (typeof response.headers !== 'undefined' && typeof response.headers['x-csrf-token'] !== 'undefined') {
      Axios.defaults.headers.common['X-CSRF-TOKEN'] = response.headers['x-csrf-token'];
    }

    return response;
  }

  public static async put<Response = any>(
    url: string,
    data?: any,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<Response>> {
    options = options || {};

    const response = await Axios.put(url, data, options);

    if (typeof response.headers !== 'undefined' && typeof response.headers['x-csrf-token'] !== 'undefined') {
      Axios.defaults.headers.common['X-CSRF-TOKEN'] = response.headers['x-csrf-token'];
    }

    return response;
  }

  public static async delete<Response = any>(
    url: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<Response>> {
    options = options || {};

    const response = await Axios.delete(url, options);

    if (typeof response.headers !== 'undefined' && typeof response.headers['x-csrf-token'] !== 'undefined') {
      Axios.defaults.headers.common['X-CSRF-TOKEN'] = response.headers['x-csrf-token'];
    }

    return response;
  }
}
