<template>
  <div class="error">
    <p-row justify-content="center">
      <p-column size="extra-large">
        <p-container align-items="center" gap-size="large">
          <p-headline>Uh oh!</p-headline>

          <p-headline size="3"
            >Something went wrong at our end.<br />Don't worry, it's not you - it's us. Sorry about that.</p-headline
          >

          <p-button color-type="tertiary" @click="reload()">Reload page</p-button>

          <div class="error__info" v-if="template.params.info && template.params.info.exception">
            <p-headline size="3">message: {{ template.params.info.message }}</p-headline>
            <p-headline size="5">exception: {{ template.params.info.exception }}</p-headline>
            <p-headline size="5">file: {{ template.params.info.file }}</p-headline>
            <p-headline size="5">line: {{ template.params.info.line }}</p-headline>

            <ul v-if="template.params.info.trace && template.params.info.trace.length > 0">
              <li v-for="(trace, traceKey) in template.params.info.trace" :key="traceKey">
                <div v-if="trace.file"><b style="width: 75px; font-weight: bold">file:</b> {{ trace.file }}</div>
                <div v-if="trace.line"><b style="width: 75px; font-weight: bold">line:</b> {{ trace.line }}</div>
                <div v-if="trace.function">
                  <b style="width: 75px; font-weight: bold">function:</b> {{ trace.function }}
                </div>
                <div v-if="trace.class"><b style="width: 75px; font-weight: bold">class:</b> {{ trace.class }}</div>
                <div v-if="trace.type"><b style="width: 75px; font-weight: bold">type:</b> {{ trace.type }}</div>
              </li>
            </ul>
          </div>
        </p-container>
      </p-column>
    </p-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ILayoutTemplate } from '@/interfaces/template';
import { IntervalServerError } from '@/interfaces/error';
import { PropType } from 'vue';

interface ILayoutTemplateServerError extends ILayoutTemplate {
  params: {
    info: IntervalServerError;
  };
}

@Component({
  name: 'template-internal-server-error'
})
export default class extends Vue {
  @Prop({ type: Object as PropType<ILayoutTemplateServerError> }) public readonly template!: ILayoutTemplateServerError;

  public reload() {
    window.location.reload();
  }
}
</script>

<style lang="scss" scoped>
.error {
  --text-color-headline: var(--color-base-500);
  padding-top: var(--gap-size-extra-large);
  text-align: center;

  &__info {
    text-align: left;
    width: 960px;
    max-width: 100%;
    margin: 30px auto;
    background-color: #f5f5f5;
    padding: 20px;

    li {
      margin: 10px 0;
    }
  }
}
</style>
