<template>
  <p-tooltip
    v-if="element.properties.tooltip"
    :text="element.properties.tooltip.text"
    :position="element.properties.tooltip.position"
  >
    <p-headline :size="String(element.properties.heading)">{{ element.properties.text }}</p-headline>
  </p-tooltip>
  <p-headline v-else :size="String(element.properties.heading)">{{ element.properties.text }}</p-headline>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementHeadline } from '@/interfaces/element';

@Component({
  name: 'layout-element-headline'
})
export default class extends Vue {
  @Prop() public element!: IElementHeadline;
}
</script>
