<script lang="ts">
import Vue, { CreateElement } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination, Autoplay } from 'swiper/modules';

@Component
export default class SwiperComponent extends Vue {
  @Prop({ type: Boolean, default: true }) readonly autoplayEnabled!: boolean;
  @Prop({ type: Number, default: 5000 }) readonly autoplayDelay!: number;

  public swiper: null | Swiper = null;

  mounted() {
    this.$nextTick(() => {
      this.swiper = new Swiper(this.$refs.swiper, {
        modules: [Pagination, Autoplay],
        loop: true,
        autoplay: this.autoplayEnabled ? { delay: this.autoplayDelay } : false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      });
    });
  }

  render(h: CreateElement) {
    const filteredSlots = this.$slots.default ? this.$slots.default.filter((vnode) => vnode.tag) : [];
    return h('div', { class: 'swiper-container' }, [
      h('div', { ref: 'swiper', class: 'swiper' }, [
        h(
          'div',
          { class: 'swiper-wrapper' },
          filteredSlots.map((vnode) => h('div', { class: 'swiper-slide' }, [vnode]))
        ),
        h('div', { class: 'swiper-pagination' })
      ])
    ]);
  }
}
</script>

<style lang="scss" scoped>
/* https://github.com/nolimits4web/swiper/issues/3599#issuecomment-1290283431 */
.swiper-container {
  display: grid;
}

.swiper-container .swiper {
  max-width: 100%;
}
</style>
