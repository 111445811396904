<template>
  <video :controls="controls" :autoplay="autoPlay" :muted="muted" :poster="poster">
    <source :src="src" />
    Sorry, your browser doesn't support embedded videos.
  </video>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: String, required: true }) public readonly src!: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly poster?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly muted!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly autoPlay!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly controls!: boolean;
}
</script>

<style lang="scss" scoped>
video {
  width: 100%;
}
</style>
