import { Node } from '@tiptap/core';

export interface IframeOptions {
  allowFullscreen: boolean;
  HTMLAttributes: {
    [key: string]: any;
  };
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    iframe: {
      /**
       * Add an iframe
       */
      setIframe: (options: { src: string; width?: string; height?: string; frameborder?: string }) => ReturnType;
    };
  }
}

export default Node.create<IframeOptions>({
  name: 'iframe',

  group: 'inline', // Treat iframe as inline to allow it inside <p>
  inline: true, // Mark it as inline element
  atom: true,

  addOptions() {
    return {
      allowFullscreen: true,
      HTMLAttributes: {
        class: 'iframe-wrapper'
      }
    };
  },

  addAttributes() {
    return {
      src: {
        default: null
      },
      width: {
        default: '560' // default width
      },
      height: {
        default: '315' // default height
      },
      frameborder: {
        default: '0' // default frameborder
      },
      allowfullscreen: {
        default: this.options.allowFullscreen,
        parseHTML: () => this.options.allowFullscreen
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'iframe',
        getAttrs: (dom: HTMLElement) => {
          const src = dom.getAttribute('src');
          if (!src) return false; // Ensure 'src' is present

          return {
            src,
            width: dom.getAttribute('width') || '560',
            height: dom.getAttribute('height') || '315',
            frameborder: dom.getAttribute('frameborder') || '0',
            allowfullscreen: dom.hasAttribute('allowfullscreen') || false
          };
        }
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    // Ensure all the attributes are passed down to the iframe
    return ['iframe', { ...HTMLAttributes }];
  },

  addCommands() {
    return {
      setIframe:
        (options: { src: string; width?: string; height?: string; frameborder?: string }) =>
        ({ tr, dispatch }) => {
          const { selection } = tr;
          const node = this.type.create({
            src: options.src,
            width: options.width || '560',
            height: options.height || '315',
            frameborder: options.frameborder || '0',
            allowfullscreen: this.options.allowFullscreen
          });

          if (dispatch) {
            tr.replaceRangeWith(selection.from, selection.to, node);
          }

          return true;
        }
    };
  }
});
