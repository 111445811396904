<template>
  <div class="search" :class="{ 'search--error': error, 'search--disabled': disabled }">
    <input
      class="search__input"
      :disabled="disabled"
      :readonly="readonly"
      :placeholder="placeholder"
      type="text"
      ref="input"
      :value="modelValue"
      @input="onInput"
    />

    <div class="search__icon" :class="{ 'search__icon--pointer-cursor': modelValue }" @click="clearSearch">
      <p-icon :icon="icon" />
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: String, required: true }) public readonly modelValue!: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly readonly!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly autofocus!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly placeholder?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly error!: boolean;

  @Prop({
    type: String as PropType<'small' | 'medium'>,
    default: 'medium',
    validator: (value: string) => ['small', 'medium'].includes(value)
  })
  public readonly size!: string;

  get icon() {
    return this.modelValue ? 'x' : 'search';
  }

  public mounted() {
    if (this.autofocus && this.$refs.input instanceof HTMLInputElement) {
      this.$refs.input.focus();
    }
  }

  public onInput(e: Event) {
    if (e.target instanceof HTMLInputElement) {
      this.$emit('update:modelValue', e.target.value);
    }
  }

  public clearSearch() {
    if (this.disabled) return;
    this.$emit('update:modelValue', '');
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/mixins/typography';

.search {
  display: flex;
  align-items: stretch;
  border: 1px solid var(--field-color-border-default);
  border-radius: var(--field-border-radius-default);
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 34px;

  &__input {
    appearance: none;
    width: 100%;
    margin: 0;
    border: 0;

    background-color: var(--field-color-background-default);
    color: var(--text-color-subdued);
    padding: var(--base-size-100) var(--base-size-900) var(--base-size-100) var(--base-size-200);

    @include component-text-default;

    &:hover {
      background-color: var(--field-color-background-hover);
    }

    &::placeholder {
      @include component-text-mask;

      padding-left: 3px;
      color: var(--text-color-help);
    }

    &:disabled {
      border-color: var(--field-color-border-disabled);
      background-color: var(--field-color-background-disabled);
      color: var(--text-color-disabled);
      cursor: not-allowed;

      & + .search__icon {
        color: var(--icon-color-disabled);
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    padding-right: var(--base-size-200);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    &--pointer-cursor {
      cursor: pointer;
      pointer-events: auto;
    }
  }

  &--error {
    border-color: var(--field-color-border-negative);
  }

  &--disabled {
    cursor: not-allowed;
  }
}
</style>
