<template>
  <p-form-element :label="label" :help-text="helpText" :disabled="disabled" :error="error">
    <div class="radio" :class="[`radio--display-${display}`]">
      <div
        class="radio__option"
        v-for="option in options"
        :key="option.value"
        :class="{
          'radio__option--selected': option.value === modelValue,
          'radio__option--disabled': disabled,
          'radio__option--error': error
        }"
        @click="selectOption(option)"
      >
        <div class="radio__option-input"></div>

        <p-label :disabled.prop="disabled">{{ option.text }}</p-label>
      </div>
    </div>
  </p-form-element>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RadioOption } from './types';
import { PropType } from 'vue';

@Component({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: [String, Number], required: false, default: undefined }) public readonly modelValue?: string | number;
  @Prop({ type: Array as PropType<RadioOption[]>, required: true }) public readonly options!: RadioOption[];
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly error?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({
    type: String as PropType<'block' | 'inline'>,
    default: 'block',
    required: false,
    validator: (value: string) => ['block', 'inline'].includes(value)
  })
  public readonly display!: 'block' | 'inline';

  public selectOption(option: RadioOption) {
    if (this.disabled) {
      return;
    }

    this.$emit('update:modelValue', option.value);
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/mixins/typography';

.radio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-size-small);

  &--display-inline {
    flex-direction: row;
    gap: var(--gap-size-medium);
    flex-wrap: wrap;
  }

  &__option {
    display: flex;
    align-items: center;
    gap: var(--gap-size-small);
    cursor: pointer;
    flex-grow: 0;

    &-input {
      display: flex;
      width: 18px;
      height: 18px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid var(--field-color-border-default, #d5d5d5);
      background: var(--field-color-background-default, #fdfdfd);

      &:before {
        content: '';
        display: none;
        width: 10px;
        height: 10px;
        border-radius: 8px;
        background: var(--icon-color-default);
      }
    }

    &:hover:not(.radio__option--disabled) {
      > .radio__option-input {
        background: var(--field-color-background-hover);
      }
    }

    &:active:not(.radio__option--disabled) {
      > .radio__option-input {
        background: var(--button-color-background-pressed);

        &:before {
          background: var(--icon-color-default-inverted);
        }
      }
    }

    &--selected {
      > .radio__option-input {
        &:before {
          display: block;
        }
      }
    }

    &--disabled {
      cursor: not-allowed;

      > .radio__option-input {
        border-color: var(--field-color-border-disabled);
        background: var(--field-color-background-disabled);

        &:before {
          background: var(--icon-color-disabled);
        }
      }
    }

    &--error {
      > .radio__option-input {
        border-color: var(--field-color-border-negative);
      }
    }
  }
}
</style>
