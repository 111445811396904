<template>
  <p-skeleton-loader
    :type="element.properties.type"
    :lines="element.properties.lines"
    :size="element.properties.size"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementSkeletonLoader } from '@/interfaces/element';

@Component({
  name: 'layout-element-skeleton-loader'
})
export default class extends Vue {
  @Prop() public element!: IElementSkeletonLoader;
}
</script>
