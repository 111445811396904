<template>
  <div class="checkboxes">
    <p-checkbox
      v-for="item in options"
      :key="item.value"
      :label="item.text"
      :model-value="modelValue.includes(item.value)"
      :disabled="disabled?.includes(item.value)"
      :error="error"
      @update:modelValue="modelValueUpdate($event, item)"
    />
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CheckboxesOption } from './types';

@Component({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: Array as PropType<Array<string | number>>, required: true }) public readonly modelValue!: Array<
    string | number
  >;
  @Prop({ type: Array as PropType<CheckboxesOption[]>, required: true }) public readonly options!: CheckboxesOption[];
  @Prop({ type: Boolean, required: false, default: false }) public readonly error!: boolean;
  @Prop({ type: Array as PropType<Array<string | number>>, required: false, default: undefined })
  public readonly disabled?: Array<string | number>;

  public modelValueUpdate(selected: boolean, option: CheckboxesOption) {
    if (selected) {
      this.$emit('update:modelValue', [...this.modelValue, option.value]);
    } else {
      this.$emit(
        'update:modelValue',
        this.modelValue.filter((value) => value !== option.value)
      );
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/mixins/typography';

.checkboxes {
  display: flex;
  gap: var(--gap-size-small);
  flex-direction: column;
}
</style>
