<template>
  <p-tooltip
    v-if="element.properties && element.properties.tooltip"
    :text="element.properties.tooltip.text"
    :position="element.properties.tooltip.position"
  >
    <p-icon :icon="element.properties.icon" :size="element.properties.size" />
  </p-tooltip>

  <p-icon v-else :icon="element.properties.icon" :size="element.properties.size" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementIcon } from '@/interfaces/element';

@Component({
  name: 'layout-element-icon'
})
export default class extends Vue {
  @Prop() public element!: IElementIcon;
}
</script>
