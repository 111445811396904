import { ILayoutTypeElement, ILayoutTypeElements } from '@/interfaces/element';
import { traverseElementChild } from './traverseElementChild';

export function traverseElements(children: ILayoutTypeElements, fn: (element: ILayoutTypeElement) => void) {
  if (children.length > 0) {
    children.forEach((child) => {
      traverseElementChild(child, fn);
    });
  }
}
