<template>
  <p-tooltip
    v-if="element.properties.tooltip"
    :text="element.properties.tooltip.text"
    :position="element.properties.tooltip.position"
  >
    <p-image
      :src="element.properties.src"
      :width="element.properties.width"
      :height="element.properties.height"
      :max-width="element.properties.maxWidth"
      :max-height="element.properties.maxHeight"
      :rotation="element.properties.rotation"
      :overlay-image="element.properties.overlayImage"
    />
  </p-tooltip>
  <p-image
    v-else
    :src="element.properties.src"
    :width="element.properties.width"
    :height="element.properties.height"
    :max-width="element.properties.maxWidth"
    :max-height="element.properties.maxHeight"
    :rotation="element.properties.rotation"
    :overlay-image="element.properties.overlayImage"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementImage } from '@/interfaces/element';

@Component({
  name: 'layout-element-image'
})
export default class extends Vue {
  @Prop() public element!: IElementImage;
}
</script>
