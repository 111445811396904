<template>
  <p-form-range-slider
    :label="element.properties.label"
    :help-text="element.properties.help"
    :placeholder="element.properties.placeholder"
    :error="element.properties.errors.join(', ')"
    :disabled="element.properties.disabled"
    :readonly="element.properties.readonly"
    :ticks="element.properties.ticks"
    :step="element.properties.step"
    :min="element.properties.min"
    :size="element.properties.size"
    :max="element.properties.max"
    v-model="element.properties.value"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementSlider } from '@/interfaces/element';
import { Iframe } from '@/iframe';
import debounce from 'lodash-decorators/debounce';
import { Trigger } from '@/Trigger';
import { PropType } from 'vue';

@Component({
  name: 'layout-element-slider'
})
export default class extends Vue {
  @Prop({ type: Object as PropType<IElementSlider>, required: true }) public readonly element!: IElementSlider;

  @Watch('element.properties.value')
  onFastValueChange() {
    if (this.element.properties.name) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }
  }

  @debounce(500)
  @Watch('element.properties.value')
  onValueChange() {
    this.$el.dispatchEvent(
      new CustomEvent('BLUEPRINT_INTERACT', {
        bubbles: true,
        composed: true
      })
    );

    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(
        this.element.properties.trigger,
        this.$el,
        this.element.properties.name,
        this.element.properties.value
      );
    }
  }
}
</script>
