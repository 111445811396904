<template>
  <p-form-element :label="label" :help-text="helpText" :disabled="disabled" :error="error" :size="size">
    <p-tags-input
      :disabled="disabled"
      :error="!!error"
      :modelValue="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
    />
  </p-form-element>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
})
export default class extends Vue {
  @Prop({ type: Array as PropType<string[]>, required: true }) public readonly modelValue!: string[];
  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: Boolean }) public readonly disabled!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly error?: string;

  @Prop({ type: String as PropType<'medium' | 'large' | 'extra-large'>, required: false, default: 'extra-large' })
  public readonly size!: 'medium' | 'large' | 'extra-large';
}
</script>
