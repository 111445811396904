import { TableCell as DefaultTableCell } from '@tiptap/extension-table-cell';

export const CustomTableCell = DefaultTableCell.extend({
  addOptions() {
    return {
      ...this.parent?.()
    };
  },

  addAttributes() {
    return {
      ...this.parent?.(),

      colspan: {
        default: null,
        parseHTML: (element) => element.getAttribute('colspan'),
        renderHTML: (attributes) => {
          if (attributes.colspan) {
            return { colspan: attributes.colspan };
          }
        }
      },
      rowspan: {
        default: null,
        parseHTML: (element) => element.getAttribute('rowspan'),
        renderHTML: (attributes) => {
          if (attributes.rowspan) {
            return { rowspan: attributes.rowspan };
          }
        }
      },

      style: {
        default: null,
        parseHTML: (element) => element.getAttribute('style'),
        renderHTML: (attributes) => {
          if (attributes.style) {
            return { style: attributes.style };
          }
        }
      }
    };
  },

  content: 'inline*', // Restrict to inline content

  parseHTML() {
    return [
      {
        tag: 'td',
        getAttrs: (node: HTMLElement) => ({
          colspan: node.getAttribute('colspan'),
          rowspan: node.getAttribute('rowspan'),
          style: node.getAttribute('style') // Parse style attribute
        })
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['td', HTMLAttributes, 0]; // Render <td> directly
  }
});
