<template>
  <div
    class="date-picker"
    @mouseenter="isInputHovered = true"
    @mouseleave="isInputHovered = false"
    :class="{ 'date-picker--disabled': disabled }"
  >
    <flat-pickr
      ref="datePicker"
      :value="modelValue"
      @input="handleInputEvent"
      :config="config"
      :class="['flat-pickr', { 'flat-pickr--error': error, 'flat-pickr--disabled': disabled }]"
      :disabled="disabled"
      :placeholder="placeholder"
      @on-open="handleCalendarOpen"
      @on-close="handleCalendarClose"
    />

    <div class="date-picker__icon" @click="clearCalendarValue">
      <p-icon :icon="(isInputHovered || isCalendarOpen) && clearable && !disabled ? 'x' : 'calendar'" size="small" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

@Component({
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  components: {
    'flat-pickr': flatPickr
  }
})
export default class extends Vue {
  @Prop({ type: String, required: false, default: null }) public readonly modelValue!: string | null;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly clearable!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly placeholder?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly error?: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly minDate?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly maxDate?: string;
  @Ref('datePicker') public readonly datePicker!: typeof flatPickr;

  public isInputHovered = false;
  public isCalendarOpen = false;

  public handleInputEvent(value: string) {
    if (!value) {
      this.$emit('update:modelValue', '');
      this.$emit('input', '');
      return;
    } else if (value !== this.modelValue) {
      this.$emit('update:modelValue', value);
      this.$emit('input', value);
    }
  }

  public clearCalendarValue() {
    if (!this.clearable || this.disabled) {
      return;
    }

    this.datePicker.fp.clear();
  }

  public handleCalendarOpen() {
    this.isCalendarOpen = true;
    this.$emit('open');
  }

  handleCalendarClose() {
    this.isCalendarOpen = false;
    this.$emit('close');
  }

  get config() {
    return {
      enableTime: false,
      ...(this.minDate && { minDate: this.minDate }),
      ...(this.maxDate && { maxDate: this.maxDate }),
      altInput: true,
      dateFormat: 'Y-m-d',
      altFormat: 'd-m-Y',
      defaultDate: this.modelValue ? this.modelValue : null
    };
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/mixins/typography';

.date-picker {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;

  &__icon {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-right: var(--base-size-200);
  }

  &--disabled {
    > .date-picker__icon {
      color: var(--text-color-disabled);
      cursor: not-allowed;
    }
  }

  ::v-deep {
    .flat-pickr {
      height: var(--field-height-medium);
      appearance: none;
      width: 100%;
      margin: 0;
      border: 1px solid var(--field-color-border-default);
      border-radius: var(--field-border-radius-default);
      background-color: var(--field-color-background-default);
      color: var(--text-color-subdued);
      padding: var(--base-size-100) var(--base-size-900) var(--base-size-100) var(--base-size-200);

      &--error {
        border-color: var(--field-color-border-negative);
      }

      @include component-text-default;
      color: var(--text-color-subdued);

      &:hover {
        background-color: var(--field-color-background-hover);
      }

      &::placeholder {
        @include component-text-mask;
        padding-left: 3px;
        color: var(--text-color-help);
      }

      &:disabled {
        border-color: var(--field-color-border-disabled);
        background-color: var(--field-color-background-disabled);
        color: var(--text-color-disabled);
        cursor: not-allowed;
      }
    }
  }
}
</style>
