<template>
  <p-form-size
    :label="element.properties.label"
    :help-text="element.properties.help"
    :units="element.properties.units"
    :help-in-tooltip="element.properties.helpInTooltip"
    :placeholder="element.properties.placeholder"
    :size="element.properties.size"
    :max="element.properties.max"
    :error="element.properties.errors.join(', ')"
    :disabled="element.properties.disabled"
    v-model="element.properties.value"
    @keypress="onInteract"
    @keydown="onKeydown"
    @update:modelValue="onInteract"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementSize } from '@/interfaces/element';
import { Trigger } from '@/Trigger';
import debounce from 'lodash-decorators/debounce';
import { Iframe } from '@/iframe';
import { autobind } from 'core-decorators';
import { cancelBlueprintUpdate } from '@/utility';

@Component({
  name: 'layout-element-size'
})
export default class extends Vue {
  @Prop() public element!: IElementSize;

  private didInteract = false;
  private didInteractTimer: number | null = null;

  @autobind
  onInteract() {
    this.didInteract = true;

    // Cancel existing blueprint calls so that a update doesn't come through if user types a bit slow
    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      cancelBlueprintUpdate(this.$el);
    }

    if (this.didInteractTimer !== null) {
      clearTimeout(this.didInteractTimer);
    }

    this.didInteractTimer = setTimeout(() => {
      this.didInteract = false;
      this.didInteractTimer = null;
    }, 500);
  }

  @Watch('element.properties.value')
  public onFastValueChange() {
    if (this.element.properties.name) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }
  }

  onKeydown() {
    this.onInteract();
  }

  @Watch('element.properties.value')
  @debounce(375)
  onValueChange(newValue: string) {
    if (this.didInteract) {
      this.$el.dispatchEvent(
        new CustomEvent('BLUEPRINT_INTERACT', {
          bubbles: true,
          composed: true
        })
      );
    }

    if (this.didInteract && this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(this.element.properties.trigger, this.$el, this.element.properties.name, newValue);
    }
  }
}
</script>
