import { Link } from '@tiptap/extension-link';
import { mergeAttributes } from '@tiptap/core';

export default Link.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      target: {
        default: null,
        parseHTML: (element) => element.getAttribute('target'),
        renderHTML: (attributes) => {
          if (attributes.target) {
            return { target: attributes.target };
          }
          return {};
        }
      },
      htmlAttributes: {
        default: {},
        parseHTML: (element) => {
          const attrs: Record<string, string> = {};
          // Collect all attributes except the ones already defined
          Array.from(element.attributes).forEach((attr) => {
            if (!['href', 'title', 'target'].includes(attr.name)) {
              attrs[attr.name] = attr.value;
            }
          });
          return attrs;
        },
        renderHTML: (attributes) => {
          // Return the collected attributes to be merged into the element
          return attributes.htmlAttributes;
        }
      }
    };
  },

  renderHTML({ HTMLAttributes }) {
    const { htmlAttributes, ...rest } = HTMLAttributes;
    return ['a', mergeAttributes(this.options.HTMLAttributes, rest, htmlAttributes), 0];
  }
});
