<template>
  <p-svg-object :src="element.properties.src" :width="element.properties.width" :height="element.properties.height" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementSvgObject } from '@/interfaces/element';

@Component({
  name: 'layout-element-svg-object'
})
export default class extends Vue {
  @Prop() public element!: IElementSvgObject;
}
</script>
