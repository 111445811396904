<template>
  <div class="toast" :class="`toast--type-${type}`">
    <div class="toast__close" @click="$emit('close-request')"><p-icon icon="x" size="medium" /></div>
    <div class="toast__headline" v-if="headline">
      <p-headline size="4">{{ headline }}</p-headline>
    </div>
    <div class="toast__text" v-if="text">{{ text }}</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PropType } from 'vue';

@Component({
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: String, required: false, default: undefined }) public readonly text?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly headline?: string;

  @Prop({
    type: String as PropType<'positive' | 'negative' | 'info'>,
    required: true,
    validator: (value: string) => ['positive', 'negative', 'info'].includes(value)
  })
  public type!: 'positive' | 'negative' | 'info';
}
</script>

<style lang="scss" scoped>
@import '../../scss/mixins/typography';

.toast {
  display: flex;
  width: var(--field-width-large);
  padding: var(--base-size-300) var(--base-size-500) var(--base-size-400) var(--base-size-500);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-size-small);
  border-radius: var(--border-radius-small);
  position: relative;

  &__close {
    position: absolute;
    top: var(--base-size-300);
    right: var(--base-size-500);
    cursor: pointer;
  }

  &__text {
    @include text-body-default;
    color: inherit;

    padding-right: var(--base-size-500);
  }

  &--type {
    &-positive {
      border: 1px solid var(--color-border-positive);
      background: var(--color-background-positive);
      color: var(--text-color-positive);

      --text-color-headline: var(--text-color-positive);
    }

    &-negative {
      border: 1px solid var(----color-border-negative);
      background: var(--color-background-negative);
      color: var(--text-color-negative);

      --text-color-headline: var(--text-color-negative);
    }

    &-info {
      border: 1px solid var(----color-border-info);
      background: var(--color-background-info);
      color: var(--text-color-info);

      --text-color-headline: var(--text-color-info);
    }
  }
}
</style>
