import { CampaignPageResource } from '@/types/api/campaign';

export class CampaignPageModel {
  public readonly resource: CampaignPageResource;

  constructor(resource: CampaignPageResource) {
    this.resource = resource;
  }

  public get uniqueAddons(): string[] {
    const addons: string[] = [];

    this.resource.rows.forEach((row) => {
      row.columns.forEach((column) => {
        column.addons.forEach((addon) => {
          if (!addons.includes(addon.alias)) {
            addons.push(addon.alias);
          }
        });
      });
    });

    return addons;
  }
}
