<template>
  <div class="email-preview">
    <slot />
    <iframe
      v-if="template.params && template.params.content"
      frameborder="0"
      height="1200"
      width="100%"
      :srcdoc="template.params.content"
    >
    </iframe>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ILayoutTemplate } from '@/interfaces/template';

@Component({
  components: {},
  name: 'email-preview'
})
export default class TemplateEmailPreview extends Vue {
  @Prop() public template!: ILayoutTemplate;
}
</script>
