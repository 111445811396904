import { render, staticRenderFns } from "./FormSearch.vue?vue&type=template&id=ef5d2b9c"
import script from "./FormSearch.vue?vue&type=script&lang=ts"
export * from "./FormSearch.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports