<template>
  <p-divider />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementDivider } from '@/interfaces/element';

@Component({
  name: 'layout-element-divider'
})
export default class extends Vue {
  @Prop() public element!: IElementDivider;
}
</script>
