<template>
  <div class="progress-step" :class="size ? `progress-step--size-${size}` : undefined">
    <div
      v-for="(step, index) in steps"
      :key="step.id"
      class="progress-step__item"
      :class="{
        'progress-step__item--completed': index < activeStepIndex,
        'progress-step__item--active': index === activeStepIndex
      }"
    >
      <div class="progress-step__item-circle" @click="index < activeStepIndex ? goToStep(step) : null">
        <div class="progress-step__item-indicator">
          <span class="progress-step__item-icon" v-if="index < activeStepIndex">
            <p-icon icon="check" display="block" />
          </span>
          <span class="progress-step__item-icon" v-else-if="index === activeStepIndex"> </span>
        </div>
        <div class="progress-step__item-label">{{ step.label }}</div>
      </div>
      <div v-if="index < steps.length - 1" class="progress-step__item-line"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PropType } from 'vue';

export interface Step {
  id: string;
  label: string;
}

@Component({
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
})
export default class extends Vue {
  @Prop({ type: String, required: true }) public readonly modelValue!: string | number;
  @Prop({ type: Array as PropType<Step[]>, required: true }) public readonly steps!: Step[];

  @Prop({
    type: String as PropType<'medium' | 'large' | 'extra-large'>,
    required: false,
    default: undefined,
    validator: (value: string) => {
      return ['medium', 'large', 'extra-large'].includes(value);
    }
  })
  public readonly size!: 'medium' | 'large' | 'extra-large';

  get activeStepIndex(): number {
    return this.steps.findIndex((step) => step.id === this.modelValue);
  }

  public goToStep(step: Step) {
    this.$emit('update:modelValue', step.id);
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/mixins/typography';

.progress-step {
  display: flex;
  width: 100%;
  min-height: 100px;

  &--size {
    &-extra-small {
      width: var(--field-width-extra-small);
    }

    &-small {
      width: var(--field-width-small);
    }

    &-medium {
      width: var(--field-width-medium);
    }

    &-large {
      width: var(--field-width-large);
    }

    &-extra-large {
      width: var(--field-width-extra-large);
    }
  }

  &__item {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;

    &-circle {
      width: 40px;
      height: 40px;
      position: relative;

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: '';
        border-bottom: 2px solid var(--color-base-400);
        width: 100%;
        z-index: 1;
      }

      .progress-step__item-indicator {
        width: 14px;
        height: 14px;
        background: var(--color-base-400);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 3;
        transform: translate(-50%, -50%);
        transition: background 0.1s ease-in-out, color 0.1s ease-in-out, width 0.1s ease-in-out, height 0.1s ease-in-out,
          margin-top 0.1s ease-in-out;
      }

      .progress-step__item-icon {
        color: var(--icon-color-default-inverted);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .progress-step__item-label {
        @include component-text-default;
        color: var(--text-color-help);
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0%);
        white-space: nowrap;
        margin-top: 16px;
      }
    }

    &:last-child {
      flex-grow: 0;

      .progress-step__item-circle::before {
        left: 0;
      }
      .progress-step__item-circle::after {
        display: none;
      }
    }

    &:first-child {
      .progress-step__item-circle::before {
        width: 50%;
        left: 75%;
      }
    }

    &--active {
      .progress-step__item-circle {
        &::after {
          position: absolute;
          top: 50%;
          left: 100%;
          transform: translate(-75%, -50%);
          content: '';
          border-bottom: 2px solid var(--color-base-400);
          width: 50%;
          z-index: 2;
        }

        .progress-step__item-label {
          @include component-text-strong;
          color: var(--text-color-default);
        }
      }
    }

    &--completed:not(&--active) {
      cursor: pointer;
      .progress-step__item-line {
        background: var(--field-color-background-control-inverted);
      }
    }

    &--completed,
    &--active {
      .progress-step__item-circle::before {
        border-bottom: 2px solid var(--field-color-background-control-inverted);
      }
    }

    &--active {
      .progress-step__item-indicator {
        background: var(--color-base-400);
        color: var(--icon-color-default-inverted);
        width: 25px;
        height: 25px;
        border: 5px solid var(--color-base-300);
      }
    }

    &--completed {
      .progress-step__item-indicator {
        background: var(--field-color-background-control-inverted);
        color: var(--icon-color-default-inverted);
        width: 40px;
        height: 40px;
      }
    }

    &-line {
      flex-grow: 1;
      height: 2px;
      background: var(--color-base-400);
    }
  }
}
</style>
