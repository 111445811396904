<template>
  <p-form-text-editor
    :label="element.properties.label"
    :help-text="element.properties.help"
    :help-in-tooltip="element.properties.helpInTooltip"
    :error="element.properties.errors.join(', ')"
    :disabled="element.properties.disabled"
    :readonly="element.properties.readonly"
    :placeholder="element.properties.placeholder"
    :toolbar="element.properties.toolbar"
    :forced-root-block="element.properties.enterTag === 'BR' ? undefined : element.properties.enterTag"
    :sections="element.properties.sections"
    :popovers="element.properties.popovers"
    :size="element.properties.size"
    :ai-assistant="element.properties.aiAssistant"
    v-model="element.properties.value"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementTextEditor } from '@/interfaces/element';
import { Trigger } from '@/Trigger';
import debounce from 'lodash-decorators/debounce';
import { Iframe } from '@/iframe';

@Component({
  name: 'layout-element-text-editor'
})
export default class extends Vue {
  @Prop() public element!: IElementTextEditor;

  @Watch('element.properties.value')
  onEditorChange() {
    if (this.element.properties.name) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }
  }

  @Watch('element.properties.value')
  @debounce(375)
  onValueChange(newValue: string) {
    this.$el.dispatchEvent(
      new CustomEvent('BLUEPRINT_INTERACT', {
        bubbles: true,
        composed: true
      })
    );

    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(this.element.properties.trigger, this.$el, this.element.properties.name, newValue);
    }
  }
}
</script>
