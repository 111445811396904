<template>
  <vue-recaptcha
    ref="recaptcha"
    :sitekey="element.properties.siteKey"
    :loadRecaptchaScript="true"
    @verify="onVerification"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementRecaptcha } from '@/interfaces/element';
import VueRecaptcha from 'vue-recaptcha';
import { EventBus } from '@/main';
import { Trigger } from '@/Trigger';

@Component({
  name: 'layout-element-recaptcha',
  components: { VueRecaptcha }
})
export default class extends Vue {
  @Prop() public element!: IElementRecaptcha;

  created() {
    EventBus.$on('BLUEPRINT_RELOADED', this.onReload);
  }

  destroyed() {
    EventBus.$off('BLUEPRINT_RELOADED', this.onReload);
  }

  onReload() {
    if (this.$refs && this.$refs.recaptcha) {
      // eslint-disable-next-line
      (this.$refs.recaptcha as any).reset();
    }
  }

  onVerification(response: string) {
    this.element.properties.value = response;
  }

  @Watch('element.properties.value')
  onValueChange() {
    this.$el.dispatchEvent(
      new CustomEvent('BLUEPRINT_INTERACT', {
        bubbles: true,
        composed: true
      })
    );

    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(
        this.element.properties.trigger,
        this.$el,
        this.element.properties.name,
        this.element.properties.value
      );
    }
  }
}
</script>
