import { Extension } from '@tiptap/core';

export interface TextAlignOptions {
  types: string[];
  alignments: string[];
  defaultAlignment: string | undefined;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    textAlign: {
      setTextAlign: (alignment: string) => ReturnType;
      unsetTextAlign: () => ReturnType;
    };
  }
}

export default Extension.create<TextAlignOptions>({
  name: 'textAlign',

  addOptions() {
    return {
      types: ['paragraph', 'heading', 'image', 'listItem'],
      alignments: ['left', 'center', 'right', 'justify'],
      defaultAlignment: ''
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: ['paragraph', 'heading'],
        attributes: {
          textAlign: {
            default: this.options.defaultAlignment,
            parseHTML: (element) => {
              const alignment = element.style.textAlign || this.options.defaultAlignment || '';
              return this.options.alignments.includes(alignment) ? alignment : this.options.defaultAlignment;
            },
            renderHTML: (attributes) => {
              if (!attributes.textAlign) {
                return {};
              }

              const styleMap = {
                left: 'text-align: left;',
                center: 'text-align: center;',
                right: 'text-align: right;',
                justify: 'text-align: justify;'
              };

              return { style: styleMap[attributes.textAlign as keyof typeof styleMap] };
            }
          }
        }
      },
      {
        types: ['listItem'],
        attributes: {
          textAlign: {
            default: this.options.defaultAlignment,
            parseHTML: (element) => {
              const alignment = element.style.textAlign || this.options.defaultAlignment || '';
              return this.options.alignments.includes(alignment) ? alignment : this.options.defaultAlignment;
            },
            renderHTML: (attributes) => {
              if (!attributes.textAlign) {
                return {};
              }

              const styleMap = {
                left: 'text-align: left;',
                center: 'text-align: center; list-style-position: inside;',
                right: 'text-align: right; list-style-position: inside;',
                justify: 'text-align: justify;'
              };

              return { style: styleMap[attributes.textAlign as keyof typeof styleMap] };
            }
          }
        }
      },
      {
        types: ['image'],
        attributes: {
          textAlign: {
            default: this.options.defaultAlignment,
            parseHTML: (element) => {
              if (element.style.float) {
                const float = element.style.float;
                if (float === 'left' || float === 'right') {
                  return float;
                }
              } else if (element.style.margin === '0 auto') {
                return 'center';
              }
              return this.options.defaultAlignment;
            },
            renderHTML: (attributes) => {
              if (!attributes.textAlign) {
                return {};
              }

              const styleMap = {
                left: 'float: left; display: block; margin: 0;',
                right: 'float: right; display: block; margin: 0;',
                center: 'display: block; margin: 0 auto; float: none;',
                justify: 'float: left; display: block; margin: 0;'
              };

              return { style: styleMap[attributes.textAlign as keyof typeof styleMap] };
            }
          }
        }
      }
    ];
  },

  addCommands() {
    return {
      setTextAlign:
        (alignment: string) =>
        ({ commands, state }) => {
          if (!this.options.alignments.includes(alignment)) {
            return false;
          }

          const { selection } = state;
          const { $from } = selection;
          let success = true;

          // Apply alignment to selected node types
          this.options.types.forEach((type) => {
            success = commands.updateAttributes(type, { textAlign: alignment }) && success;
          });

          // Additionally, if the selection is inside a listItem or list, apply alignment
          const depth = $from.depth;
          for (let i = depth; i > 0; i--) {
            const node = $from.node(i);
            if (node.type.name === 'listItem') {
              success = commands.updateAttributes(node.type.name, { textAlign: alignment }) && success;
              break;
            }
          }

          return success;
        },

      unsetTextAlign:
        () =>
        ({ commands, state }) => {
          const { selection } = state;
          const { $from } = selection;
          let success = true;

          // Unset alignment from selected node types
          this.options.types.forEach((type) => {
            success = commands.resetAttributes(type, 'textAlign') && success;
          });

          // Additionally, if the selection is inside a listItem or list, unset alignment
          const depth = $from.depth;
          for (let i = depth; i > 0; i--) {
            const node = $from.node(i);
            if (node.type.name === 'listItem') {
              success = commands.resetAttributes(node.type.name, 'textAlign') && success;
              break;
            }
          }

          return success;
        }
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Shift-l': () => {
        this.editor.commands.setTextAlign('left');
        return true;
      },
      'Mod-Shift-e': () => {
        this.editor.commands.setTextAlign('center');
        return true;
      },
      'Mod-Shift-r': () => {
        this.editor.commands.setTextAlign('right');
        return true;
      },
      'Mod-Shift-j': () => {
        this.editor.commands.setTextAlign('justify');
        return true;
      }
    };
  }
});
