<template>
  <menu-overlay :show="show" :title="title" :description="description" @close-request="$emit('close-request')">
    <template v-slot:header>
      <p-button class="menu-overlay__header-button" color-type="primary" @click="goToAllCampaigns()"
        >Show all campaigns</p-button
      >

      <p-tabs v-device-desktop>
        <p-tabs-item
          v-for="categoryItem in availableCategories"
          :key="categoryItem.id"
          :text="categoryItem.label"
          :selected.prop="category === categoryItem.id"
          @select="category = categoryItem.id"
        />
      </p-tabs>

      <p-tabs v-device-mobile>
        <p-tabs-item
          v-for="categoryItem in availableCategories"
          :key="categoryItem.id"
          :text="categoryItem.label"
          size="small"
          :selected.prop="category === categoryItem.id"
          @select="category = categoryItem.id"
        />
      </p-tabs>
    </template>

    <transition-group
      name="slide-left"
      class="menu-campaigns"
      tag="div"
      v-if="ready"
      :duration="{ enter: 300, leave: 0 }"
      appear
    >
      <menu-nav-item
        v-for="type in filteredCampaignTypes"
        :key="`${type.id}${category}`"
        :href="`/campaigns?type=${type.alias}`"
        :access="type.access"
        :active="type.active"
        @mouseenter="onMouseEnter(type)"
        @mouseleave="onMouseLeave(type)"
        @click="$emit('close-request')"
      >
        <template v-slot:icon>
          <p-icon v-if="type.icon" size="large" :icon="type.icon" />
        </template>

        {{ type.name }}
      </menu-nav-item>
    </transition-group>

    <div class="menu-campaigns" v-else>
      <menu-nav-item v-for="i in 34" :key="i" href="#" @click.stop>
        <template v-slot:icon>
          <p-skeleton-loader type="image" size="medium" />
        </template>

        <p-skeleton-loader type="text" />
      </menu-nav-item>
    </div>

    <template v-slot:footer>
      <p-button color-type="secondary" @click="openCreateCampaign()">Create new campaign</p-button>
      <p-button color-type="primary" @click="goToAllCampaigns()">Show all campaigns</p-button>
    </template>
  </menu-overlay>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import MenuOverlay from './MenuOverlay.vue';
import MenuNavItem from './MenuNavItem.vue';
import { AppRequest } from '@/app_request';
import { CampaignTypeResource } from '@/types/api/campaign';
import { getDevice } from '@/utility';
import { useWorkspaceStore } from '@/store/workspaceStore';

interface CampaignCategory {
  id: string;
  label: string;
}

@Component({
  components: { MenuOverlay, MenuNavItem }
})
export default class extends Vue {
  @Prop({ type: Boolean, required: false, default: true }) public readonly show!: boolean;

  public campaignTypes: CampaignTypeResource[] = [];
  public ready = false;
  public category = '';
  public hoveredCampaignTypes: number[] = [];

  public async mounted() {
    this.campaignTypes = (await AppRequest.get<{ data: CampaignTypeResource[] }>('/api/v1/campaign-type')).data.data;
    this.ready = true;
  }

  public get availableCategories(): CampaignCategory[] {
    const categories: CampaignCategory[] = [
      { id: '', label: 'All games' },
      { id: 'Luck games', label: 'Luck games' },
      { id: 'Skill games', label: 'Skill games' },
      { id: 'Knowledge games', label: 'Knowledge games' }
    ];

    if (useWorkspaceStore().workspace?.features?.enable_campaign_ads) {
      categories.push({ id: 'Ads', label: 'Ads' });
    }

    categories.push({ id: 'Accessibility', label: 'Accessibility' });

    return categories;
  }

  public openCreateCampaign() {
    this.$emit('open-create-campaign');
  }

  public onMouseEnter(type: CampaignTypeResource) {
    this.hoveredCampaignTypes.push(type.id);
  }

  public onMouseLeave(type: CampaignTypeResource) {
    setTimeout(() => {
      const index = this.hoveredCampaignTypes.indexOf(type.id);

      if (index !== -1) {
        this.hoveredCampaignTypes.splice(index, 1);
      }
    }, 500);
  }

  public goToAllCampaigns() {
    const currentPath = this.$route.path;
    const hasQueryParams = Object.keys(this.$route.query).length > 0;

    if (currentPath === '/campaigns' && !hasQueryParams) {
      this.$emit('close-request');
    } else {
      this.$router.push({ path: '/campaigns', query: {} }).then(() => {
        this.$emit('close-request');
      });
    }
  }

  public get filteredCampaignTypes() {
    // since campaign types can only have 1 category like skill games, knowledge games, etc.
    // we need to predefine and filter out the accessibility types this way
    const accessibilityAliases = ['quiz', 'scratchcard', 'wof', 'spinthebottle', 'personality_test'];
    const adsAliases = [
      'quiz',
      'wof',
      'spinthebottle',
      'poll',
      'scratchcard',
      'sliceit',
      'dropgame',
      'shootit',
      'memory'
    ];

    let types = this.campaignTypes;

    if (this.category === 'Accessibility') {
      types = types.filter((type) => accessibilityAliases.includes(type.alias));
    } else if (this.category === 'Ads') {
      types = types.filter((type) => adsAliases.includes(type.alias));
    } else if (this.category !== '') {
      types = types.filter((type) => type.category === this.category);
    }

    return types.map((type) => {
      return {
        ...type,
        active: this.$route.query.type === type.alias || this.$route.path.startsWith(`/campaign/${type.alias}/`)
      };
    });
  }

  public get hoveredCampaignType(): CampaignTypeResource | null {
    if (this.hoveredCampaignTypes.length > 0) {
      return (
        this.campaignTypes.find(
          (type) => type.id === this.hoveredCampaignTypes[this.hoveredCampaignTypes.length - 1]
        ) ?? null
      );
    }

    return null;
  }

  public get activeCampaignType(): CampaignTypeResource | null {
    return this.filteredCampaignTypes.find((type) => type.active) ?? null;
  }

  public get title(): string {
    if (this.hoveredCampaignType) {
      return this.hoveredCampaignType.name;
    }

    if (this.activeCampaignType) {
      return this.activeCampaignType.name;
    }

    return 'Campaign Overview';
  }

  public get description(): string {
    if (getDevice() === 'mobile') {
      return '';
    }

    if (this.hoveredCampaignType) {
      return this.hoveredCampaignType.description;
    }

    if (this.activeCampaignType) {
      return this.activeCampaignType.description;
    }

    return 'Choose from more than 40 campaign concepts. Each template is built to promote engagement between your business and your target audience.';
  }
}
</script>

<style lang="scss" scoped>
.menu-overlay__header-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.menu-campaigns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-self: flex-start;
  gap: var(--gap-size-medium);
}

.slide-left {
  &-enter-active {
    transition: opacity 300ms linear, transform 275ms ease;
    transform: translateY(0);
    opacity: 1;
  }

  &-enter {
    opacity: 0;
    transform: translateX(20px);
  }
}
</style>
