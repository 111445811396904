<template>
  <layout-template :template="{ name: 'internal-server-error', params: {} }" />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import LayoutTemplate from '@/components/LayoutTemplate.vue';

@Component({
  components: { LayoutTemplate },
  name: 'page-internal-error'
})
export default class PageInternalError extends Vue {}
</script>
