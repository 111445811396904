<template>
  <p-form-color-picker
    :label="element.properties.label"
    :help-text="element.properties.help"
    :help-in-tooltip="element.properties.helpInTooltip"
    :disabled="element.properties.disabled"
    :color-scheme="element.properties.colorScheme"
    :error="element.properties.errors.join(', ')"
    v-model="element.properties.value"
    @interact="onInteract()"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementColorpicker } from '@/interfaces/element';
import { Trigger } from '@/Trigger';
import debounce from 'lodash-decorators/debounce';
import { cancelBlueprintUpdate } from '@/utility/cancelBlueprintUpdate';
import { Iframe } from '@/iframe';

@Component({
  name: 'layout-element-colorpicker'
})
export default class extends Vue {
  @Prop() public element!: IElementColorpicker;

  public didInteract = false;
  private didInteractTimer: number | null = null;

  onInteract() {
    this.didInteract = true;

    // Cancel existing blueprint calls so that a update doesn't come through if user types a bit slow
    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      cancelBlueprintUpdate(this.$el);
    }

    if (this.didInteractTimer !== null) {
      clearTimeout(this.didInteractTimer);
    }

    this.didInteractTimer = setTimeout(() => {
      this.didInteract = false;
      this.didInteractTimer = null;
    }, 500);
  }

  @Watch('element.properties.value')
  onFastValueChange() {
    if (this.element.properties.name && this.didInteract) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }
  }

  @Watch('element.properties.value')
  @debounce(275)
  onValueChange() {
    if (this.didInteract) {
      this.$el.dispatchEvent(
        new CustomEvent('BLUEPRINT_INTERACT', {
          bubbles: true,
          composed: true
        })
      );
    }

    if (this.didInteract && this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(
        this.element.properties.trigger,
        this.$el,
        this.element.properties.name,
        this.element.properties.value
      );
    }
  }
}
</script>
