<template>
  <p-tooltip
    v-if="element.properties.tooltip"
    :text="element.properties.tooltip.text"
    :position="element.properties.tooltip.position"
  >
    <p-paragraph :typography="element.properties.typography">{{ element.properties.text }}</p-paragraph>
  </p-tooltip>
  <p-paragraph :typography="element.properties.typography" v-else>{{ element.properties.text }}</p-paragraph>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementParagraph } from '@/interfaces/element';

@Component({
  name: 'layout-element-paragraph'
})
export default class extends Vue {
  @Prop() public element!: IElementParagraph;
}
</script>
