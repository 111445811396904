<template>
  <p-form-time-picker
    :label="element.properties.label"
    :help-text="element.properties.help"
    :error="element.properties.errors.join(', ')"
    :disabled="element.properties.disabled"
    :seconds="element.properties.seconds"
    v-model="element.properties.value"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementTimepicker } from '@/interfaces/element';
import { Trigger } from '@/Trigger';
import { Iframe } from '@/iframe';

@Component({
  name: 'layout-element-timepicker'
})
export default class extends Vue {
  @Prop() public element!: IElementTimepicker;

  @Watch('element.properties.value')
  onFastValueChange(newValue: string | null, oldValue: string | null) {
    if (!newValue && !oldValue) {
      return;
    }

    if (this.element.properties.name) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }
  }

  @Watch('element.properties.value')
  onValueChange(newValue: string | null, oldValue: string | null) {
    if (!newValue && !oldValue) {
      return;
    }

    this.$el.dispatchEvent(
      new CustomEvent('BLUEPRINT_INTERACT', {
        bubbles: true,
        composed: true
      })
    );

    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(
        this.element.properties.trigger,
        this.$el,
        this.element.properties.name,
        this.element.properties.value
      );
    }
  }
}
</script>
