<template>
  <transition name="transition" duration="275" appear>
    <div v-if="show" class="menu-overlay" ref="container">
      <div class="menu-overlay__backdrop" @click.stop.prevent="$emit('close-request')"></div>
      <div class="menu-overlay__content">
        <div class="menu-overlay__content-inner">
          <div class="menu-overlay__header">
            <div class="menu-overlay__header-info" v-if="title || description">
              <p-container gap-size="small">
                <div class="menu-overlay__header-headline" v-if="title">
                  <p-transition v-device-desktop animation="fade-up" :duration="100">
                    <p-headline size="2" :key="title">{{ title }}</p-headline>
                  </p-transition>

                  <p-headline v-device-mobile size="4" :key="title">{{ title }}</p-headline>
                </div>

                <div class="menu-overlay__header-description" v-if="description">
                  <p-transition animation="fade-up" :duration="100">
                    <p-paragraph :key="description">{{ description }}</p-paragraph>
                  </p-transition>
                </div>
              </p-container>
            </div>

            <div class="menu-overlay__header-toolbar" v-if="$slots.header">
              <slot name="header" />
            </div>
          </div>

          <hr v-if="bodyBorder" />

          <div class="menu-overlay__body">
            <slot />

            <hr v-if="bodyBorder && $slots.footer" />

            <div class="menu-overlay__footer" v-if="$slots.footer">
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class extends Vue {
  @Prop({ type: Boolean, required: false, default: true }) public readonly show!: boolean;
  @Prop({ type: String, required: true }) public readonly title!: string;
  @Prop({ type: String, required: false }) public readonly description!: string;
  @Prop({ type: Boolean, required: false, default: true }) public readonly bodyBorder!: boolean;

  @Watch('show')
  public onShowChange() {
    if (this.show) {
      this.$nextTick(() => {
        setTimeout(() => {
          document.body.addEventListener('click', this.closeDetection);
        });
      });
    } else {
      document.body.removeEventListener('click', this.closeDetection);
    }
  }

  public unmounted() {
    document.body.removeEventListener('click', this.closeDetection);
  }

  public closeDetection(e: MouseEvent) {
    if (
      this.$refs.container instanceof HTMLDivElement &&
      e.target instanceof Node &&
      !this.$refs.container.contains(e.target as Node)
    ) {
      this.$emit('close-request');
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/mixins/typography';
@import '../../../../scss/mixins/devices';

.menu-overlay {
  color: var(--text-color-default);

  &__backdrop {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-background-fade);
    color: var(--color-text-default);
  }

  &__content {
    background: var(--color-background-layer-2);
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;

    &-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 1200px;
      padding: 40px 10px;
      margin: 0 auto;
      max-width: 100%;
      gap: var(--gap-size-large);
    }
  }

  hr {
    border-color: var(--color-border-decorative);
    width: 100%;
    margin: 0;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--gap-size-small);
    align-self: stretch;
    justify-content: space-between;
    position: relative;

    &-info {
      width: 590px;
      flex-shrink: 1;
    }

    &-headline {
      width: 100%;
      height: 55px;
      position: relative;

      &-text {
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    &-description {
      width: 100%;
      height: 55px;
      position: relative;

      &-text {
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    &-toolbar {
      align-self: flex-end;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: var(--gap-size-large);
    width: 100%;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    gap: var(--gap-size-medium);
  }
}

.transition {
  &-enter-active {
    > .menu-overlay__backdrop {
      transition: opacity 275ms linear;
      opacity: 1;
    }

    > .menu-overlay__content {
      transition: opacity 300ms linear, transform 275ms ease;
      transform: translateY(0);
      opacity: 1;
    }
  }

  &-enter {
    > .menu-overlay__backdrop {
      opacity: 0;
    }

    > .menu-overlay__content {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  &-leave-active {
    > .menu-overlay__backdrop {
      transition: opacity 300ms linear;
      opacity: 0;
    }

    > .menu-overlay__content {
      transition: opacity 275ms linear, transform 275ms ease;
      transform: translateY(0);
      opacity: 0;
    }
  }

  &-leave {
    > .menu-overlay__backdrop {
      opacity: 1;
    }

    > .menu-overlay__content {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@include for-mobile-only {
  .menu-overlay {
    &__content {
      &-inner {
        padding: 20px;
      }
    }

    &__header {
      flex-wrap: wrap;

      &-headline {
        height: auto;
      }

      &-info {
        width: 100%;
        flex-shrink: 0;
      }
    }

    &__footer {
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}
</style>
