<template>
  <p-modal-plain v-if="show" :show.prop="true" position="top" headline="Edit Image" @close-request="cancelImage">
    <p-row justify-content="center">
      <p-column size="medium">
        <p-container>
          <!-- Image Source Input -->
          <p-form-input
            v-model="imageSource"
            label="Image Source"
            placeholder="Enter image source (URL)"
            size="medium"
          />

          <!-- Alt Text Input -->
          <p-form-input v-model="altText" label="Alt Description" placeholder="Alt description" size="medium" />

          <!-- Width and Height Inputs -->
          <p-row>
            <p-form-input v-model="imageWidth" label="Width" type="text" placeholder="Image width" size="small" />
            <p-form-input v-model="imageHeight" label="Height" type="text" placeholder="Image height" size="small" />
          </p-row>
          <p-row justify-content="flex-end">
            <p-button type="default" color-type="tertiary" @click="cancelImage">Cancel</p-button>
            <p-button type="default" color-type="primary" @click="insertImage"> Update </p-button>
          </p-row>
        </p-container>
      </p-column>
    </p-row>
  </p-modal-plain>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class ImageDetailModal extends Vue {
  @Prop({ type: Boolean, required: true }) public readonly show!: boolean;

  @Prop({ type: String, required: false }) public readonly imageSourceProp!: string;
  @Prop({ type: String, required: false }) public readonly altTextProp!: string;
  @Prop({ type: Number, required: false }) public readonly imageWidthProp!: number;
  @Prop({ type: Number, required: false }) public readonly imageHeightProp!: number;

  public imageSource = '';
  public altText = '';
  public imageWidth: number | null = null;
  public imageHeight: number | null = null;

  // Watch for when the modal becomes visible, and load data from props
  @Watch('show', { immediate: true })
  public onShowImageModalChanged(newVal: boolean) {
    if (newVal) {
      this.imageSource = this.imageSourceProp || '';
      this.altText = this.altTextProp || '';
      this.imageWidth = this.imageWidthProp || null;
      this.imageHeight = this.imageHeightProp || null;
    }
  }

  public cancelImage() {
    this.$emit('close-request');
  }

  public insertImage() {
    const imageData = {
      imageSource: this.imageSource,
      altText: this.altText,
      imageWidth: this.imageWidth,
      imageHeight: this.imageHeight
    };
    this.$emit('insert-image', imageData);
  }
}
</script>
