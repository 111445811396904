<template>
  <div class="maintenance">
    <p-container align-items="center" gap-size="medium">
      <img class="maintenance__logo" :src="logo" alt="" />
      <p-headline size="1">We will be back soon...</p-headline>
      <p-headline size="4">
        Playable is down for scheduled maintenance and expect to be back in a short time.
      </p-headline>
    </p-container>

    <p-container align-items="center" class="maintenance__floating">
      <img class="astronaut" :src="astronaut" alt="" />
      <img class="astronaut-bg" :src="astronautBg" alt="" />
    </p-container>

    <p-row justify-content="center" gap-size="extra-small" align-items="center">
      <p-paragraph>In the meantime checkout some of our customer cases on the website -</p-paragraph>

      <p-link href="https://www.playable.com/customers-stories" target="_blank">
        <p-button color-type="tertiary">go to www.playable.com/customers-stories</p-button>
      </p-link>
    </p-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require('@/assets/images/logo-front.svg');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const astronaut = require('@/assets/images/astronaut.png');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const astronautBg = require('@/assets/images/astronaut-bg.png');

@Component({
  name: 'page-maintenance'
})
export default class extends Vue {
  public readonly logo = logo;
  public readonly astronaut = astronaut;
  public readonly astronautBg = astronautBg;

  private timer: number | undefined;

  public mounted() {
    this.checkForMaintenance();
  }

  public unmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  private checkForMaintenance() {
    this.timer = window.setTimeout(async () => {
      const response = await fetch('/api/v1/up');

      if (response.status !== 503) {
        await this.$router.push({ path: '/' });
        return;
      }

      this.checkForMaintenance();
    }, 3000);
  }
}
</script>

<style lang="scss" scoped>
.maintenance {
  background: var(--color-blue-700);
  height: 100%;
  min-height: 100vh;
  --text-color-headline: var(--color-base-400);
  --text-color-default: var(--color-base-400);
  padding-top: var(--gap-size-extra-large);
  text-align: center;
  padding-bottom: 100px;

  &__logo {
    max-width: 240px;
    margin: 0 auto;
  }

  &__floating {
    position: relative;
    margin: 50px 0;

    .astronaut {
      animation-name: floating;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      position: absolute;
      top: 120px;
      left: auto;
      margin-right: 170px;
    }
  }

  @keyframes floating {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, 15px);
    }
    100% {
      transform: translate(0, -0px);
    }
  }
}
</style>
