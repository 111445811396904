<template>
  <div class="not-found">
    <p-row justify-content="center">
      <p-column size="large" align-items="center">
        <p-headline size="3">Page was not found</p-headline>
        <p-headline>404</p-headline>
      </p-column>
    </p-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ILayoutTemplate } from '@/interfaces/template';
import { PropType } from 'vue';

@Component({
  name: 'template-not-found'
})
export default class extends Vue {
  @Prop({ type: Object as PropType<ILayoutTemplate> }) public readonly template!: ILayoutTemplate;
}
</script>

<style lang="scss" scoped>
.not-found {
  --text-color-headline: var(--color-base-500);
  padding-top: var(--gap-size-extra-large);
}
</style>
