<template>
  <p-modal-plain position="top" headline="AI" :show.prop="true" @close-request="$emit('close-request')">
    <p-container v-if="errorText !== ''">
      <p-message type="negative" :description="errorText" icon="alert-triangle" />
    </p-container>

    <p-container align-items="center" gap-size="extra-large">
      <p-icon icon="magic-wand" size="extra-large" />

      <div v-if="!modeCustomPrompt && !showOriginalContent">
        <p-container gap-size="extra-large">
          <p-container gap-size="extra-large">
            <p-form-element
              label="Content"
              helpText="Please be aware that content generated by AI may not always be accurate"
            >
              <p-form-textarea v-model="producedText" />
              <p-button type="default" color-type="tertiary" size="small" @click="handleResetContent"
                >Revert to original content</p-button
              >
              <p-button type="default" color-type="tertiary" size="small" @click="viewOriginalContent"
                >Show original content</p-button
              >
            </p-form-element>
          </p-container>

          <p-container gap-size="extra-large">
            <p-row gap-size="medium">
              <p-badge
                text="Streamline text"
                :disabled.prop="languageCurrent === ''"
                clickable
                @click="textualRevision('streamline')"
              />
              <p-badge
                text="Condense text"
                :disabled.prop="languageCurrent === ''"
                clickable
                @click="textualRevision('condense')"
              />
              <p-badge
                text="Expand text"
                :disabled.prop="languageCurrent === ''"
                clickable
                @click="textualRevision('expand')"
              />
              <p-badge
                text="Proofread and fix grammar"
                :disabled.prop="languageCurrent === ''"
                clickable
                @click="textualRevision('proofread')"
              />
            </p-row>
          </p-container>

          <p-container gap-size="extra-large">
            <p-form-element label="Working Language" helpText="Set to enable textual editing">
              <p-select
                placeholder="Select language..."
                searchable
                :error.prop="!languageCurrent"
                @select="languageCurrent = $event.detail[0]"
              >
                <p-select-option
                  v-for="option in languageOptions"
                  :key="option.value"
                  :value="option.value"
                  :selected.prop="option.value === languageCurrent"
                  >{{ option.text }}</p-select-option
                >
              </p-select>
            </p-form-element>
            <p-form-element label="Translate" helpText="Choose the language you would like to convert the content into">
              <p-select placeholder="Select language..." searchable @select="languageTo = $event.detail[0]">
                <p-select-option
                  v-for="option in languageOptions"
                  :key="option.value"
                  :value="option.value"
                  :selected.prop="option.value === languageTo"
                  >{{ option.text }}</p-select-option
                >
              </p-select>
            </p-form-element>
          </p-container>

          <p-container gap-size="large">
            <p-row justify-content="center" gap-size="large">
              <p-button color-type="secondary" size="medium" @click="handleNewPrompt()">New prompt</p-button>
              <p-button size="medium" @click="handleInsertContent()">Insert content</p-button>
            </p-row>
          </p-container>
        </p-container>
      </div>
      <div v-if="modeCustomPrompt">
        <p-container gap-size="large">
          <p-container gap-size="extra-large">
            <p-form-element
              label="Allow AI to work its magic"
              helpText="Please be aware that content generated by AI may not always be accurate"
            >
              <p-form-textarea placeholder="Enter your prompt..." v-model="promptText" />
            </p-form-element>
          </p-container>
          <p-container gap-size="extra-large">
            <p-row justify-content="center" gap-size="large">
              <p-button size="medium" @click="customPrompt()">Send prompt</p-button>
              <p-button size="medium" color-type="tertiary" @click="closeCustomPrompt()">Back</p-button>
            </p-row>
          </p-container>
        </p-container>
      </div>
      <div v-if="showOriginalContent">
        <p-container gap-size="large">
          <p-container gap-size="extra-large">
            <p-form-element label="Content">
              <p-form-textarea readonly v-model="modelValue" />
            </p-form-element>
          </p-container>
          <p-container gap-size="extra-large">
            <p-row justify-content="center" gap-size="large">
              <p-button size="medium" @click="closeCustomPrompt()">Close</p-button>
            </p-row>
          </p-container>
        </p-container>
      </div>
    </p-container>

    <p-modal-loader v-if="modeLoading" />
  </p-modal-plain>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { AppRequest } from '@/app_request';
import { DropdownOption } from '../../form/types';
import axios from 'axios';

interface languageOptionResponse {
  data: languageOptionData[];
}

interface languageOptionData {
  text: string;
  option: string;
  value: string;
}

interface detectLanguageResponse {
  data: string;
}

interface translationResponse {
  data: string;
}

interface textualRevisionResponse {
  data: string;
}

interface customPromptResponse {
  data: string;
}

@Component({
  name: 'p-modal-ai',
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: String, required: false }) public readonly modelValue?: string;

  public errorText = '';
  public producedText = ''; // Modified product
  public promptText = ''; // Custom prompt text
  public languageCurrent = localStorage.getItem('languageCurrent') || ''; // Sets working language
  public languageTo = ''; // Language for translation
  public languageOptions: DropdownOption[] = []; // Placeholder for all language options from API

  public modeCustomPrompt = false; // Toggle modals
  public modeLoading = false; // Toggle loading state

  public showOriginalContent = false;

  public handleInitialLoad() {
    this.producedText = this.modelValue || '';
  }

  public handleNewPrompt() {
    this.modeCustomPrompt = true;
  }

  public handleResetContent() {
    this.producedText = this.modelValue || '';
  }

  public viewOriginalContent() {
    this.showOriginalContent = true;
  }

  public handleInsertContent() {
    this.modeCustomPrompt = false;
    this.$emit('update:modelValue', this.producedText);
    this.$emit('close-request');
  }

  public saveCurrentLanguage() {
    localStorage.setItem('languageCurrent', this.languageCurrent);
  }

  public get isFastTrack() {
    return !!this.$route.params.fastTrackHash;
  }

  public get apiExtendedParams(): Record<string, string> {
    if (this.$route.params.fastTrackHash) {
      return { ['fast-track']: this.$route.params.fastTrackHash };
    }

    return {};
  }

  public apiPath(endpoint: string) {
    return `/api/v1/${this.isFastTrack ? 'fast-track/' : ''}ai/${endpoint}`;
  }

  public handleError(e: unknown) {
    if (
      axios.isAxiosError(e) &&
      e.response &&
      e.response.status === 500 &&
      e.response.data &&
      typeof e.response.data === 'object' &&
      'error' in e.response.data &&
      typeof e.response.data.error === 'string'
    ) {
      this.errorText = e.response.data.error;
      return;
    } else {
      throw e; // bubble up other exceptions so that we get them in sentry
    }
  }

  // Detect language from text to edit
  public async detectLanguage(): Promise<void> {
    if (this.languageCurrent === '') {
      try {
        const response = await AppRequest.post<detectLanguageResponse>(
          this.apiPath('detect-language'),
          {
            text: this.modelValue
          },
          {
            params: this.apiExtendedParams
          }
        );
        // Assuming response.data.data holds the language information
        this.languageCurrent = response.data?.data || '';
      } catch (e) {
        if (axios.isAxiosError(e) && e.response && e.response.status === 404) {
          return;
        }
        this.handleError(e);
      }
      this.saveCurrentLanguage();
    }
  }
  // Translate text to a given language
  public async translateText(): Promise<void> {
    this.modeLoading = true;

    if (!this.producedText.length) {
      return;
    }

    try {
      this.producedText = (
        await AppRequest.post<translationResponse>(
          this.apiPath('translate'),
          {
            text: this.producedText,
            language_to: this.languageTo
          },
          {
            params: this.apiExtendedParams
          }
        )
      ).data?.data;
    } catch (e) {
      this.handleError(e);
    }

    this.languageCurrent = this.languageTo;
    this.saveCurrentLanguage();
    this.modeLoading = false;
  }

  // Fetch available languages for translation
  public async getTranslationOptions(): Promise<void> {
    try {
      const response = (
        await AppRequest.post<languageOptionResponse>(
          this.apiPath('translate/options'),
          {},
          {
            params: this.apiExtendedParams
          }
        )
      ).data?.data;

      this.languageOptions = response.map<DropdownOption>((option: languageOptionData) => ({
        text: option.text,
        value: option.value
      }));
    } catch (e) {
      this.handleError(e);
    }
  }

  // Supports multiple endpoint for text editing
  public async textualRevision(endpoint: string): Promise<void> {
    if (!this.producedText.length || this.languageCurrent === '') {
      return;
    }
    this.modeLoading = true;
    try {
      this.producedText = (
        await AppRequest.post<textualRevisionResponse>(
          this.apiPath(endpoint),
          {
            text: this.producedText,
            working_language: this.languageCurrent
          },
          {
            params: this.apiExtendedParams
          }
        )
      ).data?.data;
    } catch (e) {
      this.handleError(e);
    }

    this.modeLoading = false;
  }

  // Custom prompt
  public async customPrompt(): Promise<void> {
    if (!this.promptText.length) {
      return;
    }
    this.modeLoading = true;
    try {
      this.producedText = (
        await AppRequest.post<customPromptResponse>(
          this.apiPath('prompt'),
          {
            text: this.promptText
          },
          {
            params: this.apiExtendedParams
          }
        )
      ).data?.data;

      this.modeCustomPrompt = false;
    } catch (e) {
      this.handleError(e);
    }

    this.modeLoading = false;
  }

  public closeCustomPrompt() {
    this.modeCustomPrompt = false;
    this.showOriginalContent = false;
  }

  public async mounted(): Promise<void> {
    this.handleInitialLoad();
    await this.detectLanguage();
    await this.getTranslationOptions();
  }

  @Watch('languageCurrent')
  onLanguageCurrentChange(selectedLanguage: string) {
    this.languageCurrent = selectedLanguage;
    this.saveCurrentLanguage();
  }

  @Watch('languageTo')
  onLanguageToChange(selectedLanguage: string) {
    this.languageTo = selectedLanguage;
    this.translateText();
  }
}
</script>
