import {
  IElementNavigator,
  IElementStepGuide,
  IElementTabs,
  ILayoutTypeElement,
  ILayoutTypeElements
} from '@/interfaces/element';
import { traverseElements } from './traverseElements';

export function traverseElementChild(child: ILayoutTypeElement, fn: (element: ILayoutTypeElement) => void) {
  fn(child);

  if ('children' in child && typeof child.children !== 'undefined' && child.children !== null) {
    traverseElements(child.children, fn);
  }

  if (
    'properties' in child &&
    typeof child.properties !== 'undefined' &&
    'body' in child.properties &&
    typeof child.properties.body !== 'undefined'
  ) {
    child.properties.body.forEach((row) => {
      for (const columnKey in row.columns) {
        // eslint-disable-next-line no-prototype-builtins
        if (row.columns.hasOwnProperty(columnKey) && typeof row.columns[`${columnKey}`].column === 'object') {
          traverseElements(row.columns[`${columnKey}`].column as ILayoutTypeElements, fn);
        }
      }
    });
  }

  if ('properties' in child && 'tabs' in child.properties) {
    (child as IElementTabs).properties.tabs.forEach((tab) => {
      if (tab.content !== null) {
        traverseElements(tab.content, fn);
      }
    });
  }

  if (child.type === 'settings-navigation' && 'properties' in child && 'items' in child.properties) {
    child.properties.items.forEach((tab) => {
      if (tab.content !== null) {
        traverseElements(tab.content, fn);
      }
    });
  }

  if (child.type === 'navigator' && 'properties' in child && 'items' in child.properties) {
    (child as IElementNavigator).properties.items.forEach((item) => {
      traverseElements(item.trigger, fn);
      traverseElements(item.content, fn);
    });
  }

  if ('properties' in child && 'steps' in child.properties) {
    (child as IElementStepGuide).properties.steps.forEach((step) => {
      traverseElementChild(step.element, fn);
    });
  }
}
