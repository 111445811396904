<template>
  <div class="error">
    <p-row justify-content="center">
      <p-column size="extra-large">
        <p-container align-items="center" gap-size="large">
          <p-headline size="2">Your Role Does Not Grant Access</p-headline>

          <p-headline size="5">
            We're sorry, but it seems you do not have the necessary permissions to access this page.<br /><br />
            Please contact your administrator to request the appropriate access or explore other areas of the
            application that are available to your role
          </p-headline>

          <p-link href="/dashboard">
            <p-button color-type="tertiary">Go to Dashboard</p-button>
          </p-link>
        </p-container>
      </p-column>
    </p-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ILayoutTemplate } from '@/interfaces/template';
import { PropType } from 'vue';

@Component({
  name: 'template-unauthorized'
})
export default class extends Vue {
  @Prop({ type: Object as PropType<ILayoutTemplate> }) public readonly template!: ILayoutTemplate;
}
</script>

<style lang="scss" scoped>
.error {
  --text-color-headline: var(--color-base-500);
  padding-top: var(--gap-size-extra-large);
  text-align: center;
}
</style>
