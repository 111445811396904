<template>
  <menu-overlay :show="show" title="Workspace" @close-request="$emit('close-request')" :body-border="false">
    <p-table-client
      searchable
      searchable-autofocus
      pagination
      :header="[
        {
          id: 'name',
          label: 'Name',
          width: '400px',
          sortable: true,
          verticalAlign: 'middle'
        },
        {
          id: 'contact_name',
          label: 'Contact name',
          width: '180px',
          sortable: true,
          verticalAlign: 'middle'
        },
        {
          id: 'contact_email',
          label: 'Contact email',
          width: '180px',
          sortable: true,
          verticalAlign: 'middle'
        },
        {
          id: 'actions',
          verticalAlign: 'middle'
        }
      ]"
      :body="workspaces"
      :skeleton-loader="loading"
    >
      <template #bodyCell="{ header, row, column }">
        <div v-if="header.id === 'name'" class="customer-name">{{ column }}</div>

        <p-link v-else-if="header.id === 'actions'" :href="`/api/v1/workspace/${row.uuid}/impersonate`">
          <p-button color-type="tertiary" size="small" :disabled="row.id === workSpaceStore.workspace?.id"
            >Login</p-button
          >
        </p-link>

        <template v-else>{{ column }}</template>
      </template>
    </p-table-client>
  </menu-overlay>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import MenuOverlay from './MenuOverlay.vue';
import { AppRequest } from '@/app_request';
import { WorkspaceResource } from '@/types/api/workspace';
import { useWorkspaceStore } from '@/store/workspaceStore';

@Component({
  components: {
    MenuOverlay
  }
})
export default class extends Vue {
  @Prop({ type: Boolean, required: false, default: true }) public readonly show!: boolean;

  public loading = true;
  public workspaces: WorkspaceResource[] = [];
  public workSpaceStore = useWorkspaceStore();

  public async mounted() {
    this.workspaces = (await AppRequest.get<{ data: WorkspaceResource[] }>('/api/v1/workspace')).data.data;
    this.loading = false;
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/mixins/typography';

::v-deep {
  .customer-name {
    @include component-text-strong;

    color: var(--text-color-headline);
  }
}
</style>
